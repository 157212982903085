import Cookies from 'js-cookie';
// session cookies
//  RK_init = true | undefined
//  RK_healthcheck = "ok" | "unavailable"
//  RK_sessions = (ex){"6c01":"5e8269f6-6c01-465e-b9dc-91ea7f84ce08"}
//      RK_6c01_id = "5e8269f6-6c01-465e-b9dc-91ea7f84ce08"
//      RK_6c01_status = "open" | "close"
//      RK_6c01_token = "eyJhbGciOK..."
//      RK_6c01_data = (ex){
//          "email": "ybuffet@gmail.com",
//          "public": true,
//          "status": "open",
//          "last_name": "buffet",
//          "first_name": "yann",
//          "newsletter": false,
//          "email_status": true,
//          "phone_number_status": false
//      }

export default class Init {
    /**
     * @function set
     * @definition init session cookies (only if not exist)
     * @returns {Promise}
     */
    static set() {
        return new Promise((resolve, reject) => {
            // missing RGPD cookies !!!
            if (!Cookies.get('RK_init')) {
                this.reset();
                resolve();
            } else {
                // cookies allready exist
                resolve();
            }
        });
    }
    /**
     * @function reset
     * @definition users session hard reset used (session will be closed)
     * @returns {Promise}
     */
    static reset() {
        const ck = Cookies.get();
        for (const key of Object.keys(ck)) {
            if (key.split('_')[0] === 'RK') Cookies.remove(key);
        }
        Cookies.set('RK_init', true);
        Cookies.set('RK_lang', /^fr\b/.test(navigator.language) ? 'fr' : 'en');
        Cookies.set('RK_healthcheck', 'unavailable');
        return true;
    }
}
