import { Container, Navbar, Button, Form, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import Validator from '../../util/Validator';
import ApiCallConfig from '../../util/ApiCallConfig';
import Logo from '../commun/Logo';
import Echo from '../../util/Echo';
import { FiX } from 'react-icons/fi';
import { useMatomo } from '@datapunt/matomo-tracker-react';
export default () => {
    const activeUserIndex = useSelector((state) => state.user.activeUserIndex);
    const users = useSelector((state) => state.user.users);
    const activeUser = users[activeUserIndex];
    const { trackPageView, trackLink, trackEvent } = useMatomo();
    const [payload, setPayload] = useState({
        first_name: activeUser?.first_name || '',
        last_name: activeUser?.last_name || '',
        contact_email: activeUser?.email || '',
        contact_subject: '',
        contact_message: '',
    });
    const [sent, setSent] = useState({ form: 'd-block', message: 'd-none' });
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const handleChange = (event) => {
        setPayload({ ...payload, [event.target.name]: event.target.value });
        if (Object.keys(errors).length !== 0 || errors.constructor !== Object) {
            const e = Validator({
                ...payload,
                [event.target.name]: event.target.value,
            });
            if (e) setErrors(e);
        }
    };
    const handleSubmit = (event) => {
        console.log(payload);
        event.preventDefault();
        const e = Validator(payload);
        if (Object.keys(e).length !== 0 || e.constructor !== Object) {
            setErrors(e);
        } else {
            Axios({
                ...ApiCallConfig,
                method: 'POST',
                url: '/contact',
                data: payload,
            })
                .then((result) => {
                    if (result.status === 201) {
                        setSent({ form: 'd-none', message: 'd-block' });
                    } else if (result.status === 429) {
                        setMessage(
                            '[' +
                                result.status +
                                '] Trop de tentatives. Veuillez patienter quelques instants puis réessayez.'
                        );
                    } else {
                        setMessage(
                            '[' + result.status + '] Un problème est survenu.'
                        );
                    }
                    trackEvent({
                        category: 'contact',
                        action: 'send',
                        name: 'Contactez-nous !',
                        value: result.status === 201 ? 1 : 0,
                    });
                })
                .catch((error) => {
                    setMessage('[500] Un problème est survenu.');
                });
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        trackPageView();
        document.title = Echo().seo.title.contact
            ? Echo().seo.title.prefix +
              Echo().seo.title.contact +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, []);
    return (
        <Container fluid>
            <Navbar className="fixed-top rankio-header" bg="white" expand="xl">
                <Link to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </Link>
                <Link to="/" className="ml-auto">
                    <FiX size="1.5em" className="rk-clickable-icon-dark" />
                </Link>
            </Navbar>
            <div className="landing">
                <Row className="p-4">
                    <Col sm={1} md={2} lg={3} xxl={4}></Col>
                    <Col sm={10} md={8} lg={6} xxl={4} className={sent.message}>
                        <h1 className="font-weight-800 display-28 text-center my-4">
                            Merci !
                        </h1>
                        <div className="action-label text-center py-4">
                            Votre message a bien été envoyé.
                        </div>
                        <div className="descr-label text-center">
                            Nous vous répondrons très vite.
                        </div>
                    </Col>
                    <Col sm={10} md={8} lg={6} xxl={4} className={sent.form}>
                        <h1 className="font-weight-800 display-28 text-center mb-4">
                            Contactez-nous !
                        </h1>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group controlId="first_name">
                                        <Form.Label className="input-label mt-3">
                                            Prénom
                                        </Form.Label>
                                        <Form.Control
                                            name="first_name"
                                            type="text"
                                            placeholder="Prénom"
                                            value={payload.first_name}
                                            className="input text-capitalize"
                                            onChange={handleChange}
                                        />
                                        <Form.Text className="mt-2 ml-2 text-danger font-weight-700">
                                            {errors.first_name}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="last_name">
                                        <Form.Label className="input-label mt-3">
                                            Nom
                                        </Form.Label>
                                        <Form.Control
                                            name="last_name"
                                            type="text"
                                            placeholder="Nom"
                                            value={payload.last_name}
                                            className="input text-capitalize"
                                            onChange={handleChange}
                                        />
                                        <Form.Text className="mt-2 ml-2 text-danger font-weight-700">
                                            {errors.last_name}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="contact_email">
                                <Form.Label className="input-label mt-3">
                                    E-mail
                                </Form.Label>
                                <Form.Control
                                    name="contact_email"
                                    type="email"
                                    placeholder="nom@entreprise.com"
                                    value={payload.contact_email}
                                    className="input"
                                    onChange={handleChange}
                                />
                                <Form.Text className="mt-2 ml-2 text-danger font-weight-700">
                                    {errors.contact_email}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="contact_subject">
                                <Form.Label className="input-label mt-3">
                                    Sujet
                                </Form.Label>
                                <Form.Control
                                    name="contact_subject"
                                    type="text"
                                    placeholder="Hello"
                                    value={payload.contact_subject}
                                    className="font-weight-600 input"
                                    onChange={handleChange}
                                />
                                <Form.Text className="mt-2 ml-2 text-danger font-weight-700">
                                    {errors.contact_subject}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="contact_message">
                                <Form.Label className="input-label mt-3">
                                    Message
                                </Form.Label>
                                <Form.Control
                                    name="contact_message"
                                    as="textarea"
                                    rows={5}
                                    placeholder="Hello !"
                                    value={payload.message}
                                    className="font-weight-600 input"
                                    onChange={handleChange}
                                />
                                <Form.Text className="mt-2 ml-2 text-danger font-weight-700">
                                    {errors.contact_message}
                                </Form.Text>
                            </Form.Group>
                            <center>
                                <Button
                                    className="btn-lg btn-primary w-100 mt-4"
                                    style={{
                                        height: '5rem',
                                        maxWidth: '340px',
                                    }}
                                    type="submit"
                                >
                                    <span className="h2 font-weight-800 display-24">
                                        Envoyer
                                    </span>
                                </Button>
                            </center>
                            <Form.Group>
                                <Form.Text className="text-danger">
                                    {message}
                                </Form.Text>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col sm={1} md={2} lg={3} xxl={4}></Col>
                </Row>
            </div>
        </Container>
    );
};
