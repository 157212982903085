import Axios from 'axios';
import ApiCallConfig from './ApiCallConfig';
import { Row, Col } from 'react-bootstrap';
import { FiCheck } from 'react-icons/fi';
export default class Plan {
    /**
     * @function get
     * @definition Get plan
     * @returns {Promise}
     */
    static get(params) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'GET',
                url: '/plan',
                params,
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    static renderDescription = (plan, text = null) => {
        return (
            <>
                {plan?.object?.description
                    ?.split('  ')
                    .map((feature, index) => {
                        if (index) {
                            return (
                                <>
                                    <Row className="mt-3 v-center mx-0">
                                        <Col xs="auto" className="px-0">
                                            <FiCheck
                                                size="2rem"
                                                className="add-option-check-icon mr-3"
                                            />
                                        </Col>
                                        <Col className="add-option-collapse-first-text display-16 mt-0 ml-0 pl-0">
                                            {feature}
                                        </Col>
                                    </Row>
                                </>
                            );
                        } else {
                            if (text) {
                                return (
                                    <div className="font-weight-bold mb-4">
                                        {text}
                                    </div>
                                );
                            } else {
                                return <div className="mb-4">{feature}</div>;
                            }
                        }
                    })}
            </>
        );
    };
}
