import { Container, Fade, Navbar, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link, useHistory } from 'react-router-dom';
import { validate as isUuid } from 'uuid';
import Subscription from '../../util/Subscription';
import Workspace from '../../util/Workspace';
import Wsjf from '../../util/Wsjf';
import AxePrioBody from '../axePrio/AxePrioBody';
import Logo from '../commun/Logo';
import Echo from '../../util/Echo';
import { FiX } from 'react-icons/fi';
import { IoHelp } from 'react-icons/io5';
import { useMatomo } from '@datapunt/matomo-tracker-react';
export default () => {
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const selectedPlan = activeUser[0].selectedPlan;
    const selectedSubscription = activeUser[0].selectedSubscription;
    const selectedWorkspace = activeUser[0].selectedWorkspace;
    const [next, setNext] = useState('done');
    const [content, setContent] = useState(false);
    const [message, setMessage] = useState('');
    const { trackPageView } = useMatomo();
    useEffect(() => {
        document.title = Echo().seo.title.onboarding
            ? Echo().seo.title.prefix +
              Echo().seo.title.onboarding +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, []);
    useEffect(() => {
        switch (true) {
            case activeUser[0].first_name === undefined:
            case activeUser[0].last_name === undefined:
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/profile');
                }, 1000);
                break;
            case !isUuid(selectedPlan):
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/reset');
                }, 1000);
                break;
            case !isUuid(selectedSubscription):
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/letsgo');
                }, 1000);
            case !isUuid(selectedWorkspace):
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/workspace');
                }, 1000);
                break;
            default:
                Workspace.get({ id: selectedWorkspace }, activeUser[0].token)
                    .then((res) => {
                        if (res.status === 200) {
                            const workspaces = res.data.data.filter(
                                (w) => w.id === selectedWorkspace
                            );
                            dispatch({
                                type: 'UPDATE_WORKSPACE_LIST',
                                value: {
                                    workspace: res.data.data,
                                    id: activeUser[0].id,
                                },
                            });
                            if (workspaces.length) {
                                setContent(true);
                                if (
                                    res.data.data[0].object.status === 'unpaid'
                                ) {
                                    setNext('checkout');
                                }
                            } else {
                                history.push('/' + id + '/onboarding/reset');
                            }
                        } else if (res.status === 404) {
                            history.push('/' + id + '/onboarding/reset');
                        } else {
                            setMessage(
                                'Une erreur est survenue, veuillez réessayer.'
                            );
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                        }
                    })
                    .catch((msg) => {
                        setMessage(
                            'Une erreur est survenue, veuillez réessayer.'
                        );
                        setTimeout(() => {
                            window.location.reload();
                        }, 20000);
                    });
        }
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <Navbar className="fixed-top rankio-header" bg="white" expand="xl">
                <Link to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </Link>
                <div className="ml-auto">
                    <a
                        title="Nous contacter"
                        href="/contact"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="mr-4"
                    >
                        <IoHelp
                            size="1.5em"
                            className="rk-clickable-icon-dark"
                        />
                    </a>
                    <Link to={'/'}>
                        <FiX
                            title="Accueil"
                            size="1.5em"
                            className="rk-clickable-icon-dark"
                        />{' '}
                    </Link>
                </div>
            </Navbar>
            <Fade in={content}>
                <div className="landing">
                    <Row className="p-4">
                        <Col sm={1} md={1} lg={2} xxl={4}></Col>
                        <Col sm={11} md={11} lg={9} xxl={7}>
                            <h3 className="font-weight-800 mt-4 mb-5">
                                Vous pouvez personnaliser les axes de
                                priorisation au plus proche de votre stratégie
                                d'entreprise&nbsp;!
                                <br />
                            </h3>
                            <p className="font-weight-600">
                                Dans le panneau de configuration ci-dessous,
                                vous trouverez l'ensemble des axes de
                                priorisation par défaut qui forment le modèle
                                WSJF, mais également toute la flexibilité
                                nécessaire pour adapter la valeur business à la
                                logique de votre entreprise&nbsp;:
                            </p>
                            <p className="font-weight-600">
                                - Activer, désactiver ou supprimer des axes
                                <br />
                                - Créer de nouveaux axes stratégiques
                                <br />
                                - Modifier les poids respectifs de chaque axe
                                les uns par rapport aux autres
                                <br />
                            </p>
                            <AxePrioBody onboarding={true} next={next} />
                        </Col>
                    </Row>
                </div>
            </Fade>
        </Container>
    );
};
