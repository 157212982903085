import {
    Container,
    Fade,
    Navbar,
    Button,
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link, useHistory, useLocation } from 'react-router-dom';
import User from '../../util/User';
import Validator from '../../util/Validator';
import Logo from '../commun/Logo';
import Modal from '../modal/Modal';
import Echo from '../../util/Echo';
import { FiLogOut } from 'react-icons/fi';
import { IoHelp } from 'react-icons/io5';
import { useMatomo } from '@datapunt/matomo-tracker-react';
export default () => {
    const { id } = useParams();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const [payload, setPayload] = useState({
        first_name: ' ',
        last_name: ' ',
    });
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const [content, setContent] = useState(false);
    const [showModalLogout, setShowModalLogout] = useState(false);
    const [workspaceId, setWorkspaceId] = useState(null);
    const [subscriptionId, setSubscriptionId] = useState(null);
    const handleChange = (event) => {
        setMessage('');
        setPayload({ ...payload, [event.target.name]: event.target.value });
        if (Object.keys(errors).length !== 0 || errors.constructor !== Object) {
            const e = Validator({
                ...payload,
                [event.target.name]: event.target.value,
            });
            if (e) setErrors(e);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const e = Validator(payload);
        if (Object.keys(e).length !== 0 || e.constructor !== Object) {
            setErrors(e);
        } else {
            User.patch(activeUser[0].token, payload)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch({
                            type: 'UPDATE_USER',
                            value: {
                                ...res.data.data,
                            },
                        });
                        if (!activeUser[0].workspace.length) {
                            history.push('/' + id + '/onboarding');
                        } else {
                            history.push('/' + id + '/dashboard');
                        }
                    } else if (res.status === 204) {
                        history.push('/' + id + '/onboarding');
                    } else {
                        setMessage(res.data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setMessage('Un problème est survenu, veuillez réessayer.');
                });
        }
    };
    const { trackPageView } = useMatomo();
    useEffect(() => {
        document.title = Echo().seo.title.onboarding
            ? Echo().seo.title.prefix +
              Echo().seo.title.onboarding +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, []);
    useEffect(() => {
        User.get({}, activeUser[0].token)
            .then((res) => {
                if (res.status === 200) {
                    setContent(true);
                    setPayload({
                        first_name: res.data.data[0].object.first_name || '',
                        last_name: res.data.data[0].object.last_name || '',
                    });
                } else if (res.status === 404) {
                    history.push('/' + id + '/logout');
                } else {
                    setMessage('Une erreur est survenue, veuillez réessayer.');
                    setTimeout(window.location.reload(), 2000);
                }
            })
            .catch((err) => {
                setMessage('Une erreur est survenue, veuillez réessayer.');
                setTimeout(window.location.reload(), 2000);
            });
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container fluid>
            <Navbar className="fixed-top rankio-header" bg="white" expand="xl">
                <Link to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </Link>
                <div className="ml-auto">
                    <a
                        title="Nous contacter"
                        href="/contact"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="mr-4"
                    >
                        <IoHelp
                            size="1.5em"
                            className="rk-clickable-icon-dark"
                        />
                    </a>
                    <Link
                        onClick={() => {
                            setShowModalLogout(true);
                        }}
                    >
                        <FiLogOut
                            title="Se déconnecter"
                            size="1.5em"
                            style={{ padding: '0.2em' }}
                            className="rk-clickable-icon-dark"
                        />
                    </Link>
                </div>
            </Navbar>
            <Fade in={content}>
                <div className="landing">
                    <Row className="p-4">
                        <Col sm={1} md={2} lg={2} xxl={4}></Col>
                        <Col sm={10} md={8} lg={8} xxl={7}>
                            <h3 className="font-weight-800 mt-4 mb-5">
                                Finalisez la création de votre compte&nbsp;!
                                <br />
                            </h3>
                            <p className="font-weight-600">
                                Il suffit pour cela de renseigner votre nom et
                                prénom.
                            </p>
                        </Col>
                    </Row>
                    <Row className="p-4">
                        <Col sm={1} md={2} lg={2} xxl={4}></Col>
                        <Col sm={10} md={8} lg={5} xxl={4}>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="first_name">
                                    <Form.Label className="input-label">
                                        Prénom
                                    </Form.Label>
                                    <Form.Control
                                        name="first_name"
                                        type="text"
                                        placeholder="Prénom"
                                        value={payload.first_name}
                                        className="input"
                                        onChange={handleChange}
                                    />
                                    <Form.Text className="text-danger font-weight-bold ml-2 mb-4">
                                        {errors.first_name}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group controlId="last_name">
                                    <Form.Label className="input-label">
                                        Nom
                                    </Form.Label>
                                    <Form.Control
                                        name="last_name"
                                        type="text"
                                        placeholder="Nom"
                                        value={payload.last_name}
                                        className="input"
                                        onChange={handleChange}
                                    />
                                    <Form.Text className="text-danger font-weight-bold ml-2 mb-4">
                                        {errors.last_name}
                                    </Form.Text>
                                </Form.Group>
                                <Row className="p-0">
                                    <Col sm={10} md={8} lg={8} xxl={4}>
                                        <Button
                                            className="btn-lg btn-primary h3 font-weight-800 display-24 w-100 mt-5"
                                            style={{
                                                height: '5rem',
                                                maxWidth: '340px',
                                            }}
                                            type="submit"
                                        >
                                            Enregistrer
                                        </Button>
                                    </Col>
                                </Row>
                                <Form.Group>
                                    <Form.Text className="text-danger font-weight-bold">
                                        {message}
                                    </Form.Text>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Fade>
            <Modal
                show={showModalLogout}
                centered={true}
                modalFirstText="Sans avoir complété votre compte, vous allez être déconnecté."
                modalSecondText="êtes-vous sûr de vouloir vous déconnecter ?"
                modalThirdText=""
                modalContainerClassname="axeModalContainerClassname"
                modalContentClassName="axeModalContentClassName"
                closeIcon="axeModalcloseIcon cursorPointer"
                containerTextWithIconClassname="axesContainerTextWithIconClassname"
                alertIconClassName="axesAlertIconClassName"
                firstBtnClassName="axeModalFirstBtn"
                secondBtnClassName="axeModalSecondBtn"
                setShowModalFunction={() => setShowModalLogout(false)}
                textClassName="axeModalTextClassName"
                saveBtnFunction={() => history.push('/' + id + '/logout')}
            />
        </Container>
    );
};
