export default (team) => {
    let firstName = '';
    let lastName = '';
    switch (true) {
        case team === undefined:
            return {
                initials: 'x',
                value: 'Utilisateur supprimé',
                title: '',
            };
        case !team.hasOwnProperty('email'):
            return {
                initials: '',
                value: '',
                title: '',
            };
        case team?.first_name?.length > 0 && team?.last_name?.length > 0:
            firstName = team.first_name.replace(/\b([a-z])/g, (c) => {
                return c.toUpperCase();
            });
            lastName = team.last_name.replace(/\b([a-z])/g, (c) => {
                return c.toUpperCase();
            });
            return {
                initials: firstName[0] + lastName[0],
                value: firstName + ' ' + lastName,
                title: firstName + ' ' + lastName,
            };

        case team.email.split('@')[0].split('.').length === 2:
            firstName = team.email
                .split('@')[0]
                .split('.')[0]
                .replace(/\b([a-z])/g, (c) => {
                    return c.toUpperCase();
                });
            lastName = team.email
                .split('@')[0]
                .split('.')[1]
                .replace(/\b([a-z])/g, (c) => {
                    return c.toUpperCase();
                });
            return {
                initials: firstName[0] + lastName[0],
                value: firstName + ' ' + lastName,
                title: team.email,
            };
        case team.email.split('@')[0].split('-').length === 2:
            firstName = team.email
                .split('@')[0]
                .split('-')[0]
                .replace(/\b([a-z])/g, (c) => {
                    return c.toUpperCase();
                });
            lastName = team.email
                .split('@')[0]
                .split('-')[1]
                .replace(/\b([a-z])/g, (c) => {
                    return c.toUpperCase();
                });
            return {
                initials: firstName[0] + lastName[0],
                value: firstName + ' ' + lastName,
                title: team.email,
            };
        case team.email.split('@')[0].split('_').length === 2:
            firstName = team.email
                .split('@')[0]
                .split('_')[0]
                .replace(/\b([a-z])/g, (c) => {
                    return c.toUpperCase();
                });
            lastName = team.email
                .split('@')[0]
                .split('_')[1]
                .replace(/\b([a-z])/g, (c) => {
                    return c.toUpperCase();
                });
            return {
                initials: firstName[0] + lastName[0],
                value: firstName + ' ' + lastName,
                title: team.email,
            };
        default:
            return {
                initials:
                    team.email[0].toUpperCase() + team.email[1].toUpperCase(),
                value: team.email.split('@')[0],
                title: team.email,
            };
    }
};
