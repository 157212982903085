import Config from '../../util/Config';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TeamData from '../../util/TeamData';
export default (props) => {
    const users = useSelector((state) => state.user.users);
    const { id } = useParams();

    const validateEmail = (value) => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
    };
    let validateEmailDetails = validateEmail(props.image);

    if (props.uuid) {
        var activeUser = users.filter((user) => user.id.includes(props.uuid));
    } else {
        activeUser = users.filter((user) => user.id.includes(id));
    }

    if (validateEmailDetails) {
        return (
            <strong className="text-center font-weight-800 text-uppercase text-lg">
                {props.image[0]}
                {props.image[1]}
            </strong>
        );
    } else if (props.image) {
        return (
            <img
                src={Config.CDN.ENDPOINT + props.image}
                className={props.className}
            />
        );
    } else if (activeUser.length && activeUser[0].image) {
        return (
            <img
                src={Config.CDN.ENDPOINT + activeUser[0].image}
                className={props.className}
            />
        );
    } else {
        const data = TeamData(activeUser[0]);
        return (
            <strong className="text-center font-weight-800 text-uppercase text-lg">
                {data.initials}
            </strong>
        );
    }
};
