import React, { useState, useEffect } from 'react';
import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Modal from '../commun/RkModal';
import BlueButton from '../commun/BlueButton';
import GreyButton from '../commun/GreyButton';
import DarkButton from '../commun/DarkButton';
import TeamIcon from '../commun/TeamIcon';
import { FiInfo, FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import Config from '../../util/Config';
import Echo from '../../util/Echo';
import Alert from '../commun/Alert';
import Project from '../../util/Project';

export default (props) => {
    const { id } = useParams();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = activeUser[0].workspace[activeUser[0].activeWorkspace];
    const writeAcces = ['owner', 'admin'].includes(
        workspace.object.teams.on.filter(
            (team) => team.id === activeUser[0].id
        )[0].role
    );
    const deleteble = writeAcces && props.data?.object?.status !== 'archived';
    const [my] = useState(activeUser[0]);
    const [payload, setPayload] = useState(props.data?.object);
    useEffect(() => {
        setPayload(props.data?.object?.assignation);
    }, [props.data]);

    return (
        <>
            <div className="bg-gray mt-2 font-weight-700 display-24 p-3 mb-3 text-uppercase">
                Assignation
            </div>
            {props.data?.object?.assignation.length ? (
                <Row className="p-0 m-0">
                    <Col className="p-2 pl-3 pb-5 m-0">
                        <div
                            className="m-0 border border-gray radius-14"
                            style={{ minWidth: '560px', maxWidth: '900px' }}
                        >
                            <Table
                                responsive
                                borderless
                                striped
                                className="m-0"
                            >
                                <thead>
                                    <tr>
                                        <th className="font-weight-bold color-gray minem-w-14 pl-5">
                                            <span className="pl-5">
                                                {
                                                    Echo().teams.table.columns
                                                        .first_name
                                                }{' '}
                                                {
                                                    Echo().teams.table.columns
                                                        .last_name
                                                }
                                            </span>
                                        </th>
                                        <th className="font-weight-bold color-gray minem-w-10">
                                            {Echo().teams.table.columns.job}
                                        </th>
                                        <th className="font-weight-bold color-gray minem-w-6">
                                            {Echo().teams.table.columns.role}
                                        </th>
                                        <th
                                            key="rk_refresh"
                                            className="minem-w-5"
                                        >
                                            <div className="d-flex flex-row-reverse pr-4"></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="radius-buttom-14 font-weight-600">
                                    {props.data?.object?.assignation
                                        .map((id) => {
                                            return {
                                                id,
                                                ...props.filterStates
                                                    .assignation[id]?.data,
                                            };
                                        })
                                        .map((team, i) => {
                                            return (
                                                <tr
                                                    className={
                                                        props.data?.object
                                                            ?.assignation
                                                            .length ==
                                                        i + 1
                                                            ? 'radius-buttom-14'
                                                            : ''
                                                    }
                                                >
                                                    <td className="minem-w-14 align-middle px-0 py-2">
                                                        <span className="p-4">
                                                            <TeamIcon
                                                                team={team}
                                                                my={my}
                                                            />
                                                        </span>
                                                        <span className="pl-0">
                                                            {team?.first_name
                                                                ?.length &&
                                                            team?.last_name
                                                                ?.length
                                                                ? team.first_name.replace(
                                                                      /\b([a-z])/g,
                                                                      (c) => {
                                                                          return c.toUpperCase();
                                                                      }
                                                                  ) +
                                                                  ' ' +
                                                                  team.last_name.replace(
                                                                      /\b([a-z])/g,
                                                                      (c) => {
                                                                          return c.toUpperCase();
                                                                      }
                                                                  )
                                                                : ''}
                                                        </span>
                                                    </td>
                                                    <td className="minem-w-10 align-middle p-0">
                                                        {team.job}
                                                    </td>
                                                    <td className="minem-w-6 align-middle p-0">
                                                        {
                                                            Echo().teams.fields
                                                                .role[team.role]
                                                        }
                                                    </td>
                                                    <td
                                                        key="rk_func"
                                                        className="minem-w-5"
                                                    >
                                                        <div className="d-flex flex-row-reverse">
                                                            {deleteble ? (
                                                                <div
                                                                    onClick={() => {
                                                                        props.update(
                                                                            props
                                                                                .data
                                                                                ?.id,
                                                                            {
                                                                                assignation:
                                                                                    props.data?.object.assignation.filter(
                                                                                        (
                                                                                            as
                                                                                        ) =>
                                                                                            as !==
                                                                                            team.id
                                                                                    ),
                                                                            }
                                                                        );
                                                                    }}
                                                                    className="pointer"
                                                                >
                                                                    <FiTrash2
                                                                        size="2rem"
                                                                        color="#999999"
                                                                        className="ml-4"
                                                                    />
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col
                        lg={'auto'}
                        className="flex items-end column axe-priorisation-btn-container p-0 pb-5 mr-2"
                    >
                        {deleteble ? (
                            <BlueButton
                                content={'Ajouter'}
                                onClick={() =>
                                    (document.getElementById(
                                        'view_details_assignment_project'
                                    ).style.display = 'block')
                                }
                                icon={<FiPlusCircle size="2.2rem" />}
                            />
                        ) : (
                            ''
                        )}
                    </Col>
                </Row>
            ) : (
                <>
                    <Row
                        className="no-gutters font-weight-700 p-3 mt-3 display-16"
                        style={{
                            backgroundColor: 'rgba(0, 85, 255, 0.1)',
                        }}
                    >
                        <Col lg={'auto'}>
                            <FiInfo
                                color="#0055ff"
                                size="2.2rem"
                                className="mr-3 mt-1"
                            />
                        </Col>
                        <Col className="align-middle my-auto">
                            Aucun membre du workspace n'est assigné à ce projet.
                            <br />
                            {writeAcces &&
                                (props.data?.object?.status === 'archived' ? (
                                    <>
                                        Impossible d'ajouter une assignation car
                                        ce projet a été archivé.
                                    </>
                                ) : (
                                    <>
                                        En tant qu'administrateur du
                                        workspace&nbsp;
                                        {workspace.object.name} vous pouvez
                                        assigner un ou plusieurs membres à ce
                                        projet.
                                    </>
                                ))}
                        </Col>
                    </Row>
                    <Row className="no-gutters mt-2">
                        <Col></Col>
                        <Col
                            lg={'auto'}
                            className="flex items-end column axe-priorisation-btn-container p-0 pb-5 mr-2"
                        >
                            {deleteble ? (
                                <BlueButton
                                    content={'Ajouter'}
                                    onClick={() =>
                                        (document.getElementById(
                                            'view_details_assignment_project'
                                        ).style.display = 'block')
                                    }
                                    icon={<FiPlusCircle size="2.2rem" />}
                                />
                            ) : (
                                ''
                            )}
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};
