import { Container, Fade, Navbar, Button, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import ErrorSelector from '../error/ErrorSelector';
import { FiLoader } from 'react-icons/fi';
import User from '../../util/User';
import Plan from '../../util/Plan';
import { useMatomo } from '@datapunt/matomo-tracker-react';
export default () => {
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const { trackPageView } = useMatomo();
    useEffect(() => {
        trackPageView();
        User.headTest(activeUser[0].token)
            .then((res) => {
                switch (res.status) {
                    case 403:
                        dispatch(
                            {
                                type: 'LOGOUT',
                                value: activeUser[0].id,
                            },
                            history.push('/login')
                        );
                        break;
                    case 402:
                        dispatch({
                            type: 'SET_SELECTED_PLAN',
                            value: { id: activeUser[0].id, data: null },
                        });
                        dispatch({
                            type: 'SET_SELECTED_SUBSCRIPTION',
                            value: { id: activeUser[0].id, data: null },
                        });
                        dispatch({
                            type: 'SET_SELECTED_WORKSPACE',
                            value: { id: activeUser[0].id, data: null },
                        });
                        history.push('/prices');
                        break;
                    case 200:
                        Plan.get({
                            "object->>'active'": true,
                            "object->>'code'": 'MVP',
                            "object->>'payment_frequency'": 'annually',
                        }).then((res) => {
                            dispatch({
                                type: 'SET_SELECTED_PLAN',
                                value: {
                                    id: activeUser[0].id,
                                    data: res.data.data[0].id,
                                },
                            });
                            dispatch({
                                type: 'SET_SELECTED_SUBSCRIPTION',
                                value: { id: activeUser[0].id, data: null },
                            });
                            dispatch({
                                type: 'SET_SELECTED_WORKSPACE',
                                value: { id: activeUser[0].id, data: null },
                            });
                            history.push('/onboarding');
                        });
                        break;
                    default:
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                }
            })
            .catch((err) => {
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            });
    }, []);
    return (
        <ErrorSelector
            logo="1"
            code={200}
            title={<FiLoader size={36} className="rotating" />}
            message=" "
        />
    );
};
