import { Container, Navbar, Button, Form, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Axios from 'axios';
import Validator from '../../util/Validator';
import ApiCallConfig from '../../util/ApiCallConfig';
import Logo from '../commun/Logo';
import { FiX } from 'react-icons/fi';
export default () => {
    const [payload, setPayload] = useState({
        first_name: '',
        last_name: '',
        contact_email: '',
        message: '',
    });
    const [sent, setSent] = useState({ form: 'd-block', message: 'd-none' });
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const handleChange = (event) => {
        setPayload({ ...payload, [event.target.name]: event.target.value });
        if (Object.keys(errors).length !== 0 || errors.constructor !== Object) {
            const e = Validator({
                ...payload,
                [event.target.name]: event.target.value,
            });
            if (e) setErrors(e);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const e = Validator(payload);
        if (Object.keys(e).length !== 0 || e.constructor !== Object) {
            setErrors(e);
        } else {
            Axios({
                ...ApiCallConfig,
                method: 'POST',
                url: '/contact',
                data: payload,
            })
                .then((result) => {
                    if (result.status === 201) {
                        setSent({ form: 'd-none', message: 'd-block' });
                    } else if (result.status === 429) {
                        setMessage(
                            '[' +
                                result.status +
                                '] Trop de tentatives. Veuillez patienter quelques instants puis réessayez.'
                        );
                    } else {
                        setMessage(
                            '[' + result.status + '] un probleme est survenu'
                        );
                    }
                })
                .catch((error) => {
                    setMessage('[500] un probleme est survenu');
                });
        }
    };
    return (
        <Container fluid>
            <Navbar className="fixed-top rankio-header" bg="white" expand="xl">
                <Link to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </Link>
                <Link to="/" className="ml-auto">
                    <FiX size="1.5em" className="rk-clickable-icon-dark" />
                </Link>
            </Navbar>
            <div className="w-75 m-auto py-5">
                <Row>
                    <Col lg={3}></Col>
                    <Col lg={6} className={sent.message}>
                        <h3 className="font-weight-800 text-center my-4">
                            Merci !
                        </h3>
                        <div className="action-label text-center py-4">
                            Votre message a bien été envoyé.
                        </div>
                        <div className="text-center">
                            Nous vous remercions pour votre demande,
                            <br />
                            nous la traiterons dans les plus brefs délais.
                        </div>
                    </Col>
                    <Col lg={6} className={sent.form}>
                        <h3 className="font-weight-800 text-center my-4">
                            Contactez-nous !
                        </h3>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group controlId="first_name">
                                        <Form.Label className="input-label mt-3">
                                            Prénom
                                        </Form.Label>
                                        <Form.Control
                                            name="first_name"
                                            type="text"
                                            placeholder="Prénom"
                                            value={payload.first_name}
                                            className="input"
                                            onChange={handleChange}
                                        />
                                        <Form.Text className="mt-2 ml-2 text-danger font-weight-700">
                                            {errors.first_name}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="last_name">
                                        <Form.Label className="input-label mt-3">
                                            Nom
                                        </Form.Label>
                                        <Form.Control
                                            name="last_name"
                                            type="text"
                                            placeholder="Nom"
                                            value={payload.last_name}
                                            className="input"
                                            onChange={handleChange}
                                        />
                                        <Form.Text className="mt-2 ml-2 text-danger font-weight-700">
                                            {errors.last_name}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="contact_email">
                                <Form.Label className="input-label mt-3">
                                    E-mail
                                </Form.Label>
                                <Form.Control
                                    name="contact_email"
                                    type="email"
                                    placeholder="nom@entreprise.com"
                                    value={payload.contact_email}
                                    className="input"
                                    onChange={handleChange}
                                />
                                <Form.Text className="mt-2 ml-2 text-danger font-weight-700">
                                    {errors.contact_email}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="message">
                                <Form.Label className="input-label mt-3">
                                    Message
                                </Form.Label>
                                <Form.Control
                                    name="message"
                                    as="textarea"
                                    rows={5}
                                    placeholder="Hello !"
                                    value={payload.message}
                                    className="input"
                                    onChange={handleChange}
                                />
                                <Form.Text className="mt-2 ml-2 text-danger font-weight-700">
                                    {errors.message}
                                </Form.Text>
                            </Form.Group>
                            <center>
                                <Button
                                    className="btn-lg btn-primary w-100 mt-4"
                                    style={{ height: '5rem' }}
                                    type="submit"
                                >
                                    <strong className="h3 font-weight-800">
                                        Envoyer
                                    </strong>
                                </Button>
                            </center>
                            <Form.Group>
                                <Form.Text className="text-danger">
                                    {message}
                                </Form.Text>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col lg={3}></Col>
                </Row>
            </div>
        </Container>
    );
};
