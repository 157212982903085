import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, Row, Col, Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { validate as isUuid } from 'uuid';
import Switch from 'react-switch';
import { FiLoader, FiInfo } from 'react-icons/fi';
import User from '../../util/User';
import Plan from '../../util/Plan';
import { useMatomo } from '@datapunt/matomo-tracker-react';

export default (props) => {
    const history = useHistory();
    const state = useSelector((state) => state.user);
    const users = state.users;
    const activeIndex = useSelector((state) => state.user.activeUserIndex);
    const activeUser = users[activeIndex];
    const selectedPlan = activeUser?.selectedPlan;
    const [testCondition, setTestCondition] = useState('signup');
    const [loading, setLoading] = useState(true);
    const [planMVP, setPlanMVP] = useState({});
    const [plan, setPlan] = useState({});
    const dispatch = useDispatch();
    const { trackEvent } = useMatomo();
    const getPlan = () => {
        Plan.get({ "object->>'active'": true, "object->>'code'": 'MVP' })
            .then((res) => {
                if (res.status === 200) {
                    // only MVP plan
                    const annually = res.data.data.filter(
                        (p) => p.object.payment_frequency === 'annually'
                    )[0];
                    const monthly = res.data.data.filter(
                        (p) => p.object.payment_frequency === 'monthly'
                    )[0];
                    setPlanMVP({
                        annually: {
                            id: annually.id,
                            object: {
                                ...annually.object,
                                cost_per_user_per_month: Math.round(
                                    annually.object.prices / 10 / 12
                                ),
                                eco_percent:
                                    '(soit ' +
                                    (
                                        100 -
                                        Math.round(
                                            (annually.object.prices * 100) /
                                                (monthly.object.prices * 12) +
                                                Number.EPSILON
                                        )
                                    ).toString() +
                                    '% d’économies)',
                            },
                        },
                        monthly: {
                            id: monthly.id,
                            object: {
                                ...monthly.object,
                                cost_per_user_per_month: Math.round(
                                    monthly.object.prices / 10
                                ),
                                eco_percent: ' ',
                            },
                        },
                    });
                } else {
                    setTimeout(() => {
                        getPlan();
                    }, 2000);
                }
            })
            .catch((err) => {
                setTimeout(() => {
                    getPlan();
                }, 2000);
            });
    };
    useEffect(() => {
        getPlan();
    }, []);
    useEffect(() => {
        if (isUuid(selectedPlan) && selectedPlan === planMVP.monthly?.id) {
            setPlan(planMVP.monthly);
        } else {
            setPlan(planMVP.annually);
        }
        setLoading(false);
    }, [planMVP]);

    useEffect(() => {
        if (activeUser) {
            dispatch({
                type: 'SET_SELECTED_PLAN',
                value: { id: activeUser.id, data: plan?.id ?? null },
            });
            dispatch({
                type: 'SET_SELECTED_SUBSCRIPTION',
                value: { id: activeUser.id, data: null },
            });
            dispatch({
                type: 'SET_SELECTED_WORKSPACE',
                value: { id: activeUser.id, data: null },
            });
        }
    }, [plan]);
    useEffect(() => {
        if (props.testCondition) {
            setTestCondition(props.testCondition);
        } else if (activeUser && activeUser.id) {
            User.headTest(activeUser.token)
                .then((res) => {
                    switch (res.status) {
                        case 403:
                            dispatch(
                                {
                                    type: 'LOGOUT',
                                    value: activeUser.id,
                                },
                                history.push('/login')
                            );
                            break;
                        case 402:
                            setTestCondition('buy');
                            break;
                        case 200:
                            setTestCondition('test');
                            break;
                        default:
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                    }
                })
                .catch((err) => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                });
        } else {
            setTestCondition('signup');
        }
    }, []);
    return (
        <div id="prices">
            <div className="mt-5 p-3">
                <h2 className="font-weight-900 text-center my-4">
                    Abonnez-vous
                </h2>
                <div
                    className={
                        (loading ? 'd-block' : 'd-none') + ' text-center py-5'
                    }
                >
                    <FiLoader size={36} className="rotating" />
                </div>
                <div className={loading ? 'd-none' : 'd-block'}>
                    <div className="text-center my-4">
                        <Switch
                            className="my-4"
                            aria-label="Choix entre un abonnement annuel ou mensuel"
                            id="price-switch"
                            onColor={'#0055ff'}
                            offColor={'#000000'}
                            checked={
                                plan?.object?.payment_frequency === 'annually'
                            }
                            checkedIcon={
                                <p className="font-weight-700 display-12 text-white pl-2 pt-10">
                                    Annuel
                                </p>
                            }
                            uncheckedIcon={
                                <p className="font-weight-700 display-12 text-white pt-10">
                                    Mensuel
                                </p>
                            }
                            height={40}
                            width={100}
                            handleDiameter={35}
                            onChange={() => {
                                trackEvent({
                                    category: 'subscription',
                                    action: 'switch_prices',
                                    name:
                                        plan?.object?.payment_frequency ===
                                        'annually'
                                            ? 'Mensuel'
                                            : 'Annuel',
                                    value: 0,
                                });
                                plan?.object?.payment_frequency === 'annually'
                                    ? setPlan(planMVP.monthly)
                                    : setPlan(planMVP.annually);
                            }}
                        />
                        <div className="font-weight-bold">
                            {plan?.object?.eco_percent}&nbsp;
                        </div>
                    </div>
                    <Col md={10} lg={8} xl={7} className="mx-auto col-xxl-5">
                        <Card>
                            <Card.Title className="mb-2">
                                <h3 className="font-weight-800 mb-0 pt-4 px-4 pb-3">
                                    {plan?.object?.name}
                                </h3>
                            </Card.Title>
                            <Card.Body className="font-weight-600 text-align-left px-4">
                                {Plan.renderDescription(plan)}

                                <Row className="my-4 mx-0">
                                    <div>
                                        <span className="font-weight-800 display-48 mr-3 my-auto">
                                            {plan?.object?.payment_frequency ===
                                            'annually'
                                                ? plan?.object?.prices / 12
                                                : plan?.object?.prices}
                                            {plan?.object?.currency}
                                        </span>
                                        <span className="font-weight-600 display-18 mr-5 my-auto">
                                            HT/mois
                                        </span>
                                    </div>
                                    {plan?.object?.payment_frequency ===
                                    'annually' ? (
                                        <div>
                                            <span className="font-weight-600 display-18 mr-5 my-auto">
                                                soit
                                            </span>
                                            <span className="font-weight-800 display-48 mr-3 my-auto">
                                                {plan?.object?.prices}
                                                {plan?.object?.currency}
                                            </span>
                                            <span className="font-weight-600 display-18 my-auto">
                                                HT/an
                                            </span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </Row>
                                <div className="text-center">
                                    {testCondition === 'buy' ? (
                                        <Button
                                            variant="primary"
                                            style={{
                                                height: '5rem',
                                                maxWidth: '340px',
                                                minWidth: '240px',
                                            }}
                                            className="btn my-4 mx-auto"
                                            onClick={() => {
                                                dispatch(
                                                    {
                                                        type: 'SET_SELECTED_SUBSCRIPTION',
                                                        value: {
                                                            id: activeUser.id,
                                                            data: null,
                                                        },
                                                    },
                                                    dispatch(
                                                        {
                                                            type: 'SET_SELECTED_WORKSPACE',
                                                            value: {
                                                                id: activeUser.id,
                                                                data: null,
                                                            },
                                                        },
                                                        history.push(
                                                            '/onboarding'
                                                        )
                                                    )
                                                );
                                            }}
                                        >
                                            <span className="font-weight-800 display-24 px-3">
                                                Achetez
                                            </span>
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="primary"
                                            style={{
                                                height: '5rem',
                                                maxWidth: '340px',
                                                minWidth: '240px',
                                            }}
                                            className="btn mx-auto my-4"
                                            onClick={() => {
                                                if (activeUser?.id) {
                                                    dispatch({
                                                        type: 'SET_SELECTED_SUBSCRIPTION',
                                                        value: {
                                                            id: activeUser.id,
                                                            data: null,
                                                        },
                                                    });
                                                    dispatch({
                                                        type: 'SET_SELECTED_WORKSPACE',
                                                        value: {
                                                            id: activeUser.id,
                                                            data: null,
                                                        },
                                                    });
                                                    history.push('/onboarding');
                                                } else {
                                                    history.push('/signup');
                                                }
                                            }}
                                        >
                                            <span className="font-weight-800 display-24 px-3">
                                                Testez gratuitement pendant 14
                                                jours
                                            </span>
                                        </Button>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                        <Alert
                            variant="secondary"
                            className="my-4 bg-gray text-black font-weight-600 display-12 border-0"
                        >
                            <Row className="align-items-center">
                                <Col xs="auto">
                                    <FiInfo size="2.2rem" />
                                </Col>
                                <Col className="pl-0">
                                    <span className="font-weight-600 display-16">
                                        Ce montant correspond en moyenne à un
                                        prix de{' '}
                                    </span>
                                    <span className="font-weight-700 display-18">
                                        {plan?.object?.cost_per_user_per_month}
                                        {plan?.object?.currency}
                                    </span>
                                    <span className="font-weight-600 display-16">
                                        {' '}
                                        pour une équipe type utilisant notre
                                        application (Scrum Team, Feature Team,
                                        SAFe Agile Team, Product Team...)
                                    </span>
                                </Col>
                            </Row>
                        </Alert>
                    </Col>
                </div>
            </div>
        </div>
    );
};
