import { Container, Navbar } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Logo from '../commun/Logo';
import { FiX, FiLoader, FiCheckCircle } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import User from '../../util/User';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import BlueButton from '../commun/BlueButton';
export default () => {
    const { token } = useParams();
    const history = useHistory();
    let dispatch = useDispatch();
    const landing = () => {
        return (
            <div className="text-center">
                <h3 className="font-weight-800 my-5">
                    Vérification en cours
                    <br />
                </h3>
                <p>Veuillez patienter quelques instants</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <FiLoader size={36} className="rotating" />
            </div>
        );
    };
    const validation = () => {
        return (
            <div className="text-center">
                <h3 className="font-weight-800 my-5">
                    Finalisez l'activation de votre compte
                    <br />
                </h3>
                <p>
                    Pour valider votre compte, veuillez
                    cliquer ci-dessous :
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <BlueButton
                    btnClassnameDetails="btn-width-unset"
                    content="Activer mon compte"
                    onClick={() => {
                        validate();
                    }}
                />
            </div>
        );
    };
    const loading = () => {
        return (
            <div className="text-center">
                <h3 className="font-weight-800 my-5">
                    Validation en cours
                    <br />
                </h3>
                <p>Veuillez patienter quelques instants</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <FiLoader size={36} className="rotating" />
            </div>
        );
    };
    const success = () => {
        return (
            <>
                <div className="text-center">
                    <h3 className="font-weight-800 my-5">
                        Validation terminée
                        <br />
                    </h3>
                    <p>Vous allez être redirigé automatiquement</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <FiLoader size={36} className="rotating" />
                </div>
            </>
        );
    };
    const fail = (msg) => {
        return (
            <>
                <div className="text-center">
                    <h3 className="font-weight-800 my-5">
                        Echec de la validation !
                        <br />
                    </h3>
                    <p>{msg}</p>
                    <p>Vous allez être redirigé automatiquement</p>
                    <p>&nbsp;</p>
                    <FiLoader size={36} className="rotating" />
                </div>
            </>
        );
    };
    const [content, setContent] = useState(landing());
    const validate = () => {
        setContent(loading());
        setTimeout(() => {
            Axios({
                ...ApiCallConfig,
                method: 'GET',
                url: '/verify',
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((res) => {
                    if (res.status === 200 || res.status === 409) {
                        Axios({
                            ...ApiCallConfig,
                            method: 'GET',
                            url: '/user',
                            headers: {
                                authorization: 'Bearer ' + token,
                            },
                        })
                            .then((user) => {
                                dispatch({
                                    type: 'ADD_USER',
                                    value: {
                                        ...user.data.data[0],
                                        workspace: [],
                                    },
                                    token,
                                });
                                if (res.status === 200) {
                                    setContent(success());
                                    setTimeout(() => {
                                        history.push('/onboarding');
                                    }, 3000);
                                } else {
                                    // 409
                                    setContent(
                                        fail('Votre compte est déjà valide')
                                    );
                                    setTimeout(() => {
                                        history.push('/dashboard');
                                    }, 3000);
                                }
                            })
                            .catch((err) => {
                                console.error(err);
                                setContent(
                                    fail(
                                        'Une erreur est survenue veuillez réessayer ultérieurement'
                                    )
                                );
                                setTimeout(() => {
                                    history.push('/login');
                                }, 3000);
                            });
                    } else {
                        setContent(fail('Le lien magique a expiré'));
                        setTimeout(() => {
                            history.push('/login');
                        }, 3000);
                    }
                })
                .catch((err) => {
                    console.error(err);
                    setContent(
                        fail(
                            'Une erreur est survenue veuillez réessayer ultérieurement'
                        )
                    );
                    setTimeout(() => {
                        history.push('/login');
                    }, 3000);
                });
        }, 1000);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            Axios({
                ...ApiCallConfig,
                method: 'HEAD',
                url: '/token',
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        console.log(res.headers.get('x-remaining-seconds'));
                        setContent(validation());
                    } else {
                        setContent(fail('Le lien magique a expiré'));
                    }
                })
                .catch((err) => {
                    setContent(
                        fail(
                            'Une erreur est survenue veuillez réessayer ultérieurement'
                        )
                    );
                    setTimeout(window.location.reload(), 2000);
                });
        }, 1000);
    }, []);
    return (
        <Container fluid>
            <Navbar className="fixed-top rankio-header" bg="white" expand="xl">
                <Link to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </Link>
                <Link to="/" className="ml-auto">
                    <FiX size="1.5em" className="rk-clickable-icon-dark" />{' '}
                </Link>
            </Navbar>
            <div className="m-auto py-5">{content}</div>
        </Container>
    );
};
