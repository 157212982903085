import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
export default () => {
    return (
        <div id="contact" className="Contact">
            <div className="text-center mt-5 p-3">
                <h2 className="font-weight-900 my-4">
                    Contactez-nous pour toute question !
                </h2>
                <center>
                    <Link className="mx-auto" to="/contact">
                        <Button
                            variant="dark"
                            className="btn my-4"
                            type="submit"
                        >
                            <span className="font-weight-800 display-18">
                                Contactez-nous
                            </span>
                        </Button>
                    </Link>
                </center>
            </div>
        </div>
    );
};
