export default {
    options: {
        all: true,
        code: true,
        name: true,
        status: true,
        due_date: true,
    },
    sizes: {
        code: 8,
        name: 10,
        status: 10,
        due_date: 10,
    },
    pagination: { limit: 500, skip: 0 },
    sort: { type: '', column: '' },
    internal: { due_date: { lt: '2022-12-31', gt: '2020-01-01' } },
    filter: {
        code: '',
        name: '',
        status: {
            all: false,
            '00_to_plan': true,
            '01_planned': true,
            '02_prioritizing': true,
            '03_reviewing': true,
            '04_done': true,
            '05_aborted': false,
            '06_archived': false,
        },
        due_date: '',
    },
    type: {
        code: 'string',
        name: 'string',
        status: 'status_list',
        due_date: 'date',
    },
};
