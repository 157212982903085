import Config from './Config';
export default {
    method: 'GET',
    responseType: 'json',
    baseURL: Config.API.ENDPOINT,
    url: '/',
    validateStatus: function (status) {
        return status >= 200 && status <= 599;
    },
    timeout: Config.API.TIMEOUT,
};
