import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FiHome, FiLink, FiInfo, FiRefreshCw } from 'react-icons/fi';
import Logo from '../commun/Logo';
import { useMatomo } from '@datapunt/matomo-tracker-react';

export default (args) => { 
    const { trackEvent } = useMatomo();
    useEffect(() => {
        trackEvent({
            category: 'error',
            action: 'print',
            name: args?.code || 200,
            value: 1,
            href: window.location.href,
            customDimensions: [
                {
                    id: 1,
                    value: args?.message || 'Une erreur est survenue.',
                },
            ],
        });
    }, []);
    const displayLogo = () => {
        if (args.logo === '1') {
            return (
                <>
                    <Logo />
                </>
            );
        }
    };
    const displayRetryBtn = () => {
        if (args.retry_link || args.retry_href) {
            const colorRetryBtn = 'btn-' + args.retry_color || 'btn-primary';
            const valueRetryBtn = args.retry_value || 'Réessayer';
            const iconeRetryBtn = () => {
                if (args.retry_icone === undefined) {
                    return <FiHome size={20} className="mr-3" />;
                } else {
                    if (args.retry_icone == 'home')
                        return <FiHome size={20} className="mr-3" />;
                    if (args.retry_icone == 'info')
                        return <FiInfo size={20} className="mr-3" />;
                    if (args.retry_icone == 'reload')
                        return <FiRefreshCw size={20} className="mr-3" />;
                    else return <FiLink size={20} className="mr-3" />;
                }
            };
            if (args.retry_link)
                return (
                    <div className="my-5">
                        <Link to={args.retry_link}>
                            <Button
                                className={
                                    colorRetryBtn +
                                    ' font-weight-800 display-24'
                                }
                            >
                                {iconeRetryBtn}
                                {valueRetryBtn}
                            </Button>
                        </Link>
                    </div>
                );
            else {
                return (
                    <div className="my-5">
                        <a href={args.retry_href}>
                            <Button
                                className={
                                    colorRetryBtn +
                                    ' font-weight-800 display-24'
                                }
                            >
                                {iconeRetryBtn}
                                {valueRetryBtn}
                            </Button>
                        </a>
                    </div>
                );
            }
        } else {
            return <></>;
        }
    };

    return (
        <div style={{ height: '100%' }}>
            <center
                style={{
                    width: 'auto',
                    height: 'auto',
                    position: 'absolute',
                    top: '35%',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    margin: 'auto',
                }}
            >
                <div className="mb-5 px-3">{displayLogo()}</div>
                <div className="my-4">
                    <h2 className="font-weight-800 mb-4">{args.title}</h2>
                    {!args.message
                        ? 'Une erreur est survenue.'
                        : args.message.split('  ').map((msg, i) => {
                              return (
                                  <p
                                      className="font-weight-600 mx-4 px-3"
                                      key={i}
                                  >
                                      {msg}
                                  </p>
                              );
                          })}
                </div>
                {displayRetryBtn()}
            </center>
        </div>
    );
};
