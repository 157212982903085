import React from 'react';
import Axios from 'axios';
import ApiCallConfig from './ApiCallConfig';
export default class Session {
    /**
     * @function post
     * @definition Create session
     * @returns {Promise}
     */
    static post(workspaceId, token, payload) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'POST',
                url: '/workspace/' + workspaceId + '/session',
                headers: {
                    authorization: 'Bearer ' + token,
                },
                data: payload,
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    /**
     * @function get
     * @definition Get session
     * @returns {Promise}
     */
    static get(workspaceId, sessionId, token) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'GET',
                url: '/workspace/' + workspaceId + '/session',
                params: {
                    id: sessionId,
                },
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    /**
     * @function patch
     * @definition Update session
     * @returns {Promise}
     */
    static patch(workspaceId, sessionId, token, payload) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'PATCH',
                url: '/workspace/' + workspaceId + '/session/' + sessionId,
                headers: {
                    authorization: 'Bearer ' + token,
                },
                data: payload,
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    /**
     * @function delete
     * @definition Remove session
     * @returns {Promise}
     */
    static delete(workspaceId, sessionId, token, payload) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'DELETE',
                url: '/workspace/' + workspaceId + '/session/' + sessionId,
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
