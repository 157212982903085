import React from 'react';
import ErrorComponent from './ErrorComponent';
export default (props) => {
    const NotFound = () => {
        return (
            <ErrorComponent
                title="404"
                message="Page introuvable"
                retry_value="Revenir à la page d'accueil"
                retry_link="/"
                retry_color="outline-primary"
                retry_icone="home"
                {...props}
            />
        );
    };
    switch (props.code) {
        case 401:
            // call without token
            //   or with expired token
            //   or with invalid token | LDAP credencials
            return (
                <ErrorComponent
                    title="401"
                    message="Erreur d'authentification.  Une authentification est nécessaire pour accéder à la ressource."
                    retry_value="Revenir à la page d'accueil"
                    retry_link="/"
                    retry_color="outline-primary"
                    retry_icone="home"
                    {...props}
                />
            );
        case 402:
            return (
                <ErrorComponent
                    title="402"
                    message="Un paiement est requis pour accéder à la ressource."
                    retry_value="Revenir à la page d'accueil"
                    retry_link="/"
                    retry_color="outline-primary"
                    retry_icone="home"
                    {...props}
                />
            );
        case 403:
            // call with a valid token but unauthorized
            return (
                <ErrorComponent
                    title="Accès refusé"
                    message="Vous n'avez pas les droits nécessaires pour accéder à cette ressource."
                    retry_value="Revenir à la page d'accueil"
                    retry_link="/"
                    retry_color="outline-primary"
                    retry_icone="home"
                    {...props}
                />
            );
        case 404:
            return <>{NotFound()}</>;
        case 423:
            return (
                <ErrorComponent
                    title="Verrouillé"
                    message="L’opération ne peut avoir lieu car la ressource est verrouillée momentanément.   Veuillez réessayer ultérieurement."
                    retry_value="Réessayer"
                    retry_link="/"
                    retry_color="outline-primary"
                    retry_icone="home"
                    {...props}
                />
            );
        case 429:
            return (
                <ErrorComponent
                    code="429"
                    title="Trop de requêtes"
                    message="Veuillez patienter quelques instants, puis réessayez."
                    {...props}
                />
            );
        case 500:
        case 501:
            return (
                <ErrorComponent
                    title={props.code}
                    message="Une erreur est survenue.  Un rapport d'erreurs vient de nous d'être envoyé.  Nous faisons de notre mieux pour résoudre ce problème au plus vite."
                    retry_value="Revenir à la page d'accueil"
                    retry_link="/"
                    retry_color="outline-primary"
                    retry_icone="home"
                    {...props}
                />
            );
        case 502:
        case 503:
            return (
                <ErrorComponent
                    title={props.code}
                    message="Le service est momentanément indisponible.  Veuillez patienter quelques instants, puis réessayez."
                    {...props}
                />
            );
        default:
            console.log(props);
            if (props.code < 400) {
                // code 1xx | 2xx | 3xx
                return <>{NotFound()}</>;
            }
            if (props.code < 500) {
                // code 4xx
                return (
                    <ErrorComponent
                        title="Oops"
                        message="La requête n'a pas pu être comprise par le serveur d'application en raison d'une syntaxe erronée.   Le problème peut provenir d'un navigateur Web trop ancien."
                        retry_value="Revenir à la page d'accueil"
                        retry_link="/"
                        retry_color="outline-primary"
                        retry_icone="home"
                        {...props}
                    />
                );
            }
            return (
                // code 5xx
                // Unknown
                <ErrorComponent
                    title="Oops"
                    message="Une erreur est survenue.  Le serveur d'application est incapable de traiter la requête.  Veuillez réessayer ultérieurement."
                    retry_value="Revenir à la page d'accueil"
                    retry_link="/"
                    retry_color="outline-primary"
                    retry_icone="home"
                    {...props}
                />
            );
    }
};
