import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AxePrioHeader from './AxePrioHeader';
import AxePrioBody from './AxePrioBody';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Echo from '../../util/Echo';
import Unpaid from '../commun/Unpaid';
import IniteAccept from '../commun/IniteAccept';

function Workspace(props) {
    const users = useSelector((state) => state.user.users);
    const { trackPageView, trackEvent } = useMatomo();
    const { id } = useParams();
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = users.filter((user) => user.id.includes(id))[0].workspace[
        activeUser[0].activeWorkspace
    ];
    useEffect(() => {
        document.title = Echo().seo.title.wsjf
            ? Echo().seo.title.prefix +
              workspace.object.name +
              ' - ' +
              Echo().seo.title.wsjf +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, [activeUser[0].activeWorkspace]);
    switch (true) {
        case workspace.object.status === 'unpaid':
            return <Unpaid />;
        case workspace.object.teams.on.filter(
            (t) => t.id === activeUser[0].id
        )[0].invit !== undefined:
            return <IniteAccept veiw="axis"/>;
        default:
            return (
                <div className="frame-content relative m-2 pb-2">
                    <AxePrioHeader />
                    <AxePrioBody />
                </div>
            );
    }
}

export default Workspace;
