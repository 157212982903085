import Axios from 'axios';
import ApiCallConfig from './ApiCallConfig';
export default class Workspace {
    /**
     * @function post
     * @definition Create workspace
     * @returns {Promise}
     */
    static post(token, payload) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'POST',
                url: '/workspace',
                headers: {
                    authorization: 'Bearer ' + token,
                },
                data: payload,
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    /**
     * @function get
     * @definition Get workspace
     * @returns {Promise}
     */
    static get(params, token) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'GET',
                url: '/workspace',
                params,
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    /**
     * @function patch
     * @definition Update workspace
     * @returns {Promise}
     */
    static patch(workspaceId, token, payload) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'PATCH',
                url: '/workspace/' + workspaceId,
                headers: {
                    authorization: 'Bearer ' + token,
                },
                data: payload,
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    /**
     * @function delete
     * @definition Remove workspace
     * @returns {Promise}
     */
    static delete(workspaceId, token, payload) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'DELETE',
                url: '/workspace/' + workspaceId,
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
