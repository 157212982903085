import { Form, FormControl, Button, Row, Col } from 'react-bootstrap';
import Switch from 'react-switch';
import Select from 'react-select';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Validator from '../../util/Validator';
import PaymentMethods from '../../util/PaymentMethods';
import BlueButton from '../commun/BlueButton';
import GreyButton from '../commun/GreyButton';
import RkAlert from '../commun/Alert';
import { FiSave, FiEyeOff, FiCheck, FiInfo, FiX, FiLock } from 'react-icons/fi';
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;
export default (props) => {
    const users = useSelector((state) => state.user.users);
    const { id } = useParams();
    const activeUser = users.filter((user) => user.id.includes(id));
    const handleReset = () => {
        setErrors({});
        setDefaultSwitch(
            props.action === 'post' || props.default === props?.data?.id
        );
        setPayload({
            number: '',
            exp_month: props?.data?.card?.exp_month || 1,
            exp_year: props?.data?.card?.exp_year || 2025,
            cvc: '',
        });
    };
    const [payload, setPayload] = useState({
        number: '',
        exp_month: props?.data?.card?.exp_month || 1,
        exp_year: props?.data?.card?.exp_year || 2025,
        cvc: '',
    });
    const [expYear, setExpYear] = useState(
        Array.from(
            {
                length: 10,
            },
            (_v, k) => k + currentYear
        ).map((d) => {
            return { value: d, label: d.toString() };
        })
    );
    const [expMonth, setExpMonth] = useState(
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((d) => {
            return { value: d, label: d.toString() };
        })
    );
    const [defaultSwitch, setDefaultSwitch] = useState(true);
    const [updateDefaultPaymentMethods, setUpdateDefaultPaymentMethods] =
        useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [colorAlert, setColorAlert] = useState('danger');
    const [contentAlert, setContentAlert] = useState(
        'Une erreur est survenue veuillez nous en excuser'
    );
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const handleChange = (event) => {
        setPayload({ ...payload, [event.target.name]: event.target.value });
        if (Object.keys(errors).length !== 0 || errors.constructor !== Object) {
            const e = Validator({
                ...payload,
                [event.target.name]: event.target.value,
            });
            if (e) setErrors(e);
        }
    };
    const handleNumberPattern = (event) => {
        if (
            event.target.value.length === 0 ||
            event.target.value.match(/[0-9]/)
        ) {
            setPayload({ ...payload, [event.target.name]: event.target.value });
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setErrors({});
        switch (props.action) {
            case 'post':
                const e = Validator(payload);
                if (Object.keys(e).length !== 0 || e.constructor !== Object) {
                    setErrors(e);
                } else {
                    PaymentMethods.post(
                        {
                            number: payload.number,
                            exp_month: payload.exp_month,
                            exp_year: payload.exp_year,
                            cvc: payload.cvc,
                        },
                        activeUser[0].token
                    )
                        .then((res) => {
                            if (res.status === 201) {
                                PaymentMethods.patch(
                                    res.data.data.id,
                                    activeUser[0].token
                                )
                                    .then((res) => {
                                        if (res.status === 200) {
                                            props.done(true);
                                        } else {
                                            setColorAlert('danger');
                                            setContentAlert(
                                                'Un problème est survenu, veuillez réessayer.'
                                            );
                                            setShowAlert(true);
                                        }
                                    })
                                    .catch(() => {
                                        setColorAlert('danger');
                                        setContentAlert(
                                            'Un problème est survenu, veuillez réessayer.'
                                        );
                                        setShowAlert(true);
                                    });
                            } else if (res.status === 422) {
                                setColorAlert('danger');
                                setContentAlert("La carte n'est pas valide");
                                setShowAlert(true);
                            } else if (res.status === 429) {
                                setColorAlert('danger');
                                setContentAlert(
                                    'Trop de tentatives. Veuillez patienter quelques instants puis réessayez.'
                                );
                                setShowAlert(true);
                            } else {
                                setColorAlert('danger');
                                setContentAlert(
                                    'Un problème est survenu, veuillez réessayer.'
                                );
                                setShowAlert(true);
                            }
                        })
                        .catch((msg) => {
                            setColorAlert('danger');
                            setContentAlert(
                                'Un problème est survenu, veuillez réessayer.'
                            );
                            setShowAlert(true);
                        });
                }

                break;
            case 'patch':
                PaymentMethods.put(props?.data?.id, activeUser[0].token, {
                    exp_month: payload.exp_month,
                    exp_year: payload.exp_year,
                })
                    .then((res) => {
                        if (res.status === 200) {
                            props.done(true);
                        } else if (res.status === 204) {
                            props.done();
                        } else if (res.status === 429) {
                            setColorAlert('danger');
                            setContentAlert(
                                'Trop de tentatives. Veuillez patienter quelques instants puis réessayez.'
                            );
                            setShowAlert(true);
                        } else {
                            setColorAlert('danger');
                            setContentAlert(
                                'Un problème est survenu, veuillez réessayer.'
                            );
                            setShowAlert(true);
                        }
                    })
                    .catch((msg) => {
                        setColorAlert('danger');
                        setContentAlert(
                            'Un problème est survenu, veuillez réessayer.'
                        );
                        setShowAlert(true);
                    });
                if (updateDefaultPaymentMethods)
                    PaymentMethods.patch(
                        props?.data?.id,
                        activeUser[0].token
                    ).then((res) => {
                        if (res.status === 200) {
                            props.done(true);
                        }
                    });

                break;

            default:
                setColorAlert('danger');
                setContentAlert('Un problème est survenu, veuillez réessayer.');
                setShowAlert(true);
        }
    };
    useEffect(() => {
        if (payload.exp_year === currentYear) {
            setExpMonth(
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                    .filter((m) => m > currentMonth)
                    .map((d) => {
                        return { value: d, label: d.toString() };
                    })
            );
            if (payload.exp_month < currentMonth) {
                setPayload({ ...payload, exp_month: currentMonth });
            }
        } else {
            setExpMonth(
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((d) => {
                    return { value: d, label: d.toString() };
                })
            );
        }
    }, [payload.exp_year]);
    useEffect(() => {
        setShowAlert(false);
        setDefaultSwitch(
            props.action === 'post' || props.default === props?.data?.id
        );
        setUpdateDefaultPaymentMethods(false);
        setPayload({
            number: '',
            exp_month: props?.data?.card?.exp_month || 1,
            exp_year: props?.data?.card?.exp_year || 2025,
            cvc: '',
        });
    }, [props]);
    useEffect(() => {
        handleReset();
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Form
                noValidate
                onSubmit={handleSubmit}
                onReset={() => {
                    handleReset();
                    props.done();
                }}
            >
                <Row className="m-0">
                    <Col lg={'auto'} className="p-0">
                        <Form.Group controlId="number">
                            <Row className="m-0">
                                <Form.Label className="input-label mt-4">
                                    Numéro de la carte
                                </Form.Label>
                                <FiLock size="1.2rem" className="ml-3 mt-4" />
                            </Row>
                            <Form.Control
                                name="number"
                                type="text"
                                maxLength="19"
                                placeholder={
                                    props.action === 'post'
                                        ? 'xxxx xxxx xxxx xxxx'
                                        : '**** **** **** ' +
                                          props?.data?.card?.last4
                                }
                                value={
                                    props.action === 'post'
                                        ? payload.number
                                        : ''
                                }
                                className="input"
                                style={{
                                    width: '250px',
                                    letterSpacing: '0.1rem',
                                }}
                                autocomplete="cc-number"
                                onChange={handleNumberPattern}
                                disabled={props.action !== 'post'}
                            />
                            <Form.Text className="mt-2 ml-2 text-danger font-weight-bold">
                                {errors.number}
                            </Form.Text>
                        </Form.Group>
                        <Row className="m-0">
                            <Col lg={'auto'} className="p-0">
                                <Row className="m-0">
                                    <Col lg={'auto'} className="p-0 pr-2">
                                        <div
                                            style={{
                                                width: '80px',
                                            }}
                                        >
                                            <Form.Group controlId="exp_month">
                                                <Form.Label className="input-label mt-3">
                                                    Mois
                                                </Form.Label>
                                                <Select
                                                    id="cc-exp-month"
                                                    name="exp_month"
                                                    value={{
                                                        value: payload.exp_month,
                                                        label: payload.exp_month.toString(),
                                                    }}
                                                    className="input"
                                                    autoComplete={
                                                        'cc-exp-month'
                                                    }
                                                    onChange={(event) => {
                                                        setPayload({
                                                            ...payload,
                                                            exp_month:
                                                                event.value,
                                                        });
                                                    }}
                                                    options={expMonth}
                                                />
                                            </Form.Group>
                                        </div>
                                    </Col>
                                    <Col lg={'auto'} className="p-0 pr-2">
                                        <div
                                            style={{
                                                width: '105px',
                                            }}
                                        >
                                            <Form.Group controlId="exp_year">
                                                <Form.Label className="input-label mt-3">
                                                    Année
                                                </Form.Label>
                                                <Select
                                                    id="cc-exp-year"
                                                    name="exp_year"
                                                    value={{
                                                        value: payload.exp_year,
                                                        label: payload.exp_year.toString(),
                                                    }}
                                                    className="input"
                                                    autoComplete={'cc-exp-year'}
                                                    onChange={(event) => {
                                                        setPayload({
                                                            ...payload,
                                                            exp_year:
                                                                event.value,
                                                        });
                                                    }}
                                                    options={expYear}
                                                />
                                            </Form.Group>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="p-0">
                                <Form.Group controlId="cvc">
                                    <Form.Label className="input-label mt-3">
                                        CVC
                                    </Form.Label>
                                    <FormControl
                                        name="cvc"
                                        type="text"
                                        maxLength="4"
                                        placeholder="xxx"
                                        value={
                                            props.action === 'post'
                                                ? payload.cvc
                                                : '***'
                                        }
                                        className="input"
                                        style={{
                                            width: '75px',
                                        }}
                                        autocomplete="cc-csc"
                                        onChange={handleNumberPattern}
                                        disabled={props.action !== 'post'}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Text className="mt-2 ml-2 text-danger font-weight-bold">
                            {errors.cvc}
                        </Form.Text>
                        <Form.Group>
                            <Form.Text className="text-danger font-weight-bold">
                                {message}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col className="pr-0 pl-4">
                        <p className="input-label mt-4">Définir par défaut</p>
                        <Switch
                            className=""
                            onColor={'#0055ff'}
                            offColor={'#999999'}
                            checked={defaultSwitch}
                            uncheckedIcon={''}
                            checkedIcon={''}
                            height={24}
                            width={50}
                            handleDiameter={22}
                            onChange={() => {
                                if (props.default === props?.data?.id) {
                                    setColorAlert('danger');
                                    setContentAlert(
                                        "Il n'est pas possible de désactiver le moyen de paiement par défaut, veuillez en sélectionner un autre."
                                    );
                                    setShowAlert(true);
                                } else {
                                    setDefaultSwitch(!defaultSwitch);
                                    setUpdateDefaultPaymentMethods(
                                        !updateDefaultPaymentMethods
                                    );
                                }
                            }}
                        />
                    </Col>
                    {props.onboarding ? (
                        <Col lg={'12'}>
                            <Row className="p-0">
                                <Col sm={10} md={8} lg={6} xxl={4}>
                                    <Button
                                        className="btn-lg btn-outline-primary h3 font-weight-800 display-24 w-100 mt-5 px-3"
                                        style={{
                                            height: '5rem',
                                            maxWidth: '300px',
                                        }}
                                        type="reset"
                                    >
                                        Annuler
                                    </Button>
                                </Col>
                                <Col sm={10} md={8} lg={6} xxl={4}>
                                    <Button
                                        className="btn-lg btn-primary h3 font-weight-800 display-24 w-100 mt-5"
                                        style={{
                                            height: '5rem',
                                            maxWidth: '300px',
                                        }}
                                        type="submit"
                                    >
                                        Enregistrer
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    ) : (
                        <Col lg={'auto'} className="p-0 mt-3">
                            <BlueButton
                                content={<>Enregistrer</>}
                                icon={<FiSave size="2.2rem" />}
                                displayRowClassname="axeDisplayRowClassnameBtn"
                                type="submit"
                            />
                            <br></br>
                            <GreyButton
                                content={<>Annuler</>}
                                icon={<FiX size="2.2rem" />}
                                type="reset"
                            />
                        </Col>
                    )}
                </Row>
            </Form>
            {/*<Alert
                variant="secondary"
                className="my-5 bg-gray text-black font-weight-600 display-12 border-0"
            >
                <Row className="align-items-center">
                    <Col xs="auto">
                        <FiInfo size="2.5rem" />
                    </Col>
                    <Col>
                        En envoyant ce formulaire, vous confirmez que vous
                        acceptez nos &nbsp;
                        <Link to="/condition" className="text-primary">
                            Conditions d'utilisation
                        </Link>
                        &nbsp; et consentez au traitement de vos données
                        personnelles tel qu'indiqué dans notre &nbsp;
                        <Link to="/privacy" className="text-primary">
                            Politique de respect de la vie privée.
                        </Link>
                    </Col>
                </Row>
            </Alert>*/}
            <RkAlert
                color={colorAlert}
                content={contentAlert}
                showAlert={showAlert}
                handleCloseAlert={() => setShowAlert(false)}
            />
        </>
    );
};
