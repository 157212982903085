import { Row } from 'react-bootstrap';
import TeamIcon from './TeamIcon';

export default (props) => {
    return (
        <>
            {(props?.teams?.length && (
                <Row className="p-1">
                    {props.teams.map((team) => {
                        return <TeamIcon team={team} my={props.my} />;
                    })}
                </Row>
            )) ||
                ''}
        </>
    );
};
