export default {
    project: {
        fields: {
            status: {
                all: 'Toutes',
                to_prioritize: 'A prioriser',
                planned: 'Planifié',
                prioritized: 'Priorisé',
                buffer: 'Buffer',
                analyzing: "En cours d'analyse",
                approved: 'Approuvé',
                backlog: 'Backlog',
                in_progress: 'En cours de réalisation',
                done: 'Terminé',
                stand_by: 'Stand-by',
                aborted: 'Abandonné',
                archived: 'Archivé',
            },
            assignation: { all: 'Toutes' },
            tags: { all: 'Tous' },
        },
        table: {
            columns: {
                all: 'Toutes',
                code: 'Code',
                name: 'Libellé',
                wsjf: 'WSJF',
                business_value: 'Valeur  business',
                time_criticality: 'Contrainte  de temps',
                risk_opportunity:
                    "Réduction de  risque/Création  d'opportunité",
                cost: 'Coût du délai  de livraison',
                effort: 'Effort',
                status: 'Statut',
                tags: 'Tag',
                assignation: 'Assignation',
                end_prioritizing: 'Date de priorisation',
            },
        },
    },
    session: {
        fields: {
            status: {
                all: 'Toutes',
                '00_to_plan': 'A planifier',
                '01_planned': 'Planifiée',
                '02_prioritizing': 'En cours de priorisation',
                '03_reviewing': 'En review',
                '04_done': 'Terminée',
                '05_aborted': 'Abandonnée',
                '06_archived': 'Archivée',
            },
        },
        table: {
            columns: {
                all: 'Toutes',
                code: 'Code',
                name: 'Libellé',
                status: 'Statut',
                due_date: 'Date de priorisation',
            },
        },
    },
    teams: {
        fields: {
            status: {
                all: 'Toutes',
                on: 'Actif',
                off: 'Inactif',
                waiting: 'Invitation en attente...',
            },
            role: {
                all: 'Toutes',
                owner: 'Propriétaire',
                admin: 'Admin',
                member: 'Participant',
                guest: 'Invité',
            },
        },
        table: {
            columns: {
                all: 'Toutes',
                first_name: 'Prénom',
                last_name: 'Nom',
                role: 'Rôle',
                status: 'Statut',
                email: 'Email',
                phone_number: 'Téléphone',
                job: 'Poste',
                company_name: 'Entreprise',
            },
        },
    },
    seo: {
        title: {
            prefix: '',
            suffix: ' | Rankio',
            default: 'Rankio',
            landing: '🚀 Accueil',
            login: 'Se connecter',
            logout: 'Se déconnecter',
            signup: "S'inscrire",
            recovery: 'Mot de passe oublié',
            validate: '🪄 Magic link',
            reset: 'Reset',
            contact: 'Contact',
            subscribe: 'Beta 🚧',
            unsubscribe: 'Se désabonner',
            account: '👋 Hello',
            account_profile: 'Mon profil',
            account_setting: 'Paramètres',
            account_billing: 'Facturation',
            dashboard: 'Tableau de bord',
            workspace: 'Workspace',
            wsjf: 'Axes de priorisation',
            WsjfPreview: 'Méthode de calcul du WSJF',
            tags: 'Tags',
            project: 'Projets',
            session: 'Sessions de priorisation',
            teams: 'Utilisateurs',
            onboarding: 'Onboarding',
            healthcheck: '🟢 Santé du service',
        },
    },
    faq: [
        {
            request: "Qu'est-ce qu'un Admin ?",
            responses:
                "Un Admin est un utilisateur de l'application capable de :   __tab__- Configurer l'instance de priorisation de l'équipe ou de l'entreprise :  __tab____tab__- Gérer les axes de priorisation.  __tab____tab__- Gérer les projets.  __tab____tab__- Gérer les sessions de priorisation.  __tab____tab__- Gérer les invitations pour le scoring des projets.  __tab__- Mettre à jour toutes les informations de l'application (sauf le scoring produit par les Participants).  __tab__- Inviter des utilisateurs et gérer leurs droits au sein du workspace.  __tab__- Modifier l'offre et les options souscrites et gérer la facturation (uniquement pour l'Admin propriétaire du workspace).",
        },
        {
            request: "Qu'est-ce qu'un Participant ?",
            responses:
                "Un Participant est un utilisateur de l'application capable de :   __tab__- Accéder en lecture seule à l'ensemble de l'application, et notamment à la liste des projets priorisés ainsi qu'à leurs détails. Il peut ainsi modifier les filtres des tableaux comme il le souhaite, mais ne peut modifier aucune donnée.  __tab__- Scorer les projets, selon les autorisations données par l'Admin.",
        },
        {
            request: "Qu'est-ce qu'un Invité ?",
            responses:
                "Un Invité est un utilisateur de l'application capable de :   __tab__- Accéder en lecture seule à l'ensemble de l'application, et notamment à la liste des projets priorisés ainsi qu'à leurs détails. Il peut ainsi modifier les filtres des tableaux comme il le souhaite, mais ne peut modifier aucune donnée.",
        },
        {
            request: "Qu'est-ce qu'un Workspace ?",
            responses:
                "Un Workspace est un environnement de travail dédié à une équipe, managé par un ou plusieurs Admins, et regroupant des projets priorisés les uns par rapport aux autres au sein d'un portfolio cohérent.  Il est défini par une configuration unique d'axes de priorisation qui reflète la stratégie de l'équipe ou de l'entreprise.",
        },
        {
            request: "Qu'est-ce qu'un Axe de priorisation ?",
            responses:
                "Un Axe de priorisation est un élément qui reflète la stratégie de l'équipe ou de l'entreprise, et qui permet de comparer tous les projets selon les mêmes critères.  Ensemble, ils forment la configuration unique d'un workspace.",
        },
        {
            request: "Qu'est-ce qu'une Session de priorisation ?",
            responses:
                "Une Session de priorisation est le moment où des porteurs de projets présentent leurs besoins à l'ensemble des Participants, leur permettant ensuite de les scorer pour définir leur ordre de priorité de réalisation.",
        },
        {
            request:
                "Qu'est-ce que la méthode du WSJF (Weighted Shortest Job First) utilisée au sein de l'application Rankio ?",
            responses:
                'Le WSJF (Weighted Shortest Job First) est une méthode de priorisation, popularisée notamment au travers du développement du framework Lean Agile SAFe, qui permet de déterminer la priorité des tâches à réaliser en fonction de leur propension à créer de la valeur.  Elle consiste à accorder une priorité de réalisation plus haute aux fonctionnalités les plus importantes, les plus créatrices de valeur, et les plus courtes.',
        },
        {
            request: 'Comment ajouter des Admin ?',
            responses:
                "Pour ajouter un Admin, il suffit de modifier le rôle d'un utilisateur existant ou bien d'inviter un nouvel utilisateur en lui affectant le rôle d'Admin. Il n'y a pas de restriction dans le nombre d'Admin au sein d'un workspace, vous pouvez en ajouter autant que vous voulez.  Il faut néanmoins savoir que chaque Admin supplémentaire est une option payante : chaque ajout suppose l'achat d'un abonnement supplémentaire au même tarif et à la même périodicité que l'abonnement principal.",
        },
        {
            request: "Qu'est-ce que la période de test ?",
            responses:
                "Vous pouvez essayer gratuitement l'ensemble des fonctionnalités de l'application pendant cette période de test de 14 jours. Cet essai est sans engagement et il n'est pas nécessaire de renseigner des moyens de paiement pour y avoir droit.  A l'issue de cette période de test, nous vous solliciterons pour souscrire un abonnement.",
        },
        {
            request: "Quelles sont les conditions d'engagement ?",
            responses:
                "La période de test est un essai gratuit sans engagement, à l'issue de laquelle un abonnement devra être souscrit pour continuer à accéder au service.   L'abonnement mensuel est sans engagement. Il est renouvelé automatiquement, sauf en cas de demande de résiliation. Il peut être interrompu à n'importe quel moment. La résiliation effective a lieu à l'issue de la période déjà réglée.   L'abonnement annuel représente un engagement d'un an. Il est renouvelé automatiquement, sauf en cas de demande de résiliation. Il peut être interrompu à n'importe quel moment. La résiliation effective a lieu à l'issue de la période déjà réglée.",
        },
        {
            request: 'Comment résilier ?',
            responses:
                "La résiliation est automatique à l'issue de la période de test en cas de non abonnement.  Elle est sinon disponible dans les paramètres de l'application, rubrique Facturation, et prend effet à l'issue de la facturation en cours au moment de la démarche (soit à la fin du mois en cours dans le cas de l'abonnement mensuel, soit à la fin de l'année en cours dans le cas de l'abonnement annuel).",
        },
        {
            request:
                'Quelles sont les modalités de confidentialité des données ?',
            responses:
                "Par défaut, les contrôles de sécurité de base de Rankio assurent le chiffrement du trafic et du stockage des données. Celles-ci ne sont conservées que jusqu'à 3 mois après la fin de l'abonnement pour permettre une reprise d'activité rapide en cas de réabonnement.   En outre, la protection de vos informations commence par un contrôle des identités. Rankio vous permet non seulement de gérer vos utilisateurs et de simplifier leur authentification, mais aussi d’attribuer des autorisations et des rôles. Nous vous offrons ainsi les solutions qui vous permettront de vous assurer que seuls les personnes autorisées pourront accéder aux informations de votre entreprise dans Rankio.",
        },
    ],
    commun: {
        all: 'Toutes',
        search: 'Recherche',
        gt: 'Minimum',
        lt: 'Maximum',
        date_gt: 'Date minimum',
        date_lt: 'Date maximum',
        column: 'Colonne',
        columns: 'Colonnes',
        filter: 'Filtre',
        filters: 'Filtres',
        export: 'Export csv',
        table_options: `Options  du tableau`,
        undefined_category: `Categorie non définie`,
        undefined_tag: `Tag non défini`,
    },
    button: {
        buy: 'Achetez',
        test: 'Testez gratuitement',
        discover: 'Réservez  une démo',
    },
    img: {
        dashboard_title: 'Une liste de projets priorisés',
        dashboard_alt: 'Consulter le dashboard listant les projets priorisés',
    },
    home: {
        title: "L'outil collaboratif  pour prioriser vos projets",
        sub_title:
            'Rankio aide vos équipes à délivrer plus de valeur  en sélectionnant les bons projets à lancer',
    },
    features: {
        title: 'Fonctionnalités',
        sub_title1: 'Une liste de projets priorisés',
        descr1: 'Classez vos projets en fonction de leur capacité à créer le plus de valeur le plus rapidement possible en suivant la méthode du WSJF (Weighted Shortest Job First) et définissez ainsi leur ordre de réalisation.',
        descr1bis:
            'Filtrez-les aisément pour avoir une vision plus opérationnelle et déterminez ceux qui vont entrer dans le backlog de chacune de vos équipes de réalisation.',
        sub_title2: 'Un scoring collaboratif',
        descr2: "Bénéficiez de l'intelligence collective et invitez l'ensemble de vos équipes à donner un score pour chacun de vos projets aussi simplement qu'un poker planning.",
        descr2bis:
            'Utilisez la diversité des expertises de vos participants (Business, Marketing, Design, IT) pour co-construire une roadmap produit partagée et efficace.',
        sub_title3: 'La personnalisation de vos axes stratégiques',
        descr3: 'Choisissez les axes de priorisation qui reflètent le mieux la stratégie de votre entreprise ou de votre équipe.',
        descr3bis:
            'Alignez vos différentes équipes dans une direction commune pour une meilleure efficience.',
    },
};
