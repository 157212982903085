import React, { useState } from 'react';
import { Collapse, Form } from 'react-bootstrap';
import { FiTrash2 } from 'react-icons/fi';

function Axes(props) {
    const [open, setOpen] = useState(false);
    return (
        <div className={` ${props.className} AxeContainer p-3`}>
            <Form>
                <Form.Group controlId="axe">
                    <div className="flex v-center mb-3">
                        <div
                            onClick={() => setOpen(!open)}
                            className="mr-3 cursor-pointer"
                        >
                            {open === false
                                ? props.openIconName
                                : props.closeIconName}
                        </div>
                        <Form.Control
                            className={`${props.inputNewAxeNameErr} ${props.disabled} font-weight-800 input mr-3`}
                            type="text"
                            placeholder={props.palceholderFirstInput}
                            style={{ maxWidth: '38ch' }}
                            value={props.valueFirstInput}
                            onChange={(e) => props.onChangeInputName(e)}
                            disabled={
                                props.disabledName
                                    ? props.disabledName
                                    : props.disabled
                            }
                        />
                        {!props.effort && (
                            <div style={{ position: 'relative' }}>
                                <Form.Control
                                    className={`${props.inputClassname} ${props.disabled} ${props.inputNewAxeValueErr} font-weight-800 input`}
                                    type="text"
                                    placeholder={props.palceholderSecondInput}
                                    style={{
                                        maxWidth: '14ch',
                                    }}
                                    value={props.valueSecondInput}
                                    onChange={(e) =>
                                        props.onChangeInputValue(e)
                                    }
                                    disabled={props.disabled}
                                />
                                <span
                                    className={
                                        props.deleteSousAxe ||
                                            props.switch === true
                                            ? `${props.disabled} axeValueInputDetailsSmall`
                                            : `${props.disabled} axeValueInputDetails`
                                    }
                                >
                                    %
                                </span>
                            </div>
                        )}
                        {props.switch === true && (
                            <div className="tt">
                                <Form.Check
                                    className="switchButtonStyle mx-3"
                                    checked={props.checked}
                                    type="switch"
                                    size="xl"
                                    id={props.id}
                                    onChange={props.onCkecked}
                                    disabled={
                                        props.disabled === true ? true : false
                                    }
                                />
                            </div>
                        )}
                        {props.deleteButton === true && (
                            <div
                                onClick={() => props.deleteSousAxe()}
                                className="pointer deleteBtnAxes"
                                disabled={props.disabled}
                            >
                                <FiTrash2
                                    size="2rem"
                                    color="#999999"
                                    className="ml-3"
                                />
                            </div>
                        )}
                    </div>
                </Form.Group>
                <Collapse in={open}>
                    <div className="axesCollapseContainer">
                        <Form.Control
                            className={`${props.inputDuplicatedError} ${props.disabled} ${props.inputnewAxeCodeErr} font-weight-600 input mb-3`}
                            type="text"
                            placeholder={props.palceholderThirdInput}
                            style={{ maxWidth: '10ch' }}
                            value={props.valueThirdInput}
                            onChange={(e) => props.onChangeInputCode(e)}
                            disabled={
                                props.disabledCode
                                    ? props.disabledCode
                                    : props.disabled
                            }
                        />
                        <Form.Control
                            as="textarea"
                            rows={5}
                            className={`${props.disabled} font-weight-600 input mr-40`}
                            type="text"
                            placeholder={props.descriptionPlaceholder}
                            value={props.description}
                            disabled={props.disabled}
                            onChange={(e) => props.changeDescription(e)}
                        />
                    </div>
                </Collapse>
            </Form>
        </div>
    );
}

export default Axes;
