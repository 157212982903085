import React, { useState } from 'react';
import { Collapse, Dropdown, Form } from 'react-bootstrap';
import { FiTrash2, FiPlus } from 'react-icons/fi';

function TagsDetails(props) {
    const [open, setOpen] = useState(false);
    const [colorsList, setColorsList] = useState([
        'ffaa00',
        'ff0055',
        '00ff55',
        '2b00ff',
    ]);
    return (
        <div className={` ${props.className} AxeContainer p-3`}>
            <Form>
                <Form.Group controlId="axe">
                    <div className="flex v-center mb-3">
                        <div
                            onClick={() => setOpen(!open)}
                            className="mr-3 cursor-pointer"
                        >
                            {open === false
                                ? props.openIconName
                                : props.closeIconName}
                        </div>
                        <Form.Control
                            className={`font-weight-800 input mr-3 ${
                                !props.tags.active
                            }`}
                            type="text"
                            placeholder={props.placeholderFirstInput}
                            maxLength="40"
                            style={{ maxWidth: '30ch' }}
                            value={props.tags.name}
                            onChange={(e) => props.onChangeInputName(e)}
                            onBlur={() => props.onBlurInputName()}
                            disabled={
                                !props.tags.active ||
                                !['owner', 'admin'].includes(props.userRole)
                            }
                        />
                        {['owner', 'admin'].includes(props.userRole) ? (
                            <>
                                <Dropdown className="mx-2" id="tags-dropdown">
                                    <Dropdown.Toggle
                                        disabled={!props.tags.active}
                                        className="p-0 mx-2 tags-color"
                                        style={{
                                            backgroundColor:
                                                '#' + props.tags.color,
                                        }}
                                    ></Dropdown.Toggle>
                                    <Dropdown.Menu
                                        className="radius-14 bg-white shadow  p-0 mt-1"
                                        style={{ width: '20ch' }}
                                    >
                                        {colorsList
                                            .filter(
                                                (el) => el !== props.tags.color
                                            )
                                            .map((color, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() =>
                                                        props.onChangeColor(
                                                            color
                                                        )
                                                    }
                                                    className="pointer tags-color"
                                                    style={{
                                                        backgroundColor:
                                                            '#' + color,
                                                    }}
                                                ></div>
                                            ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </>
                        ) : (
                            <div
                                className="pointer tags-color mx-3"
                                style={{
                                    backgroundColor: '#' + props.tags.color,
                                }}
                            ></div>
                        )}
                        {props.switch === true && (
                            <Form.Check
                                className="switchButtonStyle mx-3"
                                checked={props.tags.active}
                                type="switch"
                                size="xl"
                                id={`c${props.cIndex}`}
                                onChange={() =>
                                    props.onCheckCategory(!props.tags.active)
                                }
                                disabled={
                                    !['owner', 'admin'].includes(props.userRole)
                                }
                            />
                        )}
                        {['owner', 'admin'].includes(props.userRole) && (
                            <div
                                onClick={() => props.deleteCategory()}
                                className="pointer"
                            >
                                <FiTrash2
                                    size="2rem"
                                    color="#999999"
                                    className="ml-4"
                                />
                            </div>
                        )}
                        {/* {props.drgaBtn === true && <div className='pointer'><FiMenu size="2.2rem" className='ml-20' /></div>} */}
                    </div>
                </Form.Group>
                <Collapse in={open}>
                    <div>
                        {props.tags.tags.map((tag, tIndex) => {
                            return (
                                <div
                                    key={tIndex}
                                    className="flex v-center mb-3 ml-5"
                                >
                                    <Form.Control
                                        className={`text-white text-align-center input mr-3 ${
                                            !props.tags.active
                                        }`}
                                        onKeyUp={(event) => {
                                            event.target.value = event.target.value.replace(
                                                /([^0-9a-zA-Z])/g,
                                                ''
                                            );
                                        }}
                                        maxLength="7"
                                        type="text"
                                        placeholder="Tag"
                                        style={{
                                            maxWidth: '8ch',
                                            backgroundColor:
                                                '#' + props.tags.color,
                                        }}
                                        value={tag.code}
                                        onChange={(e) =>
                                            props.onChangeTagInputCode(
                                                e,
                                                props.cIndex,
                                                tIndex
                                            )
                                        }
                                        onBlur={() =>
                                            props.onBlurTagInputName()
                                        }
                                        disabled={
                                            !props.tags.active ||
                                            !['owner', 'admin'].includes(
                                                props.userRole
                                            )
                                        }
                                    />
                                    <Form.Control
                                        className={`input mr-3 ${
                                            !props.tags.active
                                        }`}
                                        maxLength="40"
                                        type="text"
                                        placeholder={
                                            props.placeholderSecondInput
                                        }
                                        style={{ maxWidth: '35ch' }}
                                        value={tag.name}
                                        onChange={(e) =>
                                            props.onChangeTagInputName(
                                                e,
                                                props.cIndex,
                                                tIndex
                                            )
                                        }
                                        onBlur={() =>
                                            props.onBlurTagInputName()
                                        }
                                        disabled={
                                            !props.tags.active ||
                                            !['owner', 'admin'].includes(
                                                props.userRole
                                            )
                                        }
                                    />
                                    {['owner', 'admin'].includes(
                                        props.userRole
                                    ) && (
                                        <div
                                            onClick={() =>
                                                props.deleteTag(
                                                    props.cIndex,
                                                    tIndex
                                                )
                                            }
                                            className="pointer"
                                        >
                                            <FiTrash2
                                                size="1.8rem"
                                                color="#999999"
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                        {['owner', 'admin'].includes(props.userRole) && (
                            <FiPlus
                                size="2rem"
                                className="rk-clickable-icon-secondary ml-5"
                                onClick={() => props.onAddTagClick()}
                            />
                        )}
                    </div>
                </Collapse>
            </Form>
        </div>
    );
}

export default TagsDetails;
