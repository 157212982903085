import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './components/styles/tables.css';
import reportWebVitals from './reportWebVitals';
import Init from './util/Init';
import HealthCheck from './util/HealthCheck';
import Config from './util/Config';
import ErrorSelector from './components/error/ErrorSelector';
import { Provider } from 'react-redux';
import store from './redux/Store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { persistor } from './redux/Store';
const instance = createInstance(Config.ANALYTICS);
const container = document.getElementById('root');
// Create a root.
const root = ReactDOMClient.createRoot(container);
const Rankio = (status = true) => {
    Config.APP_ENV != 'production' && console.log(Config);
    setTimeout(
        () => {
            Init.set()
                .then(() => {
                    document.getElementById('init').remove();
                    if (status === true) {
                        root.render(
                            <Provider store={store}>
                                <PersistGate persistor={persistor}>
                                    <MatomoProvider value={instance}>
                                        <App />
                                    </MatomoProvider>
                                </PersistGate>
                            </Provider>
                        );
                    } else {
                        root.render(
                            <React.StrictMode>
                                <Router>
                                    <ErrorSelector
                                        logo="1"
                                        code={503}
                                        title="503"
                                        message="Le service est momentanément indisponible."
                                        retry_value="Réessayer"
                                        retry_href="javascript:window.location.reload();"
                                        retry_color="primary"
                                        retry_icone="reload"
                                    />
                                </Router>
                            </React.StrictMode>
                        );
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // document.getElementById("init").remove();
                    root.render(
                        <React.StrictMode>
                            <Router>
                                <ErrorSelector
                                    logo="1"
                                    code={418}
                                    title="418"
                                    message="La configuration a échoué.  Il s'agit probablement d'un problème de compatibilité.    Contactez-nous pour obtenir de l'aide :    support@rankio.app    ou"
                                    retry_value="Réinitialiser l'application"
                                    retry_href="/reset"
                                    retry_color="danger"
                                    retry_icone="email"
                                />
                            </Router>
                        </React.StrictMode>
                    );
                });
        },
        document.cookie.indexOf('RK_init') !== -1 ? 0 : 1
    );
};
HealthCheck()
    .then((availability) => {
        if (availability === 'ok') {
            Rankio(true);
        } else {
            Rankio(false);
        }
    })
    .catch((error) => {
        console.log(error);
        Rankio(false);
    });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
