export default {
    options: {
        all: false,
        first_name: true,
        last_name: true,
        role: true,
        status: true,
        email: true,
        phone_number: false,
        job: false,
        company_name: false,
    },
    sizes: {
        first_name: 10,
        last_name: 10,
        role: 10,
        status: 8,
        email: 14,
        phone_number: 8,
        job: 10,
        company_name: 10,
    },
    pagination: { limit: 500, skip: 0 },
    sort: { type: '', column: '' },
    filter: {
        first_name: '',
        last_name: '',
        role: {
            all: true,
            owner: true,
            admin: true,
            member: true,
            guest: true,
        },
        status: {
            all: false,
            on: true,
            off: false,
            waiting: true,
        },
        email: '',
        phone_number: '',
        job: '',
        company_name: '',
    },
    type: {
        first_name: 'string',
        last_name: 'string',
        role: 'role',
        status: 'status_teams',
        email: 'string',
        phone_number: 'string',
        job: 'string',
        company_name: 'string',
    },
};
