import React, { useState, useEffect } from 'react';
import { Collapse, Row, Col, Table, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FiInfo, FiCalendar } from 'react-icons/fi';
import Modal from '../commun/RkModal';
import Echo from '../../util/Echo';
import Project from '../../util/Project';
import CalcSquare from '../commun/CalcSquare';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export default (props) => {
    const { id } = useParams();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = activeUser[0].workspace[activeUser[0].activeWorkspace];
    const deletable = ['owner', 'admin'].includes(
        workspace.object.teams.on.filter(
            (team) => team.id === activeUser[0].id
        )[0].role
    );
    const readOnly = !deletable || props.data?.object?.status === 'archived';
    const [showChart, setShowChart] = useState(false);
    const data = {
        labels: ['0', '1', '2', '3', '5', '8', '13', '20', '40', '100', '?'],
        datasets: [
            {
                label: 'Effort',
                data: [0, 3, 1, 5, 0, 0, 0, 0, 0, 0, 1],
                backgroundColor: 'rgba(0,85,255)',
            },
        ],
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
    };

    return (
        <>
            <div className="bg-gray mt-2 font-weight-700 display-24 p-3 mb-3 text-uppercase">
                Scoring
            </div>
            {props.data?.object?.rating ? (
                <>
                    <div className="m-2 ml-3">
                        <Form.Group>
                            <Form.Label className="input-label">
                                Date du scoring
                            </Form.Label>
                            <Row className="m-0">
                                <Form.Control
                                    className="input"
                                    style={{ maxWidth: '15ch' }}
                                    value={new Date(
                                        props.data.object.rating.end_prioritizing
                                    ).toLocaleDateString('fr-FR')}
                                    disabled={true}
                                />
                                <FiCalendar
                                    size="1.3rem"
                                    className="datepicker-icon my-auto"
                                />
                            </Row>
                        </Form.Group>
                    </div>
                    <div
                        className="m-3 mb-5 border border-gray radius-14"
                        style={{
                            maxWidth:
                                props.data.object.rating.wsjf.business_value.axis.filter(
                                    (ax) => ax.active
                                ).length *
                                    130 +
                                520 +
                                (props.data.object.rating.wsjf.risk_opportunity
                                    .active
                                    ? 200
                                    : 0) +
                                (props.data.object.rating.wsjf.time_criticality
                                    .active
                                    ? 130
                                    : 0) +
                                'px',
                        }}
                    >
                        <Table responsive borderless striped className="m-0">
                            <thead>
                                <tr>
                                    <th className="font-weight-700 display-14 p-2 color-gray minem-w-8">
                                        WSJF
                                    </th>
                                    <th className="font-weight-700 display-14 p-2 color-gray minem-w-8">
                                        Valeur business
                                    </th>
                                    {props.data.object.rating.wsjf.business_value.axis.filter(
                                        (ax) => ax.active
                                    ).length ? (
                                        props.data.object.rating.wsjf.business_value.axis.map(
                                            (axis) => {
                                                if (axis.active)
                                                    return (
                                                        <th className="font-weight-700 display-14 p-2 color-gray minem-w-8">
                                                            {axis.name}
                                                        </th>
                                                    );
                                            }
                                        )
                                    ) : (
                                        <></>
                                    )}
                                    {props.data.object.rating.wsjf
                                        .time_criticality.active ? (
                                        <th className="font-weight-700 display-14 p-2 color-gray minem-w-8">
                                            Contrainte de temps
                                        </th>
                                    ) : (
                                        <></>
                                    )}
                                    {props.data.object.rating.wsjf
                                        .risk_opportunity.active ? (
                                        <th className="font-weight-700 display-14 p-2 color-gray minem-w-12">
                                            Réduction de risque /
                                            <br />
                                            Création d'opportunité
                                        </th>
                                    ) : (
                                        <></>
                                    )}
                                    <th className="font-weight-700 display-14 p-2 color-gray minem-w-8">
                                        Coût du délai
                                        <br />
                                        de livraison
                                    </th>
                                    <th className="font-weight-700 display-14 p-2 color-gray minem-w-6">
                                        Effort
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                onClick={() => setShowChart(!showChart)}
                            >
                                <tr
                                className="radius-buttom-14">
                                    <td className="minem-w-8 p-2">
                                        <CalcSquare
                                            centred={false}
                                            value={
                                                props.data.object.rating.result
                                                    .wsjf || '-'
                                            }
                                            variant="primary"
                                        />
                                    </td>
                                    <td className="minem-w-8 p-2">
                                        <CalcSquare
                                            value={
                                                props.data.object.rating.result
                                                    .business_value
                                            }
                                        />
                                    </td>
                                    {props.data.object.rating.wsjf.business_value.axis.filter(
                                        (ax) => ax.active
                                    ).length ? (
                                        props.data.object.rating.wsjf.business_value.axis.map(
                                            (axis) => {
                                                if (axis.active)
                                                    return (
                                                        <td className="minem-w-8 p-2">
                                                            <CalcSquare
                                                                value={
                                                                    props.data
                                                                        .object
                                                                        .rating
                                                                        .result
                                                                        .business_value_axis[
                                                                        axis.ref
                                                                    ] || '-'
                                                                }
                                                            />
                                                        </td>
                                                    );
                                            }
                                        )
                                    ) : (
                                        <></>
                                    )}
                                    {props.data.object.rating.wsjf
                                        .time_criticality.active ? (
                                        <td className="minem-w-8 p-2">
                                            <CalcSquare
                                                value={
                                                    props.data.object.rating
                                                        .result
                                                        .time_criticality || '-'
                                                }
                                            />
                                        </td>
                                    ) : (
                                        <></>
                                    )}
                                    {props.data.object.rating.wsjf
                                        .risk_opportunity.active ? (
                                        <td className="minem-w-12 p-2">
                                            <CalcSquare
                                                value={
                                                    props.data?.object?.rating
                                                        ?.result
                                                        ?.risk_opportunity ||
                                                    '-'
                                                }
                                            />
                                        </td>
                                    ) : (
                                        <></>
                                    )}
                                    <td className="minem-w-8 p-2">
                                        <CalcSquare
                                            value={
                                                props.data?.object?.rating
                                                    ?.result?.cost
                                            }
                                        />
                                    </td>
                                    <td className="minem-w-6 p-2">
                                        <CalcSquare
                                            value={
                                                props.data?.object?.rating
                                                    ?.result?.effort
                                            }
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        {/*
                        <Collapse in={showChart}>
                            <Bar options={options} data={data} />
                        </Collapse>*/}
                    </div>
                </>
            ) : (
                <Row
                    className="no-gutters font-weight-700 p-3 mt-3 mb-5 display-16"
                    style={{
                        backgroundColor: 'rgba(0, 85, 255, 0.1)',
                    }}
                >
                    <Col lg={'auto'}>
                        <FiInfo
                            color="#0055ff"
                            size="2.2rem"
                            className="mr-3 mt-1"
                        />
                    </Col>
                    <Col>
                        Cette section est incomplète car le projet n'a jamais
                        été scoré.
                        <br />
                        {props.data?.object?.status === 'archived' ? (
                            <>
                                Ce projet ne peut pas être priorisé, car il a
                                été archivé.
                            </>
                        ) : (
                            <>
                                Les informations seront ajoutées automatiquement
                                à l'issue de la session de priorisation
                                contenant ce projet.
                            </>
                        )}
                    </Col>
                </Row>
            )}
        </>
    );
};
