import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Col, Row } from 'react-bootstrap';
import {
    FiPlusCircle,
    FiPlus,
    FiActivity,
    FiChevronDown,
    FiChevronUp,
    FiEdit,
    FiSave,
    FiX,
    FiCornerDownRight,
} from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DarkButton from '../commun/DarkButton';
import BlueButton from '../commun/BlueButton';
import GreyButton from '../commun/GreyButton';
import Axes from './Axes';
import Alert from '../commun/Alert';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import WsjfPreview from './WsjfPreview';
import RkModal from '../commun/RkModal';
import Modal from '../modal/Modal';
import DefaultConfig from '../../default/fr/stratdriver';
const newAxeRef = React.createRef();

function AxePrioBody(props) {
    const dispatch = useDispatch();
    const { id } = useParams();
    const history = useHistory();
    const users = useSelector((state) => state.user.users);
    let activeUser = users.filter((user) => user.id.includes(id));
    let Workspace = activeUser[0].workspace[activeUser[0].activeWorkspace];

    const [showAlertDuplicated, setShowAlertDuplicated] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);
    const [showAlertDriver, setShowAlertDriver] = useState(false);
    const [showAlertStratDriver, setShowAlertStratDriver] = useState(false);
    const [Updated, setUpdated] = useState(false);
    const [BusinessValues, setBusinessValues] = useState(
        activeUser[0].workspace[activeUser[0].activeWorkspace].object.wsjf
            .business_value
    );
    const [TimeCriticality, setTimeCriticality] = useState(
        activeUser[0].workspace[activeUser[0].activeWorkspace].object.wsjf
            .time_criticality
    );
    const [RiskOpportunity, setRiskOpportunity] = useState(
        activeUser[0].workspace[activeUser[0].activeWorkspace].object.wsjf
            .risk_opportunity
    );
    const [Effort, setEffort] = useState(
        activeUser[0].workspace[activeUser[0].activeWorkspace].object.wsjf
            .effort
    );
    const [sousAxeError, setSousAxeError] = useState(false);
    const [sousAxeDiplicatedError, setSousAxeDiplicatedError] = useState(false);
    const [axeError, setAxeError] = useState(false);
    const [active, setActive] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalInit, setShowModalInit] = useState(false);
    const [disabledInput, setDisabledInput] = useState(true);
    const [axisDeleted, setAxisDeleted] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showAlertAddAxe, setShowAlertAddAxe] = useState(false);
    const [updatedCodes, setUpdatedCodes] = useState([]);
    const [updatedCodesIndex, setUpdatedCodesIndex] = useState([]);

    const getWorkspace = () => {
        Axios({
            ...ApiCallConfig,
            method: 'GET',
            url: '/workspace',
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
        }).then((res) => {
            if (res.status === 200) {
                setBusinessValues(
                    res.data.data[activeUser[0].activeWorkspace].object.wsjf
                        .business_value
                );
                setTimeCriticality(
                    res.data.data[activeUser[0].activeWorkspace].object.wsjf
                        .time_criticality
                );
                setRiskOpportunity(
                    res.data.data[activeUser[0].activeWorkspace].object.wsjf
                        .risk_opportunity
                );
                setEffort(
                    res.data.data[activeUser[0].activeWorkspace].object.wsjf
                        .effort
                );
            }
        });
    };
    useEffect(() => {
        getWorkspace();
        document.onkeyup = function (e) {
            switch (true) {
                case e.ctrlKey && e.altKey && e.which == 73:
                    setShowModalInit(true);
                    break;
            }
        };
    }, []);

    useEffect(() => {
        AnnulerBtnFunction();
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth);
        });
    }, [activeUser[0].activeWorkspace]);

    const scrollToBottom = () => {
        newAxeRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const addAxeFunction = () => {
        if (BusinessValues.axis.length >= 3) {
            setShowAlertAddAxe(true);
            setTimeout(() => {
                setShowAlertAddAxe(false);
            }, 7000);
        }
        let newBusinessValues = BusinessValues;
        newBusinessValues.axis.push({
            ref: uuidv4(),
            active: true,
            code: '',
            name: '',
            value: 0,
        });
        setBusinessValues(newBusinessValues);
        setUpdated(!Updated);
        scrollToBottom();
    };
    const setShowModalDetails = () => {
        setShowModal(!showModal);
    };
    const onChangeInputValue = (e, axis) => {
        setAxeError(false);
        var reg = '^[0-9]*$';
        if (e.target.value.match(reg) && e.target.value <= 100) {
            switch (axis) {
                case 'business':
                    let newBV = BusinessValues;
                    newBV.value = Number(e.target.value);
                    setBusinessValues(newBV);
                    setUpdated(!Updated);
                    break;
                case 'time':
                    let newTC = TimeCriticality;
                    newTC.value = Number(e.target.value);
                    setTimeCriticality(newTC);
                    setUpdated(!Updated);
                    break;
                case 'risk':
                    let newRO = RiskOpportunity;
                    newRO.value = Number(e.target.value);
                    setRiskOpportunity(newRO);
                    setUpdated(!Updated);
                    break;
                default:
                    break;
            }
        }
    };
    const onChangeAxisInputValue = (e, index) => {
        setSousAxeError(false);
        var reg = '^[0-9]*$';
        if (e.target.value.match(reg) && Number(e.target.value) <= 100) {
            let newBusValue = BusinessValues;
            let axe = newBusValue.axis[index];
            axe.value = Number(e.target.value);
            newBusValue.axis[index] = axe;
            setBusinessValues(newBusValue);
            setUpdated(!Updated);
        }
    };
    const onChangeAxisInputCode = (e, index) => {
        setUpdatedCodesIndex([]);
        let newBusValue = BusinessValues;
        let axe = newBusValue.axis[index];
        axe.code = e.target.value.toUpperCase();
        newBusValue.axis[index] = axe;
        setBusinessValues(newBusValue);
        setUpdated(!Updated);
        let ObjectUpdated = {
            index: index,
            value: e.target.value,
        };
        let findElement = updatedCodes.findIndex((el) => el.index === index);
        let copyArry = updatedCodes;
        if (findElement !== -1) {
            copyArry[findElement] = ObjectUpdated;
        } else {
            copyArry.push(ObjectUpdated);
        }
        setUpdatedCodes(copyArry);
    };
    const onChangeAxisInputName = (e, index) => {
        let newBusValue = BusinessValues;
        let axe = newBusValue.axis[index];
        axe.name = e.target.value;
        newBusValue.axis[index] = axe;
        setBusinessValues(newBusValue);
        setUpdated(!Updated);
    };
    const onChangeAxisInputDescription = (e, index) => {
        let newBV = BusinessValues;
        newBV.axis[index].description = e.target.value;
        setBusinessValues(newBV);
        setUpdated(!Updated);
    };
    const onChangeInputCode = (e, axis) => {
        switch (axis) {
            case 'business':
                let newBV = BusinessValues;
                newBV.code = e.target.value;
                setBusinessValues(newBV);
                setUpdated(!Updated);
                break;
            case 'time':
                let newTC = TimeCriticality;
                newTC.code = e.target.value;
                setTimeCriticality(newTC);
                setUpdated(!Updated);
                break;
            case 'risk':
                let newRO = RiskOpportunity;
                newRO.code = e.target.value;
                setRiskOpportunity(newRO);
                setUpdated(!Updated);
                break;
            case 'effort':
                let newEff = Effort;
                newEff.code = e.target.value;
                setEffort(newEff);
                setUpdated(!Updated);
                break;
            default:
                break;
        }
    };
    const onChangeInputName = (e, axis) => {};
    const onChageInputDescription = (e, axis) => {
        switch (axis) {
            case 'business':
                let newBV = BusinessValues;
                newBV.description = e.target.value;
                setBusinessValues(newBV);
                setUpdated(!Updated);
                break;
            case 'time':
                let newTC = TimeCriticality;
                newTC.description = e.target.value;
                setTimeCriticality(newTC);
                setUpdated(!Updated);
                break;
            case 'risk':
                let newRO = RiskOpportunity;
                newRO.description = e.target.value;
                setRiskOpportunity(newRO);
                setUpdated(!Updated);
                break;
            case 'effort':
                let newEff = Effort;
                newEff.description = e.target.value;
                setEffort(newEff);
                setUpdated(!Updated);
                break;
            default:
                break;
        }
    };
    const deleteSousAxe = (index) => {
        let newBusinessValues = BusinessValues;
        let newAxis = BusinessValues.axis.filter((el, i) => index !== i);
        newBusinessValues.axis = newAxis;
        setBusinessValues(newBusinessValues);
        setUpdated(!Updated);
        setAxisDeleted(true);
    };
    const onCkeckedFunction = (el) => {
        let filterAxesIndex = BusinessValues.axis.findIndex(
            (axe) => axe.code === el.code
        );
        let newBusinessValues = BusinessValues;
        newBusinessValues.axis[filterAxesIndex].active =
            !newBusinessValues.axis[filterAxesIndex].active;
        setBusinessValues(newBusinessValues);
        setActive(!active);
    };
    const renderAxis = () => {
        return BusinessValues.axis.map((el, index) => (
            <Row className="m-0" style={{ maxWidth: '666px' }}>
                <Col sm={1} className="p-0 mt-4">
                    <FiCornerDownRight
                        className="text-secondary ml-3"
                        size="2.2rem"
                    />
                </Col>
                <Col className="p-0">
                    <Axes
                        className="ml-3 mb-4"
                        openIconName={<FiChevronDown size="2rem" />}
                        closeIconName={<FiChevronUp size="2rem" />}
                        palceholderFirstInput="Axe stratégique"
                        palceholderSecondInput="40"
                        palceholderThirdInput="AXS"
                        valueFirstInput={el.name}
                        valueSecondInput={el.active ? el.value : 0}
                        valueThirdInput={el.code}
                        descriptionPlaceholder={`Description de l'axe ${el.name}`}
                        description={el.description}
                        switch={true}
                        checked={el.active}
                        deleteButton={!disabledInput || props.onboarding}
                        id={el.code + index}
                        onChangeInputValue={(e) =>
                            onChangeAxisInputValue(e, index)
                        }
                        onChangeInputCode={(e) =>
                            onChangeAxisInputCode(e, index)
                        }
                        onChangeInputName={(e) =>
                            onChangeAxisInputName(e, index)
                        }
                        changeDescription={(e) =>
                            onChangeAxisInputDescription(e, index)
                        }
                        deleteSousAxe={() => deleteSousAxe(index)}
                        inputClassname={
                            sousAxeError && el.active === true
                                ? 'redContainer redBorder'
                                : ''
                        }
                        onCkecked={() => onCkeckedFunction(el)}
                        disabled={
                            disabledInput && props.onboarding === undefined
                                ? true
                                : ['owner', 'admin'].includes(
                                      Workspace.object.teams.on.filter(
                                          (user) => user.id === activeUser[0].id
                                      )[0].role
                                  )
                                ? false
                                : true
                        }
                        windowWidth={windowWidth}
                        inputDuplicatedError={
                            updatedCodesIndex.findIndex(
                                (el) => el === index
                            ) !== -1
                                ? 'redContainer'
                                : ''
                        }
                    />
                </Col>
            </Row>
        ));
    };

    const handleCloseAlert = () => {
        setShowAlertDriver(false);
        setShowAlertStratDriver(false);
        setShowAlertDuplicated(false);
    };
    const handleCloseNotif = () => {
        setShowAlertAddAxe(false);
    };
    const handleShow = () => {
        document.getElementById('view-formula').style.display = 'block';
    };

    const editBtnFunction = () => {
        setDisabledInput(false);
    };
    const AnnulerBtnFunction = () => {
        setDisabledInput(true);
        setAxisDeleted(false);
        setSousAxeError(false);
        setAxeError(false);
        setSousAxeDiplicatedError(false);
        getWorkspace();
    };
    const initConfig = () => {
        Axios({
            ...ApiCallConfig,
            method: 'PUT',
            url: `/workspace/${Workspace.id}/wsjf`,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
            data: DefaultConfig,
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: 'SET_WORKSPACE_DATA',
                        value: {
                            workspaceId: Workspace.id,
                            data: res.data.data,
                            id,
                            index: activeUser[0].activeWorkspace,
                        },
                    });
                    setDisabledInput(true);
                    setAxisDeleted(false);
                    setSousAxeError(false);
                    setAxeError(false);
                    getWorkspace();
                }
            })
            .catch((error) => {
                setShowAlertError(true);
            });
    };
    const saveBtnFunction = () => {
        let newWsjf = {
            business_value: BusinessValues,
            risk_opportunity: RiskOpportunity,
            time_criticality: TimeCriticality,
            effort: Effort,
        };
        Axios({
            ...ApiCallConfig,
            method: 'PUT',
            url: `/workspace/${Workspace.id}/wsjf`,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
            data: newWsjf,
        }).then((res) => {
            if (res.status === 200 || res.status === 204) {
                dispatch({
                    type: 'SET_WORKSPACE_DATA',
                    value: {
                        workspaceId: Workspace.id,
                        data: res.data.data,
                        id,
                        index: activeUser[0].activeWorkspace,
                    },
                });
                setDisabledInput(true);
                setAxisDeleted(false);
                setSousAxeError(false);
                setAxeError(false);
                if (props.onboarding) {
                    history.push(
                        '/' + id + '/onboarding/' + props.next || 'reset'
                    );
                }
            }
            if (res.data.message === 'duplicate business value axis code') {
                let arryIndex = [];
                BusinessValues.axis.map((el) => {
                    let findInAxes = updatedCodes.find(
                        (item) => item.value === el.code
                    );
                    if (findInAxes) {
                        if (arryIndex.length === 0) {
                            arryIndex.push(findInAxes.index);
                        } else if (arryIndex.indexOf(findInAxes.index) === -1) {
                            arryIndex.push(findInAxes.index);
                        }
                    }
                });
                setUpdatedCodesIndex(arryIndex);
                setSousAxeDiplicatedError(true);
                setShowAlertDuplicated(true);
                setTimeout(() => {
                    setShowAlertDuplicated(false);
                }, 3000);
            }
        });
    };

    const saveBtnClick = () => {
        let total = 0;
        let totalAxe = Number(BusinessValues.value);
        for (let i = 0; i < BusinessValues.axis.length; i++) {
            if (BusinessValues.axis[i].active === true) {
                total += Number(BusinessValues.axis[i].value);
            }
        }
        if (RiskOpportunity.active === true) {
            totalAxe = totalAxe + Number(RiskOpportunity.value);
        }
        if (TimeCriticality.active === true) {
            totalAxe = totalAxe + Number(TimeCriticality.value);
        }
        if (totalAxe !== 100) {
            setShowAlertDriver(true);
            setAxeError(true);
            setTimeout(() => {
                setShowAlertDriver(false);
            }, 5000);
        }
        if (
            BusinessValues.axis.filter((ax) => ax.active === true).length > 0 &&
            total !== 100
        ) {
            setShowAlertStratDriver(true);
            setSousAxeError(true);
            setTimeout(() => {
                setShowAlertStratDriver(false);
            }, 5000);
        } else if (axisDeleted && props.onboarding !== true) {
            setShowModal(true);
        } else {
            saveBtnFunction();
        }
    };

    return (
        <div className={props.onboarding ? '' : 'workspace-body-Controller'}>
            <Row className="axesContainer mx-0">
                <Col className="px-0">
                    <Axes
                        className="my-4 ml-3"
                        openIconName={<FiChevronDown size="2rem" />}
                        closeIconName={<FiChevronUp size="2rem" />}
                        palceholderFirstInput="Valeur business"
                        palceholderSecondInput="40"
                        palceholderThirdInput="VBU"
                        valueFirstInput={'Valeur business'}
                        valueSecondInput={BusinessValues.value}
                        valueThirdInput={BusinessValues.code}
                        switch={false}
                        deleteButton={false}
                        onChangeInputValue={(e) =>
                            onChangeInputValue(e, 'business')
                        }
                        onChangeInputCode={(e) =>
                            onChangeInputCode(e, 'business')
                        }
                        onChangeInputName={(e) =>
                            onChangeInputName(e, 'business')
                        }
                        inputClassname={axeError ? 'redContainer' : ''}
                        disabledName={true}
                        disabled={
                            disabledInput && props.onboarding === undefined
                                ? true
                                : ['owner', 'admin'].includes(
                                      Workspace.object.teams.on.filter(
                                          (user) => user.id === activeUser[0].id
                                      )[0].role
                                  )
                                ? false
                                : true
                        }
                        changeDescription={(e) =>
                            onChageInputDescription(e, 'business')
                        }
                        description={BusinessValues.description}
                    />
                    {renderAxis()}
                    <div ref={newAxeRef} />
                    {(!disabledInput || props.onboarding) && (
                        <FiPlus
                            size="2rem"
                            className="rk-clickable-icon-primary ml-5 mb-4"
                            onClick={() => addAxeFunction()}
                        />
                    )}
                    <Axes
                        className=" ml-3"
                        openIconName={<FiChevronDown size="2rem" />}
                        closeIconName={<FiChevronUp size="2rem" />}
                        palceholderFirstInput="Contrainte de temps"
                        palceholderSecondInput="30"
                        palceholderThirdInput="CTE"
                        valueSecondInput={
                            TimeCriticality.active ? TimeCriticality.value : 0
                        }
                        valueFirstInput={'Contrainte de temps'}
                        valueThirdInput={TimeCriticality.code}
                        switch={true}
                        deleteButton={false}
                        onChangeInputValue={(e) =>
                            onChangeInputValue(e, 'time')
                        }
                        onChangeInputCode={(e) => onChangeInputCode(e, 'time')}
                        inputClassname={
                            axeError && TimeCriticality.active === true
                                ? 'redContainer'
                                : ''
                        }
                        checked={TimeCriticality.active}
                        onCkecked={() => {
                            let newTC = TimeCriticality;
                            newTC.active = !TimeCriticality.active;
                            setTimeCriticality(newTC);
                            setUpdated(!Updated);
                            setAxeError(false);
                        }}
                        id="CTE"
                        changeDescription={(e) =>
                            onChageInputDescription(e, 'time')
                        }
                        description={TimeCriticality.description}
                        disabledName={true}
                        disabled={
                            disabledInput && props.onboarding === undefined
                                ? true
                                : ['owner', 'admin'].includes(
                                      Workspace.object.teams.on.filter(
                                          (user) => user.id === activeUser[0].id
                                      )[0].role
                                  )
                                ? false
                                : true
                        }
                    />
                    <Axes
                        className="my-4 ml-3"
                        openIconName={<FiChevronDown size="2rem" />}
                        closeIconName={<FiChevronUp size="2rem" />}
                        palceholderFirstInput="Réduction de risque ou création d'opportunité"
                        palceholderSecondInput="30"
                        palceholderThirdInput="RRO"
                        valueFirstInput={
                            "Réduction de risque ou création d'opportunité"
                        }
                        valueSecondInput={
                            RiskOpportunity.active ? RiskOpportunity.value : 0
                        }
                        valueThirdInput={RiskOpportunity.code}
                        switch={true}
                        deleteButton={false}
                        onChangeInputValue={(e) =>
                            onChangeInputValue(e, 'risk')
                        }
                        onChangeInputCode={(e) => onChangeInputCode(e, 'risk')}
                        onChangeInputName={(e) => onChangeInputName(e, 'risk')}
                        inputClassname={
                            axeError && RiskOpportunity.active == true
                                ? 'redContainer'
                                : ''
                        }
                        id="RRO"
                        checked={RiskOpportunity.active}
                        onCkecked={() => {
                            let newRO = RiskOpportunity;
                            newRO.active = !RiskOpportunity.active;
                            setRiskOpportunity(newRO);
                            setUpdated(!Updated);
                            setAxeError(false);
                        }}
                        changeDescription={(e) =>
                            onChageInputDescription(e, 'risk')
                        }
                        description={RiskOpportunity.description}
                        disabledName={true}
                        disabled={
                            disabledInput && props.onboarding === undefined
                                ? true
                                : ['owner', 'admin'].includes(
                                      Workspace.object.teams.on.filter(
                                          (user) => user.id === activeUser[0].id
                                      )[0].role
                                  )
                                ? false
                                : true
                        }
                    />
                    <Axes
                        className="mb-5 ml-3"
                        openIconName={<FiChevronDown size="2rem" />}
                        closeIconName={<FiChevronUp size="2rem" />}
                        valueFirstInput={'Effort'}
                        valueThirdInput={Effort.code}
                        deleteButton={false}
                        inputClassname={axeError ? 'redContainer' : ''}
                        id="EFF"
                        effort={true}
                        disabledName={true}
                        onChangeInputCode={(e) =>
                            onChangeInputCode(e, 'effort')
                        }
                        changeDescription={(e) =>
                            onChageInputDescription(e, 'effort')
                        }
                        description={Effort.description}
                        descriptionPlaceholder="Quelle est la taille de la fonctionnalité ou du projet à développer ? Quel est l'effort à fournir par l'équipe de développement pour sa réalisation ?"
                        disabled={
                            disabledInput && props.onboarding === undefined
                                ? true
                                : ['owner', 'admin'].includes(
                                      Workspace.object.teams.on.filter(
                                          (user) => user.id === activeUser[0].id
                                      )[0].role
                                  )
                                ? false
                                : true
                        }
                    />
                </Col>
                {props.onboarding === undefined ? (
                    <Col
                        lg={'auto'}
                        className="flex items-end column axe-priorisation-btn-container pl-4 pr-2 mt-3"
                    >
                        {disabledInput === true ? (
                            ['owner', 'admin'].includes(
                                Workspace.object.teams.on.filter(
                                    (user) => user.id === activeUser[0].id
                                )[0].role
                            ) && (
                                <BlueButton
                                    content={<>Modifier</>}
                                    icon={<FiEdit size="2.2rem" />}
                                    displayRowClassname="axeDisplayRowClassnameBtn"
                                    onClick={() => editBtnFunction()}
                                />
                            )
                        ) : (
                            <>
                                <BlueButton
                                    content={<>Enregistrer</>}
                                    icon={<FiSave size="2.2rem" />}
                                    displayRowClassname="axeDisplayRowClassnameBtn"
                                    onClick={() => saveBtnClick()}
                                />
                                <GreyButton
                                    content={<>Annuler</>}
                                    icon={<FiX size="2.2rem" />}
                                    onClick={() => AnnulerBtnFunction()}
                                />
                                <DarkButton
                                    content={
                                        <>
                                            Ajouter un axe<br></br>stratégique
                                        </>
                                    }
                                    icon={<FiPlusCircle size="2.2rem" />}
                                    onClick={() => addAxeFunction()}
                                />
                            </>
                        )}
                        <DarkButton
                            content={<>Méthode de calcul du WSJF</>}
                            icon={<FiActivity size="2.2rem" />}
                            otherClassName="items-center"
                            onClick={handleShow}
                        />
                    </Col>
                ) : (
                    ''
                )}
            </Row>
            {props.onboarding === true ? (
                <Row className="p-0">
                    <Col sm={6} md={5} lg={4} xxl={4}>
                        <Link
                            className="d-block d-xl-none"
                            to={'/' + id + '/onboarding/setup'}
                        >
                            <Button
                                className="btn-lg btn-outline-primary h3 font-weight-800 display-18 w-100 my-3"
                                style={{
                                    height: '5rem',
                                    maxWidth: '340px',
                                }}
                            >
                                Précédent
                            </Button>
                        </Link>
                        <Link
                            className="d-none d-xl-block"
                            to={'/' + id + '/onboarding/setup'}
                        >
                            <Button
                                className="btn-lg btn-outline-primary h3 font-weight-800 display-24 w-100 my-3"
                                style={{
                                    height: '5rem',
                                    maxWidth: '340px',
                                }}
                            >
                                Précédent
                            </Button>
                        </Link>
                    </Col>
                    <Col sm={6} md={5} lg={4} xxl={4}>
                        <Button
                            className="d-block d-xl-none btn-lg btn-primary h3 font-weight-800 display-18 w-100 my-3 px-2"
                            style={{
                                height: '5rem',
                                maxWidth: '340px',
                            }}
                            onClick={() => saveBtnClick()}
                        >
                            Valider cette
                            <br />
                            configuration
                        </Button>
                        <Button
                            className="d-none d-xl-block btn-lg btn-primary h3 font-weight-800 display-24 w-100 my-3 px-2"
                            style={{
                                height: '5rem',
                                maxWidth: '340px',
                            }}
                            onClick={() => saveBtnClick()}
                        >
                            Valider cette
                            <br />
                            configuration
                        </Button>
                    </Col>
                </Row>
            ) : (
                ''
            )}
            <RkModal
                id="view-formula"
                title="Méthode de calcul du WSJF"
                className="card h-auto my-0 w-100"
            >
                <WsjfPreview
                    BusinessValues={BusinessValues}
                    TimeCriticality={TimeCriticality}
                    RiskOpportunity={RiskOpportunity}
                    activeSousAxesArray={BusinessValues.axis.filter(
                        (axe) => axe.active === true
                    )}
                />
            </RkModal>
            <Alert
                color="danger"
                content="Une erreur est survenue, veuillez réessayer ultérieurement."
                showAlert={showAlertError}
                setAlertShow={setShowAlertError}
                handleCloseAlert={() => setShowAlertError(false)}
            />
            <Alert
                color="danger"
                content="La somme des pourcentages des axes de priorisation principaux doit être égale à 100%."
                showAlert={showAlertDriver}
                setAlertShow={setShowAlertDriver}
                handleCloseAlert={() => handleCloseAlert()}
            />
            <Alert
                color="danger"
                content="La somme des pourcentages des axes stratégiques composant la valeur business doit être égale à 100%."
                showAlert={showAlertStratDriver}
                setAlertShow={setShowAlertStratDriver}
                handleCloseAlert={() => handleCloseAlert()}
            />
            <Alert
                color="danger"
                content="code dupliqué"
                showAlert={showAlertDuplicated}
                setAlertShow={setShowAlertDuplicated}
                handleCloseAlert={() => handleCloseAlert()}
            />
            <Alert
                color="primary"
                content="Vous pouvez encore ajouter des axes stratégiques, mais nous préconisons de restreindre leur nombre à 3 pour des raisons d'efficacité opérationnelle."
                showAlert={showAlertAddAxe}
                setAlertShow={setShowAlertAddAxe}
                handleCloseAlert={() => handleCloseNotif()}
                contentClassname="axecontentClassname"
            />
            <Modal
                show={showModal}
                centered={true}
                modalFirstText="Etes-vous certain de vouloir supprimer cet axe de priorisation ? L'ensemble des données"
                modalSecondText="associées à cet axe seront supprimées."
                modalThirdText="Cette action est irréversible."
                modalContainerClassname="axeModalContainerClassname"
                modalContentClassName="axeModalContentClassName"
                closeIcon="axeModalcloseIcon cursorPointer"
                containerTextWithIconClassname="axesContainerTextWithIconClassname"
                alertIconClassName="axesAlertIconClassName"
                firstBtnClassName="axeModalFirstBtn"
                secondBtnClassName="axeModalSecondBtn"
                setShowModalFunction={() => setShowModalDetails()}
                textClassName="axeModalTextClassName"
                saveBtnFunction={() => saveBtnFunction()}
            />
            <Modal
                show={showModalInit}
                centered={true}
                modalFirstText="Etes-vous certain de vouloir réinitialiser les axes de priorisation ? L'ensemble des données"
                modalSecondText="associées à cette configuration seront supprimées."
                modalThirdText="Cette action est irréversible."
                modalContainerClassname="axeModalContainerClassname"
                modalContentClassName="axeModalContentClassName"
                closeIcon="axeModalcloseIcon cursorPointer"
                containerTextWithIconClassname="axesContainerTextWithIconClassname"
                alertIconClassName="axesAlertIconClassName"
                firstBtnClassName="axeModalFirstBtn"
                secondBtnClassName="axeModalSecondBtn"
                setShowModalFunction={() => setShowModalInit(false)}
                textClassName="axeModalTextClassName"
                saveBtnFunction={() => initConfig()}
            />
        </div>
    );
}

export default AxePrioBody;
