import { useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { FiUser, FiCreditCard, FiSettings } from 'react-icons/fi';
import Profile from './Profile';
import Billings from './Billings';
import Settings from './Settings';
import ErrorSelector from '../error/ErrorSelector';
import Echo from '../../util/Echo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
export default () => {
    const { view, id } = useParams();
    const backgroundStyle = {
        backgroundColor: 'rgba(153, 153, 153, 0.1)',
    };
    const { trackPageView } = useMatomo();
    useEffect(() => {
        if (!['profile', 'billings', 'settings'].includes(view))
            document.title = Echo().seo.title.account
                ? Echo().seo.title.prefix +
                  Echo().seo.title.account +
                  Echo().seo.title.suffix
                : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, []);
    return (
        <div>
            <Row className="m-0">
                <Col sm={4} xl={3} className="p-2">
                    <Link
                        to={'/' + id + '/account/profile'}
                        className="text-decoration-none text-black"
                    >
                        <Card
                            className="d-none d-xl-block border-0 w-100 line-height-14 minem-h-6 cursor-pointer"
                            style={view === 'profile' ? backgroundStyle : null}
                        >
                            <Card.Body>
                                <Row className="my-2 pt-1">
                                    <Col sm={2}>
                                        <FiUser size="2em" />
                                    </Col>
                                    <Col
                                        sm={10}
                                        className="font-weight-700 display-24 text-align-left align-self-center pl-4"
                                    >
                                        Profil
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Link>
                    <Link
                        to={'/' + id + '/account/profile'}
                        className="text-decoration-none text-black"
                    >
                        <Card
                            className="d-xl-none d-block border-0 w-100 line-height-14 minem-h-6 cursor-pointer"
                            style={view === 'profile' ? backgroundStyle : null}
                        >
                            <Card.Body>
                                <Row className="my-2 pt-1">
                                    <Col sm={2}>
                                        <FiUser size="2em" />
                                    </Col>
                                    <Col
                                        sm={10}
                                        className="font-weight-700 display-24 text-align-left align-self-center pl-4"
                                    >
                                        Profil
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col sm={4} xl={3} className="p-2">
                    <Link
                        to={'/' + id + '/account/billings'}
                        className="text-decoration-none text-black"
                    >
                        <Card
                            className="d-none d-xl-block border-0 w-100 line-height-14 minem-h-6 cursor-pointer"
                            style={view === 'billings' ? backgroundStyle : null}
                        >
                            <Card.Body>
                                <Row className="my-2 pt-1">
                                    <Col sm={2}>
                                        <FiCreditCard size="2em" />
                                    </Col>
                                    <Col
                                        sm={10}
                                        className="font-weight-700 display-24 text-align-left align-self-center pl-4 pr-0"
                                    >
                                        Facturation
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Link>
                    <Link
                        to={'/' + id + '/account/billings'}
                        className="text-decoration-none text-black"
                    >
                        <Card
                            className="d-xl-none d-block border-0 w-100 line-height-14 minem-h-6 cursor-pointer"
                            style={view === 'billings' ? backgroundStyle : null}
                        >
                            <Card.Body>
                                <Row className="my-2 pt-1">
                                    <Col sm={2}>
                                        <FiCreditCard size="2em" />
                                    </Col>
                                    <Col
                                        sm={10}
                                        className="font-weight-700 display-24 text-align-left align-self-center pl-4 pr-0"
                                    >
                                        Facturation
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col sm={4} xl={3} className="p-2">
                    <Link
                        to={'/' + id + '/account/settings'}
                        className="text-decoration-none text-black"
                    >
                        <Card
                            className="d-none d-xl-block border-0 w-100 line-height-14 minem-h-6 cursor-pointer"
                            style={view === 'settings' ? backgroundStyle : null}
                        >
                            <Card.Body>
                                <Row className="my-2 pt-1">
                                    <Col sm={2}>
                                        <FiSettings size="2em" />
                                    </Col>
                                    <Col
                                        sm={10}
                                        className="font-weight-700 display-24 text-align-left align-self-center pl-4 pr-0"
                                    >
                                        Paramètres
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Link>
                    <Link
                        to={'/' + id + '/account/settings'}
                        className="text-decoration-none text-black"
                    >
                        <Card
                            className="d-xl-none d-block border-0 w-100 line-height-14 minem-h-6 cursor-pointer"
                            style={view === 'settings' ? backgroundStyle : null}
                        >
                            <Card.Body>
                                <Row className="my-2 pt-1">
                                    <Col sm={2}>
                                        <FiSettings size="2em" />
                                    </Col>
                                    <Col
                                        sm={10}
                                        className="font-weight-700 display-24 text-align-left align-self-center pl-4 pr-0"
                                    >
                                        Paramètres
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
            </Row>
            <div style={{ height: '100%' }}>
                {view === 'billings' ? (
                    <Billings />
                ) : view === 'settings' ? (
                    <Settings />
                ) : view === 'profile' ? (
                    <Profile />
                ) : view === undefined ? (
                    <ErrorSelector
                        code={200}
                        title="Bienvenue"
                        message="sur votre compte Rankio 🙂"
                        retry_value="Voir mon profil"
                        retry_link="/account/profile"
                    />
                ) : (
                    <ErrorSelector
                        code={404}
                        title="404"
                        message="Page introuvable"
                        retry_value="Revenir à mon profil"
                        retry_link="/account/profile"
                        retry_color="outline-primary"
                    />
                )}
            </div>
        </div>
    );
};
