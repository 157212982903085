import Config from '../../util/Config';
import Error from '../error/ErrorComponent';
export default () => {
    return (
        <div className="pl-1">
            <strong className="rank">Rank</strong>
            <strong className="io">
                io
                {Config.APP_ENV === 'staging' ? (
                    <sup
                        className="font-weight-800 display-12 text-danger"
                        style={{
                            top: '-24px',
                            left: '-4px',
                        }}
                    >
                        BETA
                    </sup>
                ) : (
                    <></>
                )}
            </strong>
        </div>
    );
};
