import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

export default function DeleteButton(props) {
    return (
        <Button
            variant="delete"
            className={`btn-width m-2 ${props.btnClassnameDetails}`}
            onClick={props.onClick}
            id={props?.id}
        >
            {props.icon ? (
                <Row className={props.displayRowClassname + 'v-center'}>
                    <Col sm={2} className="my-auto pl-1">
                        {props.icon}
                    </Col>
                    <Col
                        sm={10}
                        className="font-weight-bold display-16 text-align-left pl-4 pr-0"
                    >
                        {props.content}
                    </Col>
                </Row>
            ) : (
                <div className="custom-btn-without-icon">{props.content}</div>
            )}
        </Button>
    );
}

DeleteButton.defaultProps = {
    btnClassnameDetails: '',
    displayRowClassname:''
};
