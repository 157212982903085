import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AppContainer from './AppContainer';
import Landing from './components/landing/Landing';
import Signup from './components/account/Signup';
import Login from './components/account/Login';
import LoginToken from './components/account/LoginToken';
import Logout from './components/account/Logout';
import OnboardingWelcome from './components/onboarding/Welcome';
import OnboardingReset from './components/onboarding/Reset';
import OnboardingLetsGo from './components/onboarding/LetsGo';
import OnboardingWorkspace from './components/onboarding/Workspace';
import OnboardingSetup from './components/onboarding/Setup';
import OnboardingConfig from './components/onboarding/Config';
import OnboardingDone from './components/onboarding/Done';
import OnboardingCheckout from './components/onboarding/Checkout';
import OnboardingProfile from './components/onboarding/Profile';
import Contact from './components/contact/Contact';
import Validate from './components/account/Validate';
import ValidateToken from './components/account/ValidateToken';
import AcceptInvit from './components/account/AcceptInvit';
import UpdateEmail from './components/account/UpdateEmail';
import Recovery from './components/account/Recovery';
import Reset from './components/account/Reset';
import Subscribe from './components/newsletter/Subscribe';
import Unsubscribe from './components/newsletter/Unsubscribe';
import Search from './components/search/Search';
import Subscription from './components/subscription/Subscription';
import Dashboard from './components/dashboard/Dashboard';
import Axis from './components/axePrio/AxePrio';
import Wsjf from './components/axePrio/Wsjf';
import Project from './components/project/Project';
import Tags from './components/tags/Tags';
import Account from './components/account/Account';
import ErrorPage from './components/error/ErrorPage';
import Redirect from './Redirect';
import HealthCheck from './components/commun/HealthCheck';
import Workspace from './components/workspace/Workspace';
import Teams from './components/teams/Teams';
import Session from './components/session/Session';
import SessionDetails from './components/session/SessionDetails';
import ErrorSelector from './components/error/ErrorSelector';
import './App.scss';
import './components/styles/commun.css';
export default () => {
    return (
        <>
            {/*<Rgpd />*/}
            <Router>
                <Switch>
                    <Route
                        exact
                        path={[
                            '/',
                            '/home',
                            '/index',
                            '/index.html',
                            '/index.htm',
                        ]}
                    >
                        <Landing />
                    </Route>
                    <Route exact path="/features">
                        <Landing to="features" />
                    </Route>
                    <Route exact path="/benefits">
                        <Landing to="benefits" />
                    </Route>
                    <Route exact path="/prices">
                        <Landing to="prices" />
                    </Route>
                    <Route exact path="/faq">
                        <Landing to="faq" />
                    </Route>
                    <Route exact path="/signup">
                        <Signup />
                    </Route>
                    <Route exact path="/login">
                        <Login />
                    </Route>
                    <Route
                        exact
                        path={[
                            // remove this old format
                            '/login/token=:token',
                            '/login/:token',
                        ]}
                    >
                        <LoginToken />
                    </Route>
                    <Route exact path="/:id/logout">
                        <Logout />
                    </Route>
                    <Route exact path="/contact">
                        <Contact />
                    </Route>
                    <Route exact path="/validate">
                        <Validate />
                    </Route>
                    <Route
                        exact
                        path={[
                            // remove this old format
                            '/validate/token=:token',
                            '/validate/:token',
                        ]}
                    >
                        <ValidateToken />
                    </Route>
                    <Route
                        exact
                        path={[
                            // remove this old format
                            '/invit/:workspace/token=:token',
                            '/invit/:token',
                        ]}
                    >
                        <AcceptInvit />
                    </Route>
                    <Route
                        exact
                        path={[
                            // remove this old format
                            '/update/email/token=:token',
                            '/update/:token',
                        ]}
                    >
                        <UpdateEmail />
                    </Route>
                    <Route exact path="/recovery">
                        <Recovery />
                    </Route>
                    <Route exact path="/reset">
                        <Reset />
                    </Route>
                    <Route exact path="/subscribe">
                        <Subscribe />
                    </Route>
                    <Route exact path="/unsubscribe">
                        <Unsubscribe />
                    </Route>
                    <Route
                        exact
                        path={[
                            // remove this old format
                            '/unsubscribe/token=:token',
                            '/unsubscribe/:token',
                        ]}
                    >
                        <Unsubscribe />
                    </Route>
                    <Route exact path={['/error', '/error/:code(\\d+)']}>
                        <ErrorPage />
                    </Route>
                    <Route exact path="/healthcheck">
                        <HealthCheck />
                    </Route>
                    <Route exact path="/:id/s=:s">
                        <AppContainer>
                            <Search />
                        </AppContainer>
                    </Route>
                    <Route exact path="/:id/subscription/:subscriptionId">
                        <AppContainer>
                            <Subscription />
                        </AppContainer>
                    </Route>
                    <Route
                        exact
                        path={['/:id/onboarding/welcome', '/:id/onboarding']}
                    >
                        <OnboardingWelcome />
                    </Route>
                    <Route exact path="/:id/onboarding/reset">
                        <OnboardingReset />
                    </Route>
                    <Route exact path="/:id/onboarding/letsgo">
                        <OnboardingLetsGo />
                    </Route>
                    <Route exact path="/:id/onboarding/workspace">
                        <OnboardingWorkspace />
                    </Route>
                    <Route exact path="/:id/onboarding/setup">
                        <OnboardingSetup />
                    </Route>
                    <Route exact path="/:id/onboarding/config">
                        <OnboardingConfig />
                    </Route>
                    <Route exact path="/:id/onboarding/done">
                        <OnboardingDone />
                    </Route>
                    <Route exact path="/:id/onboarding/checkout">
                        <OnboardingCheckout />
                    </Route>
                    <Route exact path="/:id/onboarding/profile">
                        <OnboardingProfile />
                    </Route>
                    <Route exact path="/:id/account">
                        <AppContainer menu="account">
                            <Account />
                        </AppContainer>
                    </Route>
                    <Route exact path="/:id/account/:view">
                        <AppContainer menu="account">
                            <Account />
                        </AppContainer>
                    </Route>
                    <Route exact path="/:id/dashboard">
                        <AppContainer menu="dashboard">
                            <Dashboard />
                        </AppContainer>
                    </Route>
                    <Route exact path="/:id/project">
                        <AppContainer menu="project">
                            <Project />
                        </AppContainer>
                    </Route>
                    <Route exact path="/:id/tags">
                        <AppContainer menu="tags">
                            <Tags />
                        </AppContainer>
                    </Route>
                    <Route exact path="/:id/session">
                        <AppContainer menu="session">
                            <Session />
                        </AppContainer>
                    </Route>
                    <Route
                        exact
                        path="/:id/workspace/:workspaceId/session/:sessionId"
                    >
                        <AppContainer menu="session">
                            <SessionDetails />
                        </AppContainer>
                    </Route>
                    <Route exact path="/:id/workspace">
                        <AppContainer menu="workspace">
                            <Workspace />
                        </AppContainer>
                    </Route>
                    <Route exact path="/:id/axis">
                        <AppContainer menu="axis">
                            <Axis />
                        </AppContainer>
                    </Route>
                    <Route exact path="/:id/wsjf">
                        <AppContainer menu="wsjf">
                            <Wsjf />
                        </AppContainer>
                    </Route>
                    <Route exact path="/:id/teams">
                        <AppContainer menu="teams">
                            <Teams />
                        </AppContainer>
                    </Route>
                    <Route
                        path={[
                            '/s=:s',
                            '/onboarding',
                            '/logout',
                            '/account',
                            '/account/:view',
                            '/dashboard',
                            '/project',
                            '/tags',
                            '/session',
                            '/workspace',
                            '/axis',
                            '/wsjf',
                            '/teams',
                            '/goto/workspace/:workspaceId/project/:projectId',
                            '/goto/workspace/:workspaceId/session/:sessionId',
                            '/goto/workspace/:workspaceId/teams/:teamsId',
                            '/goto/workspace/:workspaceId',
                        ]}
                        component={Redirect}
                    />
                    <Route>
                        <ErrorSelector logo="1" code={404} />
                    </Route>
                </Switch>
            </Router>
            {/* <Version /> */}
        </>
    );
};
