export default {
    options: {
        all: true,
        code: true,
        name: true,
        status: true,
        wsjf: true,
        business_value: true,
        effort: true,
        assignation: true,
        tags: true,
    },
    sizes: {
        code: 8,
        name: 14,
        status: 10,
        wsjf: 8,
        business_value: 8,
        effort: 8,
        assignation: 14,
        tags: 14,
    },
    pagination: { limit: 500, skip: 0 },
    sort: { type: 'desc', column: 'wsjf' },
    filter: {
        code: '',
        name: '',
        status: {
            all: false,
            to_prioritize: true,
            planned: true,
            prioritized: true,
            buffer: true,
            analyzing: true,
            approved: true,
            backlog: true,
            in_progress: true,
            done: false,
            stand_by: true,
            aborted: false,
            archived: false,
        },
        wsjf: { max: null, min: null },
        business_value: { max: null, min: null },
        effort: { max: null, min: null },
        assignation: {
            all: { selected: false },
        },
        tags: {
            all: { selected: false },
        },
    },
    type: {
        code: 'string',
        name: 'string',
        status: 'status_list',
        wsjf: 'wsjf',
        business_value: 'wsjf',
        effort: 'wsjf',
        assignation: 'teams',
        tags: 'tags',
        end_prioritizing: 'timestamp',
    },
};
