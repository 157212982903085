import { Container, Fade, Navbar, Button, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { validate as isUuid } from 'uuid';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Logo from '../commun/Logo';
import Echo from '../../util/Echo';
import { FiX } from 'react-icons/fi';
import { IoHelp } from 'react-icons/io5';
import { useMatomo } from '@datapunt/matomo-tracker-react';
export default () => {
    const { id } = useParams();
    const history = useHistory();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const selectedPlan = activeUser[0].selectedPlan;
    const selectedSubscription = activeUser[0].selectedSubscription;
    const selectedWorkspace = activeUser[0].selectedWorkspace;
    const [logo, setLogo] = useState(false);
    const [letsgo, setLetsgo] = useState(false);
    const [content, setContent] = useState(false);
    const { trackPageView } = useMatomo();
    useEffect(() => {
        document.title = Echo().seo.title.onboarding
            ? Echo().seo.title.prefix +
              Echo().seo.title.onboarding +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, []);
    useEffect(() => {
        setLogo(true);
        switch (true) {
            case activeUser[0].first_name === undefined:
            case activeUser[0].last_name === undefined:
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/profile');
                }, 1000);
                break;
            case !isUuid(selectedPlan):
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/reset');
                }, 1000);
                break;
            case isUuid(selectedWorkspace):
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/workspace');
                }, 1000);
                break;
            case isUuid(selectedSubscription):
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/letsgo');
                }, 1000);
                break;
            default:
                setTimeout(() => {
                    setLogo(false);
                }, 1800);
                setTimeout(() => {
                    setContent(true);
                }, 2000);
                setTimeout(() => {
                    setLetsgo(true);
                }, 3200);
        }
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Fade in={logo}>
                <div style={{ height: '100%' }}>
                    <center
                        style={{
                            width: 'auto',
                            height: 'auto',
                            position: 'absolute',
                            top: '45%',
                            bottom: '0',
                            left: '0',
                            right: '0',
                            margin: 'auto',
                        }}
                    >
                        <div className="mb-5 px-3">
                            <Logo />
                        </div>
                    </center>
                </div>
            </Fade>

            <Fade in={content}>
                <Container fluid>
                    <Navbar
                        className="fixed-top rankio-header"
                        bg="white"
                        expand="xl"
                    >
                        <Link to="/">
                            <Navbar.Brand>
                                <Logo />
                            </Navbar.Brand>
                        </Link>
                        <div className="ml-auto">
                            <a
                                title="Nous contacter"
                                href="/contact"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="mr-4"
                            >
                                <IoHelp
                                    size="1.5em"
                                    className="rk-clickable-icon-dark"
                                />
                            </a>
                            <Link to={'/'}>
                                <FiX
                                    title="Accueil"
                                    size="1.5em"
                                    className="rk-clickable-icon-dark"
                                />{' '}
                            </Link>
                        </div>
                    </Navbar>
                    <div className="landing">
                        <Row className="p-4">
                            <Col sm={1} md={2} lg={2} xxl={4}></Col>
                            <Col sm={10} md={8} lg={8} xxl={7}>
                                <h3 className="font-weight-800 mt-4 mb-5">
                                    Bienvenue
                                    <span className="text-capitalize">
                                        {' ' + activeUser[0].first_name}
                                    </span>
                                    <span> !</span>
                                    <br />
                                </h3>
                                <p className="font-weight-600">
                                    Nous allons vous accompagner dans la
                                    configuration de votre workspace.
                                </p>
                            </Col>
                        </Row>
                        <Fade in={letsgo}>
                            <Row className="p-4">
                                <Col sm={1} md={2} lg={2} xxl={4}></Col>
                                <Col sm={10} md={8} lg={5} xxl={4}>
                                    <Link to={'/' + id + '/onboarding/letsgo'}>
                                        <Button
                                            className="btn-lg btn-primary h3 font-weight-800 display-24 w-100 my-3"
                                            style={{
                                                height: '5rem',
                                                maxWidth: '340px',
                                            }}
                                        >
                                            Allons-y !
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Fade>
                    </div>
                </Container>
            </Fade>
        </>
    );
};
