import React, { useState, useEffect } from 'react';
import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Modal from '../commun/RkModal';
import BlueButton from '../commun/BlueButton';
import GreyButton from '../commun/GreyButton';
import DarkButton from '../commun/DarkButton';
import TeamIcon from '../commun/TeamIcon';
import { FiTrash2, FiMail, FiX, FiSearch, FiSave } from 'react-icons/fi';
import Config from '../../util/Config';
import Echo from '../../util/Echo';
import Alert from '../commun/Alert';
import Teams from '../../util/Teams';

export default (props) => {
    const { id } = useParams();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = activeUser[0].workspace[activeUser[0].activeWorkspace];
    const deletable = ['owner', 'admin'].includes(
        workspace.object.teams.on.filter(
            (team) => team.id === activeUser[0].id
        )[0].role
    );
    const readOnly = !deletable || props.data?.object?.status === 'archived';
    const [my] = useState(activeUser[0]);
    const [workspaceTeams, setWorkspaceTeams] = useState([]);
    const [initialWorkspaceTeams, setInitialWorkspaceTeams] = useState([]);
    const [teamsToAssign, setTeamsToAssign] = useState([]);
    const [keywords, setKeywords] = useState('');
    useEffect(() => {
        resetTeamsToAssign();
    }, [props.data]);
    useEffect(() => {
        Teams.get(workspace.id, activeUser[0].token)
            .then((res) => {
                setWorkspaceTeams(res.data.data);
                setTeamsToAssign([
                    ...(teamsToAssign || []),
                    ...res.data.data
                        .map((t) => t.id)
                        .filter((t) => !initialWorkspaceTeams.includes(t)),
                ]);
            })
            .catch((err) => {
                setWorkspaceTeams([]);
            });
    }, [users]);
    const resetTeamsToAssign = () => {
        setKeywords('');
        setTeamsToAssign(props.data?.object?.assignation || []);
        Teams.get(workspace.id, activeUser[0].token)
            .then((res) => {
                setWorkspaceTeams(res.data.data);
                setInitialWorkspaceTeams(res.data.data.map((t) => t.id));
            })
            .catch((err) => {
                setWorkspaceTeams([]);
            });
    };
    const renderTheRestOfWorkspaceTeams = () => {
        return workspaceTeams
            .filter((t) => !teamsToAssign.includes(t.id))
            .filter((t) => t.object.status !== 'off')
            .filter(
                (t) =>
                    t.object.first_name.includes(keywords.toLowerCase()) ||
                    t.object.last_name.includes(keywords.toLowerCase()) ||
                    t.object.email.includes(keywords.toLowerCase()) ||
                    t.object.job.includes(keywords.toLowerCase())
            )
            .map((team) => (
                <div
                    onClick={() => {
                        setTeamsToAssign([...teamsToAssign, team.id]);
                    }}
                >
                    <TeamIcon team={team.object} my={my} />
                </div>
            ));
    };
    const renderWorkspaceTeamsToAssign = () => {
        return teamsToAssign
            .map((id) => {
                return {
                    id,
                    ...workspaceTeams.filter((t) => t.id === id)?.[0]?.object,
                };
            })
            .map((team) => (
                <div
                    onClick={() => {
                        setTeamsToAssign(
                            teamsToAssign.filter((t) => t !== team.id)
                        );
                    }}
                >
                    <TeamIcon team={team} my={my} />
                </div>
            ));
    };
    return (
        <>
            <Modal
                id="view_details_assignment_project"
                title="Assignation"
                className="card invite-user-container-width m-2 mt-40"
            >
                <div className="session-invite-modal-big-container">
                    <Row className="mr-0 ml-0">
                        <Col lg={7} xl={8} className="pl-0 pb-5">
                            <div className="mt-4">
                                <span className="descr-label">
                                    A qui voulez-vous assigner ce projet ?
                                </span>
                            </div>
                            <div className="action-label pt-5 pb-3">
                                Membres du workspace
                            </div>
                            <div className="session-invite-search-container">
                                <Form.Control
                                    name="search"
                                    type="text"
                                    placeholder="Rechercher..."
                                    value={keywords}
                                    className="rounded border-0 session-search-input "
                                    onChange={(e) =>
                                        setKeywords(e.target.value)
                                    }
                                />
                                <FiSearch
                                    size="1.5rem"
                                    className="session-search-icon"
                                />
                            </div>
                            <div className="session-member-container">
                                {renderTheRestOfWorkspaceTeams()}
                            </div>
                            <div className="action-label mt-4 pb-3">
                                Personnes assignées au projet
                            </div>
                            <div className="session-member-container">
                                {renderWorkspaceTeamsToAssign()}
                            </div>
                        </Col>
                        <Col
                            lg={'auto'}
                            className="session-details-col-display"
                        >
                            <div className="add-session-btn-container">
                                <BlueButton
                                    content={<>Enregistrer</>}
                                    icon={<FiSave size="2.2rem" />}
                                    displayRowClassname="axeDisplayRowClassnameBtn"
                                    onClick={() => {
                                        props.update(props.data.id, {
                                            assignation: teamsToAssign,
                                        });
                                        document.getElementById(
                                            'view_details_assignment_project'
                                        ).style.display = 'none';
                                    }}
                                />
                                <GreyButton
                                    content={<>Annuler</>}
                                    icon={<FiX size="2.2rem" />}
                                    onClick={() => {
                                        resetTeamsToAssign();
                                        document.getElementById(
                                            'view_details_assignment_project'
                                        ).style.display = 'none';
                                    }}
                                />
                                <DarkButton
                                    content={
                                        <>
                                            Inviter des<br></br>utilisateurs
                                        </>
                                    }
                                    icon={<FiMail size="2.2rem" />}
                                    onClick={() => {
                                        document.getElementById(
                                            'view_invite_team'
                                        ).style.display = 'block';
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    );
};
