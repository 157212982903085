import Axios from 'axios';
import ApiCallConfig from './ApiCallConfig';
export default class Feature {
    /**
     * @function get
     * @definition Get features
     * @returns {Promise}
     */
    static get(params) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'GET',
                url: '/feature',
                params,
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}
