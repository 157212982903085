import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Alert from '../commun/Alert';
import { FiUpload, FiTrash2, FiX } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import ProfilPhoto from '../commun/ProfilPhoto';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';

export default (props) => {
    const [alertPhotoFailedShow, setAlertPhotoFailedShow] = useState(false);
    const [alertTimeout, setAlertTimeout] = useState(null);
    const handleShowAlert = (setAlertShow) => {
        setAlertShow(true);
        setAlertTimeout(
            setTimeout(() => {
                setAlertShow(false);
            }, 3000)
        );
    };
    let dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const [image, setImage] = useState(props.image);
    const show = props.show;
    const setShow = props.setShow;
    const { id } = useParams();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const hiddenFileInput = React.useRef(null);

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleChange = async (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            const base64 = await convertBase64(fileUploaded);
            Axios({
                ...ApiCallConfig,
                timeout: 20000,
                method: 'PATCH',
                url: '/user',
                headers: {
                    authorization: 'Bearer ' + activeUser[0].token,
                },
                data: { image: base64 },
            })
                .then((result) => {
                    if (result.status == 200) {
                        if (props.getData) props.getData();
                        dispatch({
                            type: 'UPDATE_USER',
                            value: result.data.data,
                        });
                        setShow(false);
                    } else if (result.status === 409) {
                        handleShowAlert(setAlertPhotoFailedShow);
                        setMessage(result);
                        setShow(false);
                    } else if (result.status === 429) {
                        handleShowAlert(setAlertPhotoFailedShow);
                        setMessage(result);
                        setShow(false);
                    } else if (result.status === 403) {
                        handleShowAlert(setAlertPhotoFailedShow);
                        setMessage(result);
                        setShow(false);
                    } else {
                        handleShowAlert(setAlertPhotoFailedShow);
                        setMessage(result);
                        setShow(false);
                    }
                })
                .catch((error) => {
                    handleShowAlert(setAlertPhotoFailedShow);
                    setMessage(error);
                    setShow(false);
                });
        }
    };
    const handleShow = () => {
        if (show) {
            props.setShow(false);
        } else {
            props.setShow(true);
        }
    };

    const removePhoto = async () => {
        Axios({
            ...ApiCallConfig,
            method: 'PATCH',
            url: '/user',
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
            data: { image: 'null' },
        })
            .then((result) => {
                if (result.status == 200) {
                    setImage(result.data.data.object.image);
                    dispatch({ type: 'UPDATE_USER', value: result.data.data });
                    setShow(false);
                } else if (result.status === 409) {
                    setMessage(result);
                    setShow(false);
                } else if (result.status === 429) {
                    setMessage(result);
                    setShow(false);
                } else if (result.status === 403) {
                    setMessage(result);
                    setShow(false);
                } else {
                    setMessage(result);
                    setShow(false);
                }
            })
            .catch((error) => {
                setMessage(error);
                setShow(false);
            });
    };

    return (
        <>
            <Button
                className="profil-image display-24 p-0 mx-3"
                // onClick={handleShow}
                // id="profil_photo"
                // title="Changer la photo de profil"
            >
                {image ? (
                    <ProfilPhoto className="profil-image" image={image} />
                ) : (
                    <ProfilPhoto className="profil-image" />
                )}
            </Button>
            <div className="d-none">
                <div
                    className={
                        props.show
                            ? 'w-100 radius-14 card border-0 dropdown-menu-right d-block'
                            : 'd-none'
                    }
                    style={{
                        maxWidth: '26rem',
                        minWidth: '20rem',
                        position: 'absolute',
                        top: '130px',
                        left: '16px',
                        zIndex: '2',
                    }}
                    id="profil_photo_modal"
                >
                    <div className="bg-bg-gray rounded">
                        <div>
                            <div
                                className="mt-3"
                                onClick={() => {
                                    // props.setShow ? props.setShow(false) : setShow(false);
                                    handleShow();
                                }}
                                style={{ position: 'absolute', right: '16px' }}
                            >
                                <FiX
                                    size="1.5em"
                                    className="rk-clickable-icon-dark"
                                />
                            </div>

                            <Button
                                className="bg-transparent border-0 no-outline p-0 mx-3 mt-5"
                                onClick={handleClick}
                            >
                                <Row>
                                    <Col sm={1} className="my-auto">
                                        <FiUpload size="2em" color="#000000" />
                                    </Col>
                                    <Col
                                        sm="auto"
                                        className="display-16 font-weight-700 text-black cursor-pointer ml-3 my-auto"
                                    >
                                        Charger une nouvelle photo
                                    </Col>
                                </Row>
                            </Button>
                            <input
                                type="file"
                                ref={hiddenFileInput}
                                onChange={handleChange}
                                style={{ display: 'none' }}
                                accept="image/*"
                            />
                        </div>

                        <Button
                            className="bg-transparent border-0 no-outline p-0 m-3 mb-4"
                            onClick={() => removePhoto()}
                        >
                            <Row>
                                <Col sm={1} className="my-auto">
                                    <FiTrash2 size="2em" color="#000000" />
                                </Col>
                                <Col
                                    sm="auto"
                                    className="display-16 font-weight-700 text-black cursor-pointer ml-3 my-auto"
                                >
                                    Supprimer la photo actuelle
                                </Col>
                            </Row>
                        </Button>
                    </div>
                </div>
                <Alert
                    color="danger"
                    content="La photo de profil n'a pas pu être changée, veuillez réessayer."
                    showAlert={alertPhotoFailedShow}
                    setAlertShow={setAlertPhotoFailedShow}
                    alertTimeout={alertTimeout}
                />
            </div>
        </>
    );
};
