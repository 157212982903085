import React, { useState, useEffect } from 'react';
import { Form, Row, Table, Col } from 'react-bootstrap';
import RkTableFilter from './RkTableFilter';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Modal from '../modal/Modal';
import TeamsList from '../commun/TeamsList';
import TagsList from '../commun/TagsList';
import CalcSquare from '../commun/CalcSquare';
import { FiTrash2, FiArchive, FiRefreshCw } from 'react-icons/fi';
import Echo from '../../util/Echo';
import Project from '../../util/Project';
import Session from '../../util/Session';
import TeamData from '../../util/TeamData';
import Teams from '../../util/Teams';
import Error from '../error/ErrorComponent';

export default (props) => {
    const { id } = useParams();
    const state = useSelector((state) => state.user);
    const users = state.users;
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = activeUser[0].workspace[activeUser[0].activeWorkspace];
    const userRole = workspace.object.teams.on.filter(
        (team) => team.id === activeUser[0].id
    )[0].role;
    const [my] = useState(activeUser[0]);
    const [RkTableSize, setRkTableSize] = useState({
        tableWidth: document.getElementById('rk-table')?.clientWidth,
        tableHeight: document.getElementById('rk-table')?.clientHeight - 130,
        offsetWidth: document.getElementById('rk-table-head')?.offsetWidth,
        offsetHeight: document.getElementById('rk-table-body')?.offsetHeight,
    });
    const renderStringFilter = (resource, column) => {
        return (
            <form className="mt-2 mb-3">
                <label className="pb-0 mb-0">
                    <label className="checkrow">
                        <Row className="ml-4">
                            <Col xs={1} className="mt-2 p-0 pr-3 ml-2"></Col>
                            <Col
                                xs={8}
                                className="font-weight-700 display-12 p-0 pl-2"
                            >
                                {Echo(state.lang).commun.search}&nbsp;:
                            </Col>
                        </Row>
                    </label>
                </label>
                <input
                    type="text"
                    onChange={(e) =>
                        props.handleFilterState(column, e.target.value)
                    }
                    value={props.filterStates[column]}
                    className="radius-14 filter-input diplay-12 font-weight-700"
                    placeholder="Renseigner..."
                ></input>
            </form>
        );
    };
    const renderWsjfFilter = (resource, column) => {
        return (
            <form className="mt-2 mb-3">
                <label className="pb-0 mb-0">
                    <label className="checkrow">
                        <Row className="ml-4">
                            <Col xs={1} className="mt-2 p-0 pr-3 ml-2"></Col>
                            <Col
                                xs={8}
                                className="font-weight-700 display-12 p-0 pl-2"
                            >
                                {Echo(state.lang).commun.lt}&nbsp;:
                            </Col>
                        </Row>
                    </label>
                </label>
                <input
                    type="number"
                    step="1"
                    min={props.filterStates[column].gt}
                    onChange={(e) =>
                        props.handleFilterState(column, {
                            ...props.filterStates[column],
                            lt: e.target.value,
                        })
                    }
                    value={props.filterStates[column].lt}
                    className="radius-14 filter-input diplay-12 font-weight-700"
                    placeholder=""
                ></input>
                <label className="pb-0 mb-0 mt-2">
                    <label className="checkrow">
                        <Row className="ml-4">
                            <Col xs={1} className="mt-2 p-0 pr-3 ml-2"></Col>
                            <Col
                                xs={8}
                                className="font-weight-700 display-12 p-0 pl-2"
                            >
                                {Echo(state.lang).commun.gt}&nbsp;:
                            </Col>
                        </Row>
                    </label>
                </label>
                <input
                    type="number"
                    min="0"
                    max={props.filterStates[column].lt}
                    step="1"
                    onChange={(e) =>
                        props.handleFilterState(column, {
                            ...props.filterStates[column],
                            gt: e.target.value,
                        })
                    }
                    value={props.filterStates[column].gt}
                    className="radius-14 filter-input diplay-12 font-weight-700"
                    placeholder=""
                ></input>
            </form>
        );
    };
    const renderDateFilter = (resource, column) => {
        return (
            <form className="mt-2 mb-3">
                <label className="pb-0 mb-0">
                    <label className="checkrow">
                        <Row className="ml-4">
                            <Col xs={1} className="mt-2 p-0 pr-3 ml-2"></Col>
                            <Col
                                xs={8}
                                className="font-weight-700 display-12 p-0 pl-2"
                            >
                                {Echo(state.lang).commun.date_lt}&nbsp;:
                            </Col>
                        </Row>
                    </label>
                </label>
                <input
                    type="date"
                    onChange={(e) =>
                        props.handleIntervalState(column, e.target.value, 'lt')
                    }
                    value={props.intervalStates[column]}
                    className="radius-14 filter-input diplay-12 font-weight-700"
                    placeholder=""
                ></input>
                <label className="pb-0 mb-0 mt-2">
                    <label className="checkrow">
                        <Row className="ml-4">
                            <Col xs={1} className="mt-2 p-0 pr-3 ml-2"></Col>
                            <Col
                                xs={8}
                                className="font-weight-700 display-12 p-0 pl-2"
                            >
                                {Echo(state.lang).commun.date_gt}&nbsp;:
                            </Col>
                        </Row>
                    </label>
                </label>
                <input
                    type="date"
                    onChange={(e) =>
                        props.handleIntervalState(column, e.target.value, 'gt')
                    }
                    value={props.intervalStates[column]}
                    className="radius-14 filter-input diplay-12 font-weight-700"
                    placeholder=""
                ></input>
            </form>
        );
    };
    const renderEnumFilter = (resource, column) => {
        return (
            <>
                {Object.keys(props.filterStates[column]).map((key) => (
                    <label
                        className={key === 'all' ? 'checkrow mb-4' : 'checkrow'}
                    >
                        <Row className="ml-4">
                            <Col xs={1} className="ml-3">
                                <input
                                    type="checkbox"
                                    checked={props.filterStates[column][key]}
                                    onChange={(e) => e.preventDefault()}
                                />
                                <span
                                    className="checkmark"
                                    onClick={() =>
                                        props.handleFilterState(column, key)
                                    }
                                ></span>
                            </Col>
                            <Col
                                xs={8}
                                className="font-weight-700 display-12 pl-0"
                            >
                                <div
                                    className="pointer"
                                    onClick={() =>
                                        props.handleFilterState(column, key)
                                    }
                                >
                                    {
                                        Echo(state.lang)[resource].fields[
                                            column
                                        ][key]
                                    }
                                </div>
                            </Col>
                        </Row>
                    </label>
                ))}
            </>
        );
    };
    const renderTagsFilter = (resource, column) => {
        let category;
        const renderTagsCatehory = (cat) => {
            if (cat !== undefined && cat !== category) {
                category = cat;
                return (
                    <label className="font-weight-700 display-12 pl-4 mt-3">
                        {cat.length > 0
                            ? cat
                            : Echo(state.lang).commun.undefined_category}
                    </label>
                );
            }
        };
        return (
            <>
                {Object.keys(props.filterStates[column]).map((key) => {
                    let category = '';
                    return (
                        <>
                            {renderTagsCatehory(
                                props.filterStates[column][key]?.data?.category
                            )}
                            <label
                                className="checkrow"
                                title={
                                    key === 'all'
                                        ? Echo(state.lang)[resource].fields[
                                              column
                                          ].all
                                        : props.filterStates[column][key].data
                                              .name
                                }
                            >
                                <Row className="ml-4">
                                    <Col xs={1} className="ml-3">
                                        <input
                                            type="checkbox"
                                            checked={
                                                props.filterStates[column][key]
                                                    .selected
                                            }
                                            onChange={(e) => e.preventDefault()}
                                        />
                                        <span
                                            className="checkmark pointer"
                                            onClick={() =>
                                                props.handleFilterState(
                                                    column,
                                                    key
                                                )
                                            }
                                        ></span>
                                    </Col>
                                    <Col
                                        xs={8}
                                        className="font-weight-700 display-12 pl-0"
                                    >
                                        <div
                                            className="pointer"
                                            onClick={() =>
                                                props.handleFilterState(
                                                    column,
                                                    key
                                                )
                                            }
                                        >
                                            {(key === 'all' &&
                                                Echo(state.lang)[resource]
                                                    .fields[column].all) ||
                                                (props.filterStates[column][key]
                                                    .data.code.length > 0 &&
                                                    props.filterStates[column][
                                                        key
                                                    ].data.code) ||
                                                Echo(state.lang).commun
                                                    .undefined_tag}
                                        </div>
                                    </Col>
                                </Row>
                            </label>
                        </>
                    );
                })}
            </>
        );
    };
    const renderTeamsFilter = (resource, column) => {
        return (
            <>
                {Object.keys(props.filterStates[column]).map((key) => (
                    <label
                        className={key === 'all' ? 'checkrow mb-4' : 'checkrow'}
                        title={
                            key === 'all'
                                ? Echo(state.lang)[resource].fields[column].all
                                : renderTeamsData(
                                      props.filterStates[column][key].data
                                  ).title
                        }
                    >
                        <Row className="ml-4">
                            <Col xs={1} className="ml-3">
                                <input
                                    type="checkbox"
                                    checked={
                                        props.filterStates[column][key].selected
                                    }
                                    onChange={(e) => e.preventDefault()}
                                />
                                <span
                                    className="checkmark pointer"
                                    onClick={() =>
                                        props.handleFilterState(column, key)
                                    }
                                ></span>
                            </Col>
                            <Col
                                xs={8}
                                className="font-weight-700 display-12 pl-0"
                            >
                                <div
                                    className="pointer"
                                    onClick={() =>
                                        props.handleFilterState(column, key)
                                    }
                                >
                                    {key === 'all'
                                        ? Echo(state.lang)[resource].fields[
                                              column
                                          ].all
                                        : renderTeamsData(
                                              props.filterStates[column][key]
                                                  .data
                                          ).value}
                                </div>
                            </Col>
                        </Row>
                    </label>
                ))}
            </>
        );
    };
    const renderTeamsData = (team) => {
        return TeamData(team);
    };
    const renderData = (row, column) => {
        return (
            <td
                key={column}
                className={
                    'display-14 text-break align-middle pointer py-0 px-2 minem-w-' +
                        props.config?.sizes[column] || '8'
                }
                {...([
                    'first_name',
                    'last_name',
                    'email',
                    'code',
                    'name',
                ].includes(column) && {
                    onClick: () => props.selectedRow(row),
                })}
            >
                {(props.config?.type[column] === 'string' && (
                    <div className="px-2">{row.object[column]}</div>
                )) ||
                    (props.config?.type[column] === 'teams' && (
                        <div className="pl-3">
                            <TeamsList
                                my={my}
                                teams={row.object[column].map(
                                    (id) => props.filterStates[column][id]?.data
                                )}
                            />
                        </div>
                    )) ||
                    (props.config?.type[column] === 'tags' && (
                        <div className="pl-3">
                            <TagsList
                                size="sm"
                                tags={row.object[column].map(
                                    (code) =>
                                        props.filterStates[column][code]?.data
                                )}
                            />
                        </div>
                    )) ||
                    (props.config?.type[column] === 'role' &&
                        (['owner', 'admin'].includes(userRole) ? (
                            <div className="projectStatusDropDownContainer ml-0">
                                <Select
                                    value={{
                                        value: row.object[column],
                                        label: Echo(state.lang)[props.resource]
                                            .fields[column][row.object[column]],
                                    }}
                                    onChange={(e) => {
                                        props.setLoading(true);
                                        Teams.patch(
                                            workspace.id,
                                            row.id,
                                            activeUser[0].token,
                                            {
                                                role: e.value,
                                            }
                                        )
                                            .then((res) => {
                                                if (res.status === 402) {
                                                    document.getElementById(
                                                        'view_add_option'
                                                    ).style.display = 'block';
                                                }
                                                props.getData();
                                            })
                                            .catch(() =>
                                                props.setLoading(false)
                                            );
                                    }}
                                    options={Object.keys(
                                        props.filterStates[column]
                                    )
                                        .filter(
                                            (opt) =>
                                                ![
                                                    'all',
                                                    'owner',
                                                    row.object[column],
                                                ].includes(opt)
                                        )
                                        .map((opt) => {
                                            return {
                                                value: opt,
                                                label: Echo(state.lang)[
                                                    props.resource
                                                ].fields[column][opt],
                                            };
                                        })}
                                    isDisabled={
                                        row.object.email ===
                                            activeUser[0].email ||
                                        row.object.role === 'owner'
                                    }
                                />
                            </div>
                        ) : (
                            <div className="pl-2">
                                {
                                    Echo(state.lang)[props.resource].fields[
                                        column
                                    ][row.object[column]]
                                }
                            </div>
                        ))) ||
                    (props.config?.type[column] === 'status_project' &&
                        (['owner', 'admin'].includes(userRole) ? (
                            <div className="projectStatusDropDownContainer ml-0">
                                <Select
                                    value={{
                                        value: row.object[column],
                                        label: Echo(state.lang)[props.resource]
                                            .fields[column][row.object[column]],
                                    }}
                                    onChange={(e) => {
                                        props.setLoading(true);
                                        Project.patch(
                                            row.object.workspace,
                                            row.id,
                                            activeUser[0].token,
                                            {
                                                status: e.value,
                                            }
                                        )
                                            .then(() => props.getData())
                                            .catch(() =>
                                                props.setLoading(false)
                                            );
                                    }}
                                    options={Object.keys(
                                        props.filterStates[column]
                                    )
                                        .filter(
                                            (opt) =>
                                                ![
                                                    'all',
                                                    row.object[column],
                                                ].includes(opt)
                                        )
                                        .map((opt) => {
                                            return {
                                                value: opt,
                                                label: Echo(state.lang)[
                                                    props.resource
                                                ].fields[column][opt],
                                            };
                                        })}
                                    isDisabled={
                                        row.object[column] === 'archived'
                                    }
                                />
                            </div>
                        ) : (
                            <div className="pl-2">
                                {
                                    Echo(state.lang)[props.resource].fields[
                                        column
                                    ][row.object[column]]
                                }
                            </div>
                        ))) ||
                    (props.config?.type[column] === 'status_list' && (
                        <div className="pl-2">
                            {
                                Echo(state.lang)[props.resource].fields[column][
                                    row.object[column]
                                ]
                            }
                        </div>
                    )) ||
                    (props.config?.type[column] === 'status_teams' &&
                        (['owner', 'admin'].includes(userRole) ? (
                            <div className="">
                                {row.object.status === 'waiting' ? (
                                    Echo(state.lang)[props.resource].fields[
                                        column
                                    ][row.object[column]]
                                ) : (
                                    <Form.Check
                                        className="switchButtonStyle mx-3"
                                        checked={row.object.status === 'on'}
                                        type="switch"
                                        size="xl"
                                        id={`c${row.id}`}
                                        onChange={(e) => {
                                            props.setLoading(true);
                                            Teams.patch(
                                                workspace.id,
                                                row.id,
                                                activeUser[0].token,
                                                {
                                                    status:
                                                        row.object.status ===
                                                        'on'
                                                            ? 'off'
                                                            : 'on',
                                                }
                                            )
                                                .then(() => props.getData())
                                                .catch(() =>
                                                    props.setLoading(false)
                                                );
                                        }}
                                        disabled={
                                            row.object.role === 'owner' ||
                                            row.object.email ===
                                                activeUser[0].email ||
                                            ['owner', 'admin'].indexOf(
                                                userRole
                                            ) === -1
                                        }
                                    />
                                )}
                            </div>
                        ) : (
                            <div>
                                {
                                    Echo(state.lang)[props.resource].fields[
                                        column
                                    ][row.object[column]]
                                }
                            </div>
                        ))) ||
                    (props.config?.type[column] === 'enum' &&
                        Echo(state.lang)[props.resource].fields[column][
                            row.object[column]
                        ]) ||
                    (props.config?.type[column] === 'timestamp' &&
                        (row.object?.rating?.[column] ? (
                            <div className="pl-3">
                                {new Date(
                                    row.object?.rating?.[column]
                                ).toLocaleDateString('fr-FR', {
                                    weekday: 'short',
                                    year: 'numeric',
                                    month: 'short',
                                    day: '2-digit',
                                })}
                            </div>
                        ) : (
                            ''
                        ))) ||
                    (props.config?.type[column] === 'date' &&
                        (row.object[column] ? (
                            <div className="pl-3">
                                {new Date(
                                    row.object[column]
                                ).toLocaleDateString('fr-FR', {
                                    weekday: 'short',
                                    year: 'numeric',
                                    month: 'short',
                                    day: '2-digit',
                                })}
                            </div>
                        ) : (
                            '-'
                        ))) ||
                    (props.config?.type[column] === 'wsjf' &&
                        row.object?.rating?.result !== undefined && (
                            <div className="pl-2">
                                {[
                                    'wsjf',
                                    'business_value',
                                    'risk_opportunity',
                                    'time_criticality',
                                    'cost',
                                    'effort',
                                ].includes(column) ? (
                                    <CalcSquare
                                        centred={false}
                                        value={
                                            row.object?.rating?.result?.[column]
                                        }
                                        variant={
                                            column === 'wsjf'
                                                ? 'primary'
                                                : undefined
                                        }
                                    />
                                ) : (
                                    <CalcSquare
                                        centred={false}
                                        value={
                                            row.object?.rating?.result
                                                ?.business_value_axis?.[column]
                                        }
                                    />
                                )}
                            </div>
                        )) || <div className="pl-3">{row.object[column]}</div>}
            </td>
        );
    };
    const handleScrollX = (e) => {
        document.getElementById(
            'rk-table-head'
        ).style.marginLeft = `-${e.target.scrollLeft}px`;
        if (document.getElementById('rk-table-error'))
            document.getElementById(
                'rk-table-error'
            ).style.marginLeft = `${e.target.scrollLeft}px`;
    };
    const handleWindowSize = () => {
        setRkTableSize({
            tableWidth: document.getElementById('rk-table')?.clientWidth,
            tableHeight:
                document.getElementById('rk-table')?.clientHeight - 130,
            offsetWidth: document.getElementById('rk-table-head')?.offsetWidth,
            offsetHeight:
                document.getElementById('rk-table-body')?.offsetHeight,
        });
    };
    useEffect(() => {
        handleWindowSize();
        window.addEventListener('scroll', handleScrollX, true);
        window.addEventListener('resize', handleWindowSize);
        return () => {
            window.removeEventListener('scroll', handleScrollX, true);
            window.removeEventListener('scroll', handleWindowSize, true);
        };
    }, []);
    useEffect(() => {
        handleWindowSize();
    }, [props.optionsStates]);
    return (
        <div
            id="rk-table"
            className="overflow-hidden font-weight-600 radius-14 bg-white shadow m-2 p-0"
        >
            <Table striped borderless className="m-0">
                <thead id="rk-table-head">
                    <tr>
                        {Object.keys(props?.filterStates || {})?.map(
                            (column) => {
                                if (props.optionsStates[column])
                                    switch (props.config?.type[column]) {
                                        case 'string':
                                            return (
                                                <th
                                                    key={column}
                                                    className={
                                                        'minem-w-' +
                                                        props.config?.sizes[
                                                            column
                                                        ]
                                                    }
                                                >
                                                    <RkTableFilter
                                                        title={
                                                            Echo(state.lang)[
                                                                props.resource
                                                            ].table.columns[
                                                                column
                                                            ]
                                                        }
                                                        column={column}
                                                        handleSortState={
                                                            props.handleSortState
                                                        }
                                                        sortStates={
                                                            props.sortStates
                                                        }
                                                        type={
                                                            props.config?.type[
                                                                column
                                                            ]
                                                        }
                                                        filter={
                                                            props.filterStates[
                                                                column
                                                            ].length > 1
                                                        }
                                                        handleFilterReset={() =>
                                                            props.handleFilterReset(
                                                                column
                                                            )
                                                        }
                                                    >
                                                        {renderStringFilter(
                                                            props.resource,
                                                            column
                                                        )}
                                                    </RkTableFilter>
                                                </th>
                                            );
                                        case 'date':
                                            return (
                                                <th
                                                    key={column}
                                                    className={
                                                        'minem-w-' +
                                                        props.config?.sizes[
                                                            column
                                                        ]
                                                    }
                                                >
                                                    <RkTableFilter
                                                        title={
                                                            Echo(state.lang)[
                                                                props.resource
                                                            ].table.columns[
                                                                column
                                                            ]
                                                        }
                                                        column={column}
                                                        handleSortState={
                                                            props.handleSortState
                                                        }
                                                        sortStates={
                                                            props.sortStates
                                                        }
                                                        type={
                                                            props.config?.type[
                                                                column
                                                            ]
                                                        }
                                                        filter={
                                                            props.filterStates[
                                                                column
                                                            ].lt !== '' ||
                                                            props.filterStates[
                                                                column
                                                            ].gt !== ''
                                                        }
                                                        handleFilterReset={() =>
                                                            props.handleFilterReset(
                                                                column
                                                            )
                                                        }
                                                    >
                                                        {renderDateFilter(
                                                            props.resource,
                                                            column
                                                        )}
                                                    </RkTableFilter>
                                                </th>
                                            );
                                        case 'enum':
                                        case 'role':
                                        case 'status_project':
                                        case 'status_teams':
                                        case 'status_list':
                                            return (
                                                <th
                                                    key={column}
                                                    className={
                                                        'minem-w-' +
                                                        props.config?.sizes[
                                                            column
                                                        ]
                                                    }
                                                >
                                                    <RkTableFilter
                                                        title={
                                                            Echo(state.lang)[
                                                                props.resource
                                                            ].table.columns[
                                                                column
                                                            ]
                                                        }
                                                        column={column}
                                                        handleSortState={
                                                            props.handleSortState
                                                        }
                                                        sortStates={
                                                            props.sortStates
                                                        }
                                                        type={
                                                            props.config?.type[
                                                                column
                                                            ]
                                                        }
                                                        filter={
                                                            Object.values(
                                                                props
                                                                    .filterStates[
                                                                    column
                                                                ]
                                                            ).filter((c) => c)
                                                                .length > 0
                                                        }
                                                        handleFilterReset={() =>
                                                            props.handleFilterReset(
                                                                column
                                                            )
                                                        }
                                                    >
                                                        {renderEnumFilter(
                                                            props.resource,
                                                            column
                                                        )}
                                                    </RkTableFilter>
                                                </th>
                                            );
                                        case 'tags':
                                            return (
                                                <th
                                                    key={column}
                                                    className={
                                                        'minem-w-' +
                                                        props.config?.sizes[
                                                            column
                                                        ]
                                                    }
                                                >
                                                    <RkTableFilter
                                                        title={
                                                            Echo(state.lang)[
                                                                props.resource
                                                            ].table.columns[
                                                                column
                                                            ]
                                                        }
                                                        column={column}
                                                        handleSortState={
                                                            props.handleSortState
                                                        }
                                                        sortStates={
                                                            props.sortStates
                                                        }
                                                        type={
                                                            props.config?.type[
                                                                column
                                                            ]
                                                        }
                                                        filter={
                                                            Object.values(
                                                                props
                                                                    .filterStates[
                                                                    column
                                                                ]
                                                            ).filter(
                                                                (c) =>
                                                                    c.selected
                                                            ).length > 0
                                                        }
                                                        handleFilterReset={() =>
                                                            props.handleFilterReset(
                                                                column
                                                            )
                                                        }
                                                    >
                                                        {renderTagsFilter(
                                                            props.resource,
                                                            column
                                                        )}
                                                    </RkTableFilter>
                                                </th>
                                            );
                                        case 'wsjf':
                                            return (
                                                <th
                                                    key={column}
                                                    className={
                                                        'minem-w-' +
                                                        props.config?.sizes[
                                                            column
                                                        ]
                                                    }
                                                >
                                                    <RkTableFilter
                                                        title={
                                                            ![
                                                                'wsjf',
                                                                'business_value',
                                                                'risk_opportunity',
                                                                'time_criticality',
                                                                'cost',
                                                                'effort',
                                                            ].includes(column)
                                                                ? props.echo?.[
                                                                      column
                                                                  ]
                                                                      .split(
                                                                          '  '
                                                                      )
                                                                      .map(
                                                                          (
                                                                              s
                                                                          ) => {
                                                                              return (
                                                                                  <p className="m-0">
                                                                                      {
                                                                                          s
                                                                                      }
                                                                                  </p>
                                                                              );
                                                                          }
                                                                      )
                                                                : Echo(
                                                                      state.lang
                                                                  )
                                                                      [
                                                                          props
                                                                              .resource
                                                                      ].table.columns[
                                                                          column
                                                                      ].split(
                                                                          '  '
                                                                      )
                                                                      .map(
                                                                          (
                                                                              s
                                                                          ) => {
                                                                              return (
                                                                                  <p className="m-0">
                                                                                      {
                                                                                          s
                                                                                      }
                                                                                  </p>
                                                                              );
                                                                          }
                                                                      )
                                                        }
                                                        column={column}
                                                        handleSortState={
                                                            props.handleSortState
                                                        }
                                                        sortStates={
                                                            props.sortStates
                                                        }
                                                        type={
                                                            props.config?.type[
                                                                column
                                                            ]
                                                        }
                                                        filter={
                                                            props.filterStates[
                                                                column
                                                            ].lt !== '' ||
                                                            props.filterStates[
                                                                column
                                                            ].gt !== ''
                                                        }
                                                        handleFilterReset={() =>
                                                            props.handleFilterReset(
                                                                column
                                                            )
                                                        }
                                                    >
                                                        {renderWsjfFilter(
                                                            props.resource,
                                                            column
                                                        )}
                                                    </RkTableFilter>
                                                </th>
                                            );
                                        case 'teams':
                                            return (
                                                <th
                                                    key={column}
                                                    className={
                                                        'minem-w-' +
                                                        props.config?.sizes[
                                                            column
                                                        ]
                                                    }
                                                >
                                                    <RkTableFilter
                                                        title={
                                                            Echo(state.lang)[
                                                                props.resource
                                                            ].table.columns[
                                                                column
                                                            ]
                                                        }
                                                        column={column}
                                                        handleSortState={
                                                            props.handleSortState
                                                        }
                                                        sortStates={
                                                            props.sortStates
                                                        }
                                                        type={
                                                            props.config?.type[
                                                                column
                                                            ]
                                                        }
                                                        filter={
                                                            Object.values(
                                                                props
                                                                    .filterStates[
                                                                    column
                                                                ]
                                                            ).filter(
                                                                (c) =>
                                                                    c.selected
                                                            ).length > 0
                                                        }
                                                        handleFilterReset={() =>
                                                            props.handleFilterReset(
                                                                column
                                                            )
                                                        }
                                                    >
                                                        {renderTeamsFilter(
                                                            props.resource,
                                                            column
                                                        )}
                                                    </RkTableFilter>
                                                </th>
                                            );
                                        default:
                                            return (
                                                <th
                                                    key={column}
                                                    className={
                                                        'minem-w-' +
                                                            props.config?.sizes[
                                                                column
                                                            ] || '8'
                                                    }
                                                >
                                                    <RkTableFilter
                                                        title={
                                                            Echo(state.lang)[
                                                                props.resource
                                                            ].table.columns[
                                                                column
                                                            ] || column
                                                        }
                                                        column={column}
                                                        handleSortState={
                                                            props.handleSortState
                                                        }
                                                        sortStates={
                                                            props.sortStates
                                                        }
                                                        type={
                                                            props.config?.type[
                                                                column
                                                            ]
                                                        }
                                                    ></RkTableFilter>
                                                </th>
                                            );
                                    }
                            }
                        )}
                        <th key="rk_refresh" className="minem-w-7">
                            <div className="d-flex flex-row-reverse pr-4">
                                <FiRefreshCw
                                    size="2rem"
                                    color="#999999"
                                    className={
                                        props.loading
                                            ? 'rotating ml-4'
                                            : 'pointer ml-4'
                                    }
                                    onClick={() => props.getData()}
                                />
                            </div>
                        </th>
                    </tr>
                </thead>
            </Table>
            <Table
                id="rk-table-body"
                responsive
                striped
                borderless
                className="scroll-x scroll-y m-0"
            >
                <tbody className="rk-height-330">
                    {Object.values(props?.optionsStates || {}).filter(
                        (col) => col === true
                    ).length === 0 ? (
                        <>
                            <tr
                                style={{
                                    width: RkTableSize.offsetWidth,
                                }}
                            ></tr>
                            <div
                                id="rk-table-error"
                                className="flex v-center h-center"
                                style={{
                                    width: RkTableSize.tableWidth,
                                    height: RkTableSize.tableHeight,
                                }}
                            >
                                Vous n'avez sélectionné aucune colonne !
                            </div>
                        </>
                    ) : props.data.length === 0 ? (
                        <>
                            <tr
                                style={{
                                    width: RkTableSize.offsetWidth,
                                }}
                            ></tr>
                            <div
                                id="rk-table-error"
                                className="flex v-center h-center"
                                style={{
                                    width: RkTableSize.tableWidth,
                                    height: RkTableSize.tableHeight,
                                }}
                            >
                                {props.loading ? (
                                    <FiRefreshCw
                                        size={36}
                                        className="rotating"
                                    />
                                ) : (
                                    <span>
                                        Aucun résultat ne correspond à votre
                                        recherche !
                                    </span>
                                )}
                            </div>
                        </>
                    ) : (
                        props.data.map((row, i) => (
                            <tr key={i} style={{ height: '64px' }}>
                                {Object.keys(props.filterStates).map(
                                    (column) => {
                                        return props.optionsStates[column]
                                            ? renderData(row, column)
                                            : '';
                                    }
                                )}

                                <td key="rk_func" className="minem-w-7">
                                    <div className="d-flex flex-row-reverse">
                                        {props.delete &&
                                        ['owner', 'admin'].includes(
                                            userRole
                                        ) ? (
                                            props.resource === 'teams' &&
                                            (row.object.email ===
                                                activeUser[0].email ||
                                                row.object.role === 'owner') ? (
                                                ''
                                            ) : (
                                                <div
                                                    onClick={() =>
                                                        props.delete(row.id)
                                                    }
                                                    className="pointer"
                                                >
                                                    <FiTrash2
                                                        size="2rem"
                                                        color="#999999"
                                                        className="ml-4"
                                                    />
                                                </div>
                                            )
                                        ) : (
                                            ''
                                        )}
                                        {props.archive &&
                                        ['owner', 'admin'].includes(userRole) &&
                                        ![
                                            '00_to_plan',
                                            '01_planned',
                                            '06_archived',
                                            'archived',
                                        ].includes(row.object.status) ? (
                                            <div
                                                onClick={() =>
                                                    props.archive(row.id)
                                                }
                                                className="pointer"
                                            >
                                                <FiArchive
                                                    size="2rem"
                                                    color="#999999"
                                                    className="ml-4"
                                                />
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>
        </div>
    );
};
