import { React, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FiLinkedin, FiTwitter, FiHeart } from 'react-icons/fi';
import Config from '../../util/Config';
import Cookies from 'js-cookie';
import Logo from './Logo';
export default () => {
    const [healthCheck, setHealthCheck] = useState(
        Cookies.get('RK_healthcheck') || 'unavailable'
    );
    const developmentFooter = () => {
        if (Config.REACT_APP_ENV === 'development') {
            return (
                <span className="float-right">
                    status: <strong>{healthCheck}</strong>
                </span>
            );
        } else {
            return;
        }
    };
    return (
        <div className="Footer">
            <Row className="m-0">
                <Col sm={3} md={4} lg={3} className="d-none d-sm-block px-3 col-xxl-2">
                    <Logo />
                </Col>
                <Col sm={10} md={5} className="px-3 mb-4 mr-4 col-xxl-4">
                    <span className="font-weight-800 display-18">
                        Qui sommes-nous ?
                    </span>
                    <p className="font-weight-600 mt-3">
                        <a
                            href="https://www.linkedin.com/in/ybuffet"
                            target="_blank"
                            aria-label="Lien vers le compte LinkedIn de Yann"
                            className="text-primary"
                        >
                            Yann
                        </a>
                        <span>, plus de 18 ans d'expérience en tant que consultant, & </span>
                        <a
                            href="https://www.linkedin.com/in/oussemadaoud"
                            target="_blank"
                            aria-label="Lien vers le compte LinkedIn d'Oussema"
                            className="text-primary"
                        >
                            Oussema
                        </a>
                        <span>, plus de 7 ans d'expérience en tant que développeur full stack, 2 entrepreneurs passionnés,
                        participant aux transformations actuelles des
                        organisations, et voulant libérer les équipes
                        pour leur permettre de créer encore plus de valeur !</span>
                    </p>
                </Col>
                <Col sm={3} md={2} className="px-3 mb-4">
                    <span className="font-weight-800 display-18">
                        Communauté
                    </span>
                    <br />
                    <a
                        href="https://www.linkedin.com/company/rankioapp"
                        target="_blank"
                        aria-label="Lien vers le compte LinkedIn de l'entreprise"
                    >
                        <FiLinkedin
                            className="text-primary display-18 mt-3"
                            size="2rem"
                        />
                    </a>
                    <br />
                    <a 
                        href="https://twitter.com/rankioapp"
                        target="_blank"
                        aria-label="Lien vers le compte Twitter de l'entreprise"
                    >
                        <FiTwitter
                            className="text-primary display-18 mt-3"
                            size="2rem"
                        />
                    </a>
                </Col>
            </Row>
            <p className="text-secondary small mt-3 px-3">
                Copyright © Rankio, 2023 - All rights reserved. Made with{' '}
                <FiHeart className="text-primary" /> between Paris, Antibes, 
                La Baule & Sfax
                {developmentFooter()}
            </p>
        </div>
    );
};
