import React, { useEffect, useState } from 'react';
import { Row, Dropdown, Col } from 'react-bootstrap';
import { FiDownload } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// see : https://www.papaparse.com/docs#json-to-csv
import Papa from 'papaparse';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Echo from './Echo';

const Config = {
    delimiter: ';', // auto-detect
    newline: '\r\n', // auto-detect
    quoteChar: '"',
    escapeChar: '"',
    header: true, // column
    transformHeader: undefined,
    dynamicTyping: false,
    preview: 0,
    encoding: '',
    worker: false,
    comments: false,
    step: function (results, parser) {
        console.log('Row data:', results.data);
        console.log('Row errors:', results.errors);
    },
    complete: function (results, file) {
        console.log('Parsing complete:', results, file);
    },
    error: undefined,
    download: false,
    downloadRequestHeaders: undefined,
    downloadRequestBody: undefined,
    skipEmptyLines: false,
    chunk: undefined,
    chunkSize: undefined,
    fastMode: undefined,
    beforeFirstChunk: undefined,
    withCredentials: undefined,
    transform: undefined,
    delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
};
const download = async (workspace, data, type) => {
    const hiddenElement = document.createElement('a');
    switch (type) {
        case 'project':
            const tags = [];
            await workspace.object.tags.categories.forEach((cat) =>
                cat.tags.forEach((tag) => {
                    tags[tag.ref] = tag.code;
                })
            );
            // const assignment = [];
            // await workspace.object.assignment.categories.forEach((cat) =>
            //         assignment[tag.ref] = tag.code;
            // );
            hiddenElement.href =
                'data:text/csv;charset=utf-8,' +
                encodeURI(
                    Papa.unparse(
                        data.map((p) => {
                            return {
                                ...p.object,
                                tags: p.object.tags
                                    .map((ref) => tags[ref])
                                    .join(' '),
                            };
                        }),
                        Config
                    )
                );
            break;
        default:
            data.map((p) => p.object);
    }
    hiddenElement.target = '_blank';
    hiddenElement.download =
        'exports_rk_' +
        workspace.object.code +
        '_' +
        type +
        '_' +
        new Date().toLocaleDateString('en-GB').split('/').reverse().join('') +
        '.csv';
    hiddenElement.click();
};
export default (props) => {
    const { trackPageView, trackEvent } = useMatomo();
    const users = useSelector((state) => state.user.users);
    const { id } = useParams();
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = users.filter((user) => user.id.includes(id))[0].workspace[
        activeUser[0].activeWorkspace
    ];
    return <></>;
    // return (
    //     <Row
    //         className="font-weight-700 display-14 cursor-pointer"
    //         onClick={() => {
    //             download(workspace, props.data, props.resource);
    //         }}
    //     >
    //         <Col sm={3} className="my-auto">
    //             <FiDownload size="2em" />
    //         </Col>
    //         <Col sm={9} className="pl-1 my-auto">
    //             {Echo().commun.export}
    //         </Col>
    //     </Row>
    // );
};
