import { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import AccountDropdown from '../commun/AccountDropdown';
import Lang from '../commun/Lang';
import { Link as ScrollLink } from 'react-scroll';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import User from '../../util/User';

export default ({ sm, onSelectMenu }) => {
    const history = useHistory();
    const state = useSelector((state) => state.user);
    const users = state.users;
    const activeIndex = useSelector((state) => state.user.activeUserIndex);
    const activeUser = users[activeIndex];
    const dispatch = useDispatch();
    const setHash = (landingElement) => {
        const myElement = document.getElementById(landingElement);
        const topPos = myElement.offsetTop;
        document.getElementById('landing').scrollTop = topPos;
    };
    const [login, setLogin] = useState(
        <Col className="p-0 pt-2">
            <Link to="/login" className="header-item text-black px-2 mr-xl-3">
                Se connecter
            </Link>
        </Col>
    );
    const [signup, setSignup] = useState(
        <Link to="/signup" className="mx-2">
            <Button className="btn-sm btn-primary">
                <strong>Tester</strong>
            </Button>
        </Link>
    );
    useEffect(() => {
        if (activeUser && activeUser.id) {
            if (activeUser.workspace.length) {
                setLogin(
                    <Col className="p-0 pt-2">
                        <Link
                            to={
                                '/' + activeUser.id.split('-')[0] + '/dashboard'
                            }
                            className="header-item text-black px-2"
                        >
                            {
                                activeUser.workspace[activeUser.activeWorkspace]
                                    .object.name
                            }
                        </Link>
                    </Col>
                );
            } else {
                User.headTest(activeUser.token)
                    .then((res) => {
                        switch (res.status) {
                            case 403:
                                dispatch(
                                    {
                                        type: 'LOGOUT',
                                        value: activeUser.id,
                                    },
                                    history.push('/login')
                                );
                                break;
                            case 402:
                                setLogin(
                                    <Col className="p-0 pl-1">
                                        <ScrollLink
                                            activeClass="active"
                                            to="prices"
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                            onClick={() => {
                                                if (sm) onSelectMenu('prices');
                                            }}
                                        >
                                            <Button className="btn-sm btn-primary">
                                                <strong>Achetez</strong>
                                            </Button>
                                        </ScrollLink>
                                    </Col>
                                );
                                break;
                            case 200:
                                setLogin(
                                    <Col className="p-0 pl-1">
                                        <Link to="/onboarding" className="mx-2">
                                            <Button className="btn-sm btn-primary">
                                                <strong>Testez</strong>
                                            </Button>
                                        </Link>
                                    </Col>
                                );
                                break;
                            default:
                                setTimeout(() => {
                                    window.location.reload();
                                }, 2000);
                        }
                    })
                    .catch((err) => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    });
            }
            setSignup(
                <div className="ml-4">
                    <AccountDropdown id={activeUser.id} />
                </div>
            );
        }
    }, []);
    return (
        <>
            <Row className="pt-0 mr-0 ml-auto">
                <Col className="p-0 pt-2 px-xl-2">
                    <ScrollLink
                        className="header-item cursor-pointer p-2"
                        activeClass="active"
                        to="home"
                        spy={true}
                        smooth={true}
                        duration={500}
                        onClick={() => {
                            if (sm) onSelectMenu('home');
                        }}
                    >
                        Accueil
                    </ScrollLink>
                </Col>
                <Col xs={12} className="d-lg-none d-block m-2"></Col>
                <Col className="p-0 pt-2 px-xl-2">
                    <ScrollLink
                        className="header-item cursor-pointer p-2"
                        activeClass="active"
                        to="features"
                        spy={true}
                        smooth={true}
                        duration={500}
                        onClick={() => {
                            if (sm) onSelectMenu('features');
                        }}
                    >
                        Fonctionnalités
                    </ScrollLink>
                </Col>
                <Col xs={12} className="d-lg-none d-block m-2"></Col>
                <Col className="p-0 pt-2 px-xl-2">
                    <ScrollLink
                        className="header-item cursor-pointer p-2"
                        activeClass="active"
                        to="benefits"
                        spy={true}
                        smooth={true}
                        duration={500}
                        onClick={() => {
                            if (sm) onSelectMenu('benefits');
                        }}
                    >
                        Bénéfices
                    </ScrollLink>
                </Col>
                <Col xs={12} className="d-lg-none d-block m-2"></Col>
                <Col className="p-0 pt-2 px-xl-2">
                    <ScrollLink
                        className="header-item cursor-pointer p-2"
                        activeClass="active"
                        to="prices"
                        spy={true}
                        smooth={true}
                        duration={500}
                        onClick={() => {
                            if (sm) onSelectMenu('prices');
                        }}
                    >
                        Tarifs
                    </ScrollLink>
                </Col>
                <Col xs={12} className="d-lg-none d-block m-2"></Col>
                <Col className="p-0 pt-2 pr-4 pl-xl-2 pr-xl-5">
                    <ScrollLink
                        className="header-item cursor-pointer p-2"
                        activeClass="active"
                        to="contact"
                        spy={true}
                        smooth={true}
                        duration={500}
                        onClick={() => {
                            if (sm) onSelectMenu('contact');
                        }}
                    >
                        Contact
                    </ScrollLink>
                </Col>
                <Col xs={12} className="d-lg-none d-block m-2"></Col>
                <Col className="p-0 pt-2 pr-4 pl-xl-2 pr-xl-5">
                    <Lang lang={state.lang} />
                </Col>
                <Col xs={12} className="d-lg-none d-block m-2"></Col>
                {login}
                <Col xs={12} className="d-lg-none d-block m-3"></Col>
                <Col className="p-0">{signup}</Col>
            </Row>
        </>
    );
};
