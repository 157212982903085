import React from 'react';
import Axios from 'axios';
import ApiCallConfig from './ApiCallConfig';
export default class Project {
    /**
     * @function post
     * @definition Create project
     * @returns {Promise}
     */
    static post(workspaceId, token, payload) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'POST',
                url: '/workspace/' + workspaceId + '/project',
                headers: {
                    authorization: 'Bearer ' + token,
                },
                data: payload,
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    /**
     * @function get
     * @definition Get project
     * @returns {Promise}
     */
    static get(workspaceId, projectId, token) {
        console.log('get user');
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'GET',
                url: '/workspace/' + workspaceId + '/project',
                params: {
                    id: projectId,
                },
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    /**
     * @function patch
     * @definition Update project
     * @returns {Promise}
     */
    static patch(workspaceId, projectId, token, payload) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'PATCH',
                url: '/workspace/' + workspaceId + '/project/' + projectId,
                headers: {
                    authorization: 'Bearer ' + token,
                },
                data: payload,
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    /**
     * @function delete
     * @definition Remove project
     * @returns {Promise}
     */
    static delete(workspaceId, projectId, token, payload) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'DELETE',
                url: '/workspace/' + workspaceId + '/project/' + projectId,
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
