import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import logger from "redux-logger-simple";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./reducers/UserReducer"



const persistConfig = {
    key: "Rankio",
    timeout: null,
    storage,
    whitelist: ['user'],
    blacklist: []
};
const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && process.env.NODE_ENV !== 'production'

        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        })
        : compose;

const enhancer = composeEnhancers(
    applyMiddleware(logger)
);
const reducers = combineReducers({
    user: userReducer,
});

const persistedReducers = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducers, enhancer);

export default store;
export const persistor = persistStore(store);
