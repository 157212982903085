import Config from '../../util/Config';
import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    Collapse,
    Form,
    Row,
    Alert as Info,
} from 'react-bootstrap';
import {
    FiEdit,
    FiLoader,
    FiCheck,
    FiMinus,
    FiLogOut,
    FiTrash2,
    FiAlertCircle,
    FiX,
    FiCreditCard,
    FiShoppingCart,
    FiPlus,
    FiInfo,
    FiChevronDown,
    FiChevronUp,
} from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Switch from 'react-switch';
import Select from 'react-select';
import BlueButton from '../commun/BlueButton';
import DarkButton from '../commun/DarkButton';
import GreyButton from '../commun/GreyButton';
import Echo from '../../util/Echo';
import ErrorSelector from '../error/ErrorSelector';
import Alert from '../commun/Alert';
import TeamIcon from '../commun/TeamIcon';
import TeamData from '../../util/TeamData';
import Plan from '../../util/Plan';
import Teams from '../../util/Teams';
import Feature from '../../util/Feature';
import Subscription from '../../util/Subscription';
import PaymentMethods from '../../util/PaymentMethods';
import PaymentMethodsForm from '../account/PaymentMethods.js';
import Modal from '../commun/RkModal';

export default function AjouterOption(props) {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.user.users);
    const { id } = useParams();
    const activeUser = users.filter((user) => user.id.includes(id));
    const [my] = useState(activeUser[0]);
    const [open, setOpen] = useState({});
    const [thisAdminFeature, setThisAdminFeature] = useState({});
    const [adminList, setAdminList] = useState({});
    const [thisPlan, setThisPlan] = useState({});
    const [thisSubscription, setThisSubscription] = useState([]);
    const [paymentFrequency, setPaymentFrequency] = useState('annually');
    const [defaultPaymentMethods, setDefaultPaymentMethods] = useState(null);
    const [accessDenied, setAccessDenied] = useState(false);
    const [checkoutDone, setCheckoutDone] = useState(false);
    const [loadingSubscription, setLoadingSubscription] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [colorAlert, setColorAlert] = useState('danger');
    const [contentAlert, setContentAlert] = useState(
        'Une erreur est survenue, veuillez nous en excuser'
    );
    const Workspaces = activeUser[0].workspace;
    useEffect(() => {
        Feature.get({ "object->>'code'": 'ADMIN' }).then((res) => {
            if (res.status === 200) {
                const annually = res.data.data.filter(
                    (f) => f.object.payment_frequency === 'annually'
                )[0];
                const monthly = res.data.data.filter(
                    (f) => f.object.payment_frequency === 'monthly'
                )[0];
                setThisAdminFeature({
                    annually,
                    monthly,
                });
            } else {
                setColorAlert('danger');
                setContentAlert(
                    'Une erreur est survenue, veuillez réessayer ultérieurement'
                );
                setShowAlert(true);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
        });
        Plan.get({ "object->>'active'": true, "object->>'code'": 'MVP' })
            .then((planRes) => {
                if (planRes.status === 200) {
                    const annually = planRes.data.data.filter(
                        (p) => p.object.payment_frequency === 'annually'
                    )[0];
                    const monthly = planRes.data.data.filter(
                        (p) => p.object.payment_frequency === 'monthly'
                    )[0];
                    setThisPlan({
                        annually: {
                            id: annually.id,
                            object: {
                                ...annually.object,
                                cost_per_user_per_month: Math.round(
                                    annually.object.prices / 10 / 12
                                ),
                                eco_percent:
                                    '(soit ' +
                                    (
                                        100 -
                                        Math.round(
                                            (annually.object.prices * 100) /
                                                (monthly.object.prices * 12) +
                                                Number.EPSILON
                                        )
                                    ).toString() +
                                    '% d’économies)',
                            },
                        },
                        monthly: {
                            id: monthly.id,
                            object: {
                                ...monthly.object,
                                cost_per_user_per_month: Math.round(
                                    monthly.object.prices / 10
                                ),
                                eco_percent: ' ',
                            },
                        },
                    });
                } else {
                    setAccessDenied(true);
                }
            })
            .catch((err) => {
                setAccessDenied(true);
            });
        getPaymentMethods();
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        Subscription.get({ id: props.subscriptionId }, activeUser[0].token)
            .then(async (subscriptionRes) => {
                if (subscriptionRes.status === 200) {
                    // only one workspace pre subscription
                    const workspaceId = Workspaces.filter(
                        (w) =>
                            w.object.subscription ===
                            subscriptionRes.data.data[0].id
                    )?.[0]?.id;
                    if (workspaceId)
                        Teams.get(workspaceId, activeUser[0].token).then(
                            (res) => {
                                setAdminList({
                                    workspaceId,
                                    data: res?.data?.data.filter(
                                        (t) =>
                                            t.object.role === 'admin' &&
                                            t.object.status !== 'off'
                                    ),
                                });
                            }
                        );
                    setThisSubscription(subscriptionRes.data.data[0]);
                    setPaymentFrequency(
                        thisPlan?.annually?.id ===
                            subscriptionRes.data.data[0].object.plan
                            ? 'annually'
                            : 'monthly',
                        setLoadingSubscription(false)
                    );
                    setLoadingSubscription(false);
                } else {
                    setAccessDenied(true);
                }
            })
            .catch((err) => {
                setAccessDenied(true);
            });
    }, [thisPlan]);
    useEffect(() => {
        if (thisSubscription.id) {
            const newSubscription = { ...thisSubscription };
            // update plan id
            newSubscription.object.plan = thisPlan?.[paymentFrequency]?.id;
            // update features admin ids
            newSubscription.object.features =
                thisSubscription.object.features.map(
                    () => thisAdminFeature?.[paymentFrequency]?.id
                );
            setThisSubscription(
                newSubscription,
                console.log(
                    'thisSubscription',
                    thisSubscription.object.plan,
                    thisSubscription.object.features
                )
            );
        }
    }, [paymentFrequency]);
    useEffect(() => {
        if (thisSubscription.id) {
            Subscription.patch(thisSubscription.id, activeUser[0].token, {
                plan: thisSubscription.object.plan,
                features: thisSubscription.object.features,
            });
        }
    }, [thisSubscription]);
    const getPaymentMethods = () => {
        PaymentMethods.get({}, activeUser[0].token)
            .then((res) => {
                if (res.status === 200) {
                    setDefaultPaymentMethods(res.data.default || null);
                } else if (res.status === 404) {
                    setDefaultPaymentMethods(null);
                } else {
                    setColorAlert('danger');
                    setContentAlert(
                        'Une erreur est survenue, veuillez réessayer ultérieurement'
                    );
                    setShowAlert(true);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            })
            .catch((err) => {
                setColorAlert('danger');
                setContentAlert(
                    'Une erreur est survenue, veuillez réessayer ultérieurement'
                );
                setShowAlert(true);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            });
    };
    const renderFeatureAdmin = (list) => {
        return (
            <>
                {list.map((sb) => {
                    let ltft = [...new Set(sb?.object?.features)];
                    return (
                        <>
                            {ltft.map((ft) => {
                                return (
                                    <Card
                                        key={sb.id + '-admin'}
                                        className="display-14 p-0 m-3 mb-4 mr-0"
                                        style={{ maxWidth: '780px' }}
                                    >
                                        <Row className="m-0">
                                            <Col
                                                md={'auto'}
                                                className="cursor-pointer px-3 my-auto"
                                                onClick={() => {
                                                    setOpen({
                                                        ...open,
                                                        [sb.id + '-admin']:
                                                            open?.[
                                                                sb.id + '-admin'
                                                            ] === true
                                                                ? false
                                                                : true,
                                                    });
                                                }}
                                            >
                                                {open?.[sb.id + '-admin'] ===
                                                true ? (
                                                    <FiChevronUp
                                                        color="#999999"
                                                        size="2.2rem"
                                                    />
                                                ) : (
                                                    <FiChevronDown
                                                        color="#999999"
                                                        size="2.2rem"
                                                    />
                                                )}
                                            </Col>
                                            <Col className="p-3 my-auto">
                                                <p className="display-20 font-weight-800 m-0">
                                                    {
                                                        thisAdminFeature?.[
                                                            paymentFrequency
                                                        ].object.name
                                                    }
                                                </p>
                                            </Col>
                                            <Col
                                                md={'auto'}
                                                className="p-3 my-auto"
                                            >
                                                <div className="add-option-add-total-nbr-admin-container">
                                                    <FiMinus
                                                        className="fiche-user-icon-minus"
                                                        onClick={() => {
                                                            rmAdmin();
                                                        }}
                                                    />
                                                    <div className="add-option-number-admin">
                                                        {
                                                            thisSubscription
                                                                ?.object
                                                                ?.features
                                                                ?.length
                                                        }
                                                    </div>
                                                    <FiPlus
                                                        className="fiche-user-icon-plus"
                                                        onClick={() => {
                                                            addAdmin();
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col
                                                md={'auto'}
                                                className="p-3 pr-4 my-auto"
                                            >
                                                <div
                                                    style={{ width: '130px' }}
                                                    className="text-right display-20 font-weight-800 m-0"
                                                >
                                                    {thisSubscription?.object
                                                        ?.features?.length
                                                        ? thisSubscription
                                                              ?.object?.features
                                                              ?.length *
                                                          thisAdminFeature?.[
                                                              paymentFrequency
                                                          ]?.object?.prices
                                                        : thisAdminFeature?.[
                                                              paymentFrequency
                                                          ]?.object?.prices}
                                                    {
                                                        thisAdminFeature?.[
                                                            paymentFrequency
                                                        ]?.object?.currency
                                                    }
                                                    <br />
                                                    <span className="font-weight-bold display-16">
                                                        HT/
                                                        {paymentFrequency ===
                                                        'annually'
                                                            ? 'an'
                                                            : 'mois'}
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Collapse in={open?.[sb.id + '-admin']}>
                                            <Row className="m-0">
                                                <Col
                                                    md={'auto'}
                                                    className="px-3 my-auto"
                                                >
                                                    <FiInfo
                                                        color="#ffffff"
                                                        size="2.2rem"
                                                    />
                                                </Col>
                                                <Col className="p-3">
                                                    {renderAdmin(
                                                        adminList?.data,
                                                        adminList?.workspaceId,
                                                        activeUser?.[0]?.token,
                                                        true
                                                    )}
                                                </Col>
                                            </Row>
                                        </Collapse>
                                    </Card>
                                );
                            })}
                        </>
                    );
                })}
            </>
        );
    };
    const renderAdmin = (teams, workspaceId = 0, token = 0, edit = false) => {
        const options = [
            { value: 'member', label: Echo().teams.fields.role.member },
            { value: 'admin', label: Echo().teams.fields.role.admin },
            { value: 'guest', label: Echo().teams.fields.role.guest },
        ];
        if (teams?.length)
            return (
                <>
                    <div className="font-weight-bold mb-4">
                        Liste des utilisateurs avec un rôle d'Admin&nbsp;:
                    </div>
                    {teams?.map((t) => {
                        const tData = TeamData(t.object);
                        return (
                            <Row className="mt-3 v-center mx-0">
                                <Col xs="auto" className="px-0">
                                    <TeamIcon team={t.object} my={my} />
                                </Col>
                                <Col className="add-option-collapse-first-text text-capitalize display-16 mt-0 ml-0 pl-0">
                                    {tData.value}
                                </Col>
                                <Col className="add-option-collapse-first-text text-capitalize display-16 mt-0 ml-0 pl-0">
                                    {edit ? (
                                        <Select
                                            value={options.filter(
                                                (option) =>
                                                    option.value ===
                                                    t.object.role
                                            )}
                                            onChange={(e) => {
                                                Teams.patch(
                                                    workspaceId,
                                                    t.id,
                                                    token,
                                                    { role: e.value }
                                                ).then((res) => {
                                                    if (res.status === 200) {
                                                        const features = [
                                                            ...thisSubscription
                                                                .object
                                                                .features,
                                                        ];
                                                        const data = [
                                                            ...adminList.data.map(
                                                                (a) => {
                                                                    if (
                                                                        a.id ===
                                                                        t.id
                                                                    )
                                                                        return {
                                                                            id: a.id,
                                                                            object: {
                                                                                ...a.object,
                                                                                role: e.value,
                                                                            },
                                                                        };
                                                                    else {
                                                                        return a;
                                                                    }
                                                                }
                                                            ),
                                                        ];
                                                        setAdminList({
                                                            workspaceId,
                                                            data,
                                                        });
                                                        if (
                                                            data.filter(
                                                                (t) =>
                                                                    t.object
                                                                        .role ===
                                                                    'admin'
                                                            ).length >
                                                            features.length
                                                        ) {
                                                            addAdmin();
                                                        }
                                                    }
                                                });
                                            }}
                                            options={options.filter(
                                                (option) =>
                                                    option.value !==
                                                    t.object.role
                                            )}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </Col>
                            </Row>
                        );
                    })}
                </>
            );
        return (
            <div className="font-weight-bold mb-4">
                Aucun utilisateur actif avec le rôle d'Admin.
            </div>
        );
    };
    const rmAdmin = () => {
        const features = [...thisSubscription.object.features];
        if (
            (adminList.data?.length ? adminList.data : []).filter(
                (t) => t.object.role === 'admin'
            ).length < features.length
        ) {
            features.pop();
            setThisSubscription({
                ...thisSubscription,
                object: { ...thisSubscription.object, features },
            });
        } else {
            setColorAlert('danger');
            setContentAlert(
                "Vous ne pouvez pas avoir moins d'options d'admin supplémentaire que de membres ayant déjà ce rôle !"
            );
            setShowAlert(true);
        }
    };
    const addAdmin = () => {
        const features = [
            ...thisSubscription.object.features,
            thisAdminFeature[paymentFrequency].id,
        ];
        setThisSubscription({
            ...thisSubscription,
            object: { ...thisSubscription.object, features },
        });
    };
    return (
        <Modal
            id="view_add_option"
            title="Ajouter une option"
            className="card invite-user-container-width m-2 mt-5"
            backgroundColor="black"
        >
            <div className="ajouter-option--modal-big-container">
                <div className="fiche-user-alert-container">
                    <FiAlertCircle
                        size="2.2rem"
                        className="fiche-user-alert-icon"
                    />
                    <div>
                        <span className="fiche-user-alert-text">
                            L'ajout d'un admin supplémentaire sur un workspace
                            est une option payante.
                        </span>
                    </div>
                </div>
                <ErrorSelector
                    logo="0"
                    code={501}
                    title="Admin supplémentaire"
                    message="Indisponible pour le moment.  Veuillez nous contacter, nous vous enverrons les instructions pour ajouter des options."
                    retry_value="Contactez-nous"
                    retry_link="/contact"
                    retry_color="primary"
                />
                {/* <Row className="ml-0">
                    <Col lg={8} xl={8} className="px-0 ml-3">
                        {thisSubscription?.object?.features?.length ? (
                            <>
                                <div className="input-label m-3">
                                    Vos options&nbsp;:
                                </div>
                                {renderFeatureAdmin([thisSubscription])}
                            </>
                        ) : (
                            <>
                                <div className="action-label m-3">
                                    A quelles options voulez-vous
                                    souscrire&nbsp;?
                                </div>
                                <Card
                                    key="feature_admin"
                                    className="display-14 p-0 m-3 mb-4 mr-0"
                                    style={{ maxWidth: '780px' }}
                                >
                                    <Row className="m-0">
                                        <Col className="p-3 my-auto">
                                            <p className="display-20 font-weight-800 m-0">
                                                {
                                                    thisAdminFeature?.[
                                                        paymentFrequency
                                                    ]?.object?.name
                                                }
                                            </p>
                                        </Col>
                                        <Col
                                            md={'auto'}
                                            className="p-3 my-auto"
                                        >
                                            <Button
                                                variant="primary"
                                                className="btn-sm"
                                                onClick={() => {
                                                    addAdmin();
                                                }}
                                            >
                                                <strong>
                                                    <FiShoppingCart
                                                        size="1.4rem"
                                                        className="mr-2"
                                                    />
                                                    {'  '}
                                                    Ajouter
                                                </strong>
                                            </Button>
                                        </Col>
                                        <Col
                                            md={'auto'}
                                            className="p-3 pr-4 my-auto"
                                        >
                                            <div
                                                style={{ width: '130px' }}
                                                className="text-right display-20 font-weight-800 m-0"
                                            >
                                                {thisSubscription?.object
                                                    ?.features?.length
                                                    ? thisSubscription?.object
                                                          ?.features?.length *
                                                      thisAdminFeature?.[
                                                          paymentFrequency
                                                      ]?.object?.prices
                                                    : thisAdminFeature?.[
                                                          paymentFrequency
                                                      ]?.object?.prices}
                                                {
                                                    thisAdminFeature?.[
                                                        paymentFrequency
                                                    ]?.object?.currency
                                                }
                                                <br />
                                                <span className="font-weight-bold display-16">
                                                    HT/
                                                    {paymentFrequency ===
                                                    'annually'
                                                        ? 'an'
                                                        : 'mois'}
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </>
                        )}
                    </Col>
                    <Col
                        lg={'auto'}
                        className="sticky-fixed flex items-end column axe-priorisation-btn-container ml-4"
                    >
                        <BlueButton
                            content="Acheter"
                            RW
                            icon={<FiCreditCard size="2.2rem" />}
                            btnClassnameDetails="mt-0"
                            // onClick={handleSubmit}
                        />
                        <GreyButton
                            content="Annuler"
                            RW
                            icon={<FiX size="2.2rem" />}
                            onClick={() => {
                                document.getElementById(
                                    'view_add_option'
                                ).style.display = 'none';
                            }}
                        />
                    </Col>
                </Row> */}
            </div>
        </Modal>
    );
}
