import RkTable from '../commun/RkTable';
import Header from './Header';
import FicheUser from '../usersModal/FicheUser';
import AddTeam from './Add';
import AddOption from './Admin';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import config from './Config';
import Teams from '../../util/Teams';
import Modal from '../modal/Modal';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Echo from '../../util/Echo';
import Unpaid from '../commun/Unpaid';
import IniteAccept from '../commun/IniteAccept';

export default function TeamsLanding() {
    const users = useSelector((state) => state.user.users);
    const { trackPageView, trackEvent } = useMatomo();
    const { id } = useParams();
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = users.filter((user) => user.id.includes(id))[0].workspace[
        activeUser[0].activeWorkspace
    ];
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginationStates, setPaginationStates] = useState({
        ...config.pagination,
    });
    const [optionsStates, setOptionsStates] = useState({ ...config.options });
    const [filterStates, setFilterStates] = useState({ ...config.filter });
    const [sortStates, setSortStates] = useState({ ...config.sort });
    const [selectedRow, setSelectedRow] = useState(null);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalArchive, setShowModalArchive] = useState(false);
    const userRoleDetails = workspace.object.teams.on.filter(
        (team) => team.id === activeUser[0].id
    )[0].role;
    const handleOptionsState = (column) => {
        setOptionsStates(
            column === 'all'
                ? optionsStates.all
                    ? Object.keys(optionsStates).reduce((accumulator, key) => {
                          return { ...accumulator, [key]: false };
                      }, {})
                    : Object.keys(optionsStates).reduce((accumulator, key) => {
                          return { ...accumulator, [key]: true };
                      }, {})
                : {
                      ...optionsStates,
                      [column]: !optionsStates[column],
                      all:
                          Object.values({
                              ...optionsStates,
                              [column]: !optionsStates[column],
                              all: true,
                          }).filter((c) => !c).length === 0,
                  }
        );
    };
    const handleFilterState = (column, value) => {
        switch (config.type[column]) {
            case 'string':
                setFilterStates({
                    ...filterStates,
                    [column]: value,
                });
                break;
            case 'enum':
            case 'role':
            case 'status_teams':
                setFilterStates({
                    ...filterStates,
                    [column]:
                        value === 'all'
                            ? filterStates[column].all
                                ? Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: false,
                                          };
                                      },
                                      {}
                                  )
                                : Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: true,
                                          };
                                      },
                                      {}
                                  )
                            : {
                                  ...filterStates[column],
                                  [value]: !filterStates[column][value],
                                  all:
                                      Object.values({
                                          ...filterStates[column],
                                          [value]: !filterStates[column][value],
                                          all: true,
                                      }).filter((c) => !c).length === 0,
                              },
                });
                break;
            default:
                console.log('undefined column', column, 'type');
        }
    };
    const handleSortState = (column, type) => {
        setSortStates(
            sortStates?.type === type && sortStates?.column === column
                ? { type: '', column: '' }
                : { type, column }
        );
    };
    const handleFilterReset = (column) => {
        switch (config.type[column]) {
            case 'string':
                setFilterStates({
                    ...filterStates,
                    [column]: '',
                });
                break;
            case 'enum':
            case 'role':
            case 'status_teams':
                setFilterStates({
                    ...filterStates,
                    [column]: Object.keys(filterStates[column]).reduce(
                        (accumulator, key) => {
                            return {
                                ...accumulator,
                                [key]: false,
                            };
                        },
                        {}
                    ),
                });
                break;
            default:
                console.log('undefined column', column, 'type');
        }
    };
    const getData = (next = false) => {
        setLoading(true);
        if (next) {
            if (paginationStates.end === undefined) {
                setPaginationStates({
                    ...paginationStates,
                    skip: paginationStates.skip + paginationStates.limit,
                });
            } else {
                return;
            }
        } else {
            setPaginationStates({
                ...config.pagination,
            });
        }
        let url = `workspace/${workspace.id}/teams?${
            sortStates.type !== '' && sortStates.column !== ''
                ? '&' + sortStates.type + '=' + sortStates.column
                : ''
        }`;
        Object.keys(filterStates).forEach((column) => {
            switch (true) {
                case config.type[column] === 'string' &&
                    filterStates[column].length > 1:
                    url += `&${column}=${filterStates[column]}`;
                    break;
                case config.type[column] === 'enum':
                case config.type[column] === 'role':
                case config.type[column] === 'status_teams':
                    Object.keys(filterStates[column]).forEach((box) => {
                        if (box !== 'all' && filterStates[column][box])
                            url += `&${column}=${box}`;
                    });
                    break;
                default:
                    break;
            }
        });
        Axios({
            ...ApiCallConfig,
            method: 'GET',
            url: url,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
        })
            .then((res) => {
                setLoading(false);
                switch (true) {
                    case res.status === 200:
                        if (next) {
                            setData([...data, ...res.data.data]);
                        } else {
                            setData(res.data.data);
                        }
                        if (res.data.count <= res.data.skip) {
                            setPaginationStates({
                                ...config.pagination,
                                end: true,
                            });
                        }
                        break;
                    default:
                        setData([]);
                        break;
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    const deleteTeam = (teamId, commit = false) => {
        if (commit) {
            setLoading(true);
            Teams.delete(workspace.id, teamId, activeUser[0].token)
                .then((res) => {
                    getData();
                    document.getElementById('viewUserFile').style.display =
                        'none';
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else {
            setShowModalDelete(true);
            setSelectedRow({ ...selectedRow, id: teamId });
        }
    };
    useEffect(getData, [
        sortStates,
        filterStates,
        activeUser[0].activeWorkspace,
    ]);
    useEffect(() => {
        if (window.location.hash) {
            try {
                document.getElementById(
                    window.location.hash.split('#')[1]
                ).style.display = 'block';
            } catch (e) {
                console.log(e);
            }
        }
        document.title = Echo().seo.title.teams
            ? Echo().seo.title.prefix +
              workspace.object.name +
              ' - ' +
              Echo().seo.title.teams +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, [activeUser[0].activeWorkspace]);
    const selectRow = (row) => {
        setSelectedRow(row);
        document.getElementById('viewUserFile').style.display = 'block';
    };
    switch (true) {
        case workspace.object.status === 'unpaid':
            return <Unpaid />;
        case workspace.object.teams.on.filter(
            (t) => t.id === activeUser[0].id
        )[0].invit !== undefined:
            return <IniteAccept veiw="teams"/>;
        default:
            return (
                <>
                    {workspace.object.status === 'unpaid' ? (
                        <>
                            <Unpaid />
                        </>
                    ) : (
                        <>
                            <Header
                                data={data}
                                loading={loading}
                                optionsStates={optionsStates}
                                handleOptionsState={(column) =>
                                    handleOptionsState(column)
                                }
                            />
                            <RkTable
                                resource="teams"
                                config={config}
                                data={data}
                                getData={getData}
                                loading={loading}
                                setLoading={(s) => setLoading(s)}
                                selectedRow={(r) => selectRow(r)}
                                optionsStates={optionsStates}
                                filterStates={filterStates}
                                sortStates={sortStates}
                                handleFilterState={(column, value) =>
                                    handleFilterState(column, value)
                                }
                                handleFilterReset={(column) =>
                                    handleFilterReset(column)
                                }
                                handleSortState={(column, type) =>
                                    handleSortState(column, type)
                                }
                                delete={(id) => deleteTeam(id)}
                            />
                            <Modal
                                show={showModalDelete}
                                centered={true}
                                modalFirstText="Etes-vous certain de vouloir supprimer cet utilisateur ?"
                                modalSecondText=""
                                modalThirdText=""
                                modalContainerClassname="axeModalContainerClassname"
                                modalContentClassName="axeModalContentClassName"
                                closeIcon="axeModalcloseIcon cursorPointer"
                                containerTextWithIconClassname="axesContainerTextWithIconClassname"
                                alertIconClassName="axesAlertIconClassName"
                                firstBtnClassName="axeModalFirstBtn"
                                secondBtnClassName="axeModalSecondBtn"
                                setShowModalFunction={() =>
                                    setShowModalDelete(false)
                                }
                                textClassName="axeModalTextClassName"
                                saveBtnFunction={() =>
                                    deleteTeam(selectedRow.id, true)
                                }
                            />
                            <FicheUser
                                user={selectedRow}
                                userRole={userRoleDetails}
                                inputOption=""
                                getData={getData}
                                deleteUser={(id) => deleteTeam(id)}
                            />
                            <AddTeam getData={getData} />
                            <AddOption
                                subscriptionId={workspace.object.subscription}
                            />
                        </>
                    )}
                </>
            );
    }
}
