import React, { useEffect} from 'react';
import WsjfPreview from './WsjfPreview';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Echo from '../../util/Echo';
import Unpaid from '../commun/Unpaid';
import IniteAccept from '../commun/IniteAccept';

export default () => {
    const { id } = useParams();
    const users = useSelector((state) => state.user.users);
    const { trackPageView, trackEvent } = useMatomo();
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = activeUser[0].workspace[activeUser[0].activeWorkspace];
    useEffect(() => {
        document.title = Echo().seo.title.wsjf
            ? Echo().seo.title.prefix +
              workspace.object.name +
              ' - ' +
              Echo().seo.title.wsjf +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, [activeUser[0].activeWorkspace]);
    switch (true) {
        case workspace.object.status === 'unpaid':
            return <Unpaid />;
        case workspace.object.teams.on.filter(
            (t) => t.id === activeUser[0].id
        )[0].invit !== undefined:
            return <IniteAccept veiw="wsjf" />;
        default:
            return (
                <>
                    {workspace.object.status === 'unpaid' ? (
                        <>
                            <Unpaid />
                        </>
                    ) : (
                        <div className="frame-content relative m-2">
                            <div className="bg-gray text-black rounded-top workspace-header-style flex v-center">
                                <span className="flex v-center workspace-title ml-3 py-3">
                                    Méthode de calcul du WSJF
                                </span>
                            </div>
                            <div
                                className="workspace-body-Controller scroll-x scroll-y "
                                id="scrollSnapAlign"
                            >
                                <WsjfPreview
                                    BusinessValues={
                                        activeUser[0].workspace[
                                            activeUser[0].activeWorkspace
                                        ].object.wsjf.business_value
                                    }
                                    TimeCriticality={
                                        activeUser[0].workspace[
                                            activeUser[0].activeWorkspace
                                        ].object.wsjf.time_criticality
                                    }
                                    RiskOpportunity={
                                        activeUser[0].workspace[
                                            activeUser[0].activeWorkspace
                                        ].object.wsjf.risk_opportunity
                                    }
                                    activeSousAxesArray={activeUser[0].workspace[
                                        activeUser[0].activeWorkspace
                                    ].object.wsjf.business_value.axis.filter(
                                        (axe) => axe.active === true
                                    )}
                                />
                            </div>
                        </div>
                    )}
                </>
            );
    }
};
