import React, { useState, useEffect } from 'react';
import RkModal from '../commun/RkModal';
import { Form, Row, Col } from 'react-bootstrap';
import { FiSearch, FiX, FiSave } from 'react-icons/fi';
import GreyButton from '../commun/GreyButton';
import BlueButton from '../commun/BlueButton';
import Echo from '../../util/Echo';

function SessionAddProjects(props) {
    const [searchInput, setSearchInput] = useState('');
    const [projects, setProjects] = useState([]);
    const closeModal = () => {
        document.getElementById('session_add_projects').style.display = 'none';
    };
    useEffect(() => {
        getProjectsList();
    }, [props.projectsPriorized]);

    useEffect(() => {
        renderToPriorizeProjects();
    }, [projects]);
    const renderToPriorizeProjects = () => {
        return (
            <div className="listInvitedUserContainer">
                {props.projectsToPriorize.map((el, i) => {
                    let classNameDetails = 'invitationListeDetailsSecondCol';
                    let inputclassname = 'invitationSecondInputDetails';
                    if (i % 2 === 0) {
                        classNameDetails = 'invitationListeDetailsFirstCol';
                        inputclassname = 'invitationInputDetails';
                    } else if (i === props.projectsToPriorize.length - 1) {
                        classNameDetails =
                            classNameDetails + ' invitationListeDetailsLastCol';
                    }
                    return (
                        <div className={classNameDetails}>
                            <input
                                type="checkbox"
                                className="projects-check"
                                checked={
                                    props?.SelectedProjectList.filter(
                                        (pr) => pr.id === el.id
                                    )?.length
                                }
                                onChange={(e) =>
                                    props.checkUncheckProject(e, el)
                                }
                            />
                            <div className="invitationEmailText">
                                {el.object.name}
                            </div>
                            <div
                                className="invitationBtnDeleteContainer"
                                // onClick={() => deleteParticipantItem(el)}
                            >
                                <span className="invitationEmailText">
                                    {
                                        Echo().project.fields.status[
                                            el.object.status
                                        ]
                                    }
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderPriorizedProjects = () => {
        return (
            <div className="listInvitedUserContainer">
                {projects.map((el, i) => {
                    let classNameDetails = 'invitationListeDetailsSecondCol';
                    let inputclassname = 'invitationSecondInputDetails';
                    if (i % 2 === 0) {
                        classNameDetails = 'invitationListeDetailsFirstCol';
                        inputclassname = 'invitationInputDetails';
                    } else if (i === props.projectsPriorized.length - 1) {
                        classNameDetails =
                            classNameDetails + ' invitationListeDetailsLastCol';
                    }
                    return (
                        <div className={classNameDetails}>
                            <input
                                type="checkbox"
                                className="projects-check"
                                checked={
                                    props?.SelectedProjectList.filter(
                                        (pr) => pr.id === el.id
                                    ).length
                                }
                                onChange={(e) =>
                                    props.checkUncheckProject(e, el)
                                }
                            />
                            <div className="score-text">{el.object.name}</div>
                            <div className="score-text-status">
                                {Echo().project.fields.status[el.object.status]}
                            </div>
                            {el.object.wsjf && (
                                <div className="flex v-center h-center score-box">
                                    {el.object.wsjf}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };
    const getProjectsList = () => {
        setProjects(props.projectsPriorized);
    };
    const changeSerachValue = (e) => {
        setSearchInput(e.target.value);
        if (!e.target.value.length) {
            getProjectsList();
        } else {
            let projectsFilter = projects.filter((el) =>
                el.object.name
                    .toUpperCase()
                    .includes(e.target.value.toUpperCase())
            );
            setProjects(projectsFilter);
        }
    };

    return (
        <RkModal
            id="session_add_projects"
            title="Projets à prioriser"
            className="card m-2 invite-user-container-width mt-40"
            style={{ display: 'block' }}
        >
            <div className="session-invite-modal-big-container">
                <Row className="mr-0 ml-0">
                    <Col lg={7} xl={8} className="pl-0">
                        <div className="mt-4">
                            <span className="descr-label">
                                Quels projets voulez-vous ajouter à la session
                            </span>
                            <span className="highlight-label">
                                {' '}
                                {props.sessionName}
                            </span>
                            <span className="descr-label"> ?</span>
                        </div>
                        <div className="action-label pt-5">
                            Veuillez sélectionner les projets avec le statut "A
                            prioriser" :
                        </div>
                        {renderToPriorizeProjects()}
                        <div className="action-label pb-3">
                            Voulez-vous ajouter un autre projet ?
                        </div>
                        <div className="session-invite-search-container">
                            <Form.Control
                                name="search"
                                type="text"
                                placeholder="Rechercher..."
                                value={searchInput}
                                className="rounded border-0 session-search-input "
                                onChange={changeSerachValue}
                            />
                            <FiSearch
                                size="1.5rem"
                                className="session-search-icon"
                            />
                        </div>
                        {renderPriorizedProjects()}
                    </Col>
                    <Col lg={'auto'} className="session-details-col-display">
                        <div className="add-session-btn-container">
                            <BlueButton
                                content={<>Enregistrer</>}
                                icon={<FiSave size="2.2rem" />}
                                displayRowClassname="axeDisplayRowClassnameBtn"
                                onClick={() => {
                                    props.updateProjectsList();
                                    closeModal();
                                }}
                            />
                            <GreyButton
                                content={<>Annuler</>}
                                icon={<FiX size="2.2rem" />}
                                onClick={() => {
                                    props.clearSelectedProjects();
                                    closeModal();
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </RkModal>
    );
}

export default SessionAddProjects;
