import React from 'react';
import { Col } from 'react-bootstrap';
import Echo from '../../util/Echo';
import Faq from '../commun/Faq';
import { useSelector } from 'react-redux';

export default () => {
    const state = useSelector((state) => state.user);
    return (
        <div className="mt-5 p-3">
            <h2 className="font-weight-900 text-center mt-4 mb-5">
                Questions fréquentes
            </h2>
            <Col md={10} xl={8} className="w-100 col-xxl-7 mx-auto">
                {Echo(state.lang).faq.map((f) => (
                    <Faq data={f} />
                ))}
            </Col>
        </div>
    );
};
