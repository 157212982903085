import Axios from 'axios';
import ApiCallConfig from './ApiCallConfig';
export default class tags {
    /**
     * @function get
     * @definition Read tags config
     * @returns {Promise}
     */
    static get(workspaceId, token, params = '') {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'GET',
                url: `/workspace/${workspaceId}/tags?${params}`,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * @function put
     * @definition Update tags config
     * @returns {Promise}
     */
    static put(workspaceId, token, payload) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'PUT',
                url: '/workspace/' + workspaceId + '/tags',
                headers: {
                    authorization: 'Bearer ' + token,
                },
                data: payload,
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
