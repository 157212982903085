import Config from '../../util/Config';
import TeamData from '../../util/TeamData';
import { Button } from 'react-bootstrap';
import { FiUserX } from 'react-icons/fi';

export default (props) => {
    const data = TeamData(props.team);
    return (
        <>
            {data.initials !== 'x' ? (
                <Button
                    title={
                        props.team?.first_name?.length &&
                        props.team?.last_name?.length
                            ? props.team.first_name.replace(
                                  /\b([a-z])/g,
                                  (c) => {
                                      return c.toUpperCase();
                                  }
                              ) +
                              ' ' +
                              props.team.last_name.replace(
                                  /\b([a-z])/g,
                                  (c) => {
                                      return c.toUpperCase();
                                  }
                              ) +
                              '\n' +
                              (props.team?.job?.length > 1
                                  ? props.team.job
                                  : '') +
                              (props?.team?.company_name?.length > 1
                                  ? ' @ ' + props.team.company_name
                                  : '')
                            : data.value
                    }
                    className={`btn-sm ${
                        props.my?.email === props.team?.email
                            ? `btn-primary`
                            : props.team?.status === `off`
                            ? `btn-off`
                            : props.team?.status === `waiting`
                            ? `btn-grey`
                            : `btn-dark`
                    } btn-sm-circle no-hover no-focus w-100 p-0 mr-3 my-2`}
                >
                    {props.team?.image?.length ? (
                        <img
                            src={Config.CDN.ENDPOINT + props.team.image}
                            className="profil-icon"
                        />
                    ) : (
                        <strong className="text-center font-weight-800 text-uppercase text-lg">
                            {data.initials}
                        </strong>
                    )}
                </Button>
            ) : (
                <Button
                    title={data.value}
                    className={`not-allowed btn-sm btn-off btn-sm-circle no-hover no-focus w-100 p-0 mr-3 my-2`}
                >
                    <strong className="text-center font-weight-800 text-lg">
                        {<FiUserX size="1.2rem" />}
                    </strong>
                </Button>
            )}
        </>
    );
};
