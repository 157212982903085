import { Container, Navbar } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Logo from '../commun/Logo';
import { Link as ScrollLink } from 'react-scroll';
import { Element } from 'react-scroll';
import * as Scroll from 'react-scroll';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Menu from './Menu';
import Home from './Home';
import Features from './Features';
import Benefits from './Benefits';
import Companies from './Companies';
import Prices from './Prices';
import Faq from './Faq';
import Contact from '../commun/Contact';
import Footer from '../commun/Footer';
import { FiX } from 'react-icons/fi';
import '../styles/landing.css';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import User from '../../util/User';
import Echo from '../../util/Echo';
import Alert from '../commun/Alert';

export default (props) => {
    const history = useHistory();
    var scroller = Scroll.scroller;
    const { trackPageView, trackEvent } = useMatomo();
    const state = useSelector((state) => state.user);
    const users = state.users;
    const activeIndex = useSelector((state) => state.user.activeUserIndex);
    const activeUser = users[activeIndex];
    const location = useLocation();
    const [showMenu, setShowMenu] = useState('d-none');
    const [showLand, setShowLand] = useState('d-block');
    const [showAlertDemo, setShowAlertDemo] = useState(false);
    const dispatch = useDispatch();
    const handleCloseNotif = () => {
        setShowAlertDemo(false);
    };
    const [testCondition, setTestCondition] = useState('signup');
    const handleShow = (s) => {
        setShowMenu(s ? 'd-block' : 'd-none');
        setShowLand(s ? 'd-none' : 'd-block');
    };
    const makeMenuResponsive = () => {
        if (window.innerWidth >= 992 && showMenu === 'd-block') {
            handleShow(false);
        }
    };
    useEffect(() => {
        if (props.to) {
            scroller.scrollTo(props.to, {
                duration: 0,
                delay: 1,
                offset: 0,
            });
        }
    }, []);
    useEffect(() => {
        document.title = Echo(state.lang).seo.title.landing
            ? Echo(state.lang).seo.title.prefix +
              Echo(state.lang).seo.title.landing +
              Echo(state.lang).seo.title.suffix
            : Echo(state.lang).seo.title.default;
        trackPageView();
        window.addEventListener('resize', makeMenuResponsive, true);
        return () => {
            document.title = Echo(state.lang).seo.title.default;
            window.removeEventListener('resize', makeMenuResponsive, true);
        };
    }, [state.lang]);
    useEffect(() => {
        if (props.testCondition) {
            setTestCondition(props.testCondition);
        } else if (activeUser && activeUser.id) {
            User.headTest(activeUser.token)
                .then((res) => {
                    switch (res.status) {
                        case 403:
                            dispatch(
                                {
                                    type: 'LOGOUT',
                                    value: activeUser.id,
                                },
                                history.push('/login')
                            );
                            break;
                        case 402:
                            setTestCondition('buy');
                            break;
                        case 200:
                            setTestCondition('test');
                            break;
                        default:
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                    }
                })
                .catch((err) => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                });
        } else {
            setTestCondition('signup');
        }
    }, []);
    return (
        <>
            <Container fluid>
                setShowAlertDemo();
                <div className={showMenu}>
                    <Navbar
                        className="fixed-top rankio-header"
                        bg="white"
                        expand="xl"
                    >
                        <Link to="/" onClick={() => handleShow(false)}>
                            <Navbar.Brand>
                                <Logo />
                            </Navbar.Brand>
                        </Link>
                        <div
                            className="ml-auto"
                            onClick={() => handleShow(false)}
                        >
                            <FiX
                                size="1.5em"
                                className="rk-clickable-icon-dark"
                            />
                        </div>
                    </Navbar>
                    <Navbar className="landing" id="basic-navbar-nav">
                        <Navbar.Collapse>
                            <Menu
                                sm={true}
                                onSelectMenu={(el) => {
                                    handleShow(false);
                                    setTimeout(() => {
                                        scroller.scrollTo(el, {
                                            duration: 0,
                                            delay: 1,
                                            smooth: true,
                                            offset: 0,
                                        });
                                    }, 50);
                                }}
                            />
                        </Navbar.Collapse>
                    </Navbar>
                </div>
                <div id="sm-menu" className={showLand}>
                    <Navbar
                        className="fixed-top rankio-header"
                        bg="white"
                        expand="lg"
                    >
                        <ScrollLink
                            containerId="landing"
                            className="cursor-pointer"
                            activeClass="active"
                            to="Home"
                            spy={true}
                            smooth={true}
                            duration={500}
                        >
                            <Navbar.Brand>
                                <Logo />
                            </Navbar.Brand>
                        </ScrollLink>
                        <button
                            type="button"
                            aria-label="Menu"
                            className="navbar-toggler"
                            onClick={() => handleShow(true)}
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <Navbar.Collapse>
                            <Menu />
                        </Navbar.Collapse>
                    </Navbar>
                    <div className="landing" id="landing">
                        <Element name="home" className="element pt-5">
                            <Home
                                // testCondition={testCondition}
                                onSelectMenu={(el) => {
                                    handleShow(false);
                                    setTimeout(() => {
                                        scroller.scrollTo(el, {
                                            duration: 0,
                                            delay: 1,
                                            smooth: true,
                                            offset: 0,
                                        });
                                    }, 50);
                                }}
                            />
                        </Element>
                        <Element name="features" className="element pt-5">
                            <Features
                            // testCondition={testCondition}
                            />
                        </Element>
                        <Element name="benefits" className="element pt-5">
                            <Benefits />
                            {/* <Companies /> */}
                        </Element>
                        <Element name="prices" className="element pt-5">
                            <Prices
                            // testCondition={testCondition}
                            />
                        </Element>
                        <Element name="faq" className="element pt-5">
                            <Faq />
                        </Element>
                        <Element name="contact" className="element py-5">
                            <Contact />
                        </Element>
                        <div className="pt-5">
                            <Footer />
                        </div>
                    </div>
                </div>
            </Container>
            <Alert
                color="primary"
                timeout={1670925600 - (Date.now() / 1000).toFixed()}
                content={
                    <p>
                        Rendez-vous mardi 13 décembre à 12h pour une démo de
                        notre application&nbsp;! L'inscription se fait
                        ici&nbsp;:
                        <a
                            href="https://tally.so/r/mRWBDK"
                            target="_blank"
                            aria-label="Lien vers le formulaire d'inscription"
                            className="text-white font-weight-800"
                        >
                            https://tally.so/r/mRWBDK
                        </a>
                    </p>
                }
                showAlert={showAlertDemo}
                onMouseLeave={false}
                setAlertShow={setShowAlertDemo}
                handleCloseAlert={() => handleCloseNotif()}
                contentClassname="axecontentClassname"
            />
        </>
    );
};
