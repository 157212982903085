import React, { useState } from 'react';
import {
    Navbar,
    Form,
    InputGroup,
    FormControl,
    Button,
    Dropdown,
} from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import { IoHelp } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import Validator from '../../util/Validator';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from './Logo';
import Lang from './Lang';
import AccountDropdown from './AccountDropdown';
import { MenuItem } from 'react-pro-sidebar';
export default () => {
    const history = useHistory();
    const { id } = useParams();
    const { s } = useParams();
    const state = useSelector((state) => state.user);
    const users = state.users;
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = users.filter((user) => user.id.includes(id))[0].workspace[
        activeUser[0].activeWorkspace
    ];

    const remainingDays = Math.round(
        (workspace.object.test_date +
            86400000 * (14 + workspace.object.extra_trial) -
            Date.now()) /
            86400000
    );
    const [payload, setPayload] = useState({
        keywords: s,
    });
    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        setErrors({});
        setPayload({ ...payload, [event.target.name]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const e = Validator(payload);
        if (Object.keys(e).length !== 0 || e.constructor !== Object) {
            setErrors(e);
        } else {
            history.push('/' + id + '/s=' + payload.keywords);
        }
    };
    const SearchInput = () => {
        return (
            <Form noValidate onSubmit={handleSubmit}>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text className="bg-white border-2 border-right-0 border-black radius-14 py-1 px-3">
                            <FiSearch size={20} />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="keywords"
                        className="border-2 border-left-0 border-black radius-14 pl-0"
                        placeholder="Recherche..."
                        aria-label="Recherche..."
                        value={payload.keywords}
                        onChange={handleChange}
                        aria-describedby="basic-addon1"
                        style={{ maxWidth: '30ch' }}
                    />
                </InputGroup>
                <Form.Text className="text-danger">{errors.keywords}</Form.Text>
            </Form>
        );
    };
    return (
        <>
            <Navbar className="sticky-top rankio-header" bg="white" expand="xl">
                <Link to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </Link>
                {/* <div className="d-none d-lg-block w-25 mx-4">
                    <SearchInput />
                </div>
                <Dropdown
                    className="d-lg-none d-block ml-auto"
                    onSelect={document.dispatchEvent(new MouseEvent('click'))}
                >
                    <Dropdown.Toggle className="text-center btn-sm btn-sm-circle btn-secondary p-0 mx-2">
                        <FiSearch size={20} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        className="radius-14 dropdown-menu-right p-2 m-0"
                        style={{ width: '32ch' }}
                    >
                        <SearchInput />
                    </Dropdown.Menu>
                </Dropdown> */}
                {workspace.object.status !== 'paid' &&
                workspace.object.teams.on.filter((t) => t.role == 'owner')[0]
                    .id === activeUser[0].id ? (
                    workspace.object.test_date ? (
                        <>
                            <Button
                                variant="dark"
                                className="btn-sm d-none d-lg-block ml-auto"
                                onClick={() => {
                                    if (
                                        workspace.object.teams.on.filter(
                                            (t) => t.role == 'owner'
                                        )[0].id === activeUser[0].id
                                    ) {
                                        history.push(
                                            '/' +
                                                id +
                                                '/subscription/' +
                                                workspace.object.subscription
                                        );
                                    }
                                }}
                            >
                                <strong>
                                    Fin du test dans :&nbsp;
                                    {remainingDays >= 0 ? remainingDays : 0}j
                                </strong>
                            </Button>
                            <Button className="btn-sm btn-sm-circle btn-dark d-lg-none d-block ml-3">
                                <strong>
                                    {remainingDays >= 0 ? remainingDays : 0}j
                                </strong>
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                variant="dark"
                                className="btn-sm d-none d-lg-block ml-auto"
                                onClick={() => {
                                    history.push(
                                        '/' +
                                            id +
                                            '/subscription/' +
                                            workspace.object.subscription
                                    );
                                }}
                            >
                                <strong>Régulariser / Payer</strong>
                            </Button>
                            <Button className="btn-sm btn-sm-circle btn-primary d-lg-none d-block ml-3">
                                <strong>Régulariser / Payer</strong>
                            </Button>
                        </>
                    )
                ) : (
                    <div className="ml-auto"></div>
                )}
                <a href="/contact" target="_blank" rel="noreferrer noopener">
                    <IoHelp
                        title="Nous contacter"
                        className="rk-clickable-icon-dark ml-4"
                        size="2.2rem"
                    />
                </a>
                <div className="pl-4">
                    <Lang lang={state.lang} />
                </div>
                <div className="ml-4">
                    <AccountDropdown />
                </div>
            </Navbar>
        </>
    );
};
