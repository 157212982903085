import Cookies from 'js-cookie';
import Axios from 'axios';
import ApiCallConfig from './ApiCallConfig';
import Init from './Init';
export default class User {
    /**
     * @function post
     * @definition Create new user
     * @params {object} data
     * @returns {Promise}
     */
    static post(data) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'POST',
                url: '/user',
                data,
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(
                        'Un problème est survenu, veuillez réessayer plus tard.'
                    );
                });
        });
    }
    /**
     * @function get
     * @definition Get user object
     * @params {id} user sessionid
     * @params {uuid} user id
     * @params {string} token
     * @returns {Promise}
     */
    static init(id, uuid, token) {
        console.log('init user');
        return new Promise((resolve, reject) => {
            this.get(uuid, token)
                .then(async (result) => {
                    if (result.status === 200) {
                        await Cookies.set('RK_' + id + '_id', uuid);
                        await Cookies.set(
                            'RK_' + id + '_status',
                            result.data[0].object.status
                        );
                        await Cookies.set('RK_' + id + '_token', token);
                        await Cookies.set(
                            'RK_' + id + '_data',
                            result.data[0].object
                        );
                        await resolve();
                    } else if (result.status === 429) {
                        reject(
                            '[' +
                                result.status +
                                '] Trop de tentatives. Veuillez patienter quelques instants puis réessayez.'
                        );
                    } else if (result.status === 403) {
                        reject(
                            '[' + result.status + '] Votre session a expiré.'
                        );
                    } else {
                        reject(
                            '[' + result.status + '] Un problème est survenu.'
                        );
                    }
                })
                .catch((error) => {
                    reject(
                        'Un problème est survenu, veuillez réessayer plus tard.'
                    );
                });
        });
    }
    /**
     * @function get
     * @definition Get user object
     * @params {uuid} user uuid
     * @params {string} token
     * @returns {Promise}
     */
    static get(params, token) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'GET',
                url: '/user',
                params,
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    /**
     * @function get
     * @definition Get user next subscription status
     * @params {string} token
     * @returns {Promise}
     */
    static headTest(token) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'HEAD',
                url: '/user/test',
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    /**
     * @function patch
     * @definition Update user
     * @returns {Promise}
     */
    static patch(token, data) {
        const timeout = {};
        if (data.hasOwnProperty('image')) timeout.timeout = 10000;
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                ...timeout,
                method: 'PATCH',
                url: '/user',
                headers: {
                    authorization: 'Bearer ' + token,
                },
                data,
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    /**
     * @function set
     * @definition Update user object
     * @params {Object} => { first_name, last_name, email, password }
     * @returns {Promise}
     */
    static set(payload, id) {
        const timeout = {};
        if (payload.hasOwnProperty('image')) timeout.timeout = 10000;
        // update data cookies
        console.log('set user');
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                ...timeout,
                method: 'PATCH',
                url: '/user',
                headers: {
                    authorization:
                        'Bearer ' + Cookies.get('RK_' + id + '_token'),
                },
                data: payload,
            })
                .then((result) => {
                    if (result.status === 200) {
                        Cookies.set(
                            'RK_' + id + '_status',
                            result.data.data.object.status
                        );
                        Cookies.set(
                            'RK_' + id + '_data',
                            result.data.data.object
                        );
                        resolve();
                    } else if (result.status === 409) {
                        reject(
                            '[' +
                                result.status +
                                '] Cette adresse mail et/ou ce numéro de téléphone existe déjà.'
                        );
                    } else if (result.status === 429) {
                        reject(
                            '[' +
                                result.status +
                                '] Trop de tentatives. Veuillez patienter quelques instants puis réessayez.'
                        );
                    } else if (result.status === 403) {
                        reject(
                            '[' + result.status + '] Votre session a expiré.'
                        );
                    } else {
                        reject(
                            '[' + result.status + '] Un problème est survenu.'
                        );
                    }
                })
                .catch((error) => {
                    reject(
                        'Un problème est survenu, veuillez réessayer plus tard.'
                    );
                });
        });
    }
    /**
     * @function set
     * @definition Update user object
     * @params {Object} => { first_name, last_name, email, password }
     * @returns {Promise}
     */
    static setEmail(token) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'PATCH',
                url: '/user',
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((res) => {
                    switch (res.status) {
                        case 200:
                            resolve(res);
                            break;
                        case 204:
                            reject('Cette adresse mail est déjà validée.');
                            break;
                        case 403:
                            reject(
                                "Votre lien magique a expiré ou n'est pas valide."
                            );
                            break;
                        case 429:
                            reject(
                                'Trop de tentatives. Veuillez patienter quelques instants puis réessayez.'
                            );
                            break;
                        default:
                            reject(
                                'Un problème est survenu, veuillez réessayer plus tard.'
                            );
                    }
                })
                .catch((error) => {
                    reject(
                        'Un problème est survenu, veuillez réessayer plus tard.'
                    );
                });
        });
    }
    /**
     * @function delete
     * @definition Remove user account
     * @returns {Promise}
     */
    static recovery(data) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'POST',
                url: '/recovery',
                data,
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(
                        'Un problème est survenu, veuillez réessayer plus tard.'
                    );
                });
        });
    }
    /**
     * @function delete
     * @definition Remove user account
     * @returns {Promise}
     */
    static delete(token) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'DELETE',
                url: '/user',
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
