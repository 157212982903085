import { Container, Navbar, Button, Row, Col } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import Init from '../../util/Init';
import Logo from '../commun/Logo';
import Error from '../error/ErrorComponent';
import HealthCheck from '../../util/HealthCheck';
import { FiX, FiLoader } from 'react-icons/fi';
export default () => {
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
        if (!Cookies.get('RK_reset')) {
            // clear localStorage
            localStorage.clear();
            // reset session
            Init.reset();
            Cookies.set('RK_reset', true);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } else {
            Cookies.remove('RK_reset');
        }
    }, []);
    return (
        <>
            <Container
                fluid
                className={Cookies.get('RK_reset') ? 'd-none' : 'd-block'}
            >
                <div className="w-75 m-auto py-5">
                    <div className="text-center">
                        <h3 className="font-weight-800 my-4">
                            Réinitialisation en cours
                            <br />
                        </h3>
                        <p className="font-weight-600">
                            Veuillez patienter quelques instants
                        </p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <FiLoader size={36} className="rotating" />
                    </div>
                </div>
            </Container>
            <Container
                fluid
                className={Cookies.get('RK_reset') ? 'd-block' : 'd-none'}
            >
                <Navbar
                    className="fixed-top rankio-header"
                    bg="white"
                    expand="xl"
                >
                    <Link to="/">
                        <Navbar.Brand>
                            <Logo />
                        </Navbar.Brand>
                    </Link>
                    <Link to="/" className="ml-auto">
                        <FiX size="1.5em" className="rk-clickable-icon-dark" />{' '}
                    </Link>
                </Navbar>
                <div className="landing">
                    <Row className="p-4">
                        <Col lg={1} className="d-none d-md-block"></Col>
                        <Col lg={8}>
                            <h3 className="font-weight-800 my-4">
                                Votre session a expiré.
                            </h3>
                        </Col>
                    </Row>
                    <Row className="p-4">
                        <Col lg={1} className="d-none d-md-block"></Col>
                        <Col
                            sm={6}
                            md={5}
                            lg={4}
                            xl={3}
                            xxl={2}
                            className="p-2"
                        >
                            <Link to="/login">
                                <Button
                                    className="btn-lg btn-primary w-100 p-0"
                                    style={{
                                        height: '5rem',
                                        maxWidth: '340px',
                                    }}
                                >
                                    <span className="font-weight-800 display-24">
                                        Se connecter
                                    </span>
                                </Button>
                            </Link>
                        </Col>
                        <Col
                            sm={6}
                            md={5}
                            lg={4}
                            xl={3}
                            xxl={2}
                            className="p-2"
                        >
                            <Link to="/signup">
                                <Button
                                    className="btn-lg btn-outline-primary w-100 p-0"
                                    style={{
                                        height: '5rem',
                                        maxWidth: '340px',
                                    }}
                                >
                                    <span className="font-weight-800 display-24">
                                        Créer un compte
                                    </span>
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};
