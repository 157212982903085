import { useState } from 'react';
import { Dropdown, Col, Row } from 'react-bootstrap';
import {
    FiChevronDown,
    FiArrowDown,
    FiArrowUp,
    FiFilter,
} from 'react-icons/fi';
import Echo from '../../util/Echo';
import { useSelector } from 'react-redux';
export default (props) => {
    const state = useSelector((state) => state.user);
    const [filter, setFilter] = useState(false);
    const [filterSup, setFilterSup] = useState(false);
    const [filterInf, setFilterInf] = useState(false);
    const sortActive = [
        'string',
        'enum',
        'status_project',
        'status_teams',
        'status_list',
        'wsjf',
        'role',
        'date',
    ].includes(props?.type);
    return (
        <Dropdown>
            <Dropdown.Toggle
                variant="secondary"
                className="font-weight-700 display-14 color-gray px-3"
            >
                <div className="flex">
                    <div className="py-auto mr-3">{props.title}</div>
                    <div xs={3} className="pl-2 my-auto">
                        <Row>
                            <FiChevronDown
                                size="1.25em"
                                className={
                                    (props.sortStates.column === props.column &&
                                        props.sortStates.type !== '') ||
                                    props.filter
                                        ? 'd-none ml-1'
                                        : 'ml-1'
                                }
                            />
                        </Row>
                        <Row>
                            <FiArrowUp
                                className="ml-1 pt-0"
                                color="#0055ff"
                                size="1em"
                                className={
                                    props.sortStates.column === props.column &&
                                    props.sortStates.type === 'asc'
                                        ? ''
                                        : 'd-none'
                                }
                            />
                        </Row>
                        <Row>
                            <FiArrowDown
                                className="ml-1 pt-0"
                                color="#0055ff"
                                size="1em"
                                className={
                                    props.sortStates.column === props.column &&
                                    props.sortStates.type === 'desc'
                                        ? ''
                                        : 'd-none'
                                }
                            />
                        </Row>
                        <Row>
                            <FiFilter
                                className="ml-1 pt-0"
                                color="#0055ff"
                                size="1em"
                                className={props.filter ? '' : 'd-none'}
                            />
                        </Row>
                    </div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="radius-14 p-2 minem-w-15 table-dropdown-menu">
                <FiArrowDown
                    className={
                        sortActive ? 'header-icon' : 'header-icon-disabled'
                    }
                    size="1.25em"
                    color={
                        props.sortStates.column === props.column &&
                        props.sortStates.type === 'desc'
                            ? '#0055ff'
                            : null
                    }
                    onClick={() =>
                        sortActive &&
                        props.handleSortState(props.column, 'desc')
                    }
                />
                <FiArrowUp
                    className={
                        sortActive ? 'header-icon' : 'header-icon-disabled'
                    }
                    size="1.25em"
                    color={
                        props.sortStates.column === props.column &&
                        props.sortStates.type === 'asc'
                            ? '#0055ff'
                            : null
                    }
                    onClick={() =>
                        sortActive && props.handleSortState(props.column, 'asc')
                    }
                />
                <FiFilter
                    className="header-icon"
                    size="1.25em"
                    color={props.filter ? '#0055ff' : null}
                    onClick={props.handleFilterReset}
                />
                <div className="font-weight-700 mt-3 p-3">
                    <FiFilter size="1.25em" />{' '}
                    <span className="ml-3 display-14">
                        {Echo().commun.filters}
                    </span>
                </div>
                <div
                    className="scroll-y-on-hover scrollbar-light"
                    style={{ maxHeight: 'calc(100vh - 460px)' }}
                >
                    {props.children}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};
