import React, { useState, useEffect } from 'react';
import { Button, Collapse, Form, Row, Col, Table } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Modal from '../commun/RkModal';
import TeamsList from '../commun/TeamsList';
import TagsList from '../commun/TagsList';
import AssignmentManager from './AssignmentManager';
import CopyToClipboardButton from '../commun/CopyToClipboardButton';
import BlueButton from '../commun/BlueButton';
import GreyButton from '../commun/GreyButton';
import DeleteButton from '../commun/DeleteButton';
import $ from 'jquery';
import TagsManager from './TagsManager';
import Assignments from './DetailsAssignment';
import Tags from './DetailsTags';
import Scoring from './DetailsScoring';
import {
    FiLink,
    FiPlus,
    FiPlusCircle,
    FiInfo,
    FiArchive,
    FiTrash2,
} from 'react-icons/fi';
import DatePicker from '../commun/DatePicker';
import Validator from '../../util/Validator';
import Config from '../../util/Config';
import Echo from '../../util/Echo';
import Alert from '../commun/Alert';
import Project from '../../util/Project';

const options = [
    {
        value: 'to_prioritize',
        label: Echo().project.fields.status['to_prioritize'],
    },
    { value: 'planned', label: Echo().project.fields.status['planned'] },
    { value: 'prioritized', label: Echo().project.fields.status['prioritized'] },
    { value: 'buffer', label: Echo().project.fields.status['buffer'] },
    { value: 'analyzing', label: Echo().project.fields.status['analyzing'] },
    { value: 'approved', label: Echo().project.fields.status['approved'] },
    { value: 'backlog', label: Echo().project.fields.status['backlog'] },
    { value: 'in_progress', label: Echo().project.fields.status['in_progress'] },
    { value: 'done', label: Echo().project.fields.status['done'] },
    { value: 'stand_by', label: Echo().project.fields.status['stand_by'] },
    { value: 'aborted', label: Echo().project.fields.status['aborted'] },
    { value: 'archived', label: Echo().project.fields.status['archived'] },
];
var submitTimeout = null;

export default (props) => {
    const { id } = useParams();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = activeUser[0].workspace[activeUser[0].activeWorkspace];
    const deletable = ['owner', 'admin'].includes(
        workspace.object.teams.on.filter(
            (team) => team.id === activeUser[0].id
        )[0].role
    );
    const readOnly = !deletable || props.data?.object?.status === 'archived';
    const [my] = useState(activeUser[0]);
    const [showAssignmentMenu, setShowAssignmentMenu] = useState(false);
    const [showTagsMenu, setShowTagsMenu] = useState(false);
    const [showChart, setShowChart] = useState(false);
    const [errors, setErrors] = useState({});
    const [payload, setPayload] = useState(props.data?.object);
    const handleChange = (event) => {
        setPayload({ ...payload, [event.target.name]: event.target.value });
        const e = Validator({
            ...payload,
            [event.target.name]: event.target.value,
        });
        if (e) setErrors(e);
    };
    const handleSubmit = () => {
        console.log('submit >>>');
        if (props.data) {
            Project.patch(
                props.data.object.workspace,
                props.data.id,
                my.token,
                payload
            )
                .then((result) => {
                    switch (result.status) {
                        case 200:
                            props.getData();
                            setCodeError(false);
                            setShowAlertSuccess(true);
                            setTimeout(() => {
                                setShowAlertSuccess(false);
                            }, 5000);
                            break;
                        case 204:
                            break;
                        case 409:
                            setCodeError(true);
                            break;
                        default:
                            setCodeError(false);
                            setShowAlertFail(true);
                            setTimeout(() => {
                                setShowAlertFail(false);
                            }, 5000);
                    }
                })
                .catch((error) => {
                    setCodeError(false);
                    setShowAlertFail(true);
                    setTimeout(() => {
                        setShowAlertFail(false);
                    }, 5000);
                });
        } else {
            Project.post(workspace.id, my.token, payload)
                .then((result) => {
                    switch (result.status) {
                        case 201:
                            props.getData();
                            props.setData(result.data.data);
                            setCodeError(false);
                            setShowAlertSuccess(true);
                            setTimeout(() => {
                                setShowAlertSuccess(false);
                            }, 5000);
                            break;
                        case 204:
                            break;
                        case 409:
                            setCodeError(true);
                            break;
                        default:
                            setCodeError(false);
                            setShowAlertFail(true);
                            setTimeout(() => {
                                setShowAlertFail(false);
                            }, 5000);
                    }
                })
                .catch((error) => {
                    setCodeError(false);
                    setShowAlertFail(true);
                    setTimeout(() => {
                        setShowAlertFail(false);
                    }, 5000);
                });
        }
    };
    const handleKeyUp = () => {
        const e = Validator(payload);
        if (Object.keys(e).length !== 0 || e.constructor !== Object) {
            setErrors(e);
            return;
        } else {
            clearTimeout(submitTimeout);
            submitTimeout = null;
            submitTimeout = setTimeout(function () {
                handleSubmit();
            }, 1000);
        }
    };
    const handleBlur = () => {
        const e = Validator(payload);
        if (Object.keys(e).length !== 0 || e.constructor !== Object) {
            setErrors(e);
            return;
        } else {
            clearTimeout(submitTimeout);
            submitTimeout = null;
            handleSubmit();
        }
    };
    const [codeError, setCodeError] = useState(false);
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [showAlertFail, setShowAlertFail] = useState(false);
    $(document).on('click', function (e) {
        if (
            $(e.target).closest('#assignment_manager').length === 0 &&
            showAssignmentMenu
        ) {
            setShowAssignmentMenu(false);
        }
    });
    $(document).on('click', function (e) {
        if ($(e.target).closest('#tags_manager').length === 0 && showTagsMenu) {
            setShowTagsMenu(false);
        }
    });
    useEffect(() => {
        document
            .getElementById('view_details_project_body')
            .firstChild.scrollTo(0, 0);
    }, [props.data?.id]);
    useEffect(() => {
        setPayload({ name: '', code: '', ...props.data?.object });
        setErrors({});
    }, [props.data]);

    return (
        <>
            <Modal
                id="view_details_project"
                title={props.data ? 'Fiche projet' : 'Nouveau projet'}
                className="card invite-user-container-width m-2"
            >
                <div className="h-100-207 p-0 m-0">
                    <Row>
                        <Col className="mt-4" sm={5} xl={6}>
                            <Form className="ml-3">
                                <Form.Group controlId="projectName">
                                    <Form.Label className="input-label">
                                        Nom du projet
                                    </Form.Label>
                                    <Form.Control
                                        name="name"
                                        className="input font-weight-800"
                                        style={{ maxWidth: '48ch' }}
                                        type="text"
                                        placeholder="Libellé du projet..."
                                        value={payload?.name || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onKeyUp={handleKeyUp}
                                        maxLength="40"
                                        disabled={readOnly}
                                    />
                                    <Form.Text
                                        className="text-danger font-weight-bold mt-2 ml-2"
                                        style={{ height: '28px' }}
                                    >
                                        {errors.name}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group controlId="projectId">
                                    <Form.Label className="input-label mt-1">
                                        Code du projet
                                    </Form.Label>
                                    <Form.Control
                                        name="code"
                                        className="input"
                                        style={{ maxWidth: '10ch' }}
                                        type="text"
                                        placeholder="Code..."
                                        value={payload?.code || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onKeyUp={handleKeyUp}
                                        maxLength="7"
                                        disabled={readOnly}
                                    />
                                    <Form.Text
                                        className="text-danger font-weight-bold mt-2 ml-2"
                                        style={{ height: '28px' }}
                                    >
                                        {errors.code}
                                        {codeError && (
                                            <div>
                                                Code déjà utilisé dans ce
                                                workspace
                                            </div>
                                        )}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group controlId="projectDescription">
                                    <Form.Label className="input-label">
                                        Description du projet
                                    </Form.Label>
                                    <Form.Control
                                        name="description"
                                        className="input"
                                        style={{ maxWidth: '55ch' }}
                                        as="textarea"
                                        rows={5}
                                        fixed="true"
                                        type="text"
                                        placeholder="Description du projet..."
                                        value={payload?.description || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onKeyUp={handleKeyUp}
                                        maxLength="2000"
                                        disabled={readOnly}
                                    />
                                    <Form.Text
                                        className="text-danger font-weight-bold mt-2 ml-2"
                                        style={{ height: '28px' }}
                                    >
                                        {errors.description}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group controlId="projectDocumentation">
                                    <Form.Label className="input-label">
                                        Lien vers une documentation
                                    </Form.Label>
                                    <Form.Control
                                        name="external_url"
                                        className="input"
                                        style={{ maxWidth: '40ch' }}
                                        type="text"
                                        placeholder="URL..."
                                        value={payload?.external_url || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onKeyUp={handleKeyUp}
                                        disabled={readOnly}
                                    />
                                    <Form.Text
                                        className="text-danger font-weight-bold mt-2 ml-2"
                                        style={{ height: '28px' }}
                                    >
                                        {errors.external_url}
                                    </Form.Text>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col sm={7} xl={6}>
                            <Row className="no-gutters">
                                <Col className="mt-4">
                                    {props.data && (
                                        <div
                                            className={
                                                'rk-std-value rk-squared-value ' +
                                                (props.data?.object?.rating
                                                    ?.result?.wsjf !== undefined
                                                    ? 'bg-primary'
                                                    : 'bg-bg-gray') +
                                                ' text-white text-center mb-4'
                                            }
                                            style={{
                                                height: '80px',
                                                width: '80px',
                                            }}
                                        >
                                            <span className="font-weight-800 display-24">
                                                {props.data?.object?.rating
                                                    ?.result?.wsjf !== undefined
                                                    ? Math.round(
                                                          (props.data.object
                                                              .rating.result
                                                              .wsjf +
                                                              Number.EPSILON) *
                                                              100
                                                      ) / 100
                                                    : 'Non défini'}
                                            </span>
                                        </div>
                                    )}
                                    {props.data && (
                                        <>
                                            <div className="input-label mt-1 mb-2">
                                                Statut du projet
                                            </div>
                                            <div className="projectStatusDropDownContainer font-weight-bold ml-0">
                                                <Select
                                                    value={{
                                                        value: payload?.status,
                                                        label: Echo().project
                                                            .fields.status[
                                                            payload?.status
                                                        ],
                                                    }}
                                                    onChange={(event) => {
                                                        setPayload({
                                                            ...payload,
                                                            status: event.value,
                                                        });
                                                    }}
                                                    options={options}
                                                    isDisabled={readOnly}
                                                />
                                                {/* options={options.filter(
                                                        (option) =>
                                                            option.value !== payload?.status.value
                                                    )} */}
                                            </div>
                                        </>
                                    )}
                                </Col>
                                <Col
                                    lg={'auto'}
                                    className="flex items-end column axe-priorisation-btn-container pr-2 pt-3"
                                >
                                    {props.data && false && (
                                        <CopyToClipboardButton
                                            content={
                                                <>
                                                    Copier le lien
                                                    <br />
                                                    du projet
                                                </>
                                            }
                                            icon={<FiLink size="2.2rem" />}
                                            url={
                                                'goto/workspace/' +
                                                workspace.id +
                                                '/project/' +
                                                props.data?.id
                                            }
                                        />
                                    )}
                                    {props.data &&
                                    props.archive &&
                                    !readOnly ? (
                                        <GreyButton
                                            content={'Archiver'}
                                            icon={<FiArchive size="2.2rem" />}
                                            onClick={() =>
                                                props.archive(props.data?.id)
                                            }
                                        />
                                    ) : (
                                        ''
                                    )}
                                    {props.data && props.delete && deletable ? (
                                        <DeleteButton
                                            content={'Supprimer'}
                                            icon={<FiTrash2 size="2.2rem" />}
                                            onClick={() =>
                                                props.delete(props.data?.id)
                                            }
                                        />
                                    ) : (
                                        ''
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {props.data && (
                        <>
                            <Tags
                                data={props.data}
                                getData={props.getData}
                                loading={props.loading}
                                update={(id, payload) =>
                                    props.update(id, payload)
                                }
                                setLoading={(s) => props.setLoading(s)}
                                filterStates={props.filterStates}
                            />
                            <Assignments
                                data={props.data}
                                getData={props.getData}
                                loading={props.loading}
                                update={(id, payload) =>
                                    props.update(id, payload)
                                }
                                setLoading={(s) => props.setLoading(s)}
                                filterStates={props.filterStates}
                            />
                            <Scoring
                                data={props.data}
                                getData={props.getData}
                                loading={props.loading}
                                setLoading={(s) => props.setLoading(s)}
                                filterStates={props.filterStates}
                            />
                        </>
                    )}
                </div>
            </Modal>
            <Alert
                color="primary"
                content="Les modifications ont bien été enregistrées"
                showAlert={showAlertSuccess}
                setAlertShow={setShowAlertSuccess}
                handleCloseAlert={() => setShowAlertSuccess(false)}
                contentClassname="axecontentClassname"
            />
            <Alert
                color="danger"
                content="Une erreur est survenue lors de l'enregistrement de vos modifications. Veuillez réessayer."
                showAlert={showAlertFail}
                setAlertShow={setShowAlertFail}
                handleCloseAlert={() => setShowAlertFail(false)}
                contentClassname="axecontentClassname"
            />
        </>
    );
};
