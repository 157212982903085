import { Button } from 'react-bootstrap';

/*
 * @value string, integer, float, double, empty, undefined
 * @variant
 *
 */
const format = (d, v = false) => {
    switch (true) {
        case d === '':
        case d === undefined:
        case d === -1:
            return <></>;
        case d === null:
            return v ? <>?</> : <></>;
        case Number(d) === d:
            return <>{Math.round((d + Number.EPSILON) * 100) / 100}</>;
        case typeof d === 'string':
            return <>{d.substring(0, 1)}</>;
        default:
            return <></>;
    }
};
export default (props) => {
    /*
        default is centred
    */
    return (
        <div
            className={
                'rk-std-value' +
                (props.variant
                    ? ' rk-squared-value bg-' + props.variant + ' text-white '
                    : ' ') +
                (props?.centred === false ? ' ' : ' m-auto')
            }
        >
            {format(props.value, props.vote)}
        </div>
    );
};
