import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    Collapse,
    Row,
    Alert as Info,
} from 'react-bootstrap';
import {
    FiLoader,
    FiMinus,
    FiAlertCircle,
    FiCreditCard,
    FiShoppingCart,
    FiPlus,
    FiInfo,
    FiChevronDown,
    FiChevronUp,
    FiEdit,
} from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Switch from 'react-switch';
import Select from 'react-select';
import BlueButton from '../commun/BlueButton';
import DarkButton from '../commun/DarkButton';
import Echo from '../../util/Echo';
import ErrorSelector from '../error/ErrorSelector';
import Alert from '../commun/Alert';
import TeamIcon from '../commun/TeamIcon';
import TeamData from '../../util/TeamData';
import Plan from '../../util/Plan';
import Teams from '../../util/Teams';
import Feature from '../../util/Feature';
import Workspace from '../../util/Workspace';
import Subscription from '../../util/Subscription';
import PaymentMethods from '../../util/PaymentMethods';
import PaymentMethodsForm from '../account/PaymentMethods.js';
import RkModal from '../commun/RkModal';

function SubscriptionBody(props) {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.user.users);
    const { id, subscriptionId } = useParams();
    const history = useHistory();
    const activeUser = users.filter((user) => user.id.includes(id));
    const [my] = useState(activeUser[0]);
    const [open, setOpen] = useState({});
    const [thisAdminFeature, setThisAdminFeature] = useState({});
    const [adminList, setAdminList] = useState({});
    const [thisPlan, setThisPlan] = useState({});
    const [thisSubscription, setThisSubscription] = useState([]);
    const [paymentFrequency, setPaymentFrequency] = useState('annually');
    const [paymentMethodsList, setPaymentMethodsList] = useState([]);
    const [addPaymentMethods, setAddPaymentMethods] = useState(false);

    const [defaultPaymentMethods, setDefaultPaymentMethods] = useState({});
    const [accessDenied, setAccessDenied] = useState(false);
    const [billingsVeiw, setBillingsVeiw] = useState(false);
    const [checkoutDone, setCheckoutDone] = useState(false);
    const [checkoutError, setCheckoutError] = useState(false);
    const [loadingSubscription, setLoadingSubscription] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [colorAlert, setColorAlert] = useState('danger');
    const [contentAlert, setContentAlert] = useState(
        'Une erreur est survenue, veuillez nous en excuser'
    );
    const Workspaces = activeUser[0].workspace;
    useEffect(() => {
        if (
            id &&
            subscriptionId &&
            activeUser.length &&
            !props.onboarding &&
            !props.update &&
            Workspaces[activeUser[0].activeWorkspace].object.subscription !==
                subscriptionId
        ) {
            if (
                Workspaces[activeUser[0].activeWorkspace].object.status ===
                'unpaid'
            )
                history.push('/' + id + '/subscription/' + subscriptionId);
            else {
                history.push('/' + id + '/dashboard');
            }
        } else {
            console.log('ok !');
        }
    }, [activeUser[0].activeWorkspace]);
    useEffect(() => {
        Feature.get({ "object->>'code'": 'ADMIN' })
            .then((res) => {
                if (res.status === 200) {
                    const annually = res.data.data.filter(
                        (f) => f.object.payment_frequency === 'annually'
                    )[0];
                    const monthly = res.data.data.filter(
                        (f) => f.object.payment_frequency === 'monthly'
                    )[0];
                    setThisAdminFeature({
                        annually,
                        monthly,
                    });
                } else {
                    setColorAlert('danger');
                    setContentAlert(
                        'Une erreur est survenue, veuillez réessayer ultérieurement'
                    );
                    setShowAlert(true);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            })
            .catch((err) => {
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            });
        Plan.get({ "object->>'active'": true, "object->>'code'": 'MVP' })
            .then((planRes) => {
                if (planRes.status === 200) {
                    const annually = planRes.data.data.filter(
                        (p) => p.object.payment_frequency === 'annually'
                    )[0];
                    const monthly = planRes.data.data.filter(
                        (p) => p.object.payment_frequency === 'monthly'
                    )[0];
                    setThisPlan({
                        annually: {
                            id: annually.id,
                            object: {
                                ...annually.object,
                                cost_per_user_per_month: Math.round(
                                    annually.object.prices / 10 / 12
                                ),
                                eco_percent:
                                    '(soit ' +
                                    (
                                        100 -
                                        Math.round(
                                            (annually.object.prices * 100) /
                                                (monthly.object.prices * 12) +
                                                Number.EPSILON
                                        )
                                    ).toString() +
                                    '% d’économies)',
                            },
                        },
                        monthly: {
                            id: monthly.id,
                            object: {
                                ...monthly.object,
                                cost_per_user_per_month: Math.round(
                                    monthly.object.prices / 10
                                ),
                                eco_percent: ' ',
                            },
                        },
                    });
                } else {
                    setAccessDenied(true);
                }
            })
            .catch((err) => {
                setAccessDenied(true);
            });
        getPaymentMethods();
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        Subscription.get(
            { id: subscriptionId || props.subscriptionId },
            activeUser[0].token
        )
            .then(async (subscriptionRes) => {
                if (subscriptionRes.status === 200) {
                    // only one workspace pre subscription
                    const workspaceId = await Workspaces.filter(
                        (w) =>
                            w.object.subscription ===
                            subscriptionRes.data.data[0].id
                    )?.[0]?.id;
                    if (workspaceId) {
                        // dispatch({
                        //     type: 'SET_ACTIVE_WORKSPACE',
                        //     value: workspaceId,
                        // });
                        Teams.get(workspaceId, activeUser[0].token).then(
                            (res) => {
                                setAdminList({
                                    workspaceId,
                                    data: res?.data?.data.filter(
                                        (t) =>
                                            t.object.role === 'admin' &&
                                            t.object.status !== 'off'
                                    ),
                                });
                            }
                        );
                    }
                    setThisSubscription(subscriptionRes.data.data[0]);
                    setPaymentFrequency(
                        thisPlan?.annually?.id ===
                            subscriptionRes.data.data[0].object.plan
                            ? 'annually'
                            : 'monthly',
                        setLoadingSubscription(false)
                    );
                    setLoadingSubscription(false);
                } else {
                    setAccessDenied(true);
                }
            })
            .catch((err) => {
                setAccessDenied(true);
            });
    }, [thisPlan]);
    useEffect(() => {
        if (thisSubscription.id) {
            const newSubscription = { ...thisSubscription };
            // update plan id
            newSubscription.object.plan = thisPlan?.[paymentFrequency]?.id;
            // update features admin ids
            newSubscription.object.features =
                thisSubscription.object.features.map(
                    () => thisAdminFeature?.[paymentFrequency]?.id
                );
            setThisSubscription(
                newSubscription,
                console.log(
                    'thisSubscription',
                    thisSubscription.object.plan,
                    thisSubscription.object.features
                )
            );
        }
    }, [paymentFrequency]);
    const getPaymentMethods = () => {
        PaymentMethods.get({}, activeUser[0].token)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.default)
                        setDefaultPaymentMethods(
                            res.data.data.filter(
                                (py) => py.id === res.data.default
                            )[0]
                        );
                } else if (res.status === 404) {
                    setDefaultPaymentMethods(null);
                } else {
                    setColorAlert('danger');
                    setContentAlert(
                        'Une erreur est survenue, veuillez réessayer ultérieurement'
                    );
                    setShowAlert(true);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            })
            .catch((err) => {
                setColorAlert('danger');
                setContentAlert(
                    'Une erreur est survenue, veuillez réessayer ultérieurement'
                );
                setShowAlert(true);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            });
    };
    const renderFeatureAdmin = (list, edit = true) => {
        return (
            <>
                {list.map((sb) => {
                    let ltft = [...new Set(sb?.object?.features)];
                    return (
                        <>
                            {ltft.map((ft) => {
                                return (
                                    <Card
                                        key={sb.id + '-admin'}
                                        className="display-14 p-0 m-3 mb-4 mr-0"
                                        style={{ maxWidth: '780px' }}
                                    >
                                        <Row className="m-0">
                                            {edit ? (
                                                <Col
                                                    md={'auto'}
                                                    className="cursor-pointer px-3 my-auto"
                                                    onClick={() => {
                                                        setOpen({
                                                            ...open,
                                                            [sb.id + '-admin']:
                                                                open?.[
                                                                    sb.id +
                                                                        '-admin'
                                                                ] === true
                                                                    ? false
                                                                    : true,
                                                        });
                                                    }}
                                                >
                                                    {open?.[
                                                        sb.id + '-admin'
                                                    ] === true ? (
                                                        <FiChevronUp
                                                            color="#999999"
                                                            size="2.2rem"
                                                        />
                                                    ) : (
                                                        <FiChevronDown
                                                            color="#999999"
                                                            size="2.2rem"
                                                        />
                                                    )}
                                                </Col>
                                            ) : (
                                                <></>
                                            )}
                                            <Col className="p-3 my-auto">
                                                {edit ? (
                                                    <p className="display-20 font-weight-800 m-0">
                                                        {
                                                            thisAdminFeature?.[
                                                                paymentFrequency
                                                            ]?.object?.name
                                                        }
                                                    </p>
                                                ) : (
                                                    <>
                                                        <span className="font-weight-800 display-24">
                                                            Options
                                                        </span>
                                                        <br />
                                                        <p className="font-weight-bold display-16 m-0">
                                                            {
                                                                thisAdminFeature?.[
                                                                    paymentFrequency
                                                                ]?.object?.name
                                                            }
                                                        </p>
                                                    </>
                                                )}
                                            </Col>
                                            {edit ? (
                                                <Col
                                                    md={'auto'}
                                                    className="p-3 my-auto"
                                                >
                                                    <div className="add-option-add-total-nbr-admin-container">
                                                        <FiMinus
                                                            className="fiche-user-icon-minus"
                                                            onClick={() => {
                                                                rmAdmin();
                                                            }}
                                                        />
                                                        <div className="add-option-number-admin">
                                                            {
                                                                thisSubscription
                                                                    ?.object
                                                                    ?.features
                                                                    ?.length
                                                            }
                                                        </div>
                                                        <FiPlus
                                                            className="fiche-user-icon-plus"
                                                            onClick={() => {
                                                                addAdmin();
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            ) : (
                                                <Col
                                                    md={'auto'}
                                                    className="p-3 my-auto"
                                                >
                                                    <div className="add-option-add-total-nbr-admin-container">
                                                        <div className="add-option-number-admin">
                                                            {
                                                                thisSubscription
                                                                    ?.object
                                                                    ?.features
                                                                    ?.length
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                            <Col
                                                md={'auto'}
                                                className="p-3 pr-4 my-auto"
                                            >
                                                <div
                                                    style={{ width: '130px' }}
                                                    className="text-right display-24 font-weight-800 m-0"
                                                >
                                                    {(thisSubscription?.object
                                                        ?.features?.length
                                                        ? thisSubscription
                                                              ?.object?.features
                                                              ?.length *
                                                          thisAdminFeature?.[
                                                              paymentFrequency
                                                          ]?.object?.prices
                                                        : thisAdminFeature?.[
                                                              paymentFrequency
                                                          ]?.object?.prices
                                                    ).toFixed(2)}
                                                    {
                                                        thisAdminFeature?.[
                                                            paymentFrequency
                                                        ]?.object?.currency
                                                    }
                                                    <br />
                                                    <span className="font-weight-bold display-18">
                                                        HT/
                                                        {paymentFrequency ===
                                                        'annually'
                                                            ? 'an'
                                                            : 'mois'}
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>

                                        {edit ? (
                                            <Collapse
                                                in={open?.[sb.id + '-admin']}
                                            >
                                                <Row className="m-0">
                                                    <Col
                                                        md={'auto'}
                                                        className="px-3 my-auto"
                                                    >
                                                        <FiInfo
                                                            color="#ffffff"
                                                            size="2.2rem"
                                                        />
                                                    </Col>
                                                    <Col className="p-3">
                                                        {renderAdmin(
                                                            adminList?.data,
                                                            adminList?.workspaceId,
                                                            activeUser?.[0]
                                                                ?.token,
                                                            true
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Collapse>
                                        ) : (
                                            <></>
                                        )}
                                    </Card>
                                );
                            })}
                        </>
                    );
                })}
            </>
        );
    };
    const renderAdmin = (teams, workspaceId = 0, token = 0, edit = false) => {
        const options = [
            { value: 'member', label: Echo().teams.fields.role.member },
            { value: 'admin', label: Echo().teams.fields.role.admin },
            { value: 'guest', label: Echo().teams.fields.role.guest },
        ];
        if (teams?.length)
            return (
                <>
                    <div className="font-weight-bold mb-4">
                        Liste des utilisateurs avec un rôle d'Admin&nbsp;:
                    </div>
                    {teams?.map((t) => {
                        const tData = TeamData(t.object);
                        return (
                            <Row className="mt-3 v-center mx-0">
                                <Col xs="auto" className="px-0">
                                    <TeamIcon team={t.object} my={my} />
                                </Col>
                                <Col className="add-option-collapse-first-text text-capitalize display-16 mt-0 ml-0 pl-0">
                                    {tData.value}
                                </Col>
                                <Col className="add-option-collapse-first-text text-capitalize display-16 mt-0 ml-0 pl-0">
                                    {edit ? (
                                        <Select
                                            value={options.filter(
                                                (option) =>
                                                    option.value ===
                                                    t.object.role
                                            )}
                                            onChange={(e) => {
                                                Teams.patch(
                                                    workspaceId,
                                                    t.id,
                                                    token,
                                                    { role: e.value }
                                                ).then((res) => {
                                                    if (res.status === 200) {
                                                        const features = [
                                                            ...thisSubscription
                                                                .object
                                                                .features,
                                                        ];
                                                        const data = [
                                                            ...adminList.data.map(
                                                                (a) => {
                                                                    if (
                                                                        a.id ===
                                                                        t.id
                                                                    )
                                                                        return {
                                                                            id: a.id,
                                                                            object: {
                                                                                ...a.object,
                                                                                role: e.value,
                                                                            },
                                                                        };
                                                                    else {
                                                                        return a;
                                                                    }
                                                                }
                                                            ),
                                                        ];
                                                        setAdminList({
                                                            workspaceId,
                                                            data,
                                                        });
                                                        if (
                                                            data.filter(
                                                                (t) =>
                                                                    t.object
                                                                        .role ===
                                                                    'admin'
                                                            ).length >
                                                            features.length
                                                        ) {
                                                            addAdmin();
                                                        }
                                                    }
                                                });
                                            }}
                                            options={options.filter(
                                                (option) =>
                                                    option.value !==
                                                    t.object.role
                                            )}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </Col>
                            </Row>
                        );
                    })}
                </>
            );
        return (
            <div className="font-weight-bold mb-4">
                Aucun utilisateur actif avec le rôle d'Admin.
            </div>
        );
    };
    const rmAdmin = () => {
        const features = [...thisSubscription.object.features];
        if (
            (adminList.data?.length ? adminList.data : []).filter(
                (t) => t.object.role === 'admin'
            ).length < features.length
        ) {
            features.pop();
            setThisSubscription({
                ...thisSubscription,
                object: { ...thisSubscription.object, features },
            });
        } else {
            setColorAlert('danger');
            setContentAlert(
                "Vous ne pouvez pas avoir moins d'options d'admin supplémentaire que de membres ayant déjà ce rôle !"
            );
            setShowAlert(true);
        }
    };
    const addAdmin = () => {
        const features = [
            ...thisSubscription.object.features,
            thisAdminFeature[paymentFrequency].id,
        ];
        setThisSubscription({
            ...thisSubscription,
            object: { ...thisSubscription.object, features },
        });
    };
    const renderPaymentMethods = () => {
        return (
            <Card
                className="display-14 p-0 m-3 mb-4 mr-0"
                style={{ maxWidth: '780px' }}
            >
                <Row className="m-0">
                    <Col md={12} className="px-3 pt-3 my-auto">
                        <span className="font-weight-800 display-24">
                            Moyen de paiement
                        </span>
                    </Col>
                    <Col md={'auto'} className="px-3 my-auto">
                        <FiCreditCard
                            color="#999999"
                            size="2.2rem"
                            className="text-black"
                        />
                    </Col>
                    <Col md={'auto'} className="p-3">
                        <p className="text-secondary font-weight-600 m-0">
                            Numéro de la carte
                        </p>
                        <p className="font-weight-bold m-0">
                            ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;
                            {defaultPaymentMethods.card.last4}
                        </p>
                    </Col>
                    <Col md={'auto'} className="p-3 pb-1">
                        <p className="text-secondary font-weight-600 m-0">
                            Expire à fin
                        </p>
                        <p className="font-weight-bold m-0">
                            {defaultPaymentMethods.card.exp_month}/
                            {defaultPaymentMethods.card.exp_year}
                        </p>
                    </Col>
                    <Col md={'auto'} className="d-xl-block d-none p-3 pb-1">
                        <p className="text-secondary font-weight-600 m-0">
                            Type
                        </p>
                        <p className="font-weight-bold text-capitalize m-0">
                            {defaultPaymentMethods.card.brand}
                        </p>
                    </Col>
                </Row>
            </Card>
        );
    };
    const checkout = () => {
        if (defaultPaymentMethods?.id) {
            if (billingsVeiw) {
                setLoadingSubscription(true);
                Subscription.patch(thisSubscription.id, activeUser[0].token, {
                    plan: thisSubscription.object.plan,
                    features: thisSubscription.object.features,
                })
                    .then((resPatch) => {
                        if (resPatch.status === 200) {
                            Subscription.checkout(
                                thisSubscription.id,
                                activeUser[0].token
                            )
                                .then((res) => {
                                    setLoadingSubscription(false);
                                    setCheckoutDone(true);
                                    if (res.status !== 200) {
                                        setCheckoutError(true);
                                    }
                                    if (
                                        res.status === 200 &&
                                        props.onboarding
                                    ) {
                                        history.push(
                                            '/' + id + '/onboarding/done'
                                        );
                                    }
                                    setTimeout(() => {
                                        Workspace.get({}, activeUser[0].token)
                                            .then((res) => {
                                                if (res.status === 200) {
                                                    dispatch({
                                                        type: 'UPDATE_WORKSPACE_LIST',
                                                        value: {
                                                            workspace:
                                                                res.data.data,
                                                            id: activeUser[0]
                                                                .id,
                                                        },
                                                    });
                                                }
                                            })
                                            .catch((err) => {
                                                setCheckoutError(true);
                                            });
                                    }, 100);
                                })
                                .catch((err) => {
                                    setLoadingSubscription(false);
                                    setCheckoutDone(true);
                                    setCheckoutError(true);
                                });
                        } else {
                            setCheckoutError(true);
                        }
                    })
                    .catch((err) => {
                        setLoadingSubscription(false);
                        setCheckoutDone(true);
                        setCheckoutError(true);
                    });
            } else {
                setBillingsVeiw(true);
            }
        } else {
            setAddPaymentMethods(true);
        }
    };
    return (
        <>
            <div
                className={
                    (accessDenied ? 'd-block' : 'd-none') +
                    ' workspace-body-Controller'
                }
            >
                <ErrorSelector
                    code={403}
                    title="Accès refusé"
                    message="Vous n'avez pas les droits nécessaires pour effectuer cette opération."
                />
            </div>
            <div className={!accessDenied ? 'd-block' : 'd-none'}>
                <div
                    className={
                        (loadingSubscription ? 'd-block' : 'd-none') +
                        ' text-center py-5'
                    }
                >
                    <FiLoader size={36} className="rotating" />
                </div>
                <div
                    className={
                        loadingSubscription || checkoutDone
                            ? 'd-none'
                            : 'd-block'
                    }
                >
                    {billingsVeiw ? (
                        <>
                            <div
                                className={
                                    props.onboarding
                                        ? ''
                                        : 'workspace-body-Controller'
                                }
                            >
                                <Row className="mr-0">
                                    <Col className="pr-0">
                                        <div
                                            className="pr-3"
                                            style={{ maxWidth: '780px' }}
                                        >
                                            <Card
                                                key={thisSubscription?.id}
                                                className="display-14 p-0 ml-3 my-4 mr-0"
                                                style={{ maxWidth: '780px' }}
                                            >
                                                <Row className="m-0">
                                                    <Col className="p-3">
                                                        <span className="font-weight-800 display-24">
                                                            Plan
                                                        </span>
                                                        <br />
                                                        <p className="font-weight-bold display-16 m-0">
                                                            {
                                                                thisPlan?.[
                                                                    paymentFrequency
                                                                ]?.object?.name
                                                            }
                                                        </p>
                                                    </Col>
                                                    <Col
                                                        md={'auto'}
                                                        className="p-3 pr-4 my-auto"
                                                    >
                                                        <div
                                                            style={{
                                                                width: '130px',
                                                            }}
                                                            className="text-right display-24 font-weight-800 m-0"
                                                        >
                                                            {
                                                                thisPlan?.[
                                                                    paymentFrequency
                                                                ]?.object
                                                                    ?.prices
                                                            }
                                                            {
                                                                thisPlan?.[
                                                                    paymentFrequency
                                                                ]?.object
                                                                    ?.currency
                                                            }
                                                            <br />
                                                            <span className="font-weight-bold display-18">
                                                                HT/
                                                                {paymentFrequency ===
                                                                'annually'
                                                                    ? 'an'
                                                                    : 'mois'}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                            {thisSubscription?.object?.features
                                                ?.length ? (
                                                <>
                                                    {renderFeatureAdmin(
                                                        [thisSubscription],
                                                        false
                                                    )}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            <Card
                                                key={thisSubscription?.id}
                                                className="display-14 p-0 ml-3 my-4 mr-0"
                                                style={{ maxWidth: '780px' }}
                                            >
                                                <Row className="m-0">
                                                    <Col className="p-3">
                                                        <span className="font-weight-800 display-24">
                                                            Total
                                                        </span>
                                                    </Col>
                                                    <Col
                                                        md={'auto'}
                                                        className="p-3 pr-4 my-auto"
                                                    >
                                                        <div
                                                            style={{
                                                                width: '130px',
                                                            }}
                                                            className="text-right display-24 font-weight-800 m-0"
                                                        >
                                                            {(
                                                                thisPlan?.[
                                                                    paymentFrequency
                                                                ]?.object
                                                                    ?.prices +
                                                                (thisSubscription
                                                                    ?.object
                                                                    ?.features
                                                                    ?.length
                                                                    ? thisSubscription
                                                                          ?.object
                                                                          ?.features
                                                                          ?.length *
                                                                      thisAdminFeature?.[
                                                                          paymentFrequency
                                                                      ]?.object
                                                                          ?.prices
                                                                    : 0)
                                                            ).toFixed(2)}
                                                            {
                                                                thisPlan?.[
                                                                    paymentFrequency
                                                                ]?.object
                                                                    ?.currency
                                                            }
                                                            <br />
                                                            <span className="font-weight-bold display-18">
                                                                HT/
                                                                {paymentFrequency ===
                                                                'annually'
                                                                    ? 'an'
                                                                    : 'mois'}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="m-0">
                                                    <Col className="p-3">
                                                        <span className="font-weight-800 display-18">
                                                            TVA 20%
                                                        </span>
                                                    </Col>
                                                    <Col
                                                        md={'auto'}
                                                        className="p-3 pr-4"
                                                    >
                                                        <span className="font-weight-800 display-18">
                                                            {(
                                                                ((thisPlan?.[
                                                                    paymentFrequency
                                                                ]?.object
                                                                    ?.prices +
                                                                    (thisSubscription
                                                                        ?.object
                                                                        ?.features
                                                                        ?.length
                                                                        ? thisSubscription
                                                                              ?.object
                                                                              ?.features
                                                                              ?.length *
                                                                          thisAdminFeature?.[
                                                                              paymentFrequency
                                                                          ]
                                                                              ?.object
                                                                              ?.prices
                                                                        : 0)) *
                                                                    20) /
                                                                100
                                                            ).toFixed(2)}
                                                            {
                                                                thisPlan?.[
                                                                    paymentFrequency
                                                                ]?.object
                                                                    ?.currency
                                                            }
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row className="m-0">
                                                    <Col className="p-3">
                                                        <span className="font-weight-800 display-24">
                                                            Montant dû
                                                        </span>
                                                    </Col>
                                                    <Col
                                                        md={'auto'}
                                                        className="p-3 pr-4 my-auto"
                                                    >
                                                        <div
                                                            style={{
                                                                width: '130px',
                                                            }}
                                                            className="text-right display-24 font-weight-800 m-0"
                                                        >
                                                            <span className="font-weight-800 display-24">
                                                                {(
                                                                    ((thisPlan?.[
                                                                        paymentFrequency
                                                                    ]?.object
                                                                        ?.prices +
                                                                        (thisSubscription
                                                                            ?.object
                                                                            ?.features
                                                                            ?.length
                                                                            ? thisSubscription
                                                                                  ?.object
                                                                                  ?.features
                                                                                  ?.length *
                                                                              thisAdminFeature?.[
                                                                                  paymentFrequency
                                                                              ]
                                                                                  ?.object
                                                                                  ?.prices
                                                                            : 0)) *
                                                                        20) /
                                                                        100 +
                                                                    (thisPlan?.[
                                                                        paymentFrequency
                                                                    ]?.object
                                                                        ?.prices +
                                                                        (thisSubscription
                                                                            ?.object
                                                                            ?.features
                                                                            ?.length
                                                                            ? thisSubscription
                                                                                  ?.object
                                                                                  ?.features
                                                                                  ?.length *
                                                                              thisAdminFeature?.[
                                                                                  paymentFrequency
                                                                              ]
                                                                                  ?.object
                                                                                  ?.prices
                                                                            : 0))
                                                                ).toFixed(2)}
                                                                {
                                                                    thisPlan?.[
                                                                        paymentFrequency
                                                                    ]?.object
                                                                        ?.currency
                                                                }
                                                            </span>
                                                            <br />
                                                            <span className="font-weight-bold display-18">
                                                                TTC/
                                                                {paymentFrequency ===
                                                                'annually'
                                                                    ? 'an'
                                                                    : 'mois'}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                            {renderPaymentMethods()}
                                        </div>
                                    </Col>
                                    {props.onboarding ? (
                                        <></>
                                    ) : (
                                        <>
                                            <Col
                                                lg={'auto'}
                                                className={
                                                    props.onboarding
                                                        ? 12
                                                        : 'auto' +
                                                          'flex items-end column pr-2 mt-3'
                                                }
                                            >
                                                <BlueButton
                                                    content={'Payer'}
                                                    icon={
                                                        <FiCreditCard size="2.2rem" />
                                                    }
                                                    onClick={checkout}
                                                />
                                                <br />
                                                <DarkButton
                                                    content={'Modifier'}
                                                    icon={
                                                        <FiEdit size="2.2rem" />
                                                    }
                                                    onClick={() => {
                                                        setBillingsVeiw(false);
                                                    }}
                                                />
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            </div>
                            {props.onboarding && billingsVeiw ? (
                                <Row className="p-0">
                                    <Col sm={10} md={8} lg={6} xxl={4}>
                                        <Button
                                            className="btn-lg btn-outline-primary h3 font-weight-800 display-24 w-100 mt-5 px-3"
                                            style={{
                                                height: '5rem',
                                                maxWidth: '300px',
                                            }}
                                            onClick={() => {
                                                setBillingsVeiw(false);
                                            }}
                                        >
                                            Modifier
                                        </Button>
                                    </Col>
                                    <Col sm={10} md={8} lg={6} xxl={4}>
                                        <Button
                                            className="btn-lg btn-primary h3 font-weight-800 display-24 w-100 mt-5"
                                            style={{
                                                height: '5rem',
                                                maxWidth: '300px',
                                            }}
                                            onClick={checkout}
                                        >
                                            Payer
                                        </Button>
                                    </Col>
                                </Row>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <div
                            className={
                                props.onboarding
                                    ? ''
                                    : 'workspace-body-Controller'
                            }
                        >
                            {addPaymentMethods ? (
                                <>
                                    <div className="fiche-user-alert-container">
                                        <FiAlertCircle
                                            size="2.2rem"
                                            className="fiche-user-alert-icon"
                                        />
                                        <div>
                                            <span className="fiche-user-alert-text">
                                                Veuillez ajouter un moyen de
                                                paiement.
                                            </span>
                                        </div>
                                    </div>
                                    <div className="p-3">
                                        <PaymentMethodsForm
                                            action="post"
                                            onboarding={props.onboarding}
                                            done={(ok) => {
                                                if (ok) {
                                                    setColorAlert('primary');
                                                    setContentAlert(
                                                        'Le moyen de paiement a bien été enregistré.'
                                                    );
                                                    setShowAlert(true);
                                                    getPaymentMethods();
                                                }
                                                setAddPaymentMethods(false);
                                            }}
                                            default="00000000-0000-0000-0000-000000000000"
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Row className="mr-0">
                                        <Col className="pr-0">
                                            <Card
                                                key={thisSubscription?.id}
                                                className="display-14 p-0 ml-3 my-4 mr-0"
                                                style={{ maxWidth: '780px' }}
                                            >
                                                <Row className="m-0">
                                                    <Col
                                                        md={12}
                                                        className="d-xl-none d-block p-3"
                                                    >
                                                        <p className="text-center display-24 font-weight-800 m-0">
                                                            {
                                                                thisPlan?.[
                                                                    paymentFrequency
                                                                ]?.object?.name
                                                            }
                                                        </p>
                                                    </Col>
                                                    <Col className="d-none d-xl-block p-3">
                                                        <p className="display-24 font-weight-800 m-0">
                                                            {
                                                                thisPlan?.[
                                                                    paymentFrequency
                                                                ]?.object?.name
                                                            }
                                                        </p>
                                                    </Col>
                                                    <Col className="d-xl-none d-block p-3"></Col>
                                                    <Col
                                                        md={'auto'}
                                                        className="p-3"
                                                    >
                                                        <div
                                                            style={{
                                                                width: '190px',
                                                            }}
                                                        >
                                                            <Switch
                                                                id="price-switch"
                                                                onColor={
                                                                    '#0055ff'
                                                                }
                                                                offColor={
                                                                    '#000000'
                                                                }
                                                                checked={
                                                                    paymentFrequency ===
                                                                    'annually'
                                                                }
                                                                checkedIcon={
                                                                    <p className="font-weight-700 display-12 text-white pl-2 pt-10">
                                                                        Annuel
                                                                    </p>
                                                                }
                                                                uncheckedIcon={
                                                                    <p className="font-weight-700 display-12 text-white pt-10">
                                                                        Mensuel
                                                                    </p>
                                                                }
                                                                height={40}
                                                                width={100}
                                                                handleDiameter={
                                                                    35
                                                                }
                                                                onChange={() => {
                                                                    paymentFrequency ===
                                                                    'annually'
                                                                        ? setPaymentFrequency(
                                                                              'monthly'
                                                                          )
                                                                        : setPaymentFrequency(
                                                                              'annually'
                                                                          );
                                                                }}
                                                            />
                                                            <div className="font-weight-bold mt-2">
                                                                {
                                                                    thisPlan?.[
                                                                        paymentFrequency
                                                                    ]?.object
                                                                        ?.eco_percent
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="d-xl-none d-block p-3"></Col>
                                                    <Col
                                                        md={'auto'}
                                                        className="p-3 pr-4 my-auto"
                                                    >
                                                        <div
                                                            style={{
                                                                width: '130px',
                                                            }}
                                                            className="text-right display-24 font-weight-800 m-0"
                                                        >
                                                            {
                                                                thisPlan?.[
                                                                    paymentFrequency
                                                                ]?.object
                                                                    ?.prices
                                                            }
                                                            {
                                                                thisPlan?.[
                                                                    paymentFrequency
                                                                ]?.object
                                                                    ?.currency
                                                            }
                                                            <br />
                                                            <span className="font-weight-bold display-18">
                                                                HT/
                                                                {paymentFrequency ===
                                                                'annually'
                                                                    ? 'an'
                                                                    : 'mois'}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                    <Col className="d-xl-none d-block p-3"></Col>
                                                </Row>
                                                <Row className="m-0">
                                                    <Col className="p-3">
                                                        {Plan.renderDescription(
                                                            thisPlan?.[
                                                                paymentFrequency
                                                            ],
                                                            'Contenu :'
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Info
                                                    variant="secondary"
                                                    className="m-3 bg-gray text-black font-weight-600 display-12 border-0"
                                                >
                                                    <Row className="align-items-center">
                                                        <Col xs="auto">
                                                            <FiInfo size="2.2rem" />
                                                        </Col>
                                                        <Col className="pl-0">
                                                            <span className="font-weight-600 display-16">
                                                                Ce montant
                                                                correspond en
                                                                moyenne à un
                                                                prix de{' '}
                                                            </span>
                                                            <span className="font-weight-700 display-18">
                                                                {
                                                                    thisPlan?.[
                                                                        paymentFrequency
                                                                    ]?.object
                                                                        ?.cost_per_user_per_month
                                                                }
                                                                {
                                                                    thisPlan?.[
                                                                        paymentFrequency
                                                                    ]?.object
                                                                        ?.currency
                                                                }
                                                            </span>
                                                            <span className="font-weight-600 display-16">
                                                                {' '}
                                                                pour une équipe
                                                                type utilisant
                                                                notre
                                                                application
                                                                (Scrum Team,
                                                                Feature Team,
                                                                SAFe Agile Team,
                                                                Product Team...)
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </Info>
                                            </Card>
                                        </Col>
                                        {props.onboarding ? (
                                            <></>
                                        ) : (
                                            <Col
                                                lg={'auto'}
                                                className={
                                                    props.onboarding
                                                        ? 12
                                                        : 'auto' +
                                                          'flex items-end column pr-2 mt-3'
                                                }
                                            >
                                                <BlueButton
                                                    content={'Souscrire'}
                                                    icon={
                                                        <FiCreditCard size="2.2rem" />
                                                    }
                                                    onClick={checkout}
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                    {thisSubscription?.object?.features
                                        ?.length ? (
                                        <>
                                            <div className="input-label m-3">
                                                Vos options&nbsp;:
                                            </div>
                                            {renderFeatureAdmin([
                                                thisSubscription,
                                            ])}
                                        </>
                                    ) : (
                                        <>
                                            <div className="action-label m-3">
                                                A quelles options voulez-vous
                                                souscrire&nbsp;?
                                            </div>
                                            <Card
                                                key="feature_admin"
                                                className="display-14 p-0 m-3 mb-4 mr-0"
                                                style={{ maxWidth: '780px' }}
                                            >
                                                <Row className="m-0">
                                                    <Col className="p-3 my-auto">
                                                        <p className="display-20 font-weight-800 m-0">
                                                            {
                                                                thisAdminFeature?.[
                                                                    paymentFrequency
                                                                ]?.object?.name
                                                            }
                                                        </p>
                                                    </Col>
                                                    <Col
                                                        md={'auto'}
                                                        className="p-3 my-auto"
                                                    >
                                                        <Button
                                                            variant="primary"
                                                            className="btn-sm"
                                                            onClick={() => {
                                                                addAdmin();
                                                            }}
                                                        >
                                                            <strong>
                                                                <FiShoppingCart
                                                                    size="1.4rem"
                                                                    className="mr-2"
                                                                />
                                                                {'  '}
                                                                Ajouter
                                                            </strong>
                                                        </Button>
                                                    </Col>
                                                    <Col
                                                        md={'auto'}
                                                        className="p-3 pr-4 my-auto"
                                                    >
                                                        <div
                                                            style={{
                                                                width: '130px',
                                                            }}
                                                            className="text-right display-24 font-weight-800 m-0"
                                                        >
                                                            {thisSubscription
                                                                ?.object
                                                                ?.features
                                                                ?.length
                                                                ? thisSubscription
                                                                      ?.object
                                                                      ?.features
                                                                      ?.length *
                                                                  thisAdminFeature?.[
                                                                      paymentFrequency
                                                                  ]?.object
                                                                      ?.prices
                                                                : thisAdminFeature?.[
                                                                      paymentFrequency
                                                                  ]?.object
                                                                      ?.prices}
                                                            {
                                                                thisAdminFeature?.[
                                                                    paymentFrequency
                                                                ]?.object
                                                                    ?.currency
                                                            }
                                                            <br />
                                                            <span className="font-weight-bold display-18">
                                                                HT/
                                                                {paymentFrequency ===
                                                                'annually'
                                                                    ? 'an'
                                                                    : 'mois'}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </>
                                    )}
                                    {props.onboarding ? (
                                        <Row className="p-0">
                                            <Col sm={10} md={8} lg={6} xxl={4}>
                                                <Link
                                                    to={
                                                        '/' +
                                                        id +
                                                        '/onboarding/setup'
                                                    }
                                                >
                                                    <Button
                                                        className="btn-lg btn-outline-primary h3 font-weight-800 display-24 w-100 mt-5 px-3"
                                                        style={{
                                                            height: '5rem',
                                                            maxWidth: '300px',
                                                        }}
                                                        type="submit"
                                                    >
                                                        Précédent
                                                    </Button>
                                                </Link>
                                            </Col>
                                            <Col sm={10} md={8} lg={6} xxl={4}>
                                                <Button
                                                    className="btn-lg btn-primary h3 font-weight-800 display-24 w-100 mt-5"
                                                    style={{
                                                        height: '5rem',
                                                        maxWidth: '300px',
                                                    }}
                                                    onClick={checkout}
                                                >
                                                    Souscrire
                                                </Button>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                        </div>
                    )}

                    <Alert
                        color={colorAlert}
                        content={contentAlert}
                        showAlert={showAlert}
                        handleCloseAlert={() => setShowAlert(false)}
                    />
                </div>
                {checkoutDone ? (
                    <div className="workspace-body-Controller">
                        {checkoutError ? (
                            <ErrorSelector
                                code={501}
                                title="Oops!"
                                message="Une erreur est survenue pendant le processus de paiement !  Pas de panique, on est déjà au courant et on travaille sur la résolution de ce problème !"
                            />
                        ) : (
                            <ErrorSelector
                                code={200}
                                title="Félicitations"
                                message="Votre souscription a bien été enregistrée !"
                            />
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}

export default SubscriptionBody;
