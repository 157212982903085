import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Config from './util/Config';
import Header from './components/commun/Header';
import SideBar from './components/commun/SideBar';
import ErrorSelector from './components/error/ErrorSelector';
import User from './util/User';
import { FiLoader } from 'react-icons/fi';
import Workspace from './util/Workspace';
import { useSelector, useDispatch } from 'react-redux';
export default (props) => {
    const users = useSelector((state) => state.user.users);
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [loading, setLoding] = useState(true);
    const [login, setLogin] = useState(false);
    const { id } = useParams();
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspaces = activeUser[0].workspace;

    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        setTimeout(() => {
            setLoding(false);
        }, Config.API.TIMEOUT);
        if (activeUser.length) {
            dispatch({ type: 'SET_ACTIVE_USER_INDEX', value: { id } });
            User.get({}, activeUser[0].token)
                .then((user) => {
                    if (user.status === 200) {
                        Workspace.get({}, activeUser[0].token)
                            .then((workspace) => {
                                if (workspace.status === 200) {
                                    dispatch({
                                        type: 'UPDATE_USER',
                                        value: {
                                            ...user.data.data[0],
                                        },
                                    });
                                    dispatch({
                                        type: 'UPDATE_WORKSPACE_LIST',
                                        value: {
                                            workspace: workspace.data.data,
                                            id: activeUser[0].id,
                                        },
                                    });
                                    const newWorkspaceInvit = workspaces.filter(
                                        (w) =>
                                            w.object.teams.on.filter(
                                                (t) =>
                                                    t.id === activeUser[0].id &&
                                                    t.invit &&
                                                    !t.ignored
                                            ).length === 1
                                    );
                                    if (newWorkspaceInvit.length) {
                                        dispatch({
                                            type: 'SET_ACTIVE_WORKSPACE',
                                            value: newWorkspaceInvit[0].id,
                                        });
                                    }
                                } else if (workspace.status === 404) {
                                    // history.push('/onboarding');
                                    dispatch({
                                        type: 'UPDATE_USER',
                                        value: {
                                            ...user.data.data[0],
                                        },
                                    });
                                    dispatch({
                                        type: 'UPDATE_WORKSPACE_LIST',
                                        value: {
                                            workspace: [],
                                            id: activeUser[0].id,
                                        },
                                    });
                                } else {
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 2000);
                                }
                                setLoding(false);
                            })
                            .catch((msg) => {
                                setLogin(true);
                            });
                    } else if (user.status === 404) {
                        dispatch({
                            type: 'LOGOUT',
                            value: id,
                        });
                        setLogin(true);
                    } else {
                        setLogin(true);
                    }
                })
                .catch((error) => {
                    setLogin(true);
                });
        }
    }, []);

    const renderApp = () => {
        switch (true) {
            case loading:
                return (
                    <ErrorSelector
                        logo="1"
                        code={200}
                        title={<FiLoader size={36} className="rotating" />}
                        message=" "
                    />
                );
            case !activeUser.length:
                return (
                    <ErrorSelector
                        logo="1"
                        code={403}
                        title="Oops !"
                        message="Votre session a expiré."
                        retry_value="Se connecter"
                        retry_link="/login"
                        retry_color="primary"
                        retry_icone="plus"
                    />
                );
            case login:
                return (
                    <ErrorSelector
                        logo="1"
                        code={403}
                        title="Oops !"
                        message="Votre session a expiré."
                        retry_value="Se connecter"
                        retry_link="/login"
                        retry_color="primary"
                        retry_icone="plus"
                    />
                );
            case activeUser[0].first_name === undefined:
            case activeUser[0].last_name === undefined:
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/profile');
                }, 1800);
                return (
                    <>
                        <div className="text-center">
                            <h3 className="font-weight-800 my-5">
                                Finalisation en cours
                                <br />
                            </h3>
                            <p>Encore un peu de patience</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <FiLoader size={36} className="rotating" />
                        </div>
                    </>
                );
            case !workspaces.length:
                return (
                    <ErrorSelector
                        logo="1"
                        code={403}
                        title="Oops !"
                        message="Aucun workspace n'est associé à votre compte."
                        retry_value="Créer un workspace"
                        retry_link="/onboarding"
                        retry_color="primary"
                        retry_icone="plus"
                    />
                );
            default:
                //     if (
                //         !workspaces[activeUser[0].activeWorkspace]
                //             .object.onboarding
                //     ) {
                //         setTimeout(() => {
                //             history.push('/' + id + '/onboarding/done');
                //         }, 1000);
                //     }
                return (
                    <>
                        <Container
                            fluid
                            className="overflow-hidden d-none d-lg-block p-0"
                        >
                            <Header />
                            <div className="wrapper pr-2">
                                <div className="p-0">
                                    <SideBar
                                        open={props.menu}
                                        menuCollapse={menuCollapse}
                                        setMenuCollapse={setMenuCollapse}
                                    />
                                </div>
                                <div
                                    className={
                                        menuCollapse
                                            ? 'p-0 container-full position-relative'
                                            : 'p-0 container-reduced position-relative'
                                    }
                                >
                                    {props.children}
                                </div>
                            </div>
                        </Container>
                        <div className="d-lg-none d-block">
                            <ErrorSelector
                                logo="1"
                                code={418}
                                title=""
                                message="Pour que vous profitiez pleinement des fonctionnalités de Rankio, nous vous conseillons de vous connecter sur un ordinateur."
                                retry_value="Revenir à la page d'accueil"
                                retry_link="/"
                                retry_color="primary"
                                retry_icone="home"
                            />
                        </div>
                    </>
                );
        }
    };
    return <>{renderApp()}</>;
};
