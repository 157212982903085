const initialState = {
    version: '1.0.0',
    lang: /^fr\b/.test(navigator.language) ? 'fr' : 'en',
    users: [],
    activeUserIndex: 0,
    newEmail: '',
    expiryDate: '',
};

function userReducer(state = initialState, action) {
    let nextState;
    let newUser;
    let newIndex;
    let newUserArray;
    switch (action.type) {
        case 'SET_LANG':
            return {
                ...state,
                lang: action.value,
            };
        case 'ADD_USER':
            if (state.users && !state.users.length) {
                newUser = {
                    token: action.token,
                    id: action.value.id,
                    isOwner: action.value.isOwner,
                    email: action.value.object.email,
                    emailStatus: action.value.object.email_status,
                    first_name: action.value.object.first_name,
                    last_name: action.value.object.last_name,
                    keyWords: action.value.object.keywords,
                    newsLetter: action.value.object.newsletter,
                    phoneNumberStatus: action.value.object.phone_number_status,
                    public: action.value.object.public,
                    status: action.value.object.status,
                    type: action.value.type,
                    image: action.value.object.image,
                    workspace: action.value.workspace,
                    activeWorkspace: 0,
                    selectedPlan: null,
                    selectedSubscription: null,
                    selectedWorkspace: null,
                };
                nextState = {
                    ...state,
                    users: [...state.users, newUser],
                };
            } else {
                let verifUser =
                    state.users &&
                    state.users.filter((user) => user.id === action.value.id);
                if (verifUser && !verifUser.length) {
                    newUser = {
                        token: action.token,
                        id: action.value.id,
                        isOwner: action.value.isOwner,
                        email: action.value.object.email,
                        emailStatus: action.value.object.email_status,
                        first_name: action.value.object.first_name,
                        last_name: action.value.object.last_name,
                        keyWords: action.value.object.keywords,
                        newsLetter: action.value.object.newsletter,
                        phoneNumberStatus:
                            action.value.object.phone_number_status,
                        public: action.value.object.public,
                        status: action.value.object.status,
                        type: action.value.type,
                        image: action.value.object.image,
                        workspace: action.value.workspace,
                        activeWorkspace: 0,
                        selectedPlan: null,
                        selectedSubscription: null,
                        selectedWorkspace: null,
                    };
                    nextState = {
                        ...state,
                        users: [...state.users, newUser],
                        activeUserIndex: state.users.length,
                    };
                } else {
                    nextState = {
                        ...state,
                    };
                }
            }
            return nextState;
        case 'SET_USER_TOKEN':
            nextState = state;
            newUser = state.users.filter((user) => user.id === action.value.id);
            if (newUser.length) {
                newUser[0].token = action.value.token;
                newUserArray = state.users.filter(
                    (user) => user.id !== newUser[0].id
                );
                nextState = {
                    ...state,
                    users: [...newUserArray, newUser[0]],
                };
            }
            return nextState;
        case 'UPDATE_USER':
            newUser = state.users.filter((user) => user.id === action.value.id);
            if (action.value.object.image) {
                console.log('add/update img');
                newUser[0].image = action.value.object.image;
            } else if (
                action.value.object.image === undefined &&
                newUser[0].image !== undefined
            ) {
                console.log('remove img');
                delete newUser[0].image;
            } else {
                newUser[0].image = newUser[0].image;
            }
            if (action.value.object.first_name) {
                newUser[0].first_name = action.value.object.first_name;
            } else {
                newUser[0].first_name = newUser[0].first_name;
            }
            if (action.value.object.last_name) {
                newUser[0].last_name = action.value.object.last_name;
            } else {
                newUser[0].last_name = newUser[0].last_name;
            }
            if (action.value.object.email) {
                newUser[0].email = action.value.object.email;
            } else {
                newUser[0].email = newUser[0].email;
            }
            newUserArray = state.users.filter(
                (user) => user.id !== newUser[0].id
            );
            nextState = {
                ...state,
                users: [...newUserArray, newUser[0]],
            };
            return nextState;
        case 'SET_EMAIL_EXPIRY':
            nextState = {
                ...state,
                newEmail: action.value.email,
                expiryDate: action.value.expire,
            };
            return nextState;
        case 'SET_SELECTED_PLAN':
            console.log(action);
            newUser = state.users.filter((user) => user.id === action.value.id);
            newUser[0].selectedPlan = action.value.data;
            newUserArray = state.users.filter(
                (user) => user.id !== newUser[0].id
            );
            nextState = {
                ...state,
                users: [...newUserArray, ...newUser],
            };
            return nextState;
        case 'SET_SELECTED_SUBSCRIPTION':
            newUser = state.users.filter((user) => user.id === action.value.id);
            newUser[0].selectedSubscription = action.value.data;
            newUserArray = state.users.filter(
                (user) => user.id !== newUser[0].id
            );
            nextState = {
                ...state,
                users: [...newUserArray, ...newUser],
            };
            return nextState;
        case 'SET_SELECTED_WORKSPACE':
            newUser = state.users.filter((user) => user.id === action.value.id);
            newUser[0].selectedWorkspace = action.value.data;
            newUserArray = state.users.filter(
                (user) => user.id !== newUser[0].id
            );
            nextState = {
                ...state,
                users: [...newUserArray, ...newUser],
            };
            return nextState;
        case 'SET_ACTIVE_USER_INDEX':
            let newIndexArr = state.users.map((user, i) => {
                if (user.id.includes(action.value.id)) return i;
            });
            newIndex = newIndexArr.filter((index) => index !== undefined);
            nextState = {
                ...state,
                activeUserIndex: newIndex[0],
            };
            return nextState;
        case 'SET_ACTIVE_WORKSPACE':
            let newUsersList = state.users;
            let newWsIndex = 0;
            newUsersList[state.activeUserIndex].workspace.map((ws, i) => {
                if (ws.id === action.value) {
                    newWsIndex = i;
                }
            });
            newUsersList[state.activeUserIndex].activeWorkspace = newWsIndex;
            nextState = {
                ...state,
                users: [...newUsersList],
            };
            return nextState;
        case 'SET_WORKSPACE':
            newUser = state.users.filter((user) =>
                user.id.includes(action.value.id)
            );
            let finalUser = newUser[0];
            finalUser.workspace[action.value.index] = action.value.workspace;
            newUserArray = state.users.filter(
                (user) => user.id !== newUser[0].id
            );
            nextState = {
                ...state,
                users: [...newUserArray, finalUser],
            };
            return nextState;
        case 'SET_WORKSPACE_DATA':
            newUser = state.users.filter((user) =>
                user.id.includes(action.value.id)
            );
            let finalUserWorkspace = newUser[0];
            finalUserWorkspace.workspace[action.value.index].object.wsjf =
                action.value.data;
            newUserArray = state.users.filter(
                (user) => user.id !== newUser[0].id
            );
            nextState = {
                ...state,
                users: [...newUserArray, finalUserWorkspace],
            };
            return nextState;
        case 'SET_WORKSPACE_TAGS':
            newUser = state.users.filter((user) =>
                user.id.includes(action.value.id)
            );
            let finalUserWorkspacee = newUser[0];
            finalUserWorkspacee.workspace[action.value.index] =
                action.value.data;
            newUserArray = state.users.filter(
                (user) => user.id !== newUser[0].id
            );
            nextState = {
                ...state,
                users: [...newUserArray, finalUserWorkspacee],
            };
            return nextState;
        case 'UPDATE_WORKSPACE_LIST':
            newUser = state.users.filter((user) =>
                user.id.includes(action.value.id)
            );
            newUser[0].workspace = action.value.workspace;
            newUserArray = state.users.filter(
                (user) => user.id !== newUser[0].id
            );
            nextState = {
                ...state,
                users: [...newUserArray, ...newUser],
            };
            return nextState;
        case 'LOGOUT':
            nextState = {
                ...state,
                users: state.users.filter(
                    (user) => !user.id.includes(action.value)
                ),
                activeUserIndex: 0,
            };
            return nextState;
        default:
            return state;
    }
}

export default userReducer;
