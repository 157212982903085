import {
    Container,
    Navbar,
    Button,
    Alert,
    Form,
    Card,
    Row,
    Col,
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import User from '../../util/User';
import Validator from '../../util/Validator';
import Config from '../../util/Config';
import Echo from '../../util/Echo';
import ErrorSelector from '../error/ErrorSelector';
import Logo from '../commun/Logo';
import { FiX } from 'react-icons/fi';
import { useMatomo } from '@datapunt/matomo-tracker-react';
export default () => {
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        document.title = Echo().seo.title.recovery
            ? Echo().seo.title.prefix +
              Echo().seo.title.recovery +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, []);
    if (Config.APP_ENV === 'staging') {
        return (
            <ErrorSelector
                code={200}
                title="Mot de passe oublié"
                message="Page indisponible pour les beta testeurs.  Veuillez nous contacter, nous vous enverrons les instructions pour vous reconnecter simplement."
                retry_value="Contactez-nous"
                retry_link="/contact"
                retry_color="outline-primary"
            />
        );
    }
    const history = useHistory();
    const location = useLocation();
    const [payload, setPayload] = useState({
        email: '',
    });
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const handleChange = (event) => {
        setPayload({ ...payload, [event.target.name]: event.target.value });
        if (Object.keys(errors).length !== 0 || errors.constructor !== Object) {
            const e = Validator({
                ...payload,
                [event.target.name]: event.target.value,
            });
            if (e) setErrors(e);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const e = Validator(payload);
        if (Object.keys(e).length !== 0 || e.constructor !== Object) {
            setErrors(e);
        } else {
            User.recovery(payload)
                .then((res) => {
                    const expire = Date.now() + 3600 * 1000;
                    dispatch({
                        type: 'SET_EMAIL_EXPIRY',
                        value: { email: payload.email, expire },
                    });
                    history.push('/validate');
                })
                .catch((msg) => {
                    setMessage(msg);
                });
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <Navbar className="fixed-top rankio-header" bg="white" expand="xl">
                <Link to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </Link>
                <Link to="/" className="ml-auto">
                    <FiX size="1.5em" className="rk-clickable-icon-dark" />{' '}
                </Link>
            </Navbar>
            <div className="landing">
                <Row className="p-4">
                    <Col sm={1} md={2} lg={3} xxl={4}></Col>
                    <Col sm={10} md={8} lg={8} xxl={7}>
                        <h3 className="font-weight-800 mt-4 mb-5">
                            Mot de passe oublié
                            <br />
                        </h3>
                        <p className="font-weight-600">
                            Veuillez renseigner votre adresse email et nous vous
                            enverrons les instructions pour vous reconnecter
                            simplement&nbsp;!
                        </p>
                    </Col>
                </Row>
                <Row className="p-4">
                    <Col sm={1} md={2} lg={3} xxl={4}></Col>
                    <Col sm={10} md={8} lg={5} xxl={4}>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group controlId="email">
                                <Form.Label className="input-label">
                                    E-mail
                                </Form.Label>
                                <Form.Control
                                    name="email"
                                    type="email"
                                    placeholder="nom@entreprise.com"
                                    value={payload.email}
                                    className="input"
                                    onChange={handleChange}
                                />
                                <Form.Text className="ml-2 mb-4 text-danger font-weight-bold">
                                    {errors.email}
                                </Form.Text>
                            </Form.Group>
                            <Button
                                className="btn-lg btn-primary w-100 mt-5"
                                type="submit"
                                style={{
                                    height: '5rem',
                                    maxWidth: '340px',
                                }}
                            >
                                <strong className="h3 font-weight-800 display-24">
                                    Envoyer
                                </strong>
                            </Button>
                            <Form.Group>
                                <Form.Text className="text-danger font-weight-bold">
                                    {message}
                                </Form.Text>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};
