import { v4 as uuidv4 } from 'uuid';
export default {
    effort: {
        code: 'EFF',
        description:
            "Quelle est la taille de la fonctionnalité ou du projet à développer ? Quel est l'effort à fournir par l’équipe de développement pour sa réalisation ?",
    },
    risk_opportunity: {
        code: 'RRO',
        value: 30,
        active: true,
        description:
            "La fonctionnalité ou le projet à développer apporte-il quelque chose en plus à notre business ? Réduit-il le risque sur notre business ou sur une prochaine livraison ? Permet-il de réduire un risque existant ? Va-t-il permettre de nouvelles opportunités business ? Sa livraison va-t-elle être riche d'informations et d'enseignements pour l'avenir ?",
    },
    time_criticality: {
        code: 'CTE',
        value: 30,
        active: true,
        description:
            "Quel est le degré d'urgence de livraison de la fonctionnalité ou du projet ? Comment la business value diminue-t-elle avec le temps ? Y a-t-il une date limite de mise en service ? Les clients vont-ils attendre la mise à disposition de la fonctionnalité ou du projet, ou vont-ils basculer vers une solution alternative ? Son développement impacte-il des jalons du chemin critique ? Y a-t-il un effet immédiat sur la satisfaction client ?",
    },
    business_value: {
        code: 'VBU',
        value: 40,
        description:
            'Quelle est la valeur métier, business de la fonctionnalité ou du projet à développer ?',
        axis: [
            {
                ref: uuidv4(),
                code: 'REV',
                name: 'Augmentation du CA',
                value: 10,
                active: false,
                description:
                    "La fonctionnalité ou le projet va-t-il avoir un impact positif sur les ventes ? Attend-on une augmentation du chiffre d'affaires ?",
            },
            {
                ref: uuidv4(),
                code: 'FID',
                name: 'Fidélisation client',
                value: 10,
                active: false,
                description:
                    'La fonctionnalité ou le projet permet-il de fidéliser des clients ? Un usage ou un revenu récurrent est-il attendu ?',
            },
            {
                ref: uuidv4(),
                code: 'RAT',
                name: 'Rationalisation',
                value: 10,
                active: false,
                description:
                    "Des réductions de coûts ou des gains de productivité sont-ils attendus ? La fonctionnalité ou le projet permet-il d'industrialiser un processus ? Réduit-on des étapes d'un processus ? La livraison de la fonctionnalité ou du projet inclut-elle un décommissionnement d'application ?",
            },
            {
                ref: uuidv4(),
                code: 'UXD',
                name: "Amélioration de l'expérience utilisateur",
                value: 10,
                active: false,
                description:
                    "La fonctionnalité ou le projet va-t-il améliorer l'expérience utilisateur ?",
            },
            {
                ref: uuidv4(),
                code: 'SAT',
                name: 'Satisfaction client',
                value: 10,
                active: false,
                description:
                    'La fonctionnalité ou le projet va-t-il améliorer la satisfaction du client ?',
            },
            {
                ref: uuidv4(),
                code: 'PER',
                name: 'Accroissement de la personnalisation',
                value: 10,
                active: false,
                description:
                    "A quel point la fonctionnalité ou le projet permet de personnaliser l'expérience des clients ?",
            },
            {
                ref: uuidv4(),
                code: 'AUT',
                name: 'Autonomie du client',
                value: 10,
                active: false,
                description:
                    'La livraison de la fonctionnalité ou du projet aide-t-elle le client à être plus autonome dans sa relation avec nos produits ou services ?',
            },
            {
                ref: uuidv4(),
                code: 'INN',
                name: 'Innovation',
                value: 10,
                active: false,
                description:
                    "Quel est le niveau d'innovation de la fonctionnalité ou du projet ? Un équivalent existe-il déjà sur le marché ? Comment se situe la fonctionnalité ou le projet par rapport à la concurrence ? Contribue-t-il à structurer la démarche d'innovation ?",
            },
            {
                ref: uuidv4(),
                code: 'RSE',
                name: 'Responsabilité sociétale',
                value: 10,
                active: false,
                description:
                    "Quel est l'apport de la fonctionnalité ou du projet en matière de responsabilité sociétale ? Quels sont les enjeux sociaux et environnementaux associés ? Le projet ou la fonctionnalité améliore-t-il la prise en compte des problématiques sociales ou environnementales ?",
            },
        ],
    },
};
