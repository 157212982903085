import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SubscriptionBody from './SubscriptionBody';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Echo from '../../util/Echo';
import Unpaid from '../commun/Unpaid';
function Subscription(props) {
    const users = useSelector((state) => state.user.users);
    const { trackPageView, trackEvent } = useMatomo();
    const { id } = useParams();
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = users.filter((user) => user.id.includes(id))[0].workspace[
        activeUser[0].activeWorkspace
    ];
    useEffect(() => {
        document.title = Echo().seo.title.subscription
            ? Echo().seo.title.prefix +
              workspace.object.name +
              ' - ' +
              Echo().seo.title.subscription +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, [activeUser[0].activeWorkspace]);
    return (
        <>
            {workspace.object.teams.on.filter((t) => t.role === 'owner')[0]
                .id === activeUser[0].id ? (
                <>
                    <div className="frame-content m-2 pb-20">
                        <div className="bg-black text-white rounded-top flex v-center">
                            <span className="flex v-center workspace-title py-3 ml-3">
                                Souscription
                            </span>
                        </div>
                        <SubscriptionBody />
                    </div>
                    <p> </p>
                </>
            ) : (
                <>
                    <Unpaid />
                </>
            )}
        </>
    );
}

export default Subscription;
