import { Row, Dropdown, Col } from 'react-bootstrap';
import { FiPlusCircle, FiSettings, FiDownload } from 'react-icons/fi';
import Echo from '../../util/Echo';
import { useSelector } from 'react-redux';
import Download from '../../util/Download';

export default (props) => {
    const state = useSelector((state) => state.user);
    return (
        <Row className="m-0 px-2 mt-auto pb-2" style={{ maxWidth: '12.5rem' }}>
            <Dropdown className="w-100" style={{ height: '100%' }}>
                <Dropdown.Toggle className="btn-l btn-grey w-100">
                    <Row>
                        <Col sm={2} className="my-auto pl-1">
                            <FiSettings size="2.2rem" />
                        </Col>
                        <Col
                            sm={10}
                            className="font-weight-700 display-16 text-align-left pl-4 pr-0 flex v-center"
                        >
                            {Echo().commun.table_options
                                .split('  ')
                                .map((str) => (
                                    <>
                                        {str}
                                        <br />
                                    </>
                                ))}
                        </Col>
                    </Row>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    className="radius-14 p-0 table-dropdown-menu"
                    style={{ width: '13.5em' }}
                >
                    <Row
                        id="title"
                        className="font-weight-700 display-14 mt-4 mb-3 ml-2"
                    >
                        <Col sm={3} className="my-auto">
                            <FiPlusCircle size="2em" />
                        </Col>
                        <Col sm={9} className="pl-1 my-auto">
                            {Echo().commun.columns}
                        </Col>
                    </Row>

                    {Object.keys(props.optionsStates).map((key) => {
                        return (
                            <label key={key} className="checkrow">
                                <Row className="ml-4">
                                    <Col xs={1} className="ml-3">
                                        <input
                                            type="checkbox"
                                            checked={props.optionsStates[key]}
                                            onChange={(e) => e.preventDefault()}
                                        />
                                        <span
                                            className="checkmark"
                                            onClick={() =>
                                                props.handleOptionsState(key)
                                            }
                                        ></span>
                                    </Col>
                                    <Col
                                        xs={8}
                                        className="font-weight-700 display-12 pl-0"
                                    >
                                        <div
                                            onClick={() =>
                                                props.handleOptionsState(key)
                                            }
                                        >
                                            {props?.config?.type?.[key] ===
                                                'wsjf' &&
                                            ![
                                                'wsjf',
                                                'business_value',
                                                'risk_opportunity',
                                                'time_criticality',
                                                'cost',
                                                'effort',
                                            ].includes(key)
                                                ? props.echo[key]
                                                : Echo(state.lang)[props.resource].table
                                                      .columns?.[key]}
                                        </div>
                                    </Col>
                                </Row>
                            </label>
                        );
                    })}

                    <div className="mt-4 mb-3 ml-2">
                        <Download data={props.data} resource={props.resource} />
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </Row>
    );
};
