import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ErrorSelector from './components/error/ErrorSelector';
import ApiCallConfig from './util/ApiCallConfig';
import Axios from 'axios';

export default (args) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const activeUser = user.users[user.activeUserIndex];
    if (activeUser === undefined) {
        return (
            <>
                <ErrorSelector
                    logo="1"
                    code={403}
                    title="Accès refusé"
                    message="Vous n'avez pas les droits nécessaires pour effectuer cette opération."
                    retry_value="Se connecter"
                    retry_link="/login"
                    retry_color="primary"
                    retry_icone="home"
                />
            </>
        );
    }
    const urlParams = useParams();
    const [workspace, setWorkspace] = useState(
        activeUser.workspace[activeUser.activeWorkspace]
    );
    const getUserIdFromWorkspace = async () => {
        // return activeUser.id.split('-')[0];
        console.log(activeUser.id);
        const payload = user.users.map((u) => {
            if (u.status === 'open') {
                return {
                    id: u.id,
                    uri: '/workspace',
                    method: 'get',
                    headers: {
                        authorization: 'Bearer ' + u.token,
                    },
                };
            }
        });
        await Axios({
            ...ApiCallConfig,
            method: 'POST',
            url: `/batch`,
            data: payload,
        })
            .then(async (res) => {
                console.log(res.data.responses);
                // check workspaces for activeUser
                if (
                    res.data.responses[activeUser.id].status === 200 &&
                    res.data.responses[activeUser.id]?.data?.filter(
                        (w) => w.id === urlParams.workspaceId
                    )?.length
                ) {
                    console.log('active');
                    // dispatch({
                    //     type: 'UPDATE_WORKSPACE_LIST',
                    //     value: {
                    //         workspace: res.data.responses[activeUser.id].data,
                    //         id: activeUser.id,
                    //     },
                    // });
                    // dispatch({
                    //     type: 'SET_ACTIVE_WORKSPACE',
                    //     value: urlParams.workspaceId,
                    // });
                    console.log(activeUser.id.split('-')[0]);
                    return await activeUser.id.split('-')[0];
                }
                // check workspaces other connected users
                for (const u in res.data.responses) {
                    if (u !== activeUser.id) {
                        if (
                            res.data.responses[u].data.filter(
                                (w) => w.id === urlParams.workspaceId
                            ).length
                        ) {
                            console.log('222');
                            dispatch({
                                type: 'SET_ACTIVE_USER_INDEX',
                                value: { id: u.split('-')[0] },
                            });
                            dispatch({
                                type: 'UPDATE_WORKSPACE_LIST',
                                value: {
                                    workspace: res.data.responses[u].data,
                                    id: u,
                                },
                            });
                            dispatch({
                                type: 'SET_ACTIVE_WORKSPACE',
                                value: urlParams.workspaceId,
                            });
                            return u.split('-')[0];
                        }
                    }
                }
                return null;
            })
            .catch((err) => {
                return null;
            });
    };
    const goto = async () => {
        console.log('goto');
        const userIndex = await getUserIdFromWorkspace();
        console.log('userIndex', userIndex);
        switch (true) {
            case userIndex === null:
                console.log('is null');
                // Cookies.set('RK_next_view', args.location.pathname);
                return '/login';
            case urlParams.projectId !== undefined:
                console.log(urlParams);
                return '/' + userIndex + '/project';
            case urlParams.sessionId !== undefined:
                return '/' + userIndex + '/session';
            case urlParams.teamsId !== undefined:
                return '/' + userIndex + '/teams';
            case urlParams.workspaceId !== undefined:
                return '/' + userIndex + '/workspace';
            default:
                return '/404';
        }
    };
    useEffect(async () => {
        switch (true) {
            case args.location.pathname.split('/')[1] === 'goto':
                history.push(await goto());
                break;
            case activeUser.id === undefined:
                history.push('/login');
                break;
            default:
                history.push(
                    '/' + activeUser.id.split('-')[0] + args.location.pathname
                );
        }
    }, []);
    return <h1>loading...</h1>;
};
