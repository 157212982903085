import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Col, Row } from 'react-bootstrap';
import {
    FiPlusCircle,
    FiPlus,
    FiChevronDown,
    FiChevronUp,
} from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import BlueButton from '../commun/BlueButton';
import Tag from './TagsDetails';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';

function TagsBody(props) {
    const dispatch = useDispatch();
    const { id } = useParams();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const [workspace, setWorkspace] = useState(
        activeUser[0].workspace[activeUser[0].activeWorkspace]
    );
    const userRole = workspace.object.teams.on.filter(
        (team) => team.id === activeUser[0].id
    )[0].role;
    const [tags, setTags] = useState(
        activeUser[0].workspace[activeUser[0].activeWorkspace].object.tags
    );
    const [Changes, setChanges] = useState(false);
    const getWorkspace = () => {
        Axios({
            ...ApiCallConfig,
            method: 'GET',
            url: '/workspace',
            headers: {
                authorization: `Bearer ${activeUser[0].token}`,
            },
        }).then((res) => {
            if (res.status === 200) {
                let action = {
                    type: 'UPDATE_WORKSPACE_LIST',
                    value: { workspace: res.data.data, id: activeUser[0].id },
                };
                dispatch(action);
            }
        });
        const newCategList = tags?.categories?.length
            ? tags?.categories.sort(function check(obj1, obj2) {
                  if (obj1.active) {
                      return -1;
                  }
                  if (!obj1.active) {
                      return 1;
                  }
                  return 0;
              })
            : [];
        const newTagsList = { ...tags, categories: newCategList };
        setTags(newTagsList);
    };
    useEffect(() => {
        getWorkspace();
    }, [activeUser[0].activeWorkspace]);

    const updateTagsfunction = (payload) => {
        Axios({
            ...ApiCallConfig,
            method: 'PUT',
            url: `/workspace/${workspace.id}/tags`,
            headers: {
                authorization: `Bearer ${activeUser[0].token}`,
            },
            data: payload ? payload : tags,
        }).then((res) => {
            if (res.status === 200) {
                if (payload) {
                    setTags(payload);
                }
                setChanges(!Changes);
                let newWorkspace = workspace;
                newWorkspace.object.tags = payload;
                let action = {
                    type: 'SET_WORKSPACE_TAGS',
                    value: {
                        workspaceId: workspace.id,
                        data: newWorkspace,
                        id: activeUser[0].id,
                        index: activeUser[0].activeWorkspace,
                    },
                };
                dispatch(action);
            }
        });
    };
    const onChangeTagInputCode = (e, cIndex, tIndex) => {
        let newTags = tags;
        newTags.categories[cIndex].tags[tIndex].code = e.target.value;
        setTags(newTags);
        setChanges(!Changes);
    };
    const onChangeTagInputName = (e, cIndex, tIndex) => {
        let newTags = tags;
        newTags.categories[cIndex].tags[tIndex].name = e.target.value;
        setTags(newTags);
        setChanges(!Changes);
    };
    const onBlurTagInputName = () => {
        updateTagsfunction();
    };
    const onChangeInputName = (e, index) => {
        let newTags = tags;
        newTags.categories[index].name = e.target.value;
        setTags(newTags);
        setChanges(!Changes);
    };
    const onBlurInputName = () => {
        updateTagsfunction();
    };
    const deleteCategory = (index) => {
        let newTags = tags;
        let tag = newTags.categories;
        let newTag = tag.filter((tag, categIndex) => categIndex !== index);
        newTags.categories = newTag;
        updateTagsfunction(newTags);
    };
    const deleteTag = (cIndex, tIndex) => {
        let newTags = tags;
        let tag = newTags.categories[cIndex];
        let newTag = tag.tags.filter((tag, tagIndex) => tagIndex !== tIndex);
        newTags.categories[cIndex].tags = newTag;
        updateTagsfunction(newTags);
    };
    const onCheckCategory = (status, index) => {
        let newTags = tags;
        newTags.categories[index].active = status;
        updateTagsfunction(newTags);
    };
    const onAddTagClick = (cIndex) => {
        let newTags = tags;
        newTags.categories[cIndex].tags = [
            ...tags.categories[cIndex].tags,
            {
                ref: uuidv4(),
                name: '',
                code: '',
                number: tags.categories[cIndex].tags.length + 1,
            },
        ];
        setTags(newTags);
        setChanges(!Changes);
    };
    const onAddCategoryClick = () => {
        let newTags = tags;
        newTags.categories.push({
            name: '',
            tags: [],
            active: true,
            number: tags.categories.length,
            color: 'ffaa00',
        });
        setTags(newTags);
        setChanges(!Changes);
    };
    const onChangeColor = (cIndex, color) => {
        const newTags = tags;
        newTags.categories[cIndex].color = color;
        newTags.categories[cIndex].tags = newTags.categories[cIndex].tags.map(
            (t) => {
                return { ...t, color };
            }
        );
        updateTagsfunction(newTags);
    };

    return (
        <div className="workspace-body-Controller">
            <Row className="axesContainer mr-0">
                <Col>
                    {tags?.categories?.length > 0 ? (
                        tags.categories.map((el, i) => (
                            <Tag
                                key={i}
                                userRole={userRole}
                                className={`my-4 ml-3 ${
                                    i === tags.length - 1 ? 'mb-3' : ''
                                }`}
                                openIconName={<FiChevronDown size="2rem" />}
                                closeIconName={<FiChevronUp size="2rem" />}
                                placeholderFirstInput="Nom de la catégorie de tag"
                                placeholderSecondInput="Nom du tag"
                                tagsColor={el.color}
                                deleteButton={true}
                                switch={true}
                                drgaBtn={false}
                                tags={el}
                                cIndex={i}
                                onChangeInputName={(e) =>
                                    onChangeInputName(e, i)
                                }
                                onBlurInputName={() => onBlurInputName()}
                                onChangeTagInputCode={(e, i, tIndex) =>
                                    onChangeTagInputCode(e, i, tIndex)
                                }
                                onChangeTagInputName={(e, i, tIndex) =>
                                    onChangeTagInputName(e, i, tIndex)
                                }
                                onBlurTagInputName={() => onBlurTagInputName()}
                                onCheckCategory={(status) =>
                                    onCheckCategory(status, i)
                                }
                                deleteCategory={() => deleteCategory(i)}
                                onAddTagClick={() => onAddTagClick(i)}
                                onChangeColor={(color) =>
                                    onChangeColor(i, color)
                                }
                                deleteTag={(cIndex, tIndex) =>
                                    deleteTag(cIndex, tIndex)
                                }
                            />
                        ))
                    ) : (
                        <p className="font-weight-700 display-16 ml-3 mt-4">
                            Vous n'avez configuré aucun tag pour le moment.
                        </p>
                    )}
                    {['owner', 'admin'].includes(userRole) && (
                        <FiPlus
                            size="2.2rem"
                            className="rk-clickable-icon-primary ml-3 mb-5"
                            onClick={() => onAddCategoryClick()}
                        />
                    )}
                </Col>
                <Col
                    lg={'auto'}
                    className="flex items-end column axe-priorisation-btn-container px-2"
                >
                    {['owner', 'admin'].includes(userRole) && (
                        <BlueButton
                            btnClassnameDetails="mt-4"
                            content={
                                <>
                                    Ajouter une <br></br>catégorie
                                </>
                            }
                            icon={<FiPlusCircle size="2.2rem" />}
                            onClick={() => onAddCategoryClick()}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default TagsBody;
