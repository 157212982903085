import {
    Container,
    Fade,
    Navbar,
    Button,
    Row,
    Col,
    Alert,
} from 'react-bootstrap';
import Config from '../../util/Config';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link, useHistory } from 'react-router-dom';
import { validate as isUuid } from 'uuid';
import Workspace from '../../util/Workspace';
import Logo from '../commun/Logo';
import Echo from '../../util/Echo';
import { FiX, FiInfo } from 'react-icons/fi';
import { IoHelp } from 'react-icons/io5';
import { useMatomo } from '@datapunt/matomo-tracker-react';
export default () => {
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const selectedPlan = activeUser[0].selectedPlan;
    const selectedSubscription = activeUser[0].selectedSubscription;
    const selectedWorkspace = activeUser[0].selectedWorkspace;
    const [next, setNext] = useState('done');
    const [message, setMessage] = useState('');
    const [content, setContent] = useState(false);
    const { trackPageView } = useMatomo();
    useEffect(() => {
        document.title = Echo().seo.title.onboarding
            ? Echo().seo.title.prefix +
              Echo().seo.title.onboarding +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, []);
    useEffect(() => {
        switch (true) {
            case activeUser[0].first_name === undefined:
            case activeUser[0].last_name === undefined:
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/profile');
                }, 1000);
                break;
            case !isUuid(selectedPlan):
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/reset');
                }, 1000);
                break;
            case !isUuid(selectedSubscription):
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/letsgo');
                }, 1000);
                break;
            case !isUuid(selectedWorkspace):
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/workspace');
                }, 1000);
                break;
            default:
                Workspace.get({}, activeUser[0].token)
                    .then((res) => {
                        if (res.status === 200) {
                            const workspaces = res.data.data.filter(
                                (w) => w.id === selectedWorkspace
                            );
                            dispatch({
                                type: 'UPDATE_WORKSPACE_LIST',
                                value: {
                                    workspace: res.data.data,
                                    id: activeUser[0].id,
                                },
                            });
                            if (workspaces.length) {
                                setContent(true);
                                if (
                                    res.data.data[0].object.status === 'unpaid'
                                ) {
                                    setNext('checkout');
                                }
                            } else {
                                history.push('/' + id + '/onboarding/reset');
                            }
                        } else if (res.status === 404) {
                            history.push('/' + id + '/onboarding/reset');
                        } else {
                            setMessage(
                                'Une erreur est survenue, veuillez réessayer.'
                            );
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                        }
                    })
                    .catch((msg) => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 20000);
                    });
        }
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <Navbar className="fixed-top rankio-header" bg="white" expand="xl">
                <Link to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </Link>
                <div className="ml-auto">
                    <a
                        title="Nous contacter"
                        href="/contact"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="mr-4"
                    >
                        <IoHelp
                            size="1.5em"
                            className="rk-clickable-icon-dark"
                        />
                    </a>
                    <Link to={'/'}>
                        <FiX
                            title="Accueil"
                            size="1.5em"
                            className="rk-clickable-icon-dark"
                        />{' '}
                    </Link>
                </div>
            </Navbar>
            <Fade in={content}>
                <div className="landing">
                    <Row className="p-4">
                        <Col sm={1} md={1} lg={2} xxl={4}></Col>
                        <Col sm={11} md={11} lg={9} xxl={7}>
                            <h3 className="font-weight-800 mt-4 mb-5">
                                Déterminons ensemble quels axes de priorisation
                                reflètent le mieux votre stratégie d'entreprise
                                &nbsp;!
                                <br />
                            </h3>
                            <p className="font-weight-600">
                                En ayant comme objectif de créer le plus de
                                valeur le plus rapidement possible, la méthode
                                WSJF est la plus appropriée.
                            </p>
                            <p className="font-weight-600">
                                Elle consiste à évaluer l'ensemble de vos
                                projets selon les axes de priorisation et le
                                calcul suivants&nbsp;:
                            </p>
                            <img
                                style={{ marginLeft: '-5%', width: '100%' }}
                                src={
                                    Config.CDN.ENDPOINT +
                                    'static/media/wsjf_preview.jpg'
                                }
                            />
                            <Row className="p-0">
                                <Col sm={10} md={8} lg={4} xxl={4}>
                                    <Link
                                        className="d-block d-xl-none"
                                        to={'/' + id + '/onboarding/workspace'}
                                    >
                                        <Button
                                            className="btn-lg btn-outline-primary h3 font-weight-800 display-18 w-100 mt-5"
                                            style={{
                                                height: '5rem',
                                                maxWidth: '340px',
                                            }}
                                        >
                                            Précédent
                                        </Button>
                                    </Link>
                                    <Link
                                        className="d-none d-xl-block"
                                        to={'/' + id + '/onboarding/workspace'}
                                    >
                                        <Button
                                            className="btn-lg btn-outline-primary h3 font-weight-800 display-24 w-100 mt-5"
                                            style={{
                                                height: '5rem',
                                                maxWidth: '340px',
                                            }}
                                        >
                                            Précédent
                                        </Button>
                                    </Link>
                                </Col>
                                <Col sm={10} md={8} lg={4} xxl={4}>
                                    <Link
                                        className="d-block d-xl-none"
                                        to={'/' + id + '/onboarding/' + next}
                                    >
                                        <Button
                                            className="btn-lg btn-primary h3 font-weight-800 display-18 w-100 mt-5 px-2"
                                            style={{
                                                height: '5rem',
                                                maxWidth: '340px',
                                            }}
                                        >
                                            Choisir cette
                                            <br />
                                            configuration
                                        </Button>
                                    </Link>
                                    <Link
                                        className="d-none d-xl-block"
                                        to={'/' + id + '/onboarding/' + next}
                                    >
                                        <Button
                                            className="btn-lg btn-primary h3 font-weight-800 display-24 w-100 mt-5 px-2"
                                            style={{
                                                height: '5rem',
                                                maxWidth: '340px',
                                            }}
                                        >
                                            Choisir cette
                                            <br />
                                            configuration
                                        </Button>
                                    </Link>
                                    <Alert
                                        variant="secondary"
                                        style={{
                                            maxWidth: '340px',
                                        }}
                                        className="my-4 bg-gray text-black font-weight-600 display-12 border-0"
                                    >
                                        <Row className="align-items-center">
                                            <Col xs="auto">
                                                <FiInfo size="2.2rem" />
                                            </Col>
                                            <Col className="pl-0">
                                                Vous pourrez également
                                                personnaliser votre
                                                configuration plus tard si vous
                                                en ressentez le besoin.
                                            </Col>
                                        </Row>
                                    </Alert>
                                </Col>
                                <Col sm={10} md={8} lg={4} xxl={4}>
                                    <Link
                                        className="d-block d-xl-none"
                                        to={'/' + id + '/onboarding/config'}
                                    >
                                        <Button
                                            className="btn-lg btn-primary h3 font-weight-800 display-18 w-100 mt-5 px-2"
                                            style={{
                                                height: '5rem',
                                                maxWidth: '340px',
                                            }}
                                        >
                                            Personnaliser votre
                                            <br />
                                            configuration
                                        </Button>
                                    </Link>
                                    <Link
                                        className="d-none d-xl-block"
                                        to={'/' + id + '/onboarding/config'}
                                    >
                                        <Button
                                            className="btn-lg btn-primary h3 font-weight-800 display-24 w-100 mt-5 px-2"
                                            style={{
                                                height: '5rem',
                                                maxWidth: '340px',
                                            }}
                                        >
                                            Personnaliser votre
                                            <br />
                                            configuration
                                        </Button>
                                    </Link>
                                    <Alert
                                        variant="secondary"
                                        style={{
                                            maxWidth: '340px',
                                        }}
                                        className="my-4 bg-gray text-black font-weight-600 display-12 border-0"
                                    >
                                        <Row className="align-items-center">
                                            <Col xs="auto">
                                                <FiInfo size="2.2rem" />
                                            </Col>
                                            <Col className="pl-0">
                                                Dans 70% des cas, les
                                                entreprises choisissent cette
                                                configuration.
                                            </Col>
                                        </Row>
                                    </Alert>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Fade>
        </Container>
    );
};
