import React, { useState, useEffect } from 'react';
import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Modal from '../commun/RkModal';
import BlueButton from '../commun/BlueButton';
import GreyButton from '../commun/GreyButton';
import DarkButton from '../commun/DarkButton';
import TagButton from '../commun/TagButton';
import { FiTrash2, FiTag, FiX, FiSearch, FiSave } from 'react-icons/fi';
import Config from '../../util/Config';
import Echo from '../../util/Echo';
import Alert from '../commun/Alert';
import Tags from '../../util/Tags';

export default (props) => {
    const { id } = useParams();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = activeUser[0].workspace[activeUser[0].activeWorkspace];
    const deletable = ['owner', 'admin'].includes(
        workspace.object.teams.on.filter(
            (team) => team.id === activeUser[0].id
        )[0].role
    );
    const readOnly = !deletable || props.data?.object?.status === 'archived';
    const [my] = useState(activeUser[0]);
    const [workspaceTags, setWorkspaceTags] = useState([]);
    const [initialWorkspaceTags, setInitialWorkspaceTags] = useState([]);
    const [tagsToAssign, setTagsToAssign] = useState([]);
    const [keywords, setKeywords] = useState('');
    useEffect(() => {
        resetTagsToAssign();
    }, [props.data]);
    useEffect(() => {
        Tags.get(workspace.id, activeUser[0].token)
            .then((res) => {
                setWorkspaceTags(res.data.data);
                setTagsToAssign([
                    ...(tagsToAssign || []),
                    ...res.data.data
                        .map((t) => t.id)
                        .filter((t) => !initialWorkspaceTags.includes(t)),
                ]);
            })
            .catch((err) => {
                setWorkspaceTags([]);
            });
    }, [users]);
    const resetTagsToAssign = () => {
        setKeywords('');
        setTagsToAssign(props.data?.object?.tags || []);
        Tags.get(workspace.id, activeUser[0].token)
            .then((res) => {
                setWorkspaceTags(res.data.data);
                setInitialWorkspaceTags(res.data.data.map((t) => t.id));
            })
            .catch((err) => {
                setWorkspaceTags([]);
            });
    };
    const renderTheRestOfWorkspaceTags = () => {
        return workspaceTags
            .filter((t) => !tagsToAssign.includes(t.ref))
            .filter(
                (t) =>
                    t.code.toLowerCase().includes(keywords.toLowerCase()) ||
                    t.name.toLowerCase().includes(keywords.toLowerCase()) ||
                    t.category.toLowerCase().includes(keywords.toLowerCase())
            )
            .map((tag) => (
                <div
                    onClick={() => {
                        setTagsToAssign([...tagsToAssign, tag.ref]);
                    }}
                >
                    <TagButton tag={tag} />
                </div>
            ));
    };
    const renderWorkspaceTagsToAssign = () => {
        return tagsToAssign
            .filter((ref) => !workspaceTags.map((t) => t.key).includes(ref))
            .map((ref) => {
                return {
                    ref,
                    ...workspaceTags.filter((t) => t.ref === ref)?.[0],
                };
            })
            .map((tag) => (
                <div
                    onClick={() => {
                        setTagsToAssign(
                            tagsToAssign.filter((t) => t !== tag.ref)
                        );
                    }}
                >
                    <TagButton tag={tag} />
                </div>
            ));
    };
    return (
        <>
            <Modal
                id="view_details_tags_project"
                title="Tags"
                className="card rk-modal-level-2 m-2"
            >
                <div className="rk-modal-body-level-2">
                    <Row className="mr-0 ml-0">
                        <Col lg={7} xl={8} className="pb-5">
                            <div className="mt-4">
                                <span className="descr-label">
                                    Comment voulez-vous caractériser ce projet ?
                                </span>
                            </div>
                            <div className="action-label pt-5 pb-3">
                                Liste de tags
                            </div>
                            <div className="session-invite-search-container">
                                <Form.Control
                                    name="search"
                                    type="text"
                                    placeholder="Rechercher..."
                                    value={keywords}
                                    className="rounded border-0 session-search-input "
                                    onChange={(e) =>
                                        setKeywords(e.target.value)
                                    }
                                />
                                <FiSearch
                                    size="1.5rem"
                                    className="session-search-icon"
                                />
                            </div>
                            <div className="session-member-container">
                                {renderTheRestOfWorkspaceTags()}
                            </div>
                            <div className="action-label mt-4 pb-3">
                                Tags associés au projet
                            </div>
                            <div className="session-member-container">
                                {renderWorkspaceTagsToAssign()}
                            </div>
                        </Col>
                        <Col
                            lg={'auto'}
                            className="session-details-col-display"
                        >
                            <div className="add-session-btn-container">
                                <BlueButton
                                    content={<>Enregistrer</>}
                                    icon={<FiSave size="2.2rem" />}
                                    displayRowClassname="axeDisplayRowClassnameBtn"
                                    onClick={() => {
                                        props.update(props.data.id, {
                                            tags: tagsToAssign,
                                        });
                                        document.getElementById(
                                            'view_details_tags_project'
                                        ).style.display = 'none';
                                    }}
                                />
                                <GreyButton
                                    content={<>Annuler</>}
                                    icon={<FiX size="2.2rem" />}
                                    onClick={() => {
                                        resetTagsToAssign();
                                        document.getElementById(
                                            'view_details_tags_project'
                                        ).style.display = 'none';
                                    }}
                                />
                                <DarkButton
                                    content={
                                        <>
                                            Gestion<br></br>des tags
                                        </>
                                    }
                                    icon={<FiTag size="2.2rem" />}
                                    onClick={() => {
                                        document.getElementById(
                                            'view_tags_manager'
                                        ).style.display = 'block';
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    );
};
