import { Container, Navbar, Button, Form, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Axios from 'axios';
import Validator from '../../util/Validator';
import ApiCallConfig from '../../util/ApiCallConfig';
import Logo from '../commun/Logo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { FiRadio } from 'react-icons/fi';
export default () => {
    const { trackPageView, trackEvent } = useMatomo();
    const [payload, setPayload] = useState({ email: '', newsletter: true });
    const [sent, setSent] = useState({ form: 'd-block', message: 'd-none' });
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const handleChange = (event) => {
        setPayload({ ...payload, [event.target.name]: event.target.value });
        if (Object.keys(errors).length !== 0 || errors.constructor !== Object) {
            const e = Validator({
                ...payload,
                [event.target.name]: event.target.value,
            });
            if (e) setErrors(e);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const e = Validator(payload);
        if (Object.keys(e).length !== 0 || e.constructor !== Object) {
            setErrors(e);
        } else {
            Axios({
                ...ApiCallConfig,
                method: 'POST',
                url: '/user',
                data: payload,
            })
                .then((result) => {
                    if (result.status === 201 || result.status === 409) {
                        setSent({ form: 'd-none', message: 'd-block' });
                    } else if (result.status === 429) {
                        setMessage(
                            '[' +
                                result.status +
                                '] Trop de tentatives. Veuillez patienter quelques instants puis réessayez.'
                        );
                    } else {
                        setMessage(
                            '[' + result.status + '] un probleme est survenu'
                        );
                    }
                    trackEvent({
                        category: 'newsletter',
                        action: 'subscribe',
                        name: 'Je suis intéressé par la beta !',
                        value: result.status === 201 ? 1 : 0,
                    });
                })
                .catch((error) => {
                    setMessage('[500] un probleme est survenu');
                });
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        trackPageView();
    }, []);
    return (
        <Container fluid>
            <Navbar
                className="fixed-top rankio-header text-center"
                bg="white"
                expand="xl"
            >
                <Link className="mx-auto" to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </Link>
            </Navbar>
            <div className="landing">
                <Row className="p-4">
                    <Col lg={2}></Col>
                    <Col lg={8}>
                        <div className="flex text-danger font-weight-bold display-24 mt-4 h-center">
                            <FiRadio size="2.2rem" className="mr-3" />
                            <div className="mb-3">Beta Live</div>
                        </div>
                        <h1 className="font-weight-700 display-32 text-center mt-4 mb-5">
                            Nous construisons l'outil collaboratif qui permettra
                            à vos équipes de délivrer plus de valeur en
                            priorisant les projets clés à lancer
                        </h1>
                        <div
                            className={sent.message}
                            style={{
                                height: '200px',
                            }}
                        >
                            <div className="h-50"></div>
                            <div className="action-label text-center">
                                Merci de votre intérêt !
                            </div>
                        </div>
                        <div className={sent.form} style={{ height: '280px' }}>
                            <div className="action-label text-center pt-4">
                                Nos 1er utilisateurs ont rejoint la beta !
                            </div>
                            <div className="action-label text-center py-4">
                                Pour ceux qui ne l'ont pas encore fait,<br></br>
                                laissez-nous votre mail pour être les prochains
                                à entrer dans la beta !
                            </div>
                            <Form noValidate onSubmit={handleSubmit}>
                                <center>
                                    <Form.Group controlId="email">
                                        <Form.Control
                                            required
                                            name="email"
                                            type="email"
                                            placeholder="nom@entreprise.com"
                                            style={{ maxWidth: '360px' }}
                                            value={payload.email}
                                            className="input"
                                            onChange={handleChange}
                                        />
                                        <Form.Text className="mt-2 ml-2 text-danger font-weight-700">
                                            {errors.email}
                                        </Form.Text>
                                    </Form.Group>

                                    <Button
                                        className="btn btn-primary mt-4"
                                        type="submit"
                                    >
                                        <strong className="font-weight-800">
                                            Je suis intéressé !
                                        </strong>
                                    </Button>
                                </center>
                                <Form.Group>
                                    <Form.Text className="text-danger">
                                        {message}
                                    </Form.Text>
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                    <Col lg={2}></Col>
                    <Col lg={12} className="mt-5">
                        <div className="action-label text-center pt-4">
                            Nous sommes dès à présent disponibles pour répondre
                            à vos questions !
                        </div>
                        <center>
                            <Link className="mx-auto" to="/contact">
                                <Button
                                    variant="dark"
                                    className="btn my-4"
                                    type="submit"
                                >
                                    <strong className="font-weight-800">
                                        Contactez-nous
                                    </strong>
                                </Button>
                            </Link>
                        </center>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};
