import { Button } from 'react-bootstrap';

export default (props) => {
    return (
        <>
            <Button
                title={props.tag?.name + '\n' + props.tag?.category}
                style={{
                    backgroundColor:
                        '#' + (props.tag?.active ? props.tag.color : 'f3f3f3'),
                    color: '#' + (props.tag?.active ? 'fff' : '999'),
                }}
                className={'tag' + (props.size ? '-' + props?.size : '')}
            >
                {props.tag?.code}
            </Button>
        </>
    );
};
