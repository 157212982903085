import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { FiUser, FiCreditCard, FiSettings, FiPlusCircle } from 'react-icons/fi';
import ProfilPhoto from './ProfilPhoto';
import { useSelector, useDispatch } from 'react-redux';
export default (props) => {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.user.users);
    const { id } = useParams();
    if (props.id) {
        var activeUser = users.filter((user) => user.id === props.id);
        var secondaryUsers = users.filter((sUser) => sUser.id !== props.id);
    } else {
        activeUser = users.filter((user) => user.id.includes(id));
        secondaryUsers = users.filter((sUser) => !sUser.id.includes(id));
    }

    const setActiveUserIndex = (id) => {
        let action = { type: 'SET_ACTIVE_USER_INDEX', value: { id } };
        dispatch(action);
        return;
    };

    const logout = (id) => {
        let action = { type: 'LOGOUT', value: id };
        dispatch(action);
        return;
    };

    const goto = (url) => {
        document.dispatchEvent(new MouseEvent('click'));
        setTimeout(() => {
            window.open(url);
        }, 10);
        return;
    };

    return (
        <>
            <Link
                to={'/' + activeUser[0].id.split('-')[0] + '/account/profile'}
                className="d-lg-none d-block"
            >
                <Button className="profil-icon p-0 mr-3">
                    <ProfilPhoto
                        uuid={activeUser[0].id.split('-')[0]}
                        className="profil-icon"
                    />
                </Button>
            </Link>
            <Dropdown
                className="d-none d-lg-block mx-0"
                onSelect={document.dispatchEvent(new MouseEvent('click'))}
            >
                <Dropdown.Toggle className="profil-icon mx-2 p-0">
                    <ProfilPhoto
                        uuid={activeUser[0].id.split('-')[0]}
                        className="profil-icon"
                    />
                </Dropdown.Toggle>
                <Dropdown.Menu
                    className="radius-14 bg-white shadow dropdown-menu-right p-0 mt-2"
                    style={{ width: '34ch', margin: '-8px' }}
                >
                    <Row className="text-align-left w-100 px-0 py-2 mx-0 my-2">
                        <Col className="col-auto align-self-center pr-0">
                            <Button className="profil-icon p-0 mr-3">
                                <ProfilPhoto
                                    uuid={activeUser[0].id.split('-')[0]}
                                    className="profil-icon"
                                />
                            </Button>
                        </Col>
                        <Col className="col-9 p-0">
                            <strong className="text-black font-weight-800 display-18 text-capitalize">
                                {activeUser[0].first_name}
                            </strong>
                            <div className="font-weight-700 text-xs text-black text-truncate small">
                                {activeUser[0].email}
                            </div>
                        </Col>
                    </Row>
                    <div
                        style={{ maxHeight: 'calc(100vh - 170px)' }}
                        className="scroll-y-on-hover"
                    >
                        {activeUser.length ? (
                            <>
                                <div className="my-2 text-align-left w-100 px-3 py-2">
                                    <Link
                                        to={
                                            '/' +
                                            activeUser[0].id.split('-')[0] +
                                            '/account/profile'
                                        }
                                        className="text-secondary font-weight-700 text-decoration-none w-100"
                                    >
                                        <FiUser size="2.2em" className="mr-3" />
                                        Profil
                                    </Link>
                                </div>
                                <div className="my-2 text-align-left w-100 px-3 py-2">
                                    <Link
                                        to={
                                            '/' +
                                            activeUser[0].id.split('-')[0] +
                                            '/account/billings'
                                        }
                                        className="text-secondary font-weight-700 text-decoration-none w-100"
                                    >
                                        <FiCreditCard
                                            size="2.2em"
                                            className="mr-3"
                                        />
                                        Facturation
                                    </Link>
                                </div>
                                <div className="my-2 text-align-left w-100 px-3 py-2">
                                    <Link
                                        to={
                                            '/' +
                                            activeUser[0].id.split('-')[0] +
                                            '/account/settings'
                                        }
                                        className="text-secondary font-weight-700 text-decoration-none w-100"
                                    >
                                        <FiSettings
                                            size="2.2em"
                                            className="mr-3"
                                        />
                                        Paramètres
                                    </Link>
                                </div>
                                <div className="text-align-left w-100 p-3 my-2">
                                    <Link
                                        to={
                                            '/' +
                                            activeUser[0].id.split('-')[0] +
                                            '/logout'
                                        }
                                        className="text-black font-weight-800 text-align-left w-100"
                                    >
                                        <span>Se déconnecter</span>
                                    </Link>
                                </div>
                                {secondaryUsers.map((secondaryUser, i) => {
                                    let nextPath =
                                        window.location.pathname.substr(9);
                                    if (secondaryUser.id.split('-')[0] !== id) {
                                        return (
                                            <div
                                                key={i}
                                                onClick={() => {
                                                    setActiveUserIndex(
                                                        secondaryUser.id
                                                    );
                                                    goto(
                                                        window.location
                                                            .pathname === '/'
                                                            ? '/'
                                                            : '/' +
                                                                  secondaryUser.id.split(
                                                                      '-'
                                                                  )[0] +
                                                                  nextPath
                                                    );
                                                }}
                                                className="text-black cursor-pointer w-100"
                                            >
                                                <Row className="bg-bg-gray text-align-left w-100 py-3 mx-0 mt-2 mb-4">
                                                    <Col className="col-auto align-self-center pr-0">
                                                        <Button className="btn-sm btn-sm-circle btn-dark w-100 p-0 mr-3">
                                                            <ProfilPhoto
                                                                className="profil-icon"
                                                                uuid={
                                                                    secondaryUser.id.split(
                                                                        '-'
                                                                    )[0]
                                                                }
                                                            />
                                                        </Button>
                                                    </Col>
                                                    <Col className="col-9 align-self-center p-0">
                                                        <div className="font-weight-800 text-black text-truncate">
                                                            {
                                                                secondaryUser.email
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        );
                                    }
                                })}
                            </>
                        ) : (
                            <div className="text-align-left w-100 p-3 my-2">
                                <Link
                                    to={'/login'}
                                    className="text-black font-weight-800 text-align-left w-100"
                                >
                                    <span>Se connecter</span>
                                </Link>
                            </div>
                        )}
                        <div
                            className="bg-bg-gray text-align-left cursor-pointer w-100 p-3 mt-2 mb-5"
                            onClick={() => {
                                goto('/login');
                            }}
                        >
                            <div className="text-black font-weight-700 w-100">
                                <FiPlusCircle size={'2.3em'} className="mr-3" />
                                Ajouter un compte
                            </div>
                        </div>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};
