import RkTable from '../commun/RkTable';
import Header from './Header';
import CreateSession from './CreateSession';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import Echo from '../../util/Echo';
import config from './Config';
import Session from '../../util/Session';
import Modal from '../modal/Modal';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Config from '../../util/Config';
import Unpaid from '../commun/Unpaid';
import IniteAccept from '../commun/IniteAccept';

const options = [
    { value: '00_to_plan', label: Echo().session.fields.status['00_to_plan'] },
    { value: '01_planned', label: Echo().session.fields.status['01_planned'] },
    {
        value: '02_prioritizing',
        label: Echo().session.fields.status['02_prioritizing'],
    },
    {
        value: '03_reviewing',
        label: Echo().session.fields.status['03_reviewing'],
    },
    { value: '04_done', label: Echo().session.fields.status['04_done'] },
    { value: '05_aborted', label: Echo().session.fields.status['05_aborted'] },
    {
        value: '06_archived',
        label: Echo().session.fields.status['06_archived'],
    },
];

export default function SessionLanding() {
    const users = useSelector((state) => state.user.users);
    const { trackPageView, trackEvent } = useMatomo();
    const { id } = useParams();
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = users.filter((user) => user.id.includes(id))[0].workspace[
        activeUser[0].activeWorkspace
    ];
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginationStates, setPaginationStates] = useState({
        ...config.pagination,
    });
    const [optionsStates, setOptionsStates] = useState({ ...config.options });
    const [filterStates, setFilterStates] = useState({ ...config.filter });
    const [intervalStates, setIntervalStates] = useState({
        ...config.interval,
    });
    const [sortStates, setSortStates] = useState({ ...config.sort });
    const [session, setSession] = useState();
    const [Teams, setTeams] = useState([]);
    const [Mode, setMode] = useState('ADD');
    const [change, setChange] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalArchive, setShowModalArchive] = useState(false);
    const [ProjectList, setProjectList] = useState([]);
    const [SelectedProjectList, setSelectedProjectList] = useState([]);
    const [SelectedSession, setSelectedSession] = useState({
        id: '',
        object: {
            code: '',
            name: '',
            status: {
                value: '00_to_plan',
                label: Echo().session.fields.status['00_to_plan'],
            },
            due_date: '',
            project: [],
            teams: [],
            workspace: workspace.id,
        },
    });
    const [SelectedSessionCP, setSelectedSessionCP] = useState({
        id: '',
        object: {
            code: '',
            name: '',
            status: {
                value: '00_to_plan',
                label: Echo().session.fields.status['00_to_plan'],
            },
            due_date: '',
            project: [],
            teams: [],
            workspace: workspace.id,
        },
    });
    const [checkedSession, setCheckedSession] = useState(false);
    const handleOptionsState = (column) => {
        setOptionsStates(
            column === 'all'
                ? optionsStates.all
                    ? Object.keys(optionsStates).reduce((accumulator, key) => {
                          return { ...accumulator, [key]: false };
                      }, {})
                    : Object.keys(optionsStates).reduce((accumulator, key) => {
                          return { ...accumulator, [key]: true };
                      }, {})
                : {
                      ...optionsStates,
                      [column]: !optionsStates[column],
                      all:
                          Object.values({
                              ...optionsStates,
                              [column]: !optionsStates[column],
                              all: true,
                          }).filter((c) => !c).length === 0,
                  }
        );
    };
    const handleFilterState = (column, value) => {
        switch (config.type[column]) {
            case 'string':
                setFilterStates({
                    ...filterStates,
                    [column]: value,
                });
                break;
            case 'enum':
            case 'status_list':
                setFilterStates({
                    ...filterStates,
                    [column]:
                        value === 'all'
                            ? filterStates[column].all
                                ? Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: false,
                                          };
                                      },
                                      {}
                                  )
                                : Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: true,
                                          };
                                      },
                                      {}
                                  )
                            : {
                                  ...filterStates[column],
                                  [value]: !filterStates[column][value],
                                  all:
                                      Object.values({
                                          ...filterStates[column],
                                          [value]: !filterStates[column][value],
                                          all: true,
                                      }).filter((c) => !c).length === 0,
                              },
                });
                break;
            case 'teams':
                setFilterStates({
                    ...filterStates,
                    [column]:
                        value === 'all'
                            ? filterStates[column].all.selected
                                ? Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: {
                                                  ...filterStates[column][key],
                                                  selected: false,
                                              },
                                          };
                                      },
                                      {}
                                  )
                                : Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: {
                                                  ...filterStates[column][key],
                                                  selected: true,
                                              },
                                          };
                                      },
                                      {}
                                  )
                            : {
                                  ...filterStates[column],
                                  [value]: {
                                      ...filterStates[column][value],
                                      selected:
                                          !filterStates[column][value].selected,
                                  },
                                  all: {
                                      selected:
                                          Object.values({
                                              ...filterStates[column],
                                              [value]:
                                                  !filterStates[column][value]
                                                      .selected,
                                              all: { selected: true },
                                          }).filter((c) => !c.selected)
                                              .length === 0,
                                  },
                              },
                });
                break;
            case 'tags':
                setFilterStates({
                    ...filterStates,
                    [column]:
                        value === 'all'
                            ? filterStates[column].all.selected
                                ? Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: {
                                                  ...filterStates[column][key],
                                                  selected: false,
                                              },
                                          };
                                      },
                                      {}
                                  )
                                : Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: {
                                                  ...filterStates[column][key],
                                                  selected: true,
                                              },
                                          };
                                      },
                                      {}
                                  )
                            : {
                                  ...filterStates[column],
                                  [value]: {
                                      ...filterStates[column][value],
                                      selected:
                                          !filterStates[column][value].selected,
                                  },
                                  all: {
                                      selected:
                                          Object.values({
                                              ...filterStates[column],
                                              [value]: {
                                                  ...filterStates[column][
                                                      value
                                                  ],
                                                  selected:
                                                      !filterStates[column][
                                                          value
                                                      ].selected,
                                              },
                                              all: { selected: true },
                                          }).filter((c) => !c.selected)
                                              .length === 0,
                                  },
                              },
                });
                break;
            default:
                console.log('undefined column', column, 'type');
        }
    };
    const handleIntervalState = (column, value, op) => {
        setIntervalStates({
            ...intervalStates,
            [column]: { ...intervalStates[column], [op]: value },
        });
    };
    const handleSortState = (column, type) => {
        setSortStates(
            sortStates?.type === type && sortStates?.column === column
                ? { type: '', column: '' }
                : { type, column }
        );
    };
    const handleFilterReset = (column) => {
        switch (config.type[column]) {
            case 'string':
                setFilterStates({
                    ...filterStates,
                    [column]: '',
                });
                break;
            case 'enum':
            case 'status_list':
                setFilterStates({
                    ...filterStates,
                    [column]: Object.keys(filterStates[column]).reduce(
                        (accumulator, key) => {
                            return {
                                ...accumulator,
                                [key]: false,
                            };
                        },
                        {}
                    ),
                });
                break;
            case 'teams':
                setFilterStates({
                    ...filterStates,
                    [column]: Object.keys(filterStates[column]).reduce(
                        (accumulator, key) => {
                            return {
                                ...accumulator,
                                [key]: {
                                    ...filterStates[column][key],
                                    selected: false,
                                },
                            };
                        },
                        {}
                    ),
                });

                break;
            case 'tags':
                setFilterStates({
                    ...filterStates,
                    [column]: Object.keys(filterStates[column]).reduce(
                        (accumulator, key) => {
                            return {
                                ...accumulator,
                                [key]: {
                                    ...filterStates[column][key],
                                    selected: false,
                                },
                            };
                        },
                        {}
                    ),
                });
                break;
            default:
                console.log('undefined column', column, 'type');
        }
    };
    const getData = (next = false) => {
        setLoading(true);
        if (next) {
            if (paginationStates.end === undefined) {
                setPaginationStates({
                    ...paginationStates,
                    skip: paginationStates.skip + paginationStates.limit,
                });
            } else {
                return;
            }
        } else {
            setPaginationStates({
                ...config.pagination,
            });
        }
        let url = `workspace/${workspace.id}/session?limit=${
            paginationStates.limit
        }&skip=${paginationStates.skip}${
            sortStates.type !== '' && sortStates.column !== ''
                ? '&' +
                  sortStates.type +
                  "=object-%3E%3E'" +
                  sortStates.column +
                  "'"
                : ''
        }`;
        Object.keys(intervalStates).forEach((column) => {
            url += `&gt%20object-%3E%3E'${column}'=${intervalStates[column].gt}`;
            url += `&lt%20object-%3E%3E'${column}'=${intervalStates[column].lt}`;
        });

        Object.keys(filterStates).forEach((column) => {
            switch (true) {
                case config.type[column] === 'string' &&
                    filterStates[column].length > 1:
                    url += `&object-%3E%3E'${column}'=%${filterStates[column]}%`;
                    break;
                case config.type[column] === 'enum':
                case config.type[column] === 'status_list':
                    Object.keys(filterStates[column]).forEach((box) => {
                        if (box !== 'all' && filterStates[column][box])
                            url += `&object-%3E%3E'${column}'=%${box}%`;
                    });
                    break;
                case config.type[column] === 'teams':
                    Object.keys(filterStates[column]).forEach((box) => {
                        if (box !== 'all' && filterStates[column][box].selected)
                            url += `&object-%3E%3E'${column}'=%25%22${box}%22%25`;
                    });
                case config.type[column] === 'tags':
                    Object.keys(filterStates[column]).forEach((box) => {
                        if (box !== 'all' && filterStates[column][box].selected)
                            url += `&object-%3E%3E'${column}'=%25%22${box}%22%25`;
                    });
                    break;
                default:
                    break;
            }
        });
        Axios({
            ...ApiCallConfig,
            method: 'GET',
            url: url,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
        })
            .then((res) => {
                setLoading(false);
                switch (true) {
                    case res.status === 200:
                        if (next) {
                            setData([...data, ...res.data.data]);
                        } else {
                            setData(res.data.data);
                        }
                        if (res.data.count <= res.data.skip) {
                            setPaginationStates({
                                ...config.pagination,
                                end: true,
                            });
                        }
                        break;
                    default:
                        setData([]);
                        break;
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    const archiveSession = (sessionId, commit = false) => {
        if (commit) {
            setLoading(true);
            Session.patch(workspace.id, sessionId, activeUser[0].token, {
                status: '06_archived',
            })
                .then((res) => {
                    getData();
                    setSelectedSession({
                        ...SelectedSession,
                        object: {
                            ...SelectedSession.object,
                            status: res.data.data.object.status,
                        },
                    });
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else {
            setShowModalArchive(true);
            setSession({ ...session, id: sessionId });
        }
    };
    const deleteSession = (sessionId, commit = false) => {
        if (commit) {
            setLoading(true);
            setLoading(true);
            Session.delete(workspace.id, sessionId, activeUser[0].token)
                .then((res) => {
                    getData();
                    document.getElementById(
                        'view_create_session'
                    ).style.display = 'none';
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else {
            setShowModalDelete(true);
            setSession({ ...session, id: sessionId });
        }
    };
    useEffect(() => {
        getProjecsList();
    }, []);
    useEffect(() => {
        if (ProjectList.length && window.location.hash) {
            try {
                if (window.location.hash.split('#')[2]) {
                    Session.get(
                        workspace.id,
                        window.location.hash.split('#')[2],
                        activeUser[0].token
                    ).then((res) => {
                        if (res.status === 200) {
                            selectSession(res.data.data[0]);
                        }
                    });

                    // console.log(window.location.hash.split('#')[2]);
                } else {
                    document.getElementById(
                        window.location.hash.split('#')[1]
                    ).style.display = 'block';
                }
            } catch (e) {
                console.log(e);
            }
        }
    }, [ProjectList]);

    useEffect(() => {
        setTimeout(() => {
            Axios({
                ...ApiCallConfig,
                method: 'POST',
                url: `/batch`,
                data: [
                    {
                        id: 'tags',
                        method: 'get',
                        uri: `/workspace/${workspace.id}/tags`,
                        headers: {
                            authorization: `Bearer ${activeUser[0].token}`,
                        },
                    },
                    {
                        id: 'assignation',
                        method: 'get',
                        uri: `/workspace/${workspace.id}/teams`,
                        headers: {
                            authorization: `Bearer ${activeUser[0].token}`,
                        },
                    },
                ],
            })
                .then((res) => {
                    if (res.status === 200) {
                        const result = { ...config.filter };
                        switch (true) {
                            case res.data.responses.tags.status === 200:
                                result.tags = {
                                    ...config.filter.tags,
                                    ...res.data.responses.tags.data.reduce(
                                        (accumulator, key) => {
                                            return {
                                                ...accumulator,
                                                [key.ref]: {
                                                    data: { ...key },
                                                    selected: false,
                                                },
                                            };
                                        },
                                        {}
                                    ),
                                };
                            case res.data.responses.assignation.status === 200:
                                setTeams(res.data.responses.assignation.data);
                                result.assignation = {
                                    ...config.filter.assignation,
                                    ...res.data.responses.assignation.data.reduce(
                                        (accumulator, key) => {
                                            return {
                                                ...accumulator,
                                                [key.id]: {
                                                    data: key.object,
                                                    selected: false,
                                                },
                                            };
                                        },
                                        {}
                                    ),
                                };
                            default:
                                setFilterStates({ ...result });
                        }
                    }
                })
                .catch((error) => {});
        }, 0);
    }, [activeUser[0].activeWorkspace]);
    useEffect(getData, [
        sortStates,
        filterStates,
        activeUser[0].activeWorkspace,
    ]);
    useEffect(() => {
        document.title = Echo().seo.title.session
            ? Echo().seo.title.prefix +
              workspace.object.name +
              ' - ' +
              Echo().seo.title.session +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, [activeUser[0].activeWorkspace]);

    const getProjecsList = () => {
        let url = `workspace/${workspace.id}/project`;
        Axios({
            ...ApiCallConfig,
            method: 'GET',
            url: url,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
        }).then((res) => {
            if (res.status === 200) {
                setProjectList(res.data.data);
            }
        });
    };
    const selectSession = async (row) => {
        if (row.id !== SelectedSession.id) {
            const newRow = { ...row };
            var teamList = [];
            await Axios({
                ...ApiCallConfig,
                method: 'GET',
                url: `workspace/${workspace.id}/teams`,
                headers: {
                    authorization: 'Bearer ' + activeUser[0].token,
                },
            }).then((res) => {
                teamList = res.data.data;
                var newMembersIds = newRow.object.teams;
                for (
                    let counter = 0;
                    counter < newMembersIds.length;
                    counter++
                ) {
                    newMembersIds[counter] = teamList.filter(
                        (t) => t.id === newMembersIds[counter]
                    )[0];
                }
                const finalMemberIds = [...newMembersIds];
                newRow.object.teams = finalMemberIds;
                for (
                    let counter = 0;
                    counter < newRow.object.project.length;
                    counter++
                ) {
                    if (!newRow.object.project[counter].id) {
                        newRow.object.project[counter] = ProjectList.filter(
                            (t) => t.id === newRow.object.project[counter]
                        )[0];
                    }
                }
                setSelectedSession(newRow);
                setSelectedSessionCP({ ...newRow });
            });
            setMode('Edit');
            setSelectedProjectList(newRow.object.project);
        }
        document.getElementById('view_create_session').style.display = 'block';
    };
    const onBlurAddSession = () => {
        if (
            SelectedSession.object.name !== '' &&
            SelectedSession.object.code !== ''
        ) {
            Axios({
                ...ApiCallConfig,
                method: 'POST',
                url: `/workspace/${workspace.id}/session`,
                headers: {
                    authorization: `Bearer ${activeUser[0].token}`,
                },
                data: {
                    code: SelectedSession.object.code,
                    name: SelectedSession.object.name,
                    ...(SelectedSession.object.status !== '' && {
                        status: SelectedSession.object.status,
                    }),
                    ...(SelectedSession.object.due_date !== '' && {
                        due_date: SelectedSession.object.due_date,
                    }),
                    ...(SelectedSession.object.teams.length
                        ? {
                              teams: SelectedSession.object.teams.map(
                                  (team) => team.id
                              ),
                          }
                        : { teams: [] }),
                },
            }).then((res) => {
                if (res.data.status === 201) {
                    const newSelectedSession = { ...SelectedSession };
                    newSelectedSession.id = res.data.data.id;
                    setSelectedSession(newSelectedSession);
                    setSelectedSessionCP({ ...newSelectedSession });
                    setMode('Edit');
                    getData();
                }
            });
        }
    };
    const onBlurEditSession = () => {
        if (
            SelectedSession.object.name !== '' &&
            SelectedSession.object.code !== ''
        ) {
            Axios({
                ...ApiCallConfig,
                method: 'PATCH',
                url: `/workspace/${workspace.id}/session/${SelectedSession.id}`,
                headers: {
                    authorization: `Bearer ${activeUser[0].token}`,
                },
                data: {
                    code: SelectedSession.object.code,
                    name: SelectedSession.object.name,
                    ...(SelectedSession.object.due_date !== '' && {
                        due_date: SelectedSession.object.due_date,
                    }),
                    // status: SelectedSession.object.status,
                    teams: SelectedSession.object.teams.length
                        ? SelectedSession.object.teams.map((team) => team.id)
                        : [],
                    project: SelectedSession.object.project.length
                        ? SelectedSession.object.project.map(
                              (project) => project.id
                          )
                        : [],
                },
            }).then((res) => {
                if (res.data.status === 200) {
                    if (SelectedSession.id !== '') {
                        const newSelectedSession = { ...SelectedSession };
                        newSelectedSession.object.status =
                            res.data.data.object.status;
                        setSelectedSession(newSelectedSession);
                    }
                    getData();
                }
            });
        }
    };
    const openCreateSessionModal = () => {
        setMode('ADD');
        const newSelectedRow = {
            id: '',
            object: {
                code: '',
                name: '',
                status: '00_to_plan',
                project: [],
                teams: [],
                workspace: workspace.id,
                due_date: '',
            },
        };
        newSelectedRow.object.teams = Teams.filter(
            (team) =>
                team.object.role !== 'guest' && team.object.status !== 'off'
        );
        setSelectedSession(newSelectedRow);
        document.getElementById('view_create_session').style.display = 'block';
    };
    const onSessionNameChange = (e) => {
        const newSelectedRow = { ...SelectedSession };
        newSelectedRow.object.name = e.target.value;
        setSelectedSession(newSelectedRow);
    };
    const onSessionCodeChange = (e) => {
        const newSelectedRow = { ...SelectedSession };
        newSelectedRow.object.code = e.target.value;
        setSelectedSession(newSelectedRow);
    };
    const selectSessionStatus = (e) => {
        Axios({
            ...ApiCallConfig,
            method: 'PATCH',
            url: `/workspace/${workspace.id}/session/${SelectedSession.id}`,
            headers: {
                authorization: `Bearer ${activeUser[0].token}`,
            },
            data: {
                status: e.value,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                getData();
                const newSelectedRowObj = { ...SelectedSession };
                newSelectedRowObj.object.status = e.value;
                setSelectedSession(newSelectedRowObj);
            }
        });
    };
    const setDueDate = (e) => {
        const newSelectedRow = { ...SelectedSession };
        newSelectedRow.object.due_date = e.toISOString();
        setSelectedSession(newSelectedRow);
        if (Mode !== 'ADD') {
            onBlurEditSession();
        }
        setTimeout(() => {
            const newSelectedSession = { ...SelectedSession };
            newSelectedSession.object.status = data.filter(
                (sess) => sess.id === SelectedSession.id
            )[0].object.status;
            setSelectedSession(newSelectedSession);
        }, 400);
    };
    const checkUncheckProject = (e, projet) => {
        var newSelectedProjects = [...SelectedProjectList];
        if (e.target.checked) {
            newSelectedProjects.push(projet);
        } else {
            newSelectedProjects = newSelectedProjects.filter(
                (pr) => pr.id !== projet.id
            );
        }
        setSelectedProjectList(newSelectedProjects);
    };
    const getInvitationsFunction = async (data) => {
        var teamList = [];
        await Axios({
            ...ApiCallConfig,
            method: 'GET',
            url: `workspace/${workspace.id}/teams`,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
        }).then((res) => {
            teamList = res.data.data;
        });
        var newMembersIds = [...data.map((d) => d.id)];
        for (let counter = 0; counter < data.length; counter++) {
            if (!newMembersIds[counter]) {
                newMembersIds[counter] = teamList.filter(
                    (t) => t.object.email === data[counter].email
                )[0];
            } else {
                newMembersIds[counter] = teamList.filter(
                    (t) => t.id === data[counter].id
                )[0];
            }
        }
        const finalMemberIds = [
            ...SelectedSession.object.teams,
            ...newMembersIds,
        ];
        const newSelectedSession = { ...SelectedSession };
        newSelectedSession.object.teams = finalMemberIds;
        setSelectedSession(newSelectedSession);
        onBlurEditSession();
    };
    const updateProjectsList = async () => {
        var newProjectsIds = [...SelectedProjectList];
        for (let counter = 0; counter < newProjectsIds.length; counter++) {
            if (!newProjectsIds[counter].id) {
                newProjectsIds[counter] = ProjectList.filter(
                    (t) => t.id === newProjectsIds[counter]
                )[0];
            }
        }
        const newSelectedSession = { ...SelectedSession };
        newSelectedSession.object.project = newProjectsIds;
        setSelectedSession(newSelectedSession);
        onBlurEditSession();
        setChange(!change);
    };
    const deleteProject = (id) => {
        const newSelectedSession = { ...SelectedSession };
        newSelectedSession.object.project =
            newSelectedSession.object.project.filter((pr) => pr.id !== id);
        setSelectedSession(newSelectedSession);
        onBlurEditSession();

        setTimeout(() => {
            const newSelectedSessionn = { ...SelectedSession };

            for (
                let counter = 0;
                counter < newSelectedSessionn.object.project.length;
                counter++
            ) {
                if (!newSelectedSessionn.object.project[counter].id) {
                    newSelectedSessionn.object.project[counter] =
                        ProjectList.filter(
                            (t) =>
                                t.id ===
                                newSelectedSessionn.object.project[counter]
                        )[0];
                }
            }
            setSelectedSession(newSelectedSessionn);
        }, 800);

        clearSelectedProjects();
    };
    const clearSelectedProjects = () => {
        setSelectedProjectList(SelectedSession.object.project);
    };

    const deleteParticipantItem = (el) => {
        const newSelectedSession = { ...SelectedSession };
        newSelectedSession.object.teams =
            newSelectedSession.object.teams.filter((team) => team.id !== el.id);
        setSelectedSession(newSelectedSession);
        onBlurEditSession();
    };
    switch (true) {
        case workspace.object.status === 'unpaid':
            return <Unpaid />;
        case workspace.object.teams.on.filter(
            (t) => t.id === activeUser[0].id
        )[0].invit !== undefined:
            return <IniteAccept veiw="session"/>;
        default:
            return (
                <>
                    {workspace.object.status === 'unpaid' ? (
                        <>
                            <Unpaid />
                        </>
                    ) : (
                        <>
                            <CreateSession
                                SelectedSession={SelectedSession}
                                SelectedProjectList={SelectedProjectList}
                                options={options}
                                checkUncheckProject={(e, projet) =>
                                    checkUncheckProject(e, projet)
                                }
                                onSessionNameChange={(e) =>
                                    onSessionNameChange(e)
                                }
                                onSessionCodeChange={(e) =>
                                    onSessionCodeChange(e)
                                }
                                selectSessionStatus={(e) =>
                                    selectSessionStatus(e)
                                }
                                onBlurAddSession={() => onBlurAddSession()}
                                onBlurEditSession={() => onBlurEditSession()}
                                delete={(id) => deleteSession(id)}
                                archive={(id) => archiveSession(id)}
                                Mode={Mode}
                                checkedSession={checkedSession}
                                setCheckedSession={setCheckedSession}
                                getInvitations={(data) =>
                                    getInvitationsFunction(data)
                                }
                                updateProjectsList={() => updateProjectsList()}
                                deleteProject={(id) => deleteProject(id)}
                                deleteParticipantItem={(id) =>
                                    deleteParticipantItem(id)
                                }
                                clearSelectedProjects={() =>
                                    clearSelectedProjects()
                                }
                                setDueDate={(date) => setDueDate(date)}
                                ProjectList={ProjectList}
                                Teams={Teams}
                            />
                            <Header
                                data={data}
                                loading={loading}
                                optionsStates={optionsStates}
                                handleOptionsState={(column) =>
                                    handleOptionsState(column)
                                }
                                openCreateSessionModal={() =>
                                    openCreateSessionModal()
                                }
                            />
                            <RkTable
                                resource="session"
                                config={config}
                                data={data}
                                getData={getData}
                                loading={loading}
                                setLoading={(s) => setLoading(s)}
                                optionsStates={optionsStates}
                                filterStates={filterStates}
                                intervalStates={intervalStates}
                                sortStates={sortStates}
                                handleFilterState={(column, value) =>
                                    handleFilterState(column, value)
                                }
                                handleIntervalState={(column, value, op) =>
                                    handleIntervalState(column, value, op)
                                }
                                handleFilterReset={(column) =>
                                    handleFilterReset(column)
                                }
                                handleSortState={(column, type) =>
                                    handleSortState(column, type)
                                }
                                selectedRow={(row) => selectSession(row)}
                                archive={(id) => archiveSession(id)}
                                delete={(id) => deleteSession(id)}
                            />
                            <Modal
                                show={showModalDelete}
                                centered={true}
                                modalFirstText="Etes-vous certain de vouloir supprimer cette session ?"
                                modalSecondText="Cette action est irréversible."
                                modalThirdText=""
                                modalContainerClassname="axeModalContainerClassname"
                                modalContentClassName="axeModalContentClassName"
                                closeIcon="axeModalcloseIcon cursorPointer"
                                containerTextWithIconClassname="axesContainerTextWithIconClassname"
                                alertIconClassName="axesAlertIconClassName"
                                firstBtnClassName="axeModalFirstBtn"
                                secondBtnClassName="axeModalSecondBtn"
                                setShowModalFunction={() =>
                                    setShowModalDelete(false)
                                }
                                textClassName="axeModalTextClassName"
                                saveBtnFunction={() =>
                                    deleteSession(session.id, true)
                                }
                            />
                            <Modal
                                show={showModalArchive}
                                centered={true}
                                modalFirstText="Etes-vous certain de vouloir archiver cette session ?"
                                modalSecondText="Elle disparaîtra bien de votre liste de sessions par défaut,"
                                modalThirdText='mais restera accessible en filtrant sur le statut "Archivée".'
                                modalContainerClassname="axeModalContainerClassname"
                                modalContentClassName="axeModalContentClassName"
                                closeIcon="axeModalcloseIcon cursorPointer"
                                containerTextWithIconClassname="axesContainerTextWithIconClassname"
                                alertIconClassName="axesAlertIconClassName"
                                firstBtnClassName="axeModalFirstBtn"
                                secondBtnClassName="axeModalSecondBtn"
                                setShowModalFunction={() =>
                                    setShowModalArchive(false)
                                }
                                textClassName="axeModalTextClassName"
                                saveBtnFunction={() =>
                                    archiveSession(session.id, true)
                                }
                            />
                        </>
                    )}
                </>
            );
    }
}
