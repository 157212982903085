import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { useMatomo } from '@datapunt/matomo-tracker-react';
export default (args) => {
    const users = useSelector((state) => state.user.users);
    const { trackPageView, trackEvent } = useMatomo();
    const { id } = useParams();
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = users.filter((user) => user.id.includes(id))[0].workspace[
        activeUser[0].activeWorkspace
    ];
    const handleClose = () => {
        document.getElementById(args.id).style.display = 'none';
    };
    useEffect(handleClose, [activeUser[0].activeWorkspace]);
    useEffect(() => {
        trackPageView({
            documentTitle: args.title,
        });
    }, []);
    return (
        <div id={args.id} className={'rk-modal ' + args.className}>
            <div className="rk-modal-content border-0">
                {!args.type ? (
                    <Modal.Header
                        id={args.id + '_header'}
                        className={
                            args.backgroundColor
                                ? `bg-${args.backgroundColor} text-black rounded-top`
                                : `bg-gray text-black rounded-top`
                        }
                    >
                        <Modal.Title
                            className={
                                args.backgroundColor
                                    ? ' header-text text-white text-uppercase'
                                    : ' header-text text-black text-uppercase'
                            }
                        >
                            <strong> {args.title} </strong>
                        </Modal.Title>
                        <div className="ml-auto mt-1" onClick={handleClose}>
                            <FiX
                                size="1.5em"
                                className={
                                    args.backgroundColor === 'black'
                                        ? 'rk-clickable-icon-white'
                                        : args.backgroundColor === 'blue'
                                            ? 'rk-clickable-icon-light'
                                            : 'rk-clickable-icon-dark'
                                }
                            />
                        </div>
                    </Modal.Header>
                ) : (
                    ''
                )}
                {args.type === 'micro' ? (
                    <Modal.Body
                        id={args.id + '_body'}
                        className="bg-bg-gray rounded-top p-0"
                    >
                        <div
                            className="sticky-top float-right mt-2 mr-2"
                            onClick={handleClose}
                        >
                            <FiX
                                size="1.5em"
                                className="rk-clickable-icon-dark"
                            />
                        </div>
                        {args.children}
                    </Modal.Body>
                ) : (
                    <Modal.Body id={args.id + '_body'} className="p-0">
                        {args.children}
                    </Modal.Body>
                )}
            </div>
        </div>
    );
};
