import Axios from 'axios';
import ApiCallConfig from './ApiCallConfig';
export default class Teams {
    /**
     * @function get
     * @definition Create team
     * @returns {Promise}
     */
    static get(workspaceId, token, params = '') {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'GET',
                url: `/workspace/${workspaceId}/teams?${params}`,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    /**
     * @function post
     * @definition Create team
     * @returns {Promise}
     */
    static post(workspaceId, token, payload) {
        return new Promise((resolve, reject) => {
            if (payload.hasOwnProperty('email')) {
                Axios({
                    ...ApiCallConfig,
                    method: 'POST',
                    url: `/workspace/${workspaceId}/teams`,
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                    data: payload,
                })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } else {
                Axios({
                    ...ApiCallConfig,
                    method: 'POST',
                    url: '/batch',
                    data: Object.keys(payload).map((id) => {
                        return {
                            id,
                            uri: `/workspace/${workspaceId}/teams`,
                            method: 'post',
                            headers: {
                                authorization: `Bearer ${token}`,
                            },
                            body: {
                                email: payload[id].email,
                                role: payload[id].role,
                            },
                        };
                    }),
                })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });
    }
    /**
     * @function patch
     * @definition Update team
     * @returns {Promise}
     */
    static patch(workspaceId, teamsId, token, payload) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'PATCH',
                url: '/workspace/' + workspaceId + '/teams/' + teamsId,
                headers: {
                    authorization: 'Bearer ' + token,
                },
                data: payload,
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    /**
     * @function delete
     * @definition Remove team
     * @returns {Promise}
     */
    static delete(workspaceId, teamsId, token, payload) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'DELETE',
                url: '/workspace/' + workspaceId + '/teams/' + teamsId,
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
