import {
    Container,
    Navbar,
} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Init from '../../util/Init';
import Logo from '../commun/Logo';
import { FiX, FiLoader } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import User from '../../util/User';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
export default () => {
    const { token } = useParams();
    const history = useHistory();
    let dispatch = useDispatch();
    const loading = () => {
        return (
            <div className="text-center">
                <h3 className="font-weight-800 my-5">
                    Connexion en cours
                    <br />
                </h3>
                <p>Veuillez patienter quelques instants</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <FiLoader size={36} className="rotating" />
            </div>
        );
    };
    const fail = (msg) => {
        return (
            <>
                <div className="text-center">
                    <h3 className="font-weight-800 my-5">
                        Echec de la connexion !
                        <br />
                    </h3>
                    <p>{msg}</p>
                    <p>Vous allez être redirigé automatiquement</p>
                    <p>&nbsp;</p>
                    <FiLoader size={36} className="rotating" />
                </div>
            </>
        );
    };
    const [content, setContent] = useState(loading());
    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            Axios({
                ...ApiCallConfig,
                method: 'GET',
                url: '/token',
                headers: {
                    authorization: 'Bearer ' + token,
                },
            }).then((res) => {
                if (res.status === 200) {
                    Axios({
                        ...ApiCallConfig,
                        method: 'GET',
                        url: '/user',
                        headers: {
                            authorization: 'Bearer ' + res.data.data.token,
                        },
                    }).then((user) => {
                        dispatch({
                            type: 'ADD_USER',
                            value: { ...user.data.data[0], workspace: [] },
                            token: res.data.data.token,
                        });
                        setTimeout(() => {
                            history.push('/dashboard');
                        }, 1000);
                    });
                } else if (res.status === 409) {
                    setContent(fail('Votre compte est déjà valide'));
                    setTimeout(() => {
                        history.push('/login');
                    }, 3000);
                } else {
                    setContent(fail('Le lien magique à expiré'));
                    setTimeout(() => {
                        history.push('/login');
                    }, 3000);
                }
            });
        }, 1000);
    }, []);
    return (
        <Container fluid>
            <Navbar className="fixed-top rankio-header" bg="white" expand="xl">
                <Link to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </Link>
                <Link to="/" className="ml-auto">
                    <FiX size="1.5em" className="rk-clickable-icon-dark" />{' '}
                </Link>
            </Navbar>
            <div className="m-auto py-5">{content}</div>
        </Container>
    );
};
