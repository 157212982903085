import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Alert from '../commun/Alert';
import { FiUpload, FiX, FiLayout, FiTrash2 } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';

export default (props) => {
    const [alertPhotoFailedShow, setAlertPhotoFailedShow] = useState(false);
    const [alertTimeout, setAlertTimeout] = useState(null);
    const handleShowAlert = (setAlertShow) => {
        setAlertShow(true);
        setAlertTimeout(
            setTimeout(() => {
                setAlertShow(false);
            }, 3000)
        );
    };
    let dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const { id } = useParams();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const show = props.show;
    const setShow = props.setShow;
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const hiddenFileInput = React.useRef(null);

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleChange = async (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            const base64 = await convertBase64(fileUploaded);
            Axios({
                ...ApiCallConfig,
                timeout: '10000',
                method: 'PATCH',
                url: '/workspace/' + props.workspaceId,
                headers: {
                    authorization: 'Bearer ' + activeUser[0].token,
                },
                data: { image: base64 },
            })
                .then((res) => {
                    if (res.status === 200) {
                        props.setNewImage(res.data.data.object.image);
                        let action = {
                            type: 'SET_WORKSPACE',
                            value: {
                                workspace: res.data.data,
                                id,
                                index: activeUser[0].activeWorkspace,
                            },
                        };
                        dispatch(action);
                        setShow(false);
                    }
                })
                .catch((error) => {
                    handleShowAlert(setAlertPhotoFailedShow);
                    setMessage(error);
                    setShow(false);
                });
        }
    };
    const onPhotoClick = (image, id) => {
        Axios({
            ...ApiCallConfig,
            timeout: '10000',
            method: 'PATCH',
            url: '/workspace/' + props.workspaceId,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
            data: { image },
        })
            .then((res) => {
                if (res.status === 200) {
                    props.setNewImage(res.data.data.object.image);
                    let action = {
                        type: 'SET_WORKSPACE',
                        value: {
                            workspace: res.data.data,
                            id,
                            index: activeUser[0].activeWorkspace,
                        },
                    };
                    dispatch(action);
                    setShow(false);
                } else {
                    setShow(false);
                }
            })
            .catch((error) => {
                handleShowAlert(setAlertPhotoFailedShow);
                setMessage(error);
                setShow(false);
            });
    };
    const removePhoto = async () => {
        Axios({
            ...ApiCallConfig,
            method: 'PATCH',
            url: '/workspace/' + props.workspaceId,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
            data: { image: 'null' },
        })
            .then((res) => {
                if (res.status == 200) {
                    setShow(false);
                    props.setNewImage(null);
                    let action = {
                        type: 'SET_WORKSPACE',
                        value: {
                            workspace: res.data.data,
                            id,
                            index: activeUser[0].activeWorkspace,
                        },
                    };
                    dispatch(action);
                } else {
                    setMessage(res);
                    setShow(false);
                }
            })
            .catch((error) => {
                setMessage(error);
                setShow(false);
            });
    };

    return (
        <>
            {props.type && props.type === 'workSpace' && (
                <div
                    className={
                        show
                            ? 'radius-14 card border-0 dropdown-menu-right w-500'
                            : 'd-none'
                    }
                    style={{
                        position: 'absolute',
                        top: '123px',
                        left: '16px',
                        zIndex: '2',
                    }}
                    id="workspace_photo_modal"
                >
                    <div className="bg-bg-gray rounded flex column">
                        <div
                            className="mt-3"
                            onClick={() => {
                                setShow(false);
                            }}
                            style={{ position: 'absolute', right: '16px' }}
                        >
                            <FiX
                                size="1.5em"
                                className="rk-clickable-icon-dark"
                            />
                        </div>
                        {/* <span className="display-16 font-weight-700 text-black cursor-pointer ml-20 mt-20">
                            Sélectionner une nouvelle icône
                        </span>
                        <div className="ml-20 mt-20">
                            <div className="mb-20 flex wrap w-308" >
                                {
                                    defaultImages.map((el, index) => {
                                        return <img src={el.base64} key={index} alt='image' className='profil-icon mr-20 mb- pointer' onClick={() => onPhotoClick(el.base64, el.id)} />
                                    }
                                    )}
                            </div>
                        </div> */}
                        <Button
                            className="bg-transparent border-0 no-outline p-0 mb-3 mt-5"
                            onClick={handleClick}
                        >
                            <Row className="v-center">
                                <Col sm={1} className="ml-3 mr-3">
                                    <FiUpload size="2em" color="#000000" />
                                </Col>
                                <Col
                                    sm="auto"
                                    className="display-16 font-weight-700 text-black cursor-pointer p-0"
                                >
                                    Charger une nouvelle photo
                                </Col>
                            </Row>
                        </Button>
                        <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            style={{ display: 'none' }}
                            accept="image/*"
                        />
                        <Button
                            className="bg-transparent border-0 no-outline p-0 mb-4"
                            onClick={() => removePhoto()}
                        >
                            <Row className="v-center">
                                <Col sm={1} className="ml-3 mr-3">
                                    <FiTrash2 size="2em" color="#000000" />
                                </Col>
                                <Col
                                    sm="auto"
                                    className="display-16 font-weight-700 text-black cursor-pointer p-0"
                                >
                                    Supprimer la photo actuelle
                                </Col>
                            </Row>
                        </Button>
                    </div>
                </div>
            )}

            <Alert
                color="danger"
                content="La photo de profil n'a pas pu être changée, veuillez réessayer."
                showAlert={alertPhotoFailedShow}
                setAlertShow={setAlertPhotoFailedShow}
                alertTimeout={alertTimeout}
            />
        </>
    );
};
