import RkTable from '../commun/RkTable';
import RkModal from '../commun/RkModal';
import Header from './Header';
import ModalDetails from '../project/ModalDetails';
import ModalAssignment from '../project/ModalAssignment';
import ModalTags from '../project/ModalTags';
import AddTeam from '../teams/Add';
import AddTags from '../tags/TagsBody';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import config from './Config';
import Project from '../../util/Project';
import Modal from '../modal/Modal';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Echo from '../../util/Echo';
import Unpaid from '../commun/Unpaid';
import IniteAccept from '../commun/IniteAccept';

export default function DashboardLanding() {
    const state = useSelector((state) => state.user);
    const users = state.users;
    const { trackPageView, trackEvent } = useMatomo();
    const { id } = useParams();
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = users.filter((user) => user.id.includes(id))[0].workspace[
        activeUser[0].activeWorkspace
    ];
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginationStates, setPaginationStates] = useState({
        ...config.pagination,
    });
    const [axisName, setAxisName] = useState(undefined);
    const [customConfig, setCustomConfig] = useState(config);
    const [optionsStates, setOptionsStates] = useState({ ...config.options });
    const [filterStates, setFilterStates] = useState({ ...config.filter });
    const [sortStates, setSortStates] = useState({ ...config.sort });
    const [selectedRow, setSelectedRow] = useState(null);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalArchive, setShowModalArchive] = useState(false);
    const handleOptionsState = (column) => {
        setOptionsStates(
            column === 'all'
                ? optionsStates.all
                    ? Object.keys(optionsStates).reduce((accumulator, key) => {
                          return { ...accumulator, [key]: false };
                      }, {})
                    : Object.keys(optionsStates).reduce((accumulator, key) => {
                          return { ...accumulator, [key]: true };
                      }, {})
                : {
                      ...optionsStates,
                      [column]: !optionsStates[column],
                      all:
                          Object.values({
                              ...optionsStates,
                              [column]: !optionsStates[column],
                              all: true,
                          }).filter((c) => !c).length === 0,
                  }
        );
    };
    const handleFilterState = (column, value) => {
        switch (customConfig.type[column]) {
            case 'string':
            case 'wsjf':
                setFilterStates({
                    ...filterStates,
                    [column]: value,
                });
                break;
            case 'enum':
            case 'status_list':
                setFilterStates({
                    ...filterStates,
                    [column]:
                        value === 'all'
                            ? filterStates[column].all
                                ? Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: false,
                                          };
                                      },
                                      {}
                                  )
                                : Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: true,
                                          };
                                      },
                                      {}
                                  )
                            : {
                                  ...filterStates[column],
                                  [value]: !filterStates[column][value],
                                  all:
                                      Object.values({
                                          ...filterStates[column],
                                          [value]: !filterStates[column][value],
                                          all: true,
                                      }).filter((c) => !c).length === 0,
                              },
                });
                break;
            case 'teams':
                setFilterStates({
                    ...filterStates,
                    [column]:
                        value === 'all'
                            ? filterStates[column].all.selected
                                ? Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: {
                                                  ...filterStates[column][key],
                                                  selected: false,
                                              },
                                          };
                                      },
                                      {}
                                  )
                                : Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: {
                                                  ...filterStates[column][key],
                                                  selected: true,
                                              },
                                          };
                                      },
                                      {}
                                  )
                            : {
                                  ...filterStates[column],
                                  [value]: {
                                      ...filterStates[column][value],
                                      selected:
                                          !filterStates[column][value].selected,
                                  },
                                  all: {
                                      selected:
                                          Object.values({
                                              ...filterStates[column],
                                              [value]:
                                                  !filterStates[column][value]
                                                      .selected,
                                              all: { selected: true },
                                          }).filter((c) => !c.selected)
                                              .length === 0,
                                  },
                              },
                });
                break;
            case 'tags':
                setFilterStates({
                    ...filterStates,
                    [column]:
                        value === 'all'
                            ? filterStates[column].all.selected
                                ? Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: {
                                                  ...filterStates[column][key],
                                                  selected: false,
                                              },
                                          };
                                      },
                                      {}
                                  )
                                : Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: {
                                                  ...filterStates[column][key],
                                                  selected: true,
                                              },
                                          };
                                      },
                                      {}
                                  )
                            : {
                                  ...filterStates[column],
                                  [value]: {
                                      ...filterStates[column][value],
                                      selected:
                                          !filterStates[column][value].selected,
                                  },
                                  all: {
                                      selected:
                                          Object.values({
                                              ...filterStates[column],
                                              [value]: {
                                                  ...filterStates[column][
                                                      value
                                                  ],
                                                  selected:
                                                      !filterStates[column][
                                                          value
                                                      ].selected,
                                              },
                                              all: { selected: true },
                                          }).filter((c) => !c.selected)
                                              .length === 0,
                                  },
                              },
                });
                break;
            default:
                console.log('undefined column', column, 'type');
        }
    };
    const handleSortState = (column, type) => {
        setSortStates(
            sortStates?.type === type && sortStates?.column === column
                ? { type: '', column: '' }
                : { type, column }
        );
    };
    const handleFilterReset = (column) => {
        switch (customConfig.type[column]) {
            case 'string':
                setFilterStates({
                    ...filterStates,
                    [column]: '',
                });
                break;
            case 'wsjf':
                setFilterStates({
                    ...filterStates,
                    [column]: { lt: '', gt: '' },
                });
                break;
            case 'enum':
            case 'status_list':
                setFilterStates({
                    ...filterStates,
                    [column]: Object.keys(filterStates[column]).reduce(
                        (accumulator, key) => {
                            return {
                                ...accumulator,
                                [key]: false,
                            };
                        },
                        {}
                    ),
                });
                break;
            case 'teams':
                setFilterStates({
                    ...filterStates,
                    [column]: Object.keys(filterStates[column]).reduce(
                        (accumulator, key) => {
                            return {
                                ...accumulator,
                                [key]: {
                                    ...filterStates[column][key],
                                    selected: false,
                                },
                            };
                        },
                        {}
                    ),
                });

                break;
            case 'tags':
                setFilterStates({
                    ...filterStates,
                    [column]: Object.keys(filterStates[column]).reduce(
                        (accumulator, key) => {
                            return {
                                ...accumulator,
                                [key]: {
                                    ...filterStates[column][key],
                                    selected: false,
                                },
                            };
                        },
                        {}
                    ),
                });
                break;
            default:
                console.log('undefined column', column, 'type');
        }
    };
    const getData = (next = false) => {
        if (axisName === undefined) {
            setTimeout(() => {
                return getData(next);
            }, 10);
        } else {
            setLoading(true);
            if (next) {
                if (paginationStates.end === undefined) {
                    setPaginationStates({
                        ...paginationStates,
                        skip: paginationStates.skip + paginationStates.limit,
                    });
                } else {
                    return;
                }
            } else {
                setPaginationStates({
                    ...config.pagination,
                });
            }
            let url = `workspace/${workspace.id}/project?limit=${
                paginationStates.limit
            }&skip=${paginationStates.skip}${
                sortStates.type !== '' && sortStates.column !== ''
                    ? '&' +
                      sortStates.type +
                      (customConfig.type[sortStates.column] === 'wsjf'
                          ? [
                                'wsjf',
                                'business_value',
                                'risk_opportunity',
                                'time_criticality',
                                'cost',
                                'effort',
                            ].includes(sortStates.column)
                              ? "=object->'rating'->'result'->>'" +
                                sortStates.column +
                                "'"
                              : "=object->'rating'->'result'->'business_value_axis'->>'" +
                                sortStates.column +
                                "'"
                          : "=object->>'" + sortStates.column + "'")
                    : ''
            }`;
            Object.keys(filterStates).forEach((column) => {
                switch (true) {
                    case customConfig.type[column] === 'string' &&
                        filterStates[column].length > 1:
                        url += `&object-%3E%3E'${column}'=%${filterStates[column]}%`;
                        break;
                    case customConfig.type[column] === 'wsjf':
                        if (filterStates[column].lt !== '') {
                            url += `&lt%20object-%3E'rating'-%3E'result'`;
                            if (
                                ![
                                    'wsjf',
                                    'business_value',
                                    'risk_opportunity',
                                    'time_criticality',
                                    'cost',
                                    'effort',
                                ].includes(column)
                            ) {
                                url += `-%3E'business_value_axis'`;
                            }
                            url += `-%3E%3E'${column}'=${filterStates[column].lt}`;
                        }
                        if (filterStates[column].gt !== '') {
                            url += `&gt%20object-%3E'rating'-%3E'result'`;
                            if (
                                ![
                                    'wsjf',
                                    'business_value',
                                    'risk_opportunity',
                                    'time_criticality',
                                    'cost',
                                    'effort',
                                ].includes(column)
                            ) {
                                url += `-%3E'business_value_axis'`;
                            }
                            url += `-%3E%3E'${column}'=${filterStates[column].gt}`;
                        }
                        break;
                    case customConfig.type[column] === 'enum':
                    case customConfig.type[column] === 'status_list':
                        Object.keys(filterStates[column]).forEach((box) => {
                            if (box !== 'all' && filterStates[column][box])
                                url += `&object-%3E%3E'${column}'=%${box}%`;
                        });
                        break;
                    case customConfig.type[column] === 'teams':
                        Object.keys(filterStates[column]).forEach((box) => {
                            if (
                                box !== 'all' &&
                                filterStates[column][box].selected
                            )
                                url += `&object-%3E%3E'${column}'=%25%22${box}%22%25`;
                        });
                    case customConfig.type[column] === 'tags':
                        Object.keys(filterStates[column]).forEach((box) => {
                            if (
                                box !== 'all' &&
                                filterStates[column][box].selected
                            )
                                url += `&object-%3E%3E'${column}'=%25%22${box}%22%25`;
                        });
                        break;
                    default:
                        break;
                }
            });
            Axios({
                ...ApiCallConfig,
                method: 'GET',
                url: url,
                headers: {
                    authorization: 'Bearer ' + activeUser[0].token,
                },
            })
                .then((res) => {
                    setLoading(false);
                    switch (true) {
                        case res.status === 200:
                            if (next) {
                                setData([...data, ...res.data.data]);
                            } else {
                                setData(res.data.data);
                            }
                            if (res.data.count <= res.data.skip) {
                                setPaginationStates({
                                    ...customConfig.pagination,
                                    end: true,
                                });
                            }
                            break;
                        default:
                            setData([]);
                            break;
                    }
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    };
    const updateProject = (projectId, payload) => {
        setLoading(true);
        Project.patch(workspace.id, projectId, activeUser[0].token, payload)
            .then((res) => {
                if (res.status === 200) {
                    getData();
                    setSelectedRow(res.data.data);
                } else {
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    const archiveProject = (projectId, commit = false) => {
        if (commit) {
            setLoading(true);
            Project.patch(workspace.id, projectId, activeUser[0].token, {
                status: 'archived',
            })
                .then((res) => {
                    getData();
                    setSelectedRow(res.data.data);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else {
            setShowModalArchive(true);
            setSelectedRow({ ...selectedRow, id: projectId });
        }
    };
    const deleteProject = (projectId, commit = false) => {
        if (commit) {
            setLoading(true);
            Project.delete(workspace.id, projectId, activeUser[0].token)
                .then((res) => {
                    getData();
                    setSelectedRow(res.data.data);
                    document.getElementById(
                        'view_details_project'
                    ).style.display = 'none';
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else {
            setShowModalDelete(true);
            setSelectedRow({ ...selectedRow, id: projectId });
        }
    };
    useEffect(() => {
        setCustomConfig({
            options: {
                all: false,
                code: true,
                name: true,
                status: true,
                wsjf: true,
                business_value: true,
                ...(workspace.object.wsjf.business_value.axis.filter(
                    (ax) => ax.active
                ).length &&
                    workspace.object.wsjf.business_value.axis
                        .filter((ax) => ax.active)
                        .reduce((map, obj) => {
                            map[obj.ref] = true;
                            return map;
                        }, {})),
                ...(workspace.object.wsjf.time_criticality.active && {
                    time_criticality: true,
                }),
                ...(workspace.object.wsjf.risk_opportunity.active && {
                    risk_opportunity: true,
                }),
                cost: true,
                effort: true,
                assignation: true,
                tags: true,
                end_prioritizing: false,
            },
            sizes: {
                code: 8,
                name: 14,
                status: 10,
                wsjf: 8,
                business_value: 8,
                ...(workspace.object.wsjf.business_value.axis.filter(
                    (ax) => ax.active
                ).length &&
                    workspace.object.wsjf.business_value.axis
                        .filter((ax) => ax.active)
                        .reduce((map, obj) => {
                            map[obj.ref] = 8;
                            return map;
                        }, {})),
                ...(workspace.object.wsjf.time_criticality.active && {
                    time_criticality: 8,
                }),
                ...(workspace.object.wsjf.risk_opportunity.active && {
                    risk_opportunity: 12,
                }),
                cost: 10,
                effort: 8,
                assignation: 14,
                tags: 14,
                end_prioritizing: 12,
            },
            pagination: { limit: 500, skip: 0 },
            sort: { type: '', column: '' },
            filter: {
                code: '',
                name: '',
                status: {
                    all: false,
                    to_prioritize: true,
                    planned: true,
                    prioritized: true,
                    buffer: true,
                    analyzing: true,
                    approved: true,
                    backlog: true,
                    in_progress: true,
                    done: false,
                    stand_by: true,
                    aborted: false,
                    archived: false,
                },
                wsjf: { lt: '', gt: '' },
                business_value: { lt: '', gt: '' },
                ...(workspace.object.wsjf.business_value.axis.filter(
                    (ax) => ax.active
                ).length &&
                    workspace.object.wsjf.business_value.axis
                        .filter((ax) => ax.active)
                        .reduce((map, obj) => {
                            map[obj.ref] = { lt: '', gt: '' };
                            return map;
                        }, {})),
                ...(workspace.object.wsjf.time_criticality.active && {
                    time_criticality: { lt: '', gt: '' },
                }),
                ...(workspace.object.wsjf.risk_opportunity.active && {
                    risk_opportunity: { lt: '', gt: '' },
                }),
                cost: { lt: '', gt: '' },
                effort: { lt: '', gt: '' },
                assignation: {
                    all: { selected: false },
                },
                tags: {
                    all: { selected: false },
                },
                end_prioritizing: { lt: '', gt: '' },
            },
            type: {
                code: 'string',
                name: 'string',
                status: 'status_list',
                wsjf: 'wsjf',
                business_value: 'wsjf',
                ...(workspace.object.wsjf.business_value.axis.filter(
                    (ax) => ax.active
                ).length &&
                    workspace.object.wsjf.business_value.axis
                        .filter((ax) => ax.active)
                        .reduce((map, obj) => {
                            map[obj.ref] = 'wsjf';
                            return map;
                        }, {})),
                ...(workspace.object.wsjf.time_criticality.active && {
                    time_criticality: 'wsjf',
                }),
                ...(workspace.object.wsjf.risk_opportunity.active && {
                    risk_opportunity: 'wsjf',
                }),
                cost: 'wsjf',
                effort: 'wsjf',
                assignation: 'teams',
                tags: 'tags',
                end_prioritizing: 'timestamp',
            },
        });
        setAxisName({
            ...(workspace.object.wsjf.business_value.axis.filter(
                (ax) => ax.active
            ).length &&
                workspace.object.wsjf.business_value.axis
                    .filter((ax) => ax.active)
                    .reduce((map, obj) => {
                        // map[obj.ref] = obj.name;
                        map[obj.ref] = obj.code;
                        return map;
                    }, {})),
        });
        setOptionsStates({
            all: false,
            code: true,
            name: true,
            status: true,
            wsjf: true,
            business_value: true,
            ...(workspace.object.wsjf.business_value.axis.filter(
                (ax) => ax.active
            ).length &&
                workspace.object.wsjf.business_value.axis
                    .filter((ax) => ax.active)
                    .reduce((map, obj) => {
                        map[obj.ref] = true;
                        return map;
                    }, {})),
            ...(workspace.object.wsjf.time_criticality.active && {
                time_criticality: true,
            }),
            ...(workspace.object.wsjf.risk_opportunity.active && {
                risk_opportunity: true,
            }),
            cost: true,
            effort: true,
            assignation: true,
            tags: true,
            end_prioritizing: false,
        });

        setTimeout(() => {
            Axios({
                ...ApiCallConfig,
                method: 'POST',
                url: `/batch`,
                data: [
                    {
                        id: 'tags',
                        method: 'get',
                        uri: `/workspace/${workspace.id}/tags`,
                        headers: {
                            authorization: `Bearer ${activeUser[0].token}`,
                        },
                    },
                    {
                        id: 'assignation',
                        method: 'get',
                        uri: `/workspace/${workspace.id}/teams`,
                        headers: {
                            authorization: `Bearer ${activeUser[0].token}`,
                        },
                    },
                ],
            })
                .then((res) => {
                    if (res.status === 200) {
                        const result = {
                            code: '',
                            name: '',
                            status: {
                                all: false,
                                to_prioritize: true,
                                planned: true,
                                prioritized: true,
                                buffer: true,
                                analyzing: true,
                                approved: true,
                                backlog: true,
                                in_progress: true,
                                done: false,
                                stand_by: true,
                                aborted: false,
                                archived: false,
                            },
                            wsjf: { lt: '', gt: '' },
                            business_value: { lt: '', gt: '' },
                            ...(workspace.object.wsjf.business_value.axis.filter(
                                (ax) => ax.active
                            ).length &&
                                workspace.object.wsjf.business_value.axis
                                    .filter((ax) => ax.active)
                                    .reduce((map, obj) => {
                                        map[obj.ref] = { lt: '', gt: '' };
                                        return map;
                                    }, {})),
                            ...(workspace.object.wsjf.time_criticality
                                .active && {
                                time_criticality: { lt: '', gt: '' },
                            }),
                            ...(workspace.object.wsjf.risk_opportunity
                                .active && {
                                risk_opportunity: { lt: '', gt: '' },
                            }),
                            cost: { lt: '', gt: '' },
                            effort: { lt: '', gt: '' },
                            assignation: {
                                all: { selected: false },
                            },
                            tags: {
                                all: { selected: false },
                            },
                            end_prioritizing: { lt: '', gt: '' },
                        };
                        switch (true) {
                            case res.data.responses.tags.status === 200:
                                result.tags =
                                    res.data.responses.tags.data.reduce(
                                        (accumulator, key) => {
                                            return {
                                                ...accumulator,
                                                [key.ref]: {
                                                    data: { ...key },
                                                    selected: false,
                                                },
                                            };
                                        },
                                        {}
                                    );
                            case res.data.responses.assignation.status === 200:
                                result.assignation =
                                    res.data.responses.assignation.data.reduce(
                                        (accumulator, key) => {
                                            return {
                                                ...accumulator,
                                                [key.id]: {
                                                    data: key.object,
                                                    selected: false,
                                                },
                                            };
                                        },
                                        {}
                                    );
                            default:
                                setFilterStates({ ...result });
                        }
                    }
                })
                .catch((error) => {});
        }, 0);
    }, []);
    useEffect(getData, [
        sortStates,
        filterStates,
        activeUser[0].activeWorkspace,
    ]);
    useEffect(() => {
        document.title = Echo(state.lang).seo.title.dashboard
            ? Echo(state.lang).seo.title.prefix +
              workspace.object.name +
              ' - ' +
              Echo(state.lang).seo.title.dashboard +
              Echo(state.lang).seo.title.suffix
            : Echo(state.lang).seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo(state.lang).seo.title.default;
        };
    }, [activeUser[0].activeWorkspace, state.lang]);
    const showProject = (data = undefined) => {
        setSelectedRow(data);
        document.getElementById('view_details_project').style.display = 'block';
    };
    switch (true) {
        case workspace.object.status === 'unpaid':
            return <Unpaid />;
        case workspace.object.teams.on.filter(
            (t) => t.id === activeUser[0].id
        )[0].invit !== undefined:
            return <IniteAccept veiw="dashboard"/>;
        default:
            return (
                <>
                    <ModalDetails
                        data={selectedRow}
                        setData={(r) => showProject(r)}
                        getData={getData}
                        loading={loading}
                        setLoading={(s) => setLoading(s)}
                        filterStates={filterStates}
                        update={(id, payload) => updateProject(id, payload)}
                        archive={(id) => archiveProject(id)}
                        delete={(id) => deleteProject(id)}
                    />
                    <ModalAssignment
                        data={selectedRow}
                        getData={getData}
                        loading={loading}
                        setLoading={(s) => setLoading(s)}
                        filterStates={filterStates}
                        update={(id, payload) => updateProject(id, payload)}
                    />
                    <ModalTags
                        data={selectedRow}
                        getData={getData}
                        loading={loading}
                        setLoading={(s) => setLoading(s)}
                        filterStates={filterStates}
                        update={(id, payload) => updateProject(id, payload)}
                    />
                    <AddTeam level={3} getData={getData} />
                    <RkModal
                        id="view_tags_manager"
                        title="Gestion des tags"
                        className="card rk-modal-level-3 m-2"
                    >
                        <div className="rk-modal-body-level-3">
                            <AddTags getData={getData} />
                        </div>
                    </RkModal>
                    <Header
                        data={data}
                        addRow={(r) => showProject(r)}
                        loading={loading}
                        config={customConfig}
                        echo={axisName}
                        optionsStates={optionsStates}
                        handleOptionsState={(column) =>
                            handleOptionsState(column)
                        }
                    />
                    <RkTable
                        resource="project"
                        config={customConfig}
                        echo={axisName}
                        data={data}
                        getData={getData}
                        loading={loading}
                        setLoading={(s) => setLoading(s)}
                        selectedRow={(r) => showProject(r)}
                        optionsStates={optionsStates}
                        filterStates={filterStates}
                        sortStates={sortStates}
                        handleFilterState={(column, value) =>
                            handleFilterState(column, value)
                        }
                        handleFilterReset={(column) =>
                            handleFilterReset(column)
                        }
                        handleSortState={(column, type) =>
                            handleSortState(column, type)
                        }
                    />
                    <Modal
                        show={showModalDelete}
                        centered={true}
                        modalFirstText="Etes-vous certain de vouloir supprimer ce projet ?"
                        modalSecondText="Cette action est irréversible."
                        modalThirdText=""
                        modalContainerClassname="axeModalContainerClassname"
                        modalContentClassName="axeModalContentClassName"
                        closeIcon="axeModalcloseIcon cursorPointer"
                        containerTextWithIconClassname="axesContainerTextWithIconClassname"
                        alertIconClassName="axesAlertIconClassName"
                        firstBtnClassName="axeModalFirstBtn"
                        secondBtnClassName="axeModalSecondBtn"
                        setShowModalFunction={() => setShowModalDelete(false)}
                        textClassName="axeModalTextClassName"
                        saveBtnFunction={() =>
                            deleteProject(selectedRow.id, true)
                        }
                    />
                    <Modal
                        show={showModalArchive}
                        centered={true}
                        modalFirstText="Etes-vous certain de vouloir archiver ce projet ?"
                        modalSecondText="Il disparaîtra bien de votre liste de projets par défaut,"
                        modalThirdText='mais restera accessible en filtrant sur le statut "Archivé".'
                        modalContainerClassname="axeModalContainerClassname"
                        modalContentClassName="axeModalContentClassName"
                        closeIcon="axeModalcloseIcon cursorPointer"
                        containerTextWithIconClassname="axesContainerTextWithIconClassname"
                        alertIconClassName="axesAlertIconClassName"
                        firstBtnClassName="axeModalFirstBtn"
                        secondBtnClassName="axeModalSecondBtn"
                        setShowModalFunction={() => setShowModalArchive(false)}
                        textClassName="axeModalTextClassName"
                        saveBtnFunction={() =>
                            archiveProject(selectedRow.id, true)
                        }
                    />
                </>
            );
    }
}
