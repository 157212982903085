import { useEffect } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import Logo from '../commun/Logo';
import { FiX, FiLoader } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Echo from '../../util/Echo';
export default () => {
    const { id } = useParams();
    const history = useHistory();
    const users = useSelector((state) => state.user.users);
    let activeUser = users.filter((user) => user.id.includes(id));
    const dispatch = useDispatch();
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        document.title = Echo().seo.title.logout
            ? Echo().seo.title.prefix +
              Echo().seo.title.logout +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        setTimeout(() => {
            let action = { type: 'LOGOUT', value: id };
            dispatch(action);
            history.push('/login');
        }, 2000);
        window.scrollTo(0, 0);
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, []);
    return (
        <Container fluid>
            <Navbar className="fixed-top rankio-header" bg="white" expand="xl">
                <Link to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </Link>
                <Link to="/" className="ml-auto">
                    <FiX size="1.5em" className="rk-clickable-icon-dark" />
                </Link>
            </Navbar>
            <div className="w-75 m-auto py-5">
                <div className="text-center">
                    <h3 className="font-weight-800 my-5">
                        Déconnexion en cours
                        <br />
                    </h3>
                    <p>Veuillez patienter quelques instants</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <FiLoader size={36} className="rotating" />
                </div>
            </div>
        </Container>
    );
};
