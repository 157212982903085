import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import BlueButton from '../commun/BlueButton';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import { FiLoader, FiCheckCircle, FiInfo } from 'react-icons/fi';
export default (props) => {
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspaces = activeUser[0].workspace;
    const workspace = workspaces[activeUser[0].activeWorkspace];
    const [loading, setLoading] = useState(false);
    const { trackEvent } = useMatomo();
    const accept = () => {
        Axios({
            ...ApiCallConfig,
            method: 'GET',
            url: '/accept/' + workspace.id,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    trackEvent({
                        category: 'workspace',
                        action: 'accept',
                        name: "j'accepte l'invitation",
                        value: 0,
                    });
                }
                switch (res.status) {
                    case 200:
                        trackEvent({
                            category: 'workspace',
                            action: 'accept',
                            name: "j'accepte l'invitation",
                            value: 1,
                        });
                        // reload app to update workspace cache
                        // redirect to nextWorkspace
                        dispatch(
                            {
                                type: 'SET_ACTIVE_WORKSPACE',
                                value: workspace.id,
                            },
                            window.location.reload()
                        );
                        break;
                    case 409:
                        // reload app to update workspace cache
                        dispatch(
                            {
                                type: 'SET_ACTIVE_WORKSPACE',
                                value: workspace.id,
                            },
                            window.location.reload()
                        );
                        break;
                    case 401:
                    case 402:
                    case 403:
                    case 404:
                        history.push('/error/403');
                        break;
                    default:
                        history.push('/error/500');
                }
            })
            .catch((e) => {
                trackEvent({
                    category: 'workspace',
                    action: 'accept',
                    name: "J'accepte l'invitation",
                    value: 0,
                });
                history.push('/error/500');
            });
    };
    const ignore = async () => {
        Axios({
            ...ApiCallConfig,
            method: 'GET',
            url: '/accept/' + workspace.id,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
                action: 'ignore',
            },
        })
            .then((res) => {
                switch (res.status) {
                    case 200:
                    case 409:
                        trackEvent({
                            category: 'workspace',
                            action: 'accept',
                            name: 'Ignorer, accepter plus tard',
                            value: 0,
                        });
                        // select an other workspace as nextWorkspace
                        const nextWorkspace = workspaces.filter(
                            (w) =>
                                w.id !== workspace.id &&
                                w.object.teams.on.filter(
                                    (t) =>
                                        t.id === activeUser[0].id && !t.ignored
                                ).length === 1
                        );
                        if (nextWorkspace.length) {
                            // redirect to nextWorkspace
                            dispatch({
                                type: 'SET_ACTIVE_WORKSPACE',
                                value: nextWorkspace[0].id,
                            });
                        } else {
                            // no nextWorkspace so redirect to landing page
                            history.push('/');
                        }
                        break;
                    case 401:
                    case 402:
                    case 403:
                    case 404:
                        history.push('/error/403');
                        break;
                    default:
                        history.push('/error/500');
                }
            })
            .catch((e) => {
                history.push('/error/500');
            });
    };
    const cancel = () => {
        trackEvent({
            category: 'workspace',
            action: 'accept',
            name: "Je décline l'invitation",
            value: 0,
        });
        history.push('/');
    };
    return (
        <>
            <div className="card text-black m-2 pb-2">
                <Row className="no-gutters bg-black rounded-top">
                    <div className="font-weight-bold display-24 py-3 ml-3 text-uppercase session-details-title">
                        Workspace {workspace.object.name}
                    </div>
                </Row>
                <div className="sesion-details-big-container">
                    <div
                        className={
                            (loading ? 'd-block' : 'd-none') +
                            ' text-center py-5'
                        }
                    >
                        <FiLoader size={36} className="rotating" />
                    </div>
                    <div
                        className={
                            (loading ? 'd-none' : 'd-block') + ' text-center'
                        }
                    >
                        <p className="font-weight-bold display-18 pt-5">
                            Vous êtes invité à rejoindre le workspace{' '}
                            {workspace.object.name}
                        </p>
                        <p className="py-5">
                            <BlueButton
                                content={"J'accepte l'invitation"}
                                icon={<FiCheckCircle size="2.2rem" />}
                                onClick={accept}
                            />
                            <br />
                            <div className="mt-3">
                                <a
                                    className="text-dark pointer font-weight-bold display-16"
                                    onClick={ignore}
                                >
                                    Je regarderai ça plus tard
                                </a>
                            </div>
                        </p>
                        {/*<p>
                            Si vous pensez qu'il s'agit d'une erreur, veuillez
                            décliner l'invitation.
                        </p>
                        <Button
                            variant="danger"
                            className="btn my-4"
                            onClick={cancel}
                        >
                            <span className="font-weight-600 display-16">
                                Je décline l'invitation
                            </span>
                        </Button>*/}
                    </div>
                </div>
            </div>
        </>
    );
};
