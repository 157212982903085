import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as ScrollLink } from 'react-scroll';
import { Button, Row, Col } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Config from '../../util/Config';
import User from '../../util/User';
import { FiClipboard, FiUsers, FiSliders } from 'react-icons/fi';
import Echo from '../../util/Echo';
export default (props) => {
    const history = useHistory();
    const state = useSelector((state) => state.user);
    const users = state.users;
    const activeIndex = useSelector((state) => state.user.activeUserIndex);
    const activeUser = users[activeIndex];
    const [testCondition, setTestCondition] = useState('signup');
    const dispatch = useDispatch();
    useEffect(() => {
        if (props.testCondition) {
            setTestCondition(props.testCondition);
        } else if (activeUser && activeUser.id) {
            User.headTest(activeUser.token)
                .then((res) => {
                    switch (res.status) {
                        case 403:
                            dispatch(
                                {
                                    type: 'LOGOUT',
                                    value: activeUser.id,
                                },
                                history.push('/login')
                            );
                            break;
                        case 402:
                            setTestCondition('buy');
                            break;
                        case 200:
                            setTestCondition('test');
                            break;
                        default:
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                    }
                })
                .catch((err) => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                });
        } else {
            setTestCondition('signup');
        }
    }, []);
    return (
        <div className="mt-5 p-3">
            <h2 className="font-weight-900 text-center mt-4 mb-5">
                {Echo(state.lang).features.title}
            </h2>
            <Row className="mx-0 mb-5 h-center">
                <Col sm={10} md={8} lg={4}>
                    <FiClipboard
                        size="2.2rem"
                        className="text-primary mt-5 mb-3"
                    />
                    <h3 className="font-weight-800 display-24 mb-3">
                        {Echo(state.lang).features.sub_title1}
                    </h3>
                    <p className="font-weight-600">
                        {Echo(state.lang).features.descr1}
                        <br></br>
                        <br></br>
                        {Echo(state.lang).features.descr1bis}
                    </p>
                </Col>
                <Col lg={1} className="d-none d-lg-block"></Col>
                <Col sm={10} md={8} lg={4}>
                    <a
                        href={
                            Config.CDN.ENDPOINT + 'static/media/dashboard.jpg'
                        }
                        target="_blank"
                        title="Une liste de projets priorisés"
                    >
                        <img
                            className="card w-100 p-3 mt-5"
                            src={
                                Config.CDN.ENDPOINT +
                                'static/media/dashboard.jpg'
                            }
                            alt="Consulter le dashboard listant les projets priorisés"
                        />
                    </a>
                </Col>
            </Row>
            <Row className="mx-0 mb-5 h-center">
                <Col sm={10} md={8} lg={4} className="d-none d-lg-block">
                    <a
                        href={
                            Config.CDN.ENDPOINT +
                            'static/media/live_project_score.jpg'
                        }
                        target="_blank"
                        title="Un scoring collaboratif"
                    >
                        <img
                            className="card w-100 p-3 mt-5"
                            src={
                                Config.CDN.ENDPOINT +
                                'static/media/live_project_score.jpg'
                            }
                            alt="Utiliser l'intelligence collective pour scorer les projets"
                        />
                    </a>
                </Col>
                <Col lg={1} className="d-none d-lg-block"></Col>
                <Col sm={10} md={8} lg={4}>
                    <FiUsers size="2.2rem" className="text-primary mt-5 mb-3" />
                    <h3 className="font-weight-800 display-24 mb-3">
                        {Echo(state.lang).features.sub_title2}
                    </h3>
                    <p className="font-weight-600">
                        {Echo(state.lang).features.descr2}
                        <br></br>
                        <br></br>
                        {Echo(state.lang).features.descr2bis}
                    </p>
                </Col>
                <Col sm={10} md={8} lg={4} className="d-lg-none d-block">
                    <a
                        href={
                            Config.CDN.ENDPOINT +
                            'static/media/live_project_score.jpg'
                        }
                        target="_blank"
                        title="Un scoring collaboratif"
                    >
                        <img
                            className="card w-100 p-3 mt-5"
                            src={
                                Config.CDN.ENDPOINT +
                                'static/media/live_project_score.jpg'
                            }
                            alt="Utiliser l'intelligence collective pour scorer les projets"
                        />
                    </a>
                </Col>
            </Row>
            <Row className="mx-0 mb-5 h-center">
                <Col sm={10} md={8} lg={4}>
                    <FiSliders
                        size="2.2rem"
                        className="text-primary mt-5 mb-3"
                    />
                    <h3 className="font-weight-800 display-24 mb-3">
                        {Echo(state.lang).features.sub_title3}
                    </h3>
                    <p className="font-weight-600">
                        {Echo(state.lang).features.descr3}
                        <br></br>
                        <br></br>
                        {Echo(state.lang).features.descr3bis}
                    </p>
                </Col>
                <Col lg={1} className="d-none d-lg-block"></Col>
                <Col sm={10} md={8} lg={4}>
                    <a
                        href={
                            Config.CDN.ENDPOINT +
                            'static/media/drives_setup.jpg'
                        }
                        target="_blank"
                        title="La personnalisation de vos axes stratégiques"
                    >
                        <img
                            className="card w-100 p-3 mt-5"
                            src={
                                Config.CDN.ENDPOINT +
                                'static/media/drives_setup.jpg'
                            }
                            alt="Personnaliser vos axes stratégiques"
                        />
                    </a>
                </Col>
            </Row>
            <div className="text-center">
                {testCondition === 'buy' ? (
                    <ScrollLink
                        activeClass="active"
                        to="prices"
                        spy={true}
                        smooth={true}
                        duration={500}
                    >
                        <Button
                            variant="primary"
                            style={{
                                height: '5rem',
                                maxWidth: '340px',
                                minWidth: '240px',
                            }}
                            className="btn my-4 mx-auto"
                            type="submit"
                        >
                            <span className="font-weight-800 display-24 px-3">
                                Achetez
                            </span>
                        </Button>
                    </ScrollLink>
                ) : (
                    <Link
                        className="mx-auto"
                        to={
                            testCondition === 'test'
                                ? '/onboarding/reset'
                                : '/signup'
                        }
                    >
                        <Button
                            variant="primary"
                            style={{
                                height: '5rem',
                                maxWidth: '340px',
                                minWidth: '240px',
                            }}
                            className="btn my-4"
                        >
                            <span className="font-weight-800 display-24 px-3">
                                Testez gratuitement pendant 14 jours
                            </span>
                        </Button>
                    </Link>
                )}
            </div>
        </div>
    );
};
