import Modal from '../commun/RkModal';
import { Form, Row, Col } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import Validator from '../../util/Validator';
import Alert from '../commun/Alert';
import { useState, useEffect } from 'react';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import { useSelector, useDispatch } from 'react-redux';
import { FiX, FiMail } from 'react-icons/fi';
import BlueButton from '../commun/BlueButton';
import GreyButton from '../commun/GreyButton';

export default () => {
    const history = useHistory();
    const { id } = useParams();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const [payload, setPayload] = useState({ email: '' });
    const [showAlert, setShowAlert] = useState(false);
    const [alertTimeout, setAlertTimeout] = useState(null);
    const dispatch = useDispatch();
    const handleShowAlert = (setAlertShow) => {
        setAlertShow(true);
        setAlertTimeout(
            setTimeout(() => {
                setAlertShow(false);
            }, 3000)
        );
    };

    const handleChange = (event) => {
        setPayload({ ...payload, [event.target.name]: event.target.value });
        if (Object.keys(errors).length !== 0 || errors.constructor !== Object) {
            const e = Validator({
                ...payload,
                [event.target.name]: event.target.value,
            });
            if (e) setErrors(e);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const e = Validator(payload);
        if (Object.keys(e).length !== 0 || e.constructor !== Object) {
            setErrors(e);
        } else {
            Axios({
                ...ApiCallConfig,
                method: 'PATCH',
                url: '/user',
                headers: {
                    authorization: 'Bearer ' + activeUser[0].token,
                },
                data: { email: payload.email },
            })
                .then((result) => {
                    const expire = Date.now() + 3600 * 1000;
                    dispatch({
                        type: 'SET_EMAIL_EXPIRY',
                        value: { email: payload.email, expire },
                    });
                    handleShowAlert(setShowAlert);
                    history.push('/validate');
                })
                .catch((error) => {
                    setMessage(error);
                });
        }
    };
    return (
        <>
            <Modal
                id="profil_change_email"
                title="Changement de mail"
                className="card m-0 h-auto w-100"
            >
                <div className="h-100-330 p-0 m-0">
                    <Row>
                        <Col className="pb-5">
                            <div className="ml-3 mt-4">
                                <span className="descr-label">
                                    Pour rappel, votre mail actuel est&nbsp;:
                                </span>
                                <span className="highlight-label">
                                    {activeUser[0].email}
                                </span>
                            </div>
                            <Form
                                noValidate
                                onSubmit={handleSubmit}
                                className="ml-3 mt-4 pt-3"
                            >
                                <Form.Group controlId="email">
                                    <Form.Label className="action-label pb-2">
                                        Indiquez votre nouveau mail
                                    </Form.Label>
                                    <Form.Control
                                        name="email"
                                        type="email"
                                        placeholder="Mail..."
                                        value={payload.email}
                                        className="input"
                                        onChange={handleChange}
                                        style={{
                                            maxWidth: '50ch',
                                        }}
                                    />
                                    <Form.Text className="mt-2 ml-2 text-danger font-weight-700">
                                        {errors.email}
                                    </Form.Text>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col
                            lg={'auto'}
                            className="sticky-fixed flex items-end column axe-priorisation-btn-container pr-4 pt-4"
                        >
                            <BlueButton
                                content={
                                    <>
                                        Modifier le<br></br>mail
                                    </>
                                }
                                icon={<FiMail size="2.2rem" />}
                                btnClassnameDetails="mt-0"
                                onClick={handleSubmit}
                            />
                            <GreyButton
                                content="Annuler"
                                icon={<FiX size="2.2rem" />}
                                onClick={() => {
                                    document.getElementById(
                                        'profil_change_email'
                                    ).style.display = 'none';
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>
            <Alert
                color="primary"
                content="Le mail a bien été modifié !"
                showAlert={showAlert}
                setAlertShow={setShowAlert}
                alertTimeout={alertTimeout}
            />
        </>
    );
};
