import { Container, Navbar } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Logo from '../commun/Logo';
import { FiX, FiLoader } from 'react-icons/fi';
import { useSelector, useDispatch } from 'react-redux';
import Workspace from '../../util/Workspace';
import Token from '../../util/Token';
import User from '../../util/User';
export default () => {
    const { token } = useParams();
    const history = useHistory();
    let dispatch = useDispatch();
    const users = useSelector((state) => state.user.users);

    const loading = () => {
        return (
            <div className="text-center">
                <h3 className="font-weight-800 my-5">
                    Validation en cours
                    <br />
                </h3>
                <p>Veuillez patienter quelques instants</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <FiLoader size={36} className="rotating" />
            </div>
        );
    };
    const fail = (msg) => {
        return (
            <>
                <div className="text-center">
                    <h3 className="font-weight-800 my-5">
                        Echec de la validation !
                        <br />
                    </h3>
                    <p>{msg}</p>
                    <p>Vous allez être redirigé automatiquement</p>
                    <p>&nbsp;</p>
                    <FiLoader size={36} className="rotating" />
                </div>
            </>
        );
    };
    const [content, setContent] = useState(loading());
    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            // check token validity
            Token.get(token)
                .then((data) => {
                    const payload = jwt_decode(token);
                    if (!payload?.workspace || !payload?.id) {
                        setContent(fail('Le lien magique est invalide'));
                    } else {
                        // update token
                        dispatch({
                            type: 'SET_USER_TOKEN',
                            value: data,
                        });
                        User.get({}, data.token)
                            .then((user) => {
                                switch (user.status) {
                                    case 200:
                                        if (
                                            users.filter((user) =>
                                                user.id.includes(
                                                    data.id.split('-')[0]
                                                )
                                            ).length === 0
                                        ) {
                                            dispatch({
                                                type: 'ADD_USER',
                                                value: {
                                                    ...user.data.data[0],
                                                    workspace: [],
                                                },
                                                token: data.token,
                                            });
                                        } else {
                                            dispatch({
                                                type: 'UPDATE_USER',
                                                value: {
                                                    ...user.data.data[0],
                                                },
                                            });
                                        }
                                        Workspace.get({}, data.token)
                                            .then((workspace) => {
                                                switch (workspace.status) {
                                                    case 200:
                                                        dispatch(
                                                            {
                                                                type: 'SET_ACTIVE_USER_INDEX',
                                                                value: {
                                                                    id: payload.id.split(
                                                                        '-'
                                                                    )[0],
                                                                },
                                                            },
                                                            dispatch(
                                                                {
                                                                    type: 'UPDATE_WORKSPACE_LIST',
                                                                    value: {
                                                                        workspace:
                                                                            workspace
                                                                                .data
                                                                                .data,
                                                                        id: user
                                                                            .data
                                                                            .data[0]
                                                                            .id,
                                                                    },
                                                                },
                                                                workspace.data.data.filter(
                                                                    (w) =>
                                                                        w.id ===
                                                                        payload.workspace
                                                                ).length
                                                                    ? dispatch(
                                                                          {
                                                                              type: 'SET_ACTIVE_WORKSPACE',
                                                                              value: payload.workspace,
                                                                          },
                                                                          history.push(
                                                                              '/dashboard'
                                                                          )
                                                                      )
                                                                    : history.push(
                                                                          '/error/403'
                                                                      )
                                                            )
                                                        );
                                                        break;
                                                    default:
                                                        history.push(
                                                            '/error/' +
                                                                workspace.status
                                                        );
                                                }
                                            })
                                            .catch(() => {
                                                history.push('/error/400');
                                            });
                                        break;
                                    case 404:
                                        history.push('/signup');
                                        break;
                                    default:
                                        history.push('/error/' + user.status);
                                }
                            })
                            .catch(() => {
                                history.push('/error/400');
                            });
                    }
                })
                .catch(() => {
                    setContent(fail('Le lien magique a expiré'));
                });
        }, 1000);
    }, []);
    return (
        <Container fluid>
            <Navbar className="fixed-top rankio-header" bg="white" expand="xl">
                <Link to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </Link>
                <Link to="/" className="ml-auto">
                    <FiX size="1.5em" className="rk-clickable-icon-dark" />{' '}
                </Link>
            </Navbar>
            <div className="m-auto py-5">{content}</div>
        </Container>
    );
};
