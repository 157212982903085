import React, { useState, useEffect } from 'react';
import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Modal from '../commun/RkModal';
import BlueButton from '../commun/BlueButton';
import GreyButton from '../commun/GreyButton';
import DarkButton from '../commun/DarkButton';
import TagButton from '../commun/TagButton';
import {
    FiInfo,
    FiPlus,
    FiPlusCircle,
    FiTrash2,
    FiMail,
    FiX,
    FiSearch,
} from 'react-icons/fi';
import Config from '../../util/Config';
import Echo from '../../util/Echo';
import Alert from '../commun/Alert';
import Project from '../../util/Project';

export default (props) => {
    const { id } = useParams();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = activeUser[0].workspace[activeUser[0].activeWorkspace];
    const writeAcces = ['owner', 'admin'].includes(
        workspace.object.teams.on.filter(
            (team) => team.id === activeUser[0].id
        )[0].role
    );
    const deleteble = writeAcces && props.data?.object?.status !== 'archived';
    const [my] = useState(activeUser[0]);
    const [payload, setPayload] = useState(props.data?.object);
    useEffect(() => {
        setPayload(props.data?.object?.tags);
    }, [props.data]);

    const showModalAssignment = () => {
        document.getElementById('view_details_tags_project').style.display =
            'block';
    };
    return (
        <>
            <div className="bg-gray mt-2 font-weight-700 display-24 p-3 mb-3 text-uppercase">
                Tags
            </div>
            {props.data?.object?.tags.length ? (
                <Row className="p-0 m-0">
                    <Col className="p-2 pl-3  pb-5 m-0">
                        <div
                            className="m-0 border border-gray radius-14"
                            style={{ minWidth: '560px', maxWidth: '900px' }}
                        >
                            <Table
                                responsive
                                borderless
                                striped
                                className="m-0"
                            >
                                <thead>
                                    <tr>
                                        <th className="font-weight-bold color-gray minem-w-6 pl-4">
                                            Code
                                        </th>
                                        <th className="font-weight-bold color-gray minem-w-12">
                                            Libellé
                                        </th>
                                        <th
                                            key="rk_refresh"
                                            className="minem-w-5"
                                        >
                                            <div className="d-flex flex-row-reverse pr-4"></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="radius-buttom-14">
                                    {props.data?.object?.tags
                                        .map((ref) => {
                                            return {
                                                ref,
                                                ...props.filterStates.tags[ref]
                                                    ?.data,
                                            };
                                        })
                                        .map((tag, i) => {
                                            return (
                                                <tr
                                                    className={
                                                        props.data?.object?.tags
                                                            .length ==
                                                        i + 1
                                                            ? 'radius-buttom-14'
                                                            : ''
                                                    }
                                                >
                                                    <td className="minem-w-6 align-middle">
                                                        <TagButton
                                                            tag={tag}
                                                            size=""
                                                            className="m-0"
                                                        />
                                                    </td>
                                                    <td className="minem-w-12 font-weight-600 align-middle p-0">
                                                        {tag.name}
                                                    </td>
                                                    <td
                                                        key="rk_func"
                                                        className="minem-w-5"
                                                    >
                                                        <div className="d-flex flex-row-reverse">
                                                            {deleteble ? (
                                                                <div
                                                                    onClick={() => {
                                                                        props.update(
                                                                            props
                                                                                .data
                                                                                ?.id,
                                                                            {
                                                                                tags: props.data?.object.tags.filter(
                                                                                    (
                                                                                        t
                                                                                    ) =>
                                                                                        t !==
                                                                                        tag.ref
                                                                                ),
                                                                            }
                                                                        );
                                                                    }}
                                                                    className="pointer"
                                                                >
                                                                    <FiTrash2
                                                                        size="2rem"
                                                                        color="#999999"
                                                                        className="ml-4"
                                                                    />
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col
                        lg={'auto'}
                        className="flex items-end column axe-priorisation-btn-container p-0 pb-5 mr-2"
                    >
                        {deleteble ? (
                            <BlueButton
                                content={'Ajouter'}
                                onClick={() =>
                                    (document.getElementById(
                                        'view_details_tags_project'
                                    ).style.display = 'block')
                                }
                                icon={<FiPlusCircle size="2.2rem" />}
                            />
                        ) : (
                            ''
                        )}
                    </Col>
                </Row>
            ) : (
                <>
                    <Row
                        className="no-gutters font-weight-700 p-3 mt-3 display-16"
                        style={{
                            backgroundColor: 'rgba(0, 85, 255, 0.1)',
                        }}
                    >
                        <Col lg={'auto'}>
                            <FiInfo
                                color="#0055ff"
                                size="2.2rem"
                                className="mr-3 mt-1"
                            />
                        </Col>
                        <Col className="align-middle my-auto">
                            Aucun tag n'est attribué à ce projet.
                            <br />
                            {writeAcces && [
                                props.data?.object?.status === 'archived' ? (
                                    <>
                                        Impossible d'ajouter un tag car ce
                                        projet a été archivé.
                                    </>
                                ) : (
                                    <>
                                        En tant qu'administrateur du
                                        workspace&nbsp;
                                        {workspace.object.name} vous pouvez
                                        ajouter un ou plusieurs tags à ce
                                        projet.
                                    </>
                                ),
                            ]}
                        </Col>
                    </Row>
                    <Row className="no-gutters mt-2">
                        <Col></Col>

                        <Col
                            lg={'auto'}
                            className="flex items-end column axe-priorisation-btn-container p-0 pb-5 mr-2"
                        >
                            {deleteble ? (
                                <BlueButton
                                    content={'Ajouter'}
                                    onClick={() =>
                                        (document.getElementById(
                                            'view_details_tags_project'
                                        ).style.display = 'block')
                                    }
                                    icon={<FiPlusCircle size="2.2rem" />}
                                />
                            ) : (
                                ''
                            )}
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};
