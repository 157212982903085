import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Navbar } from 'react-bootstrap';
import ErrorSelector from './ErrorSelector';
import Logo from '../commun/Logo';
import { FiX } from 'react-icons/fi';
export default () => {
    const { code } = useParams();
    return (
        <>
            <Container fluid>
                <Navbar
                    className="fixed-top rankio-header"
                    bg="white"
                    expand="xl"
                >
                    <Link to="/">
                        <Navbar.Brand>
                            <Logo />
                        </Navbar.Brand>
                    </Link>
                    <Link to="/" className="ml-auto">
                        <FiX size="1.5em" className="rk-clickable-icon-dark" />
                    </Link>
                </Navbar>
                <ErrorSelector code={code ? parseInt(code) : 500} />
            </Container>
        </>
    );
};
