import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
    Fade,
    Button,
    Row,
    Card,
    Col,
    Collapse,
    Dropdown,
    Form,
} from 'react-bootstrap';

import { AiOutlineBank } from 'react-icons/ai';
import {
    FiLoader,
    FiTrash2,
    FiEdit,
    FiPlusCircle,
    FiInfo,
    FiCreditCard,
    FiAlertTriangle,
    FiCheckCircle,
    FiChevronDown,
    FiChevronUp,
} from 'react-icons/fi';
import Plan from '../../util/Plan';
import Feature from '../../util/Feature';
import Subscription from '../../util/Subscription';
import PaymentMethods from '../../util/PaymentMethods';
import PaymentMethodsForm from './PaymentMethods.js';
import SubscriptionBody from '../subscription/SubscriptionBody';
import RkModal from '../commun/RkModal';
import Modal from '../modal/Modal';
import BlueButton from '../commun/BlueButton';
import Alert from '../commun/Alert';
import Error from '../error/ErrorComponent';
import Teams from '../../util/Teams';
import Echo from '../../util/Echo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
export default () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const [open, setOpen] = useState({});
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalDeleteSubscription, setShowModalDeleteSubscription] =
        useState(false);
    const [showModalCancelSubscription, setShowModalCancelSubscription] =
        useState(false);
    const [
        showModalReactivateSubscription,
        setShowModalReactivateSubscription,
    ] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [colorAlert, setColorAlert] = useState('danger');
    const [contentAlert, setContentAlert] = useState(
        'Une erreur est survenue, veuillez nous en excuser'
    );
    const [_message, setMessage] = useState('');
    const [planList, setPlanList] = useState([]);
    const [featureList, setFeatureList] = useState([]);
    const [adminList, setAdminList] = useState({});
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [loadingSubscription, setLoadingSubscription] = useState(true);
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState({
        isNull: 1,
    });
    const [selectedSubscription, setSelectedSubscription] = useState({
        isNull: 1,
    });
    const [paymentMethodsList, setPaymentMethodsList] = useState([]);
    const [defaultPaymentMethods, setDefaultPaymentMethods] = useState(null);
    const [loadingPaymentMethodsList, setLoadingPaymentMethodsList] =
        useState(true);
    const Workspaces = activeUser[0].workspace;
    const renderSubscriptions = (list) => {
        try {
            return (
                <>
                    {list.map((sb) => {
                        const expireDate = Subscription.getExpireDate(sb);
                        return (
                            <Card
                                key={sb.id}
                                className={
                                    'card-' +
                                    (sb?.object?.status === 'unpaid'
                                        ? 'danger'
                                        : 'primary') +
                                    ' display-14 p-0 m-3 mb-4 mr-0'
                                }
                                style={{ maxWidth: '680px' }}
                            >
                                <Row className="m-0">
                                    <Col
                                        md={'auto'}
                                        className="cursor-pointer px-3 my-auto"
                                        onClick={() => {
                                            setOpen({
                                                ...open,
                                                [sb.id]:
                                                    open?.[sb.id] === true
                                                        ? false
                                                        : true,
                                            });
                                        }}
                                    >
                                        {open?.[sb.id] === true ? (
                                            <FiChevronUp
                                                color="#999999"
                                                size="2.2rem"
                                            />
                                        ) : (
                                            <FiChevronDown
                                                color="#999999"
                                                size="2.2rem"
                                            />
                                        )}
                                    </Col>
                                    <Col className="p-3 my-auto">
                                        <p className="display-20 font-weight-800 m-0">
                                            {
                                                planList?.filter(
                                                    (p) =>
                                                        p.id === sb.object.plan
                                                )?.[0].object.name
                                            }
                                        </p>
                                        <p className="text-capitalize display-16 font-weight-600 m-0">
                                            <span>Entreprise : </span>
                                            <span className="font-weight-bold">
                                                {sb.object.company_name}
                                            </span>
                                        </p>
                                        <p className="text-capitalize display-16 font-weight-600 m-0">
                                            <span>Validité : </span>
                                            <span className="font-weight-bold">
                                                {expireDate
                                                    ? expireDate
                                                          .toString()
                                                          .replace(/\./g, '')
                                                    : '-'}
                                            </span>
                                        </p>
                                        <p className="display-16 font-weight-600 m-0">
                                            <span>Workspace : </span>
                                            <span className="font-weight-bold">
                                                {Workspaces.filter(
                                                    (w) =>
                                                        w.object
                                                            .subscription ===
                                                        sb.id
                                                ).length ? (
                                                    Workspaces.filter(
                                                        (w) =>
                                                            w.object
                                                                .subscription ===
                                                            sb.id
                                                    ).map((w, i) => {
                                                        if (i) {
                                                            return (
                                                                <>
                                                                    {
                                                                        w.object
                                                                            .name
                                                                    }
                                                                    ,{' '}
                                                                </>
                                                            );
                                                        } else {
                                                            return (
                                                                <>
                                                                    {
                                                                        w.object
                                                                            .name
                                                                    }
                                                                </>
                                                            );
                                                        }
                                                    })
                                                ) : (
                                                    <>
                                                        <Button
                                                            variant="primary"
                                                            className="btn-sm"
                                                            onClick={() => {
                                                                dispatch({
                                                                    type: 'SET_SELECTED_PLAN',
                                                                    value: {
                                                                        id: activeUser[0]
                                                                            .id,
                                                                        data: sb
                                                                            .object
                                                                            .plan,
                                                                    },
                                                                });
                                                                dispatch({
                                                                    type: 'SET_SELECTED_SUBSCRIPTION',
                                                                    value: {
                                                                        id: activeUser[0]
                                                                            .id,
                                                                        data: sb.id,
                                                                    },
                                                                });
                                                                history.push(
                                                                    '/' +
                                                                        id +
                                                                        '/onboarding/workspace'
                                                                );
                                                            }}
                                                        >
                                                            <strong>
                                                                <FiPlusCircle
                                                                    size="1.4rem"
                                                                    className="mr-2"
                                                                />
                                                                {'  '}
                                                                créer
                                                            </strong>
                                                        </Button>
                                                    </>
                                                )}
                                            </span>
                                        </p>
                                    </Col>
                                    <Col
                                        md={'auto'}
                                        className="p-3 pr-4 my-auto"
                                    >
                                        <p className="display-20 font-weight-800 text-right m-0">
                                            {sb.object.status === 'unpaid' &&
                                            sb.object.stripe.id === undefined &&
                                            sb.object.trial_allow ? (
                                                <></>
                                            ) : (
                                                <>
                                                    {
                                                        planList?.filter(
                                                            (p) =>
                                                                p.id ===
                                                                sb.object.plan
                                                        )?.[0].object.prices
                                                    }
                                                    {
                                                        planList?.filter(
                                                            (p) =>
                                                                p.id ===
                                                                sb.object.plan
                                                        )?.[0].object.currency
                                                    }
                                                    <br />
                                                    <span className="font-weight-bold display-16">
                                                        HT/
                                                        {planList?.filter(
                                                            (p) =>
                                                                p.id ===
                                                                sb.object.plan
                                                        )?.[0].object
                                                            .payment_frequency ===
                                                        'annually'
                                                            ? 'an'
                                                            : 'mois'}
                                                    </span>
                                                </>
                                            )}
                                        </p>
                                        {sb.object.status !== 'paid' ? (
                                            <div className="my-2">
                                                {/* first chechout after trial */}
                                                {sb.object.trial_allow &&
                                                sb.object.stripe.id ===
                                                    undefined ? (
                                                    <Button
                                                        variant="primary"
                                                        className="btn-sm"
                                                        onClick={() => {
                                                            history.push(
                                                                '/' +
                                                                    id +
                                                                    '/subscription/' +
                                                                    sb.id
                                                            );
                                                        }}
                                                    >
                                                        <strong>
                                                            <FiCreditCard
                                                                size="1.4rem"
                                                                className="mr-2"
                                                            />
                                                            {'  '}
                                                            Souscrire
                                                        </strong>
                                                    </Button>
                                                ) : (
                                                    <></>
                                                )}
                                                {/* regularise checkout */}
                                                {sb.object.status ===
                                                    'unpaid' &&
                                                sb.object.stripe.id !==
                                                    undefined ? (
                                                    <Button
                                                        variant="primary"
                                                        className="btn-sm"
                                                        onClick={() => {
                                                            history.push(
                                                                '/' +
                                                                    id +
                                                                    '/subscription/' +
                                                                    sb.id
                                                            );
                                                        }}
                                                    >
                                                        <strong>
                                                            <FiAlertTriangle
                                                                size="1.4rem"
                                                                className="mr-2"
                                                            />
                                                            {'  '}
                                                            Régulariser
                                                        </strong>
                                                    </Button>
                                                ) : (
                                                    <></>
                                                )}
                                                {/* first checkout for a new subscription */}
                                                {!sb.object.trial_allow &&
                                                sb.object.status === 'unpaid' &&
                                                sb.object.stripe.id ===
                                                    undefined ? (
                                                    <Button
                                                        variant="primary"
                                                        className="btn-sm"
                                                        onClick={() => {
                                                            history.push(
                                                                '/' +
                                                                    id +
                                                                    '/subscription/' +
                                                                    sb.id
                                                            );
                                                        }}
                                                    >
                                                        <strong>
                                                            <FiCreditCard
                                                                size="1.4rem"
                                                                className="mr-2"
                                                            />
                                                            {'  '}
                                                            Payer
                                                        </strong>
                                                    </Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </Col>
                                    {/* <Col md={'auto'} className="pl-0 pr-3 my-auto">
                                    <FiEdit
                                        color="#999999"
                                        size="1.8rem"
                                        className="cursor-pointer"
                                    />
                                </Col>
                                <Col md={'auto'} className="pl-0 pr-3 my-auto">
                                    <FiTrash2
                                        color="#999999"
                                        size="1.8rem"
                                        className="cursor-pointer"
                                    />
                                </Col> */}
                                </Row>
                                <Collapse in={open?.[sb.id]}>
                                    <Row className="m-0">
                                        <Col
                                            md={'auto'}
                                            className="px-3 my-auto"
                                        >
                                            <FiInfo
                                                color="#ffffff"
                                                size="2.2rem"
                                            />
                                        </Col>
                                        <Col className="p-3">
                                            {Plan.renderDescription(
                                                planList?.filter(
                                                    (p) =>
                                                        p.id === sb.object.plan
                                                )?.[0],
                                                'Contenu :'
                                            )}
                                        </Col>
                                        <Col md={12}></Col>
                                        <Col></Col>
                                        <Col md={'auto'} className="p-4">
                                            {sb.object.to_be_cancelled ? (
                                                <Button
                                                    variant="primary"
                                                    className="btn-sm"
                                                    onClick={() => {
                                                        reactivateSubscription(
                                                            sb.id
                                                        );
                                                    }}
                                                >
                                                    <strong>
                                                        <FiCheckCircle
                                                            size="1.4rem"
                                                            className="mr-2"
                                                        />
                                                        {'  '}
                                                        Réactiver
                                                    </strong>
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="danger"
                                                    className="btn-sm"
                                                    onClick={() => {
                                                        deleteSubscription(
                                                            sb.id,
                                                            sb.object.status
                                                        );
                                                    }}
                                                >
                                                    <strong>
                                                        <FiTrash2
                                                            size="1.4rem"
                                                            className="mr-2"
                                                        />
                                                        {'  '}
                                                        {sb.object.status ===
                                                        'paid'
                                                            ? 'Annuler'
                                                            : 'Supprimer'}
                                                    </strong>
                                                </Button>
                                            )}
                                        </Col>
                                        {/* <Col md={'auto'} className="p-4">
                                            <Button
                                                variant="danger"
                                                className="btn-sm"
                                                onClick={() => {
                                                    setSelectedSubscription(
                                                        {
                                                            isNull: 1,
                                                            id: sb.id,
                                                        },
                                                        (document.getElementById(
                                                            'view-update-subscription'
                                                        ).style.display =
                                                            'block')
                                                    );
                                                }}
                                            >
                                                <strong>
                                                    <FiEdit
                                                        size="1.4rem"
                                                        className="mr-2"
                                                    />
                                                    Modifier
                                                </strong>
                                            </Button>
                                        </Col> */}
                                    </Row>
                                </Collapse>
                            </Card>
                        );
                    })}
                </>
            );
        } catch (error) {
            console.error(error);
        }
    };
    const renderFeatures = (list) => {
        return (
            <>
                {list.map((sb) => {
                    const expireDate = Subscription.getExpireDate(sb);
                    let ltft = [...new Set(sb.object.features)];
                    return (
                        <>
                            {ltft.map((ft) => {
                                return (
                                    <Card
                                        key={sb.id + '-' + ft}
                                        className={
                                            'card-' +
                                            (sb?.object?.status === 'unpaid'
                                                ? 'danger'
                                                : 'primary') +
                                            ' display-14 p-0 m-3 mb-4 mr-0'
                                        }
                                        style={{ maxWidth: '680px' }}
                                    >
                                        <Row className="m-0">
                                            <Col
                                                md={'auto'}
                                                className="px-3 my-auto"
                                                onClick={() => {
                                                    setOpen({
                                                        ...open,
                                                        [sb.id + '-' + ft]:
                                                            open?.[
                                                                sb.id + '-' + ft
                                                            ] === true
                                                                ? false
                                                                : true,
                                                    });
                                                }}
                                            >
                                                <FiChevronDown
                                                    color="#999999"
                                                    size="2.2rem"
                                                />
                                            </Col>
                                            <Col className="p-3 my-auto">
                                                <p className="display-20 font-weight-800 m-0">
                                                    {
                                                        featureList?.filter(
                                                            (f) => f.id === ft
                                                        )?.[0].object.name
                                                    }
                                                </p>
                                                <p className="text-capitalize display-16 font-weight-600 m-0">
                                                    <span>Entreprise : </span>
                                                    <span className="font-weight-bold">
                                                        {sb.object.company_name}
                                                    </span>
                                                </p>
                                                <p className="text-capitalize display-16 font-weight-600 m-0">
                                                    <span>Validité : </span>
                                                    <span className="font-weight-bold">
                                                        {expireDate
                                                            ? expireDate
                                                                  .toString()
                                                                  .replace(
                                                                      /\./g,
                                                                      ''
                                                                  )
                                                            : '-'}
                                                    </span>
                                                </p>
                                                <p className="display-16 font-weight-600 m-0">
                                                    <span>Workspace : </span>
                                                    <span className="font-weight-bold">
                                                        {Workspaces.filter(
                                                            (w) =>
                                                                w.object
                                                                    .subscription ===
                                                                sb.id
                                                        ).length
                                                            ? Workspaces.filter(
                                                                  (w) =>
                                                                      w.object
                                                                          .subscription ===
                                                                      sb.id
                                                              ).map((w, i) => {
                                                                  if (i) {
                                                                      return (
                                                                          <>
                                                                              {
                                                                                  w
                                                                                      .object
                                                                                      .name
                                                                              }
                                                                              ,{' '}
                                                                          </>
                                                                      );
                                                                  } else {
                                                                      return (
                                                                          <>
                                                                              {
                                                                                  w
                                                                                      .object
                                                                                      .name
                                                                              }
                                                                          </>
                                                                      );
                                                                  }
                                                              })
                                                            : '-'}
                                                    </span>
                                                </p>
                                            </Col>
                                            <Col
                                                md={'auto'}
                                                className="p-3 mr-2 my-auto"
                                            >
                                                <div className="add-option-admin-nbr-container m-0">
                                                    {
                                                        sb.object.features.filter(
                                                            (tf) => tf === ft
                                                        ).length
                                                    }
                                                </div>
                                            </Col>
                                            <Col
                                                md={'auto'}
                                                className="text-center p-3 pr-4 my-auto"
                                            >
                                                <p
                                                    className="display-20 font-weight-800 text-right m-0"
                                                    style={{ width: '140px' }}
                                                >
                                                    {sb.object.status ===
                                                        'unpaid' &&
                                                    sb.object.stripe.id ===
                                                        undefined &&
                                                    sb.object.trial_allow ? (
                                                        <></>
                                                    ) : (
                                                        <>
                                                            {featureList?.filter(
                                                                (f) =>
                                                                    f.id === ft
                                                            )?.[0].object
                                                                .prices *
                                                                sb.object.features.filter(
                                                                    (tf) =>
                                                                        tf ===
                                                                        ft
                                                                ).length}
                                                            {
                                                                featureList?.filter(
                                                                    (f) =>
                                                                        f.id ===
                                                                        ft
                                                                )?.[0].object
                                                                    .currency
                                                            }
                                                            <br />
                                                            <span className="font-weight-bold display-16">
                                                                HT/
                                                                {featureList?.filter(
                                                                    (f) =>
                                                                        f.id ===
                                                                        ft
                                                                )?.[0].object
                                                                    .payment_frequency ===
                                                                'annually'
                                                                    ? 'an'
                                                                    : 'mois'}
                                                            </span>
                                                        </>
                                                    )}
                                                </p>
                                                {sb.object.status !== 'paid' ? (
                                                    <div className="my-2">
                                                        {/* first chechout after trial */}
                                                        {sb.object
                                                            .trial_allow &&
                                                        sb.object.stripe.id ===
                                                            undefined ? (
                                                            <Button
                                                                variant="primary"
                                                                className="btn-sm"
                                                                onClick={() => {
                                                                    history.push(
                                                                        '/' +
                                                                            id +
                                                                            '/subscription/' +
                                                                            sb.id
                                                                    );
                                                                }}
                                                            >
                                                                <strong>
                                                                    <FiCreditCard
                                                                        size="1.4rem"
                                                                        className="mr-2"
                                                                    />
                                                                    {'  '}
                                                                    Souscrire
                                                                </strong>
                                                            </Button>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {/* regularise checkout */}
                                                        {sb.object.status ===
                                                            'unpaid' &&
                                                        sb.object.stripe.id !==
                                                            undefined ? (
                                                            <Button
                                                                variant="primary"
                                                                className="btn-sm"
                                                                onClick={() => {
                                                                    history.push(
                                                                        '/' +
                                                                            id +
                                                                            '/subscription/' +
                                                                            sb.id
                                                                    );
                                                                }}
                                                            >
                                                                <strong>
                                                                    <FiAlertTriangle
                                                                        size="1.4rem"
                                                                        className="mr-2"
                                                                    />
                                                                    {'  '}
                                                                    Régulariser
                                                                </strong>
                                                            </Button>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {/* first checkout for a new subscription */}
                                                        {!sb.object
                                                            .trial_allow &&
                                                        sb.object.status ===
                                                            'unpaid' &&
                                                        sb.object.stripe.id ===
                                                            undefined ? (
                                                            <Button
                                                                variant="primary"
                                                                className="btn-sm"
                                                                onClick={() => {
                                                                    history.push(
                                                                        '/' +
                                                                            id +
                                                                            '/subscription/' +
                                                                            sb.id
                                                                    );
                                                                }}
                                                            >
                                                                <strong>
                                                                    <FiCreditCard
                                                                        size="1.4rem"
                                                                        className="mr-2"
                                                                    />
                                                                    {'  '}
                                                                    Payer
                                                                </strong>
                                                            </Button>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </Col>
                                            {/* <Col
                                                md={'auto'}
                                                className="pl-0 pr-3 my-auto"
                                            >
                                                <FiEdit
                                                    color="#999999"
                                                    size="1.8rem"
                                                    className="cursor-pointer"
                                                />
                                            </Col>
                                            <Col
                                                md={'auto'}
                                                className="pl-0 pr-3 my-auto"
                                            >
                                                <FiTrash2
                                                    color="#999999"
                                                    size="1.8rem"
                                                    className="cursor-pointer"
                                                />
                                            </Col> */}
                                        </Row>
                                        <Collapse in={open?.[sb.id + '-' + ft]}>
                                            <Row className="m-0">
                                                <Col
                                                    md={'auto'}
                                                    className="px-3 my-auto"
                                                >
                                                    <FiInfo
                                                        color="#ffffff"
                                                        size="2.2rem"
                                                    />
                                                </Col>
                                                <Col className="p-3">
                                                    {Subscription.renderAdmin(
                                                        adminList[sb.id]
                                                    )}
                                                </Col>
                                            </Row>
                                        </Collapse>
                                    </Card>
                                );
                            })}
                        </>
                    );
                })}
            </>
        );
    };
    const renderPaymentMethods = (list, main = null) => {
        return (
            <>
                {list.map((py) => {
                    return (
                        <Card
                            key={py.id}
                            className={
                                (main === py.id ? 'card-primary ' : '') +
                                'display-14 p-0 m-3 mb-4 mr-0'
                            }
                            style={{ maxWidth: '680px' }}
                        >
                            <Row className="m-0">
                                <Col md={'auto'} className="px-3 my-auto">
                                    <FiCreditCard
                                        color="#999999"
                                        size="2.2rem"
                                        className={
                                            main === py.id ? 'text-black' : ''
                                        }
                                    />
                                </Col>
                                <Col md={'auto'} className="p-3">
                                    <p className="text-secondary font-weight-600 m-0">
                                        Numéro de la carte
                                    </p>
                                    <p className="font-weight-bold m-0">
                                        ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;
                                        {py.card.last4}
                                    </p>
                                </Col>
                                <Col md={'auto'} className="p-3 pb-1">
                                    <p className="text-secondary font-weight-600 m-0">
                                        Expire à fin
                                    </p>
                                    <p className="font-weight-bold m-0">
                                        {py.card.exp_month}/{py.card.exp_year}
                                    </p>
                                </Col>
                                <Col
                                    md={'auto'}
                                    className="d-xl-block d-none p-3 pb-1"
                                >
                                    <p className="text-secondary font-weight-600 m-0">
                                        Type
                                    </p>
                                    <p className="font-weight-bold text-capitalize m-0">
                                        {py.card.brand}
                                    </p>
                                </Col>
                                <Col className="p-0"></Col>
                                <Col md={'auto'} className="pl-0 pr-3 my-auto">
                                    <FiEdit
                                        color="#999999"
                                        size="1.8rem"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            if (
                                                py.id ===
                                                selectedPaymentMethods.id
                                            ) {
                                                document.getElementById(
                                                    'view-edit-payment-methods'
                                                ).style.display = 'block';
                                            } else {
                                                setSelectedPaymentMethods(py);
                                            }
                                        }}
                                    />
                                </Col>
                                <Col md={'auto'} className="pl-0 pr-3 my-auto">
                                    <FiTrash2
                                        color="#999999"
                                        size="1.8rem"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            if (
                                                py.id === defaultPaymentMethods
                                            ) {
                                                setColorAlert('danger');
                                                setContentAlert(
                                                    "Il n'est pas possible de supprimer le moyen de paiement par défaut."
                                                );
                                                setShowAlert(true);
                                            } else {
                                                deletePaymentMethods(py.id);
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    );
                })}
            </>
        );
    };
    const deleteSubscription = (subscriptionId, status, commit = false) => {
        if (commit) {
            setLoadingSubscription(true);
            Subscription.delete(subscriptionId, activeUser[0].token)
                .then((res) => {
                    if (res.status === 202) {
                        // TODO: remove related workspaces from cache
                        getSubscription();
                        setColorAlert('primary');
                        setContentAlert(
                            "L'abonnement a été supprimé avec succès."
                        );
                        setShowAlert(true);

                        dispatch({
                            type: 'UPDATE_WORKSPACE_LIST',
                            value: {
                                workspace: activeUser[0].workspace.filter(
                                    (w) =>
                                        w.object.subscription !== subscriptionId
                                ),
                                id: activeUser[0].id,
                            },
                        });
                    } else if (200) {
                        getSubscription();
                        setColorAlert('primary');
                        setContentAlert(
                            "L’annulation de l'abonnement a été enregistrée avec succès."
                        );
                        setShowAlert(true);
                    } else {
                        setColorAlert('danger');
                        setContentAlert(
                            'Une erreur est survenue, veuillez réessayer ultérieurement'
                        );
                        setShowAlert(true);
                    }
                })
                .catch((err) => {});
        } else {
            setSelectedSubscription({
                isNull: 1,
                id: subscriptionId,
            });
            if (status === 'paid') {
                setShowModalCancelSubscription(true);
            } else {
                setShowModalDeleteSubscription(true);
            }
        }
    };
    const reactivateSubscription = (subscriptionId, commit = false) => {
        if (commit) {
            setLoadingSubscription(true);
            Subscription.patch(subscriptionId, activeUser[0].token, {
                to_be_cancelled: false,
            })
                .then((res) => {
                    if (200) {
                        getSubscription();
                        setColorAlert('primary');
                        setContentAlert(
                            "La réactivation de l'abonnement a été enregistrée avec succès."
                        );
                        setShowAlert(true);
                    } else {
                        setColorAlert('danger');
                        setContentAlert(
                            'Une erreur est survenue, veuillez réessayer ultérieurement'
                        );
                        setShowAlert(true);
                    }
                })
                .catch((err) => {});
        } else {
            setSelectedSubscription({
                isNull: 1,
                id: subscriptionId,
            });
            setShowModalReactivateSubscription(true);
        }
    };
    const deletePaymentMethods = (paymentMethodsId, commit = false) => {
        if (commit) {
            PaymentMethods.delete(paymentMethodsId, activeUser[0].token)
                .then((res) => {
                    if (res.status === 200) {
                        setPaymentMethodsList(
                            paymentMethodsList.filter(
                                (py) => py.id !== paymentMethodsId
                            )
                        );
                        setColorAlert('primary');
                        setContentAlert(
                            'Le moyen de paiement a été supprimé avec succès.'
                        );
                        setShowAlert(true);
                    } else {
                        setColorAlert('danger');
                        setContentAlert(
                            'Une erreur est survenue, veuillez réessayer ultérieurement'
                        );
                        setShowAlert(true);
                    }
                })
                .catch((err) => {});
        } else {
            setShowModalDelete(true);
            setSelectedPaymentMethods({
                isNull: 1,
                id: paymentMethodsId,
            });
        }
    };
    const getSubscription = () => {
        Subscription.get({}, activeUser[0].token)
            .then((res) => {
                if (res.status === 200) {
                    res.data.data.forEach((sb) => {
                        const workspaceId = Workspaces.filter(
                            (w) => w.object.subscription === sb.id
                        )?.[0]?.id;
                        if (workspaceId)
                            Teams.get(workspaceId, activeUser[0].token).then(
                                (res) => {
                                    if (res.status === 200)
                                        setAdminList({
                                            ...adminList,
                                            [sb.id]: res?.data?.data.filter(
                                                (t) =>
                                                    t.object.role === 'admin' &&
                                                    t.object.status !== 'off'
                                            ),
                                        });
                                }
                            );
                    });
                    setSubscriptionList(res.data.data);
                    setLoadingSubscription(false);
                } else if (res.status === 404) {
                    setSubscriptionList([]);
                    setLoadingSubscription(false);
                } else {
                    setColorAlert('danger');
                    setContentAlert(
                        'Une erreur est survenue, veuillez réessayer ultérieurement'
                    );
                    setShowAlert(true);
                }
            })
            .catch((err) => {
                setMessage('Une erreur est survenue, veuillez réessayer.');
            });
    };
    const getPaymentMethods = () => {
        PaymentMethods.get({}, activeUser[0].token)
            .then((res) => {
                if (res.status === 200) {
                    setPaymentMethodsList(res.data.data);
                    setDefaultPaymentMethods(res.data.default || null);
                    setLoadingPaymentMethodsList(false);
                } else if (res.status === 404) {
                    setPaymentMethodsList([]);
                    setLoadingPaymentMethodsList(false);
                } else {
                    setColorAlert('danger');
                    setContentAlert(
                        'Une erreur est survenue, veuillez réessayer ultérieurement'
                    );
                    setShowAlert(true);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            })
            .catch((err) => {
                setColorAlert('danger');
                setContentAlert(
                    'Une erreur est survenue, veuillez réessayer ultérieurement'
                );
                setShowAlert(true);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            });
    };
    const { trackPageView } = useMatomo();
    useEffect(() => {
        if (!selectedPaymentMethods?.isNull) {
            document.getElementById('view-edit-payment-methods').style.display =
                'block';
        }
    }, [selectedPaymentMethods]);
    useEffect(() => {
        document.title = Echo().seo.title.account_billing
            ? Echo().seo.title.prefix +
              Echo().seo.title.account_billing +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        Feature.get({})
            .then((res) => {
                if (res.status === 200) {
                    setFeatureList(res.data.data);
                } else {
                    setColorAlert('danger');
                    setContentAlert(
                        'Une erreur est survenue, veuillez réessayer ultérieurement'
                    );
                    setShowAlert(true);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            })
            .catch((err) => {
                setColorAlert('danger');
                setContentAlert(
                    'Une erreur est survenue, veuillez réessayer ultérieurement'
                );
                setShowAlert(true);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            });
        Plan.get({})
            .then((res) => {
                getSubscription();
                if (res.status === 200) {
                    setPlanList(res.data.data);
                } else {
                    setColorAlert('danger');
                    setContentAlert(
                        'Une erreur est survenue, veuillez réessayer ultérieurement'
                    );
                    setShowAlert(true);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            })
            .catch((err) => {
                setColorAlert('danger');
                setContentAlert(
                    'Une erreur est survenue, veuillez réessayer ultérieurement'
                );
                setShowAlert(true);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            });
        getPaymentMethods();
        window.scrollTo(0, 0);
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, []);

    return (
        <>
            <div className="card text-black m-2 pb-2">
                <Row className="no-gutters bg-gray rounded-top">
                    <div className="font-weight-bold display-24 py-3 ml-3 text-uppercase">
                        Facturation
                    </div>
                </Row>
                <div className="profil-container">
                    <div
                        className={
                            (loadingSubscription ? 'd-block' : 'd-none') +
                            ' text-center py-5'
                        }
                    >
                        <FiLoader size={36} className="rotating" />
                    </div>
                    <div className={loadingSubscription ? 'd-none' : 'd-block'}>
                        {subscriptionList.length ? (
                            <>
                                <Row className="no-gutters">
                                    <Col className="pr-0 pb-5">
                                        <Row className="no-gutters mt-4 mb-3 mx-3">
                                            <Col sm={12}>
                                                <div className="input-label m-0">
                                                    Vos forfaits actuels&nbsp;:
                                                </div>
                                            </Col>
                                        </Row>

                                        {renderSubscriptions(subscriptionList)}

                                        {subscriptionList.reduce(
                                            (x, y) =>
                                                x + y.object.features.length,
                                            0
                                        ) ? (
                                            <>
                                                <div className="input-label m-3 mt-5">
                                                    Vos options actuelles&nbsp;:
                                                </div>
                                                {renderFeatures(
                                                    subscriptionList
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </Col>
                                    <Col
                                        lg={'auto'}
                                        className="flex items-end column axe-priorisation-btn-container p-0 pt-3 mr-2"
                                    ></Col>
                                </Row>
                            </>
                        ) : (
                            <>
                                <Row
                                    className="no-gutters font-weight-bold p-3 mt-3 display-16"
                                    style={{
                                        backgroundColor:
                                            'rgba(0, 85, 255, 0.1)',
                                    }}
                                >
                                    <Col sm={12}>
                                        <FiInfo
                                            color="#0055ff"
                                            size="2.2rem"
                                            className="mr-3"
                                        />
                                        <span className="my-auto align-middle">
                                            Aucun abonnement souscrit
                                        </span>
                                    </Col>{' '}
                                </Row>
                                <Row className="no-gutters">
                                    <Col></Col>
                                    <Col
                                        lg={'auto'}
                                        className="flex items-end column axe-priorisation-btn-container p-0 mr-2"
                                    >
                                        <BlueButton
                                            btnClassnameDetails="mt-3"
                                            content={
                                                <>
                                                    Souscrire à un <br></br>
                                                    abonnement
                                                </>
                                            }
                                            icon={
                                                <FiPlusCircle size="2.2rem" />
                                            }
                                            onClick={() => {
                                                history.push(
                                                    '/' +
                                                        id +
                                                        '/onboarding/reset'
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                    <Row className="no-gutters bg-gray mt-5 font-weight-bold display-24 p-3 mb-3 text-uppercase">
                        Modes de paiement
                    </Row>
                    <div
                        className={
                            (loadingPaymentMethodsList ? 'd-block' : 'd-none') +
                            ' text-center py-5'
                        }
                    >
                        <FiLoader size={36} className="rotating" />
                    </div>
                    <div
                        className={
                            loadingPaymentMethodsList ? 'd-none' : 'd-block'
                        }
                    >
                        {paymentMethodsList.length ? (
                            <>
                                {defaultPaymentMethods ? (
                                    <></>
                                ) : (
                                    <Row
                                        className="no-gutters font-weight-bold p-3 mt-3 display-16"
                                        style={{
                                            backgroundColor:
                                                'rgba(0, 85, 255, 0.1)',
                                        }}
                                    >
                                        <Col sm={12}>
                                            <FiInfo
                                                color="#0055ff"
                                                size="2.2rem"
                                                className="mr-3"
                                            />
                                            <span className="my-auto align-middle">
                                                Aucun moyen de paiement par
                                                défaut
                                            </span>
                                        </Col>{' '}
                                    </Row>
                                )}
                                <Row className="no-gutters">
                                    <Col className="pr-0 pb-5">
                                        {defaultPaymentMethods ? (
                                            <>
                                                <Row className="no-gutters mt-2 mb-3 mx-3">
                                                    <Col sm={12}>
                                                        <div className="input-label m-0">
                                                            Votre préférence de
                                                            paiement par
                                                            défaut&nbsp;:
                                                        </div>
                                                    </Col>
                                                </Row>

                                                {renderPaymentMethods(
                                                    paymentMethodsList.filter(
                                                        (py) =>
                                                            py.id ===
                                                            defaultPaymentMethods
                                                    ),
                                                    defaultPaymentMethods
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )}

                                        {paymentMethodsList.filter(
                                            (py) =>
                                                py.id !== defaultPaymentMethods
                                        ).length ? (
                                            <>
                                                <div className="input-label m-3 mt-5">
                                                    {defaultPaymentMethods
                                                        ? 'Autres cartes bancaires enregistrées'
                                                        : 'Cartes bancaires enregistrées'}
                                                </div>
                                                {renderPaymentMethods(
                                                    paymentMethodsList.filter(
                                                        (py) =>
                                                            py.id !==
                                                            defaultPaymentMethods
                                                    )
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {/* <div className="input-label m-3 mt-5">
                                            Comptes bancaires (expérimental)
                                        </div>
                                        <Card
                                            className="display-14 p-0 m-3"
                                            style={{ width: '440px' }}
                                        >
                                            <Row className="m-0">
                                                <Col
                                                    md={'auto'}
                                                    className="px-3 my-auto"
                                                >
                                                    <AiOutlineBank
                                                        color="#999999"
                                                        size="2.2rem"
                                                    />
                                                </Col>
                                                <Col
                                                    md={'auto'}
                                                    className="p-3"
                                                >
                                                    <p className="text-secondary font-weight-600 m-0">
                                                        Numéro du compte
                                                    </p>
                                                    <p className="font-weight-bold m-0">
                                                        ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;
                                                        073
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Card> */}
                                    </Col>
                                    <Col
                                        lg={'auto'}
                                        className="flex items-end column axe-priorisation-btn-container p-0 mr-2"
                                    >
                                        <BlueButton
                                            content={
                                                <>
                                                    Ajouter une <br></br>carte
                                                    bancaire
                                                </>
                                            }
                                            icon={
                                                <FiPlusCircle size="2.2rem" />
                                            }
                                            onClick={() => {
                                                document.getElementById(
                                                    'view-add-payment-methods'
                                                ).style.display = 'block';
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <>
                                <Row
                                    className="no-gutters font-weight-bold p-3 mt-3 display-16"
                                    style={{
                                        backgroundColor:
                                            'rgba(0, 85, 255, 0.1)',
                                    }}
                                >
                                    <Col sm={12}>
                                        <FiInfo
                                            color="#0055ff"
                                            size="2.2rem"
                                            className="mr-3"
                                        />
                                        <span className="my-auto align-middle">
                                            Aucun mode de paiement enregistré
                                        </span>
                                    </Col>{' '}
                                </Row>
                                <Row className="no-gutters">
                                    <Col></Col>
                                    <Col
                                        lg={'auto'}
                                        className="flex items-end column axe-priorisation-btn-container p-0 mr-2"
                                    >
                                        <BlueButton
                                            btnClassnameDetails="mt-3"
                                            content={
                                                <>
                                                    Ajouter une <br></br>carte
                                                    bancaire
                                                </>
                                            }
                                            icon={
                                                <FiPlusCircle size="2.2rem" />
                                            }
                                            onClick={() => {
                                                document.getElementById(
                                                    'view-add-payment-methods'
                                                ).style.display = 'block';
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                </div>
                <RkModal
                    id="view-update-subscription"
                    title="Gestion de souscription"
                    className="card h-auto my-0 w-100"
                >
                    <div className="h-100-330 p-0 pl-3 m-0">
                        <SubscriptionBody
                            subscriptionId={selectedSubscription?.id}
                            onboarding={false}
                            update={true}
                        />
                    </div>
                </RkModal>

                <RkModal
                    id="view-add-payment-methods"
                    title="Ajouter une carte bancaire"
                    className="card h-auto my-0 w-100"
                >
                    <div className="h-100-330 p-0 pl-3 m-0">
                        <PaymentMethodsForm
                            action="post"
                            done={(ok) => {
                                if (ok) {
                                    setColorAlert('primary');
                                    setContentAlert(
                                        'Le moyen de paiement a bien été enregistré.'
                                    );
                                    setShowAlert(true);
                                    getPaymentMethods();
                                }
                                document.getElementById(
                                    'view-add-payment-methods'
                                ).style.display = 'none';
                            }}
                            default="00000000-0000-0000-0000-000000000000"
                        />
                    </div>
                </RkModal>
                <RkModal
                    id="view-edit-payment-methods"
                    title="Modifier une carte bancaire"
                    className="card h-auto my-0 w-100"
                >
                    <div className="h-100-330 p-0 pl-3 m-0">
                        <PaymentMethodsForm
                            action="patch"
                            done={(ok) => {
                                if (ok) {
                                    setColorAlert('primary');
                                    setContentAlert(
                                        'Les modifications ont bien été enregistrées.'
                                    );
                                    setShowAlert(true);
                                    getPaymentMethods();
                                }
                                document.getElementById(
                                    'view-edit-payment-methods'
                                ).style.display = 'none';
                            }}
                            data={selectedPaymentMethods}
                            default={defaultPaymentMethods}
                        />
                    </div>
                </RkModal>
            </div>
            <Modal
                show={showModalDeleteSubscription}
                centered={true}
                modalFirstText="Etes-vous certain de vouloir annuler cette abonnement ?"
                modalSecondText="Important: Cette action prendre effet immédiat !"
                modalThirdText="Les données liées a cette souscription seront immediatement supprimé."
                modalContainerClassname="axeModalContainerClassname"
                modalContentClassName="axeModalContentClassName"
                closeIcon="axeModalcloseIcon cursorPointer"
                containerTextWithIconClassname="axesContainerTextWithIconClassname"
                alertIconClassName="axesAlertIconClassName"
                firstBtnClassName="axeModalFirstBtn"
                secondBtnClassName="axeModalSecondBtn"
                setShowModalFunction={() =>
                    setShowModalDeleteSubscription(false)
                }
                textClassName="axeModalTextClassName"
                saveBtnFunction={() =>
                    deleteSubscription(selectedSubscription.id, null, true)
                }
            />
            <Modal
                show={showModalCancelSubscription}
                centered={true}
                modalFirstText="Etes-vous certain de vouloir annuler cette abonnement ?"
                modalSecondText="Important: Cette action prendre effet à la fin de la période de facturation en cours !"
                modalThirdText="Vous pouvez réactiver cette abonnement à tout moment avant la fin de la période."
                modalContainerClassname="axeModalContainerClassname"
                modalContentClassName="axeModalContentClassName"
                closeIcon="axeModalcloseIcon cursorPointer"
                containerTextWithIconClassname="axesContainerTextWithIconClassname"
                alertIconClassName="axesAlertIconClassName"
                firstBtnClassName="axeModalFirstBtn"
                secondBtnClassName="axeModalSecondBtn"
                setShowModalFunction={() =>
                    setShowModalCancelSubscription(false)
                }
                textClassName="axeModalTextClassName"
                saveBtnFunction={() =>
                    deleteSubscription(selectedSubscription.id, null, true)
                }
            />
            <Modal
                show={showModalReactivateSubscription}
                centered={true}
                modalFirstText="Etes-vous certain de vouloir réactiver cette abonnement ?"
                modalSecondText=""
                modalThirdText=""
                modalContainerClassname="axeModalContainerClassname"
                modalContentClassName="axeModalContentClassName"
                closeIcon="axeModalcloseIcon cursorPointer"
                containerTextWithIconClassname="axesContainerTextWithIconClassname"
                alertIconClassName="axesAlertIconClassName"
                firstBtnClassName="axeModalFirstBtn"
                secondBtnClassName="axeModalSecondBtn"
                setShowModalFunction={() =>
                    setShowModalReactivateSubscription(false)
                }
                textClassName="axeModalTextClassName"
                saveBtnFunction={() =>
                    reactivateSubscription(selectedSubscription.id, true)
                }
            />
            <Modal
                show={showModalDelete}
                centered={true}
                modalFirstText="Etes-vous certain de vouloir supprimer ce moyen de paiement ?"
                modalSecondText=""
                modalThirdText=""
                modalContainerClassname="axeModalContainerClassname"
                modalContentClassName="axeModalContentClassName"
                closeIcon="axeModalcloseIcon cursorPointer"
                containerTextWithIconClassname="axesContainerTextWithIconClassname"
                alertIconClassName="axesAlertIconClassName"
                firstBtnClassName="axeModalFirstBtn"
                secondBtnClassName="axeModalSecondBtn"
                setShowModalFunction={() => setShowModalDelete(false)}
                textClassName="axeModalTextClassName"
                saveBtnFunction={() =>
                    deletePaymentMethods(selectedPaymentMethods.id, true)
                }
            />
            <Alert
                color={colorAlert}
                content={contentAlert}
                showAlert={showAlert}
                handleCloseAlert={() => setShowAlert(false)}
            />
        </>
    );
};
