import {
    Container,
    Fade,
    Navbar,
    Button,
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { validate as isUuid } from 'uuid';
import Subscription from '../../util/Subscription';
import Workspace from '../../util/Workspace';
import Validator from '../../util/Validator';
import Logo from '../commun/Logo';
import Echo from '../../util/Echo';
import { FiX } from 'react-icons/fi';
import { IoHelp } from 'react-icons/io5';
import { useMatomo } from '@datapunt/matomo-tracker-react';
export default () => {
    const code =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.charAt(Math.floor(Math.random() * 26)) +
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.charAt(Math.floor(Math.random() * 26)) +
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.charAt(Math.floor(Math.random() * 26)) +
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.charAt(Math.floor(Math.random() * 26)) +
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.charAt(Math.floor(Math.random() * 26)) +
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.charAt(Math.floor(Math.random() * 26)) +
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.charAt(Math.floor(Math.random() * 26));
    const { id } = useParams();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const [payload, setPayload] = useState({
        code,
        name: '',
        subscription: '',
    });
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const [content, setContent] = useState(false);
    const selectedPlan = activeUser[0].selectedPlan;
    const selectedSubscription = activeUser[0].selectedSubscription;
    const selectedWorkspace = activeUser[0].selectedWorkspace;
    const handleChange = (event) => {
        setMessage('');
        setPayload({ ...payload, [event.target.name]: event.target.value });
        if (Object.keys(errors).length !== 0 || errors.constructor !== Object) {
            const e = Validator({
                ...payload,
                [event.target.name]: event.target.value,
            });
            if (e) setErrors(e);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const e = Validator(payload);
        switch (true) {
            case Object.keys(e).length !== 0:
            case e.constructor !== Object:
                setErrors(e);
                break;
            case selectedWorkspace === null:
                Workspace.post(activeUser[0].token, payload)
                    .then(async (res) => {
                        if (res.status === 201) {
                            dispatch({
                                type: 'UPDATE_WORKSPACE_LIST',
                                value: {
                                    workspace: [res.data.data],
                                    id: activeUser[0].id,
                                },
                            });
                            dispatch({
                                type: 'SET_SELECTED_WORKSPACE',
                                value: {
                                    id: activeUser[0].id,
                                    data: res.data.data.id,
                                },
                            });
                            history.push('/' + id + '/onboarding/setup');
                        } else if (res.status === 409) {
                            setMessage(
                                'Ce nom de workspace existe déjà, veuillez en choisir un nouveau.'
                            );
                        } else {
                            setMessage(res.data.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setMessage(
                            'Un problème est survenu, veuillez réessayer.'
                        );
                    });
                break;
            default:
                Workspace.patch(selectedWorkspace, activeUser[0].token, payload)
                    .then((res) => {
                        if (res.status === 200) {
                            dispatch({
                                type: 'UPDATE_WORKSPACE_LIST',
                                value: {
                                    workspace: [res.data.data],
                                    id: activeUser[0].id,
                                },
                            });
                            history.push('/' + id + '/onboarding/setup');
                        } else if (res.status === 204) {
                            history.push('/' + id + '/onboarding/setup');
                        } else if (res.status === 409) {
                            setMessage(
                                'Ce nom de workspace existe déjà, veuillez en choisir un nouveau.'
                            );
                        } else {
                            setMessage(res.data.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setMessage(
                            'Un problème est survenu, veuillez réessayer.'
                        );
                    });
        }
    };
    const { trackPageView } = useMatomo();
    useEffect(() => {
        document.title = Echo().seo.title.onboarding
            ? Echo().seo.title.prefix +
              Echo().seo.title.onboarding +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, []);
    useEffect(() => {
        switch (true) {
            case activeUser[0].first_name === undefined:
            case activeUser[0].last_name === undefined:
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/profile');
                }, 1000);
                break;
            case !isUuid(selectedPlan):
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/reset');
                }, 1000);
                break;
            case !isUuid(selectedSubscription):
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/letsgo');
                }, 1000);
                break;
            case isUuid(selectedWorkspace):
                Workspace.get({}, activeUser[0].token)
                    .then((res) => {
                        if (res.status === 200) {
                            const workspaces = res.data.data.filter(
                                (w) => w.id === selectedWorkspace
                            );
                            dispatch({
                                type: 'UPDATE_WORKSPACE_LIST',
                                value: {
                                    workspace: res.data.data,
                                    id: activeUser[0].id,
                                },
                            });
                            if (workspaces.length) {
                                setContent(true);
                                setPayload({
                                    code: workspaces[0].object.code,
                                    name: workspaces[0].object.name,
                                });
                            } else {
                                history.push('/' + id + '/onboarding/reset');
                            }
                        } else if (res.status === 404) {
                            history.push('/' + id + '/onboarding/reset');
                        } else {
                            setMessage(
                                'Une erreur est survenue, veuillez réessayer.'
                            );
                            setTimeout(window.location.reload(), 2000);
                        }
                    })
                    .catch((msg) => {
                        setMessage(
                            'Une erreur est survenue, veuillez réessayer.'
                        );
                        setTimeout(window.location.reload(), 2000);
                    });
                break;
            default:
                setContent(true);
                setPayload({ ...payload, subscription: selectedSubscription });
        }
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container fluid>
            <Navbar className="fixed-top rankio-header" bg="white" expand="xl">
                <Link to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </Link>
                <div className="ml-auto">
                    <a
                        title="Nous contacter"
                        href="/contact"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="mr-4"
                    >
                        <IoHelp
                            size="1.5em"
                            className="rk-clickable-icon-dark"
                        />
                    </a>
                    <Link to={'/'}>
                        <FiX
                            title="Accueil"
                            size="1.5em"
                            className="rk-clickable-icon-dark"
                        />{' '}
                    </Link>
                </div>
            </Navbar>
            <Fade in={content}>
                <div className="landing">
                    <Row className="p-4">
                        <Col sm={1} md={2} lg={2} xxl={4}></Col>
                        <Col sm={10} md={8} lg={8} xxl={7}>
                            <h3 className="font-weight-800 mt-4 mb-5">
                                Créons maintenant votre workspace&nbsp;!
                                <br />
                            </h3>
                            <p className="font-weight-600">
                                Il suffit pour cela de renseigner un nom pour
                                votre workspace.
                            </p>
                        </Col>
                    </Row>
                    <Row className="p-4">
                        <Col sm={1} md={2} lg={2} xxl={4}></Col>
                        <Col sm={10} md={8} lg={5} xxl={4}>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="name">
                                    <Form.Label className="input-label">
                                        Nom du workspace
                                    </Form.Label>
                                    <Form.Control
                                        name="name"
                                        type="text"
                                        placeholder="Nom de votre workspace"
                                        value={payload.name}
                                        className="input"
                                        onChange={handleChange}
                                    />
                                    <Form.Text className="text-danger font-weight-bold ml-2 mb-4">
                                        {errors.name}
                                    </Form.Text>
                                </Form.Group>
                                <Row className="p-0">
                                    <Col sm={10} md={8} lg={6} xxl={4}>
                                        <Link
                                            to={'/' + id + '/onboarding/letsgo'}
                                        >
                                            <Button
                                                className="btn-lg btn-outline-primary h3 font-weight-800 display-24 w-100 mt-5 px-3"
                                                style={{
                                                    height: '5rem',
                                                    maxWidth: '340px',
                                                }}
                                            >
                                                Précédent
                                            </Button>
                                        </Link>
                                    </Col>
                                    <Col sm={10} md={8} lg={6} xxl={4}>
                                        <Button
                                            className="btn-lg btn-primary h3 font-weight-800 display-24 w-100 mt-5"
                                            style={{
                                                height: '5rem',
                                                maxWidth: '340px',
                                            }}
                                            type="submit"
                                        >
                                            Suivant
                                        </Button>
                                    </Col>
                                </Row>
                                <Form.Group>
                                    <Form.Text className="text-danger font-weight-bold">
                                        {message}
                                    </Form.Text>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Fade>
        </Container>
    );
};
