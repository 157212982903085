import Config from '../../util/Config';
import { useDispatch } from 'react-redux';
export default (props) => {
    const dispatch = useDispatch();
    return <></>
    // return (
    //     <>
    //         {props.lang === 'fr' ? (
    //             <img
    //                 src={Config.CDN.ENDPOINT + 'static/icon/united-states.png'}
    //                 className="pointer"
    //                 style={{ width: '20px' }}
    //                 onClick={() => {
    //                     dispatch({
    //                         type: 'SET_LANG',
    //                         value: 'en',
    //                     });
    //                 }}
    //             />
    //         ) : (
    //             <img
    //                 src={Config.CDN.ENDPOINT + 'static/icon/france.png'}
    //                 style={{ width: '20px' }}
    //                 className="pointer"
    //                 onClick={() => {
    //                     dispatch({
    //                         type: 'SET_LANG',
    //                         value: 'fr',
    //                     });
    //                 }}
    //             />
    //         )}
    //     </>
    // );
};
