import Modal from '../commun/RkModal';
import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { FiMail, FiTrash2, FiAlertCircle } from 'react-icons/fi';
import DarkButton from '../commun/DarkButton';
import DeleteButton from '../commun/DeleteButton';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ChangePhoto from '../account/ChangePhoto';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import Validator from '../../util/Validator';
import Echo from '../../util/Echo';
import Alert from '../commun/Alert';
import Teams from '../../util/Teams';

export default function FicheUser(props) {
    const users = useSelector((state) => state.user.users);
    const { id } = useParams();
    let dispatch = useDispatch();
    const activeUser = users.filter((user) => user.id.includes(id));
    let Workspace = activeUser[0].workspace[activeUser[0].activeWorkspace];
    const options = [
        { value: 'member', label: Echo().teams.fields.role.member },
        { value: 'admin', label: Echo().teams.fields.role.admin },
        { value: 'guest', label: Echo().teams.fields.role.guest },
    ];
    const [inputDetails, setInputDetails] = useState([
        { value: 'guest', label: 'Invité' },
    ]);
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [errors, setErrors] = useState({});
    const [payload, setPayload] = useState({
        workspaces: [
            {
                job: '',
                company_name: '',
            },
        ],
    });
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [resendInvitation, setResendInvitation] = useState(false);
    const [checked, setChecked] = useState(false);
    const [message, setMessage] = useState('');
    const [change, setChange] = useState(false);
    const handleChange = (event, input = '') => {
        if (input === 'job' || input === 'company_name') {
            const newPayload = payload;
            const index = newPayload.workspaces.findIndex(
                (el) => el.id === Workspace.id
            );
            if (index !== -1) {
                newPayload.workspaces[index][input] = event.target.value;
            } else {
                newPayload.workspaces.push({
                    id: Workspace.id,
                    job: input === 'job' ? event.target.value : '',
                    company_name:
                        input === 'company_name' ? event.target.value : '',
                    notification: true,
                });
            }
            setPayload(newPayload);
            setChange(!change);
        } else {
            setPayload({ ...payload, [event.target.name]: event.target.value });
            setChange(!change);
            const e = Validator({
                ...payload,
                [event.target.name]: event.target.value,
            });
            if (e) setErrors(e);
        }
    };
    useEffect(() => {
        getUserDetails();
    }, []);

    const getUserDetails = (event) => {
        Axios({
            ...ApiCallConfig,
            method: 'GET',
            url: '/user',
            params: {
                id: users[0].id,
            },
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
        }).then((res) => {
            if (res.status === 200) {
                let userObject = {
                    email: res.data.data[0].object.email,
                    first_name: res.data.data[0].object.first_name,
                    last_name: res.data.data[0].object.last_name,
                    phone_number: res.data.data[0].object.phone_number,
                };
                if (
                    res.data.data[0].object.workspaces &&
                    res.data.data[0].object.workspaces.length
                ) {
                    userObject.workspaces = res.data.data[0].object.workspaces;
                } else {
                    userObject.workspaces = [
                        {
                            id: Workspace.id,
                            job: '',
                            company_name: '',
                            notification: true,
                        },
                    ];
                }
                setPayload(userObject);
                setChange(!change);
            }
        });
    };
    useEffect(() => {
        setInputDetails(
            options.filter(
                (option) => option.value === props.user?.object?.role
            )
        );
        if (props?.user?.object?.status === 'on') {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [props.user]);

    const changeCheckedFunction = () => {
        Teams.patch(Workspace.id, props.user.id, activeUser[0].token, {
            status: !checked ? 'on' : 'off',
        })
            .then((result) => {
                if (result.status === 200) {
                    props.getData();
                    setChecked(!checked);
                }
            })
            .catch(() => {
                // TODO: add generic alert error
            });
    };

    const renderCheckForm = () => {
        return (
            <Col>
                {props?.user?.object?.status !== 'waiting' ? (
                    <Form.Check
                        className=""
                        checked={checked}
                        type="switch"
                        size="xl"
                        id="1"
                        onChange={() => changeCheckedFunction()}
                        disabled={
                            props?.user?.object?.role === 'owner' ||
                            props?.user?.object?.email ===
                                activeUser[0].email ||
                            ['owner', 'admin'].indexOf(props.userRole) === -1
                        }
                    />
                ) : (
                    <span className="font-weight-800">
                        Invitation en attente
                    </span>
                )}
            </Col>
        );
    };
    const changeRoleFunction = (e) => {
        if (props.userRole === 'admin' && e.value === 'admin') {
            props.setShowModalFunction();
        } else {
            Teams.patch(Workspace.id, props.user.id, activeUser[0].token, {
                role: e.value,
            })
                .then((result) => {
                    if (result.status === 200) {
                        props.getData();
                        setInputDetails(
                            options.filter((option) => option.value === e.value)
                            );
                        } else if (result.status === 402) {
                        document.getElementById(
                            'view_add_option'
                        ).style.display = 'block';
                    }
                })
                .catch(() => props.setLoading(false));
        }
    };
    const renderInputDetails = () => {
        switch (true) {
            case ['owner', 'admin'].indexOf(props.userRole) === -1:
            case props.user?.object?.role === 'owner':
            case props.user?.object?.email === activeUser[0].email:
                return (
                    <Col
                        xl={5}
                        sm={4}
                        className="fiche-user-owner-text display-18 pl-0"
                    >
                        {Echo().teams.fields.role[props.user?.object?.role]}
                    </Col>
                );
            default:
                return (
                    <Col xl={5} sm={4} className="pl-0">
                        <Select
                            value={inputDetails}
                            onChange={(e) => changeRoleFunction(e)}
                            options={options.filter(
                                (option) =>
                                    option.value !== inputDetails[0]?.value
                            )}
                            className="invitationInputDetails fiche-user-input-classname"
                            isDisabled={props.user?.object?.status === 'off'}
                        />
                    </Col>
                );
        }
    };

    const handleBlur = () => {
        Axios({
            ...ApiCallConfig,
            timeout: 10000,
            method: 'PATCH',
            url: '/user',
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
            data: payload,
        })
            .then((res) => {
                if (res.status === 200) {
                    props.getData();
                    let userData = {};
                    let objectDetails = {
                        first_name: payload.first_name,
                        last_name: payload.last_name,
                        email: payload.email,
                    };
                    userData.id = activeUser[0].id;
                    userData.object = objectDetails;
                    dispatch({ type: 'UPDATE_USER', value: userData });
                }
            })
            .catch((error) => {
                setMessage(error);
            });
    };

    const renvoyerInvitatonFunction = (email) => {
        setResendInvitation(false);
        Teams.post(Workspace.id, activeUser[0].token, {
            email,
        })
            .then((result) => {
                if (result.status === 201) {
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                    }, 3000);
                } else {
                    setResendInvitation(true);
                    setShowAlertDanger(true);
                    setTimeout(() => {
                        setShowAlertDanger(false);
                    }, 3000);
                }
            })
            .catch(() => {
                setResendInvitation(true);
                setShowAlertDanger(true);
                setTimeout(() => {
                    setShowAlertDanger(false);
                }, 3000);
            });
    };
    const handleCloseAlert = () => {
        setShowAlert(false);
        setShowAlertDanger(false);
    };
    return (
        <Modal
            id="viewUserFile"
            title="Fiche utilisateur"
            className="card m-2 invite-user-container-width"
            style={{ display: 'block' }}
        >
            <div className="user-file-modal-big-container">
                <div className="fiche-utilisateur-container">
                    <Row className="user-file-first-details-container">
                        <div className="input-label mb-2">Compte / Email</div>
                        <div
                            className={
                                props.user?.object?.status === 'off'
                                    ? 'font-weight-800 display-18 mb-40 text-secondary'
                                    : 'font-weight-800 display-18 mb-40'
                            }
                        >
                            {props.user?.object?.email}
                        </div>
                        <Form.Group controlId="first_name">
                            <Form.Label className="input-label">
                                Prénom
                            </Form.Label>
                            <Form.Control
                                name="first_name"
                                type="text"
                                placeholder="Prénom..."
                                value={
                                    props.user?.object?.email ===
                                    activeUser[0].email
                                        ? payload.first_name
                                        : props.user?.object?.first_name
                                }
                                className="text-capitalize input mb-40"
                                style={{
                                    maxWidth: '35ch',
                                }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={
                                    props.user?.object?.email !==
                                    activeUser[0].email
                                }
                            />
                        </Form.Group>
                        <Form.Group controlId="last_name">
                            <Form.Label className="input-label">Nom</Form.Label>
                            <Form.Control
                                name="last_name"
                                type="text"
                                placeholder="Nom..."
                                value={
                                    props.user?.object?.email ===
                                    activeUser[0].email
                                        ? payload.last_name
                                        : props.user?.object?.last_name
                                }
                                className="text-capitalize input mb-40"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                style={{
                                    maxWidth: '35ch',
                                }}
                                disabled={
                                    props.user?.object?.email !==
                                    activeUser[0].email
                                }
                            />
                        </Form.Group>
                        <Form.Group controlId="phone_number">
                            <Form.Label className="input-label">
                                Téléphone
                            </Form.Label>
                            <Form.Control
                                name="phone_number"
                                type="text"
                                placeholder="Téléphone..."
                                value={
                                    props.user?.object?.email ===
                                    activeUser[0].email
                                        ? payload.phone_number
                                        : props.user?.object?.phone_number
                                        ? props.user?.object?.phone_number
                                        : ''
                                }
                                className="input mb-100"
                                style={{
                                    maxWidth: '15ch',
                                }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={
                                    props.user?.object?.email !==
                                    activeUser[0].email
                                }
                            />
                        </Form.Group>
                    </Row>
                    <Row className="user-file-avatar-container">
                        <div className="input-label ml-3 mb-3">
                            Photo de profil
                        </div>
                        <div
                            id="profil_photo_modal"
                            {...(props.user?.object?.email ===
                                activeUser[0].email && {
                                onClick: () =>
                                    setShowPhotoModal(!showPhotoModal),
                            })}
                        >
                            <ChangePhoto
                                getData={() => props.getData()}
                                show={showPhotoModal}
                                {...(props.user?.object?.email ===
                                activeUser[0].email
                                    ? {
                                          setShow: (val) =>
                                              setShowPhotoModal(val),
                                      }
                                    : {
                                          setShow: () =>
                                              setShowPhotoModal(false),
                                      })}
                                image={
                                    props.user?.object?.image
                                        ? props.user?.object?.image
                                        : props.user?.object?.email
                                }
                            />
                        </div>
                    </Row>
                    {['owner', 'admin'].indexOf(props.userRole) !== -1 && (
                        <Row className="user-file-Btn-container">
                            <div style={{ marginLeft: 'auto' }}>
                                {props.user?.object?.status === 'waiting' && (
                                    <DarkButton
                                        content={
                                            <>
                                                Renvoyer une <br></br>invitation
                                            </>
                                        }
                                        RW
                                        icon={<FiMail size="2.2rem" />}
                                        onClick={() =>
                                            renvoyerInvitatonFunction(
                                                props.user?.object?.email
                                            )
                                        }
                                        btnClassnameDetails="mt-0 mr-0 mb-3"
                                    />
                                )}
                                <DeleteButton
                                    content="Supprimer"
                                    RW
                                    icon={<FiTrash2 size="2.2rem" />}
                                    onClick={() =>
                                        props.deleteUser(props.user.id)
                                    }
                                    btnClassnameDetails="mt-0 mr-0"
                                />
                            </div>
                        </Row>
                    )}
                </div>
                <div className="fiche-user-alert-container">
                    <FiAlertCircle
                        size="2.2rem"
                        className="fiche-user-alert-icon"
                    />
                    <div>
                        <span className="fiche-user-alert-text">
                            Les informations de profil suivantes sont uniquement
                            liées au workspace&nbsp;:
                        </span>
                        <span className="fiche-user-alert-second-text">
                            {Workspace.object.name}
                        </span>
                        <span className="fiche-user-alert-second-text">
                            {/* {props.WorkspaceDetails.object.name} */}
                        </span>
                    </div>
                </div>
                <div className="fiche-user-role-container">
                    <span className="fiche-user-role-title-text text-uppercase">
                        Rôle dans ce workspace
                    </span>
                </div>
                <Row className="ml-3 mb-2 no-gutter">
                    <Col xl={5} sm={4} className="input-label pl-0">
                        {' '}
                        Rôle
                    </Col>
                    <Col className="input-label">Statut</Col>
                </Row>
                <Row className="ml-3 mb-100">
                    {renderInputDetails()}
                    {renderCheckForm()}
                </Row>
                <div className="fiche-user-role-container">
                    <span className="fiche-user-role-title-text text-uppercase">
                        Entreprise
                    </span>
                </div>
                <div className="ml-3 mb-5">
                    <Form.Group controlId="company_name">
                        <Form.Label className="input-label">
                            Nom de l'entreprise
                        </Form.Label>
                        <Form.Control
                            name="company_name"
                            type="text"
                            value={
                                activeUser[0].email ===
                                props.user?.object?.email
                                    ? payload.workspaces.filter(
                                          (el) => el.id === Workspace.id
                                      )[0]?.company_name
                                    : props.user?.object?.company_name
                            }
                            placeholder="Nom de l'entreprise..."
                            className="input mb-40"
                            style={{
                                maxWidth: '44ch',
                            }}
                            onBlur={handleBlur}
                            onChange={(e) => handleChange(e, 'company_name')}
                            disabled={
                                activeUser[0].email ===
                                props.user?.object?.email
                                    ? false
                                    : true
                            }
                        />
                    </Form.Group>
                    <Form.Group controlId="job">
                        <Form.Label className="input-label">
                            Poste au sein de l'entreprise
                        </Form.Label>
                        <Form.Control
                            name="job"
                            type="text"
                            value={
                                activeUser[0].email ===
                                props.user?.object?.email
                                    ? payload.workspaces.filter(
                                          (el) => el.id === Workspace.id
                                      )[0]?.job
                                    : props.user?.object?.job
                            }
                            placeholder="Poste au sein de l'entreprise..."
                            className="input"
                            style={{
                                maxWidth: '44ch',
                            }}
                            onBlur={handleBlur}
                            onChange={(e) => handleChange(e, 'job')}
                            disabled={
                                activeUser[0].email ===
                                props.user?.object?.email
                                    ? false
                                    : true
                            }
                        />
                    </Form.Group>
                </div>
            </div>
            <Alert
                color="primary"
                content="Votre demande d'invitation a bien été renvoyée !"
                showAlert={showAlert}
                setAlertShow={setShowAlert}
                handleCloseAlert={() => handleCloseAlert()}
            />
            <Alert
                color="danger"
                content={
                    resendInvitation
                        ? 'Une invitation a déjà été envoyée récemment, veuillez réessayer dans 12h'
                        : "Votre demande d'invitation n'a pas pu être renvoyée, veuillez réessayer."
                }
                showAlert={showAlertDanger}
                setAlertShow={setShowAlertDanger}
                handleCloseAlert={() => handleCloseAlert()}
            />
        </Modal>
    );
}
FicheUser.defaultProps = {
    email: '',
    mailError: '',
    data: [],
};
