import {
    Container,
    Navbar,
    Button,
    Form,
    FormControl,
    InputGroup,
    Alert,
    Row,
    Col,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import User from '../../util/User';
import Validator from '../../util/Validator';
import Config from '../../util/Config';
import Echo from '../../util/Echo';
import ErrorSelector from '../error/ErrorSelector';
import Logo from '../commun/Logo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { FiEye, FiEyeOff, FiCheck, FiInfo, FiX } from 'react-icons/fi';
export default () => {
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        document.title = Echo().seo.title.signup
            ? Echo().seo.title.prefix +
              Echo().seo.title.signup +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, []);
    if (Config.APP_ENV === 'staging') {
        return (
            <ErrorSelector
                code={200}
                title="Inscription"
                message="Page indisponible pour les beta testeurs.  Veuillez nous contacter pour participer au développement de Rankio."
                retry_value="Contactez-nous"
                retry_link="/contact"
                retry_color="outline-primary"
            />
        );
    }
    const history = useHistory();
    const [payload, setPayload] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        newsletter: false,
    });
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const [pwdInput, setPwdInput] = useState({
        type: 'password',
        on: 'd-block',
        off: 'd-none',
    });
    const [newsletter, setNewsletter] = useState(true);
    const handleChange = (event) => {
        setPayload({ ...payload, [event.target.name]: event.target.value });
        if (Object.keys(errors).length !== 0 || errors.constructor !== Object) {
            const e = Validator({
                ...payload,
                [event.target.name]: event.target.value,
            });
            if (e) setErrors(e);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const e = Validator(payload);
        if (Object.keys(e).length !== 0 || e.constructor !== Object) {
            setErrors(e);
        } else {
            User.post(payload)
                .then((res) => {
                    switch (res.status) {
                        case 201:
                        case 409:
                            const expire = Date.now() + 3600 * 1000;
                            dispatch({
                                type: 'SET_EMAIL_EXPIRY',
                                value: { email: payload.email, expire },
                            });
                            history.push('/validate');
                            break;
                        case 403:
                            setErrors({
                                email: 'Veuillez saisir votre adresse mail professionnelle.',
                            });
                            setMessage('');
                            break;
                        case 429:
                            setMessage(
                                'Trop de tentatives. Veuillez patienter quelques instants puis réessayez.'
                            );
                            break;
                        default:
                            setMessage(
                                'Un problème est survenu, veuillez réessayer.'
                            );
                            break;
                    }
                    trackEvent({
                        category: 'connection',
                        action: 'signup',
                        name: "Je m'inscris",
                        value: res.status === 201 ? 1 : 0,
                    });
                })
                .catch((msg) => {
                    setMessage(msg);
                });
        }
    };
    const showHidePwd = () => {
        if (pwdInput.type === 'password')
            setPwdInput({ type: 'text', on: 'd-none', off: 'd-block' });
        else {
            setPwdInput({ type: 'password', on: 'd-block', off: 'd-none' });
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <Navbar className="fixed-top rankio-header" bg="white" expand="xl">
                <Link to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </Link>
                <Link to="/" className="ml-auto">
                    <FiX size="1.5em" className="rk-clickable-icon-dark" />
                </Link>
            </Navbar>
            <div className="landing">
                <Row className="p-4">
                    <Col sm={1} md={2} lg={1}></Col>
                    <Col lg={4} className="d-none d-lg-block">
                        <h3 className="font-weight-800 mb-4">
                            Pour bien prioriser vos projets
                        </h3>
                        <a
                            href={
                                Config.CDN.ENDPOINT +
                                'static/media/dashboard.jpg'
                            }
                            target="_blank"
                            title="Une liste de projets priorisés"
                        >
                            <img
                                className="card w-100 mt-3 p-3"
                                src={
                                    Config.CDN.ENDPOINT +
                                    'static/media/dashboard.jpg'
                                }
                                alt="Consulter le dashboard listant les projets priorisés"
                            />
                        </a>
                    </Col>
                    <Col lg={1} className="d-none d-lg-block"></Col>
                    <Col sm={10} md={8} lg={5}>
                        <h3 className="font-weight-800 mb-4">
                            Créer votre compte Rankio
                        </h3>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group controlId="first_name">
                                        <Form.Label className="input-label mt-3">
                                            Prénom
                                        </Form.Label>
                                        <Form.Control
                                            name="first_name"
                                            type="text"
                                            placeholder="Prénom"
                                            value={payload.first_name}
                                            className="input"
                                            onChange={handleChange}
                                        />
                                        <Form.Text className="mt-2 ml-2 text-danger font-weight-bold">
                                            {errors.first_name}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="last_name">
                                        <Form.Label className="input-label mt-3">
                                            Nom
                                        </Form.Label>
                                        <Form.Control
                                            name="last_name"
                                            type="text"
                                            placeholder="Nom"
                                            value={payload.last_name}
                                            className="input"
                                            onChange={handleChange}
                                        />
                                        <Form.Text className="mt-2 ml-2 text-danger font-weight-bold">
                                            {errors.last_name}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="email">
                                <Form.Label className="input-label mt-3">
                                    E-mail
                                </Form.Label>
                                <Form.Control
                                    name="email"
                                    type="email"
                                    placeholder="nom@entreprise.com"
                                    value={payload.email}
                                    className="input"
                                    onChange={handleChange}
                                />
                                <Form.Text className="mt-2 ml-2 text-danger font-weight-bold">
                                    {errors.email}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Form.Label className="input-label mt-3">
                                    Mot de passe
                                </Form.Label>
                                <InputGroup>
                                    <FormControl
                                        name="password"
                                        type={pwdInput.type}
                                        placeholder="Mot de passe"
                                        value={payload.password}
                                        className="input"
                                        onChange={handleChange}
                                    />
                                    <InputGroup.Text
                                        id="pwdEys"
                                        style={{
                                            backgroundColor:
                                                'rgba(153, 153, 153, 0.1)',
                                            borderTopLeftRadius: '0px',
                                            borderBottomLeftRadius: '0px',
                                        }}
                                        className="cursor-pointer radius-14 border-0 text-secondary display-20 hover-grey"
                                        onClick={showHidePwd}
                                    >
                                        <FiEye className={pwdInput.off} />
                                        <FiEyeOff className={pwdInput.on} />
                                    </InputGroup.Text>
                                </InputGroup>
                                <Form.Text className="mt-2 ml-2">
                                    <span className="text-danger font-weight-bold">
                                        {!errors.password
                                            ? ''
                                            : errors.password.split('  ')[0]}
                                    </span>
                                    <ul className="pl-0">
                                        {!errors.password
                                            ? ''
                                            : errors.password
                                                  .split('  ')
                                                  .map((e, i) => {
                                                      if (i)
                                                          if (e[0] === 'o')
                                                              return (
                                                                  <li
                                                                      key={i}
                                                                      className="text-success font-weight-bold"
                                                                  >
                                                                      <FiCheck />
                                                                      {e.substring(
                                                                          1
                                                                      )}
                                                                  </li>
                                                              );
                                                          else if (
                                                              e[0] === 'x'
                                                          ) {
                                                              return (
                                                                  <li
                                                                      key={i}
                                                                      className="text-danger font-weight-bold"
                                                                  >
                                                                      <FiX />
                                                                      {e.substring(
                                                                          1
                                                                      )}
                                                                  </li>
                                                              );
                                                          }
                                                  })}
                                    </ul>
                                </Form.Text>
                            </Form.Group>
                            <Form.Group
                                className="font-weight-600 small"
                                controlId="formBasicCheckbox"
                            >
                                <Form.Check
                                    className="mt-3"
                                    type="checkbox"
                                    label="J'accepte de recevoir des informations sur les produits, les offres et l'entreprise Rankio"
                                    value="1"
                                    checked={payload.newsletter}
                                    onChange={(e) =>
                                        handleChange({
                                            target: {
                                                value: e.currentTarget.checked,
                                                name: 'newsletter',
                                            },
                                        })
                                    }
                                />
                            </Form.Group>
                            <center>
                                <Button
                                    className="btn-lg btn-primary w-100 mt-4"
                                    style={{
                                        height: '5rem',
                                        maxWidth: '340px',
                                    }}
                                    type="submit"
                                >
                                    <span className="h3 font-weight-800 display-24">
                                        Tester
                                    </span>
                                </Button>
                            </center>
                            <Form.Group>
                                <Form.Text className="text-danger font-weight-bold">
                                    {message}
                                </Form.Text>
                            </Form.Group>
                            <Alert
                                variant="secondary"
                                className="my-4 bg-gray text-black font-weight-600 display-12 border-0"
                            >
                                <Row className="align-items-center">
                                    <Col xs="auto">
                                        <FiInfo size="2.5rem" />
                                    </Col>
                                    <Col>
                                        En envoyant ce formulaire, vous
                                        confirmez que vous acceptez nos &nbsp;
                                        <Link
                                            to="/condition"
                                            className="text-primary"
                                        >
                                            Conditions d'utilisation
                                        </Link>
                                        &nbsp; et consentez au traitement de vos
                                        données personnelles tel qu'indiqué dans
                                        notre &nbsp;
                                        <Link
                                            to="/privacy"
                                            className="text-primary"
                                        >
                                            Politique de respect de la vie
                                            privée.
                                        </Link>
                                    </Col>
                                </Row>
                            </Alert>
                        </Form>
                    </Col>
                    <Col sm={1} md={2} lg={1}></Col>
                </Row>
            </div>
        </Container>
    );
};
