import { Row } from 'react-bootstrap';
import { FiInfo, FiX, FiAlertTriangle } from 'react-icons/fi';
import { useEffect } from 'react';
var showTimeout = null;
export default (props) => {
    const showStyle = props.showAlert ? ' d-block' : ' d-none';
    const setIcon = () => {
        if (props.color == 'primary') {
            return <FiInfo size="1.75em" className="ml-3 mr-3" />;
        } else if (props.color == 'danger') {
            return <FiAlertTriangle size="1.75em" className="ml-3 mr-3" />;
        }
    };
    const setExit = () => {
        if (props.color == 'primary') {
            return (
                <FiX
                    size="1.5em"
                    className="rk-clickable-icon-light my-auto"
                    onClick={() => {
                        props.handleCloseAlert();
                    }}
                />
            );
        } else if (props.color == 'danger') {
            return (
                <FiX
                    size="1.5em"
                    className="rk-clickable-icon-danger my-auto"
                    onClick={() => {
                        props.handleCloseAlert();
                    }}
                />
            );
        }
    };
    useEffect(() => {
        clearTimeout(showTimeout);
        showTimeout = null;
        showTimeout = setTimeout(function () {
            props.handleCloseAlert();
        }, props.timeout || 5000);
    }, [props.content, props.showAlert === true]);
    return (
        <div
            className={'rk-alert bg-' + props.color + showStyle}
            onMouseLeave={() => {
                props.onMouseLeave !== false && props.handleCloseAlert();
            }}
        >
            <Row className="mt-2 w-100 mx-0">
                {setIcon()}
                <span
                    className={`${props.contentClassname} p-0 display-14 font-weight-700 mt-1`}
                >
                    {props.content}
                </span>
                <span style={{ position: 'absolute', right: '20px' }}>
                    {setExit()}
                </span>
            </Row>
        </div>
    );
};
