import React, { useEffect, useState } from 'react';
import Config from '../../util/Config';
import { useHistory, useParams, Link } from 'react-router-dom';
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarContent,
    SidebarFooter,
} from 'react-pro-sidebar';

import {
    FiLayout,
    FiFileText,
    FiTag,
    FiClock,
    FiArrowLeft,
    FiArrowRight,
    FiKey,
    FiActivity,
    FiUsers,
    FiPlus,
    FiGrid,
} from 'react-icons/fi';
import { Button, Dropdown } from 'react-bootstrap';
import '../styles/sidebar.css';
import '../styles/sidebar.scss';
import { useDispatch, useSelector } from 'react-redux';
export default (props) => {
    const { id } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id))[0];
    const Workspaces = users.filter((user) => user.id.includes(id))[0]
        .workspace;
    const Workspace = Workspaces[activeUser.activeWorkspace];
    let menuCollapse = props.menuCollapse;
    const [sideBarOpen, setSideBarOpen] = useState(
        !JSON.parse(localStorage.getItem('menuCollapsed'))
    );
    let setMenuCollapse = props.setMenuCollapse;
    function menuIconClick() {
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
        localStorage.setItem('menuCollapsed', !menuCollapse);
        setSideBarOpen(!sideBarOpen);
    }
    function getMenuCollapsed() {
        if (localStorage.getItem('menuCollapsed') !== null) {
            setMenuCollapse(JSON.parse(localStorage.getItem('menuCollapsed')));
        }
    }
    const switchWorkspace = async (wsId) => {
        await dispatch({ type: 'SET_ACTIVE_WORKSPACE', value: wsId });
        if (Workspace.object.status === 'unpaid') {
            history.push(
                '/' + id + '/subscription/' + Workspace.object.subscription
            );
        } else {
            history.push('/' + id + '/dashboard');
        }
    };
    useEffect(() => {
        getMenuCollapsed();
    }, []);
    return (
        <div id="header" className="m-2">
            <ProSidebar collapsed={menuCollapse} id="main-sidebar">
                <SidebarHeader className="p-2">
                    <div
                        className="closemenu"
                        onClick={() => {
                            menuIconClick();
                        }}
                    >
                        {menuCollapse ? (
                            <FiArrowRight
                                size="1.5em"
                                className="rk-clickable-icon-dark"
                            />
                        ) : (
                            <FiArrowLeft
                                size="1.5em"
                                className="rk-clickable-icon-dark"
                            />
                        )}
                    </div>
                </SidebarHeader>
                <SidebarContent>
                    <Menu iconShape="square">
                        <Dropdown className="mx-2 mt-2" id="dropdownWorkspace">
                            <Dropdown.Toggle className="p-0 mx-2">
                                <Button
                                    className="btn-sm btn-sm-circle"
                                    to="/account"
                                >
                                    {Workspace.object.image ? (
                                        <img
                                            src={
                                                Config.CDN.ENDPOINT +
                                                Workspace.object.image
                                            }
                                            className="profil-icon"
                                        />
                                    ) : (
                                        <strong className="text-center font-weight-800 text-uppercase text-lg">
                                            {Workspaces[
                                                activeUser.activeWorkspace
                                            ].object.name.substr(0, 2)}
                                        </strong>
                                    )}
                                </Button>
                                {sideBarOpen && (
                                    <span className="display-18 font-weight-800 color-black text-truncate active-ws ml-10">
                                        {Workspace.object.name}
                                    </span>
                                )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                className="radius-14 bg-white shadow dropdown-menu-right p-0 mt-1"
                                style={{ width: '200px' }}
                            >
                                {Workspaces.filter(
                                    (wSpace) => wSpace.id !== Workspace.id
                                ).map((ws, index) => (
                                    <div
                                        className="flex v-center m-10 pointer"
                                        onClick={() => switchWorkspace(ws.id)}
                                    >
                                        <Button className="btn-sm btn-sm-circle btn-dark">
                                            {ws.object.image ? (
                                                <img
                                                    src={
                                                        Config.CDN.ENDPOINT +
                                                        ws.object.image
                                                    }
                                                    className="profil-icon"
                                                />
                                            ) : (
                                                <strong className="text-center font-weight-800 text-uppercase text-lg">
                                                    {ws.object.name.substr(
                                                        0,
                                                        2
                                                    )}
                                                </strong>
                                            )}
                                        </Button>
                                        {sideBarOpen && (
                                            <span className="display-16 font-weight-800 color-black ml-10 text-truncate active-ws pointer">
                                                {ws.object.name}
                                            </span>
                                        )}
                                    </div>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>

                        <div className="h-menu scroll-y-on-hover">
                            <MenuItem
                                icon={<FiLayout size="2.5em" />}
                                active={props.open === 'dashboard'}
                                className="mb-2"
                            >
                                <span className="display-16">Dashboard</span>
                                {Workspace.object.status === 'unpaid' ? (
                                    <>
                                        <Link
                                            to={
                                                '/' +
                                                id +
                                                '/subscription/' +
                                                Workspace.object.subscription
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Link to={'/' + id + '/dashboard'} />
                                    </>
                                )}
                            </MenuItem>
                            <MenuItem
                                icon={<FiFileText size="2.5em" />}
                                active={props.open === 'project'}
                                className=" my-2"
                            >
                                <span className="display-16">Projet</span>
                                {Workspace.object.status === 'unpaid' ? (
                                    <>
                                        <Link
                                            to={
                                                '/' +
                                                id +
                                                '/subscription/' +
                                                Workspace.object.subscription
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Link to={'/' + id + '/project'} />
                                    </>
                                )}
                            </MenuItem>
                            <MenuItem
                                id="submenu-item"
                                className="ml-3 mb-2"
                                icon={<FiTag size="2em" />}
                                active={props.open === 'tags'}
                            >
                                <span className="display-14">Tags</span>
                                {Workspace.object.status === 'unpaid' ? (
                                    <>
                                        <Link
                                            to={
                                                '/' +
                                                id +
                                                '/subscription/' +
                                                Workspace.object.subscription
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Link to={'/' + id + '/tags'} />
                                    </>
                                )}
                            </MenuItem>
                            <MenuItem
                                icon={<FiClock size="2.5em" />}
                                active={props.open === 'session'}
                                className=" my-2"
                            >
                                <span className="display-16">Session</span>
                                {Workspace.object.status === 'unpaid' ? (
                                    <>
                                        <Link
                                            to={
                                                '/' +
                                                id +
                                                '/subscription/' +
                                                Workspace.object.subscription
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Link to={'/' + id + '/session'} />
                                    </>
                                )}
                            </MenuItem>
                            <MenuItem
                                icon={<FiGrid size="2.5em" />}
                                active={props.open === 'workspace'}
                                className=" my-2"
                            >
                                <span className="display-16">Workspace</span>
                                {Workspace.object.status === 'unpaid' ? (
                                    <>
                                        <Link
                                            to={
                                                '/' +
                                                id +
                                                '/subscription/' +
                                                Workspace.object.subscription
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Link to={'/' + id + '/workspace'} />
                                    </>
                                )}
                            </MenuItem>
                            <MenuItem
                                id="submenu-item"
                                className="ml-3 mb-2"
                                icon={<FiKey size="2em" />}
                                active={props.open === 'axis'}
                            >
                                <span className="display-14">
                                    Axes de
                                    <br />
                                    priorisation
                                </span>
                                {Workspace.object.status === 'unpaid' ? (
                                    <>
                                        <Link
                                            to={
                                                '/' +
                                                id +
                                                '/subscription/' +
                                                Workspace.object.subscription
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Link to={'/' + id + '/axis'} />
                                    </>
                                )}
                            </MenuItem>
                            <MenuItem
                                id="submenu-item"
                                className="ml-3 mb-2"
                                icon={<FiActivity size="2em" />}
                                active={props.open === 'wsjf'}
                            >
                                <span className="display-14">
                                    Méthode de
                                    <br />
                                    calcul WSJF
                                </span>
                                {Workspace.object.status === 'unpaid' ? (
                                    <>
                                        <Link
                                            to={
                                                '/' +
                                                id +
                                                '/subscription/' +
                                                Workspace.object.subscription
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Link to={'/' + id + '/wsjf'} />
                                    </>
                                )}
                            </MenuItem>
                            <MenuItem
                                id="submenu-item"
                                className="ml-3 mb-2"
                                icon={<FiUsers size="2em" />}
                                active={props.open === 'teams'}
                            >
                                <span className="display-14">Utilisateurs</span>
                                {Workspace.object.status === 'unpaid' ? (
                                    <>
                                        <Link
                                            to={
                                                '/' +
                                                id +
                                                '/subscription/' +
                                                Workspace.object.subscription
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Link to={'/' + id + '/teams'} />
                                    </>
                                )}
                            </MenuItem>
                        </div>
                    </Menu>
                </SidebarContent>
                <SidebarFooter className="mb-3">
                    <Menu>
                        <MenuItem
                            icon={
                                <FiPlus
                                    size="2.5em"
                                    className="rk-clickable-icon-dark"
                                />
                            }
                            active={true}
                            className=" my-2"
                        >
                            <span className="display-16">
                                Nouveau
                                <br /> workspace
                            </span>
                            <Link to={'/' + id + '/onboarding/reset'} />
                        </MenuItem>
                    </Menu>
                </SidebarFooter>
            </ProSidebar>
        </div>
    );
};
