const environments = {
    development: {
        API: {
            ENDPOINT: 'https://detroit.api.rankio.app',
            TIMEOUT: 3000,
        },
        WSS: {
            ENDPOINT: 'wss://detroit.api.rankio.app',
        },
        CDN: {
            ENDPOINT: 'https://detroit.cdn.rankio.app/',
            CACHEAGE: 31536000,
        },
        ANALYTICS: {
            urlBase: 'https://analytics.rankio.app',
            siteId: 3,
        },
    },
    local: {
        API: {
            ENDPOINT: 'http://localhost:8080',
            TIMEOUT: 9000,
        },
        WSS: {
            ENDPOINT: 'ws://localhost:8080',
        },
        CDN: {
            ENDPOINT: 'https://detroit.cdn.rankio.app/',
            CACHEAGE: 31536000,
        },
        ANALYTICS: {
            urlBase: 'https://analytics.rankio.app',
            siteId: 3,
        },
    },
    test: {
        API: {
            ENDPOINT: 'https://detroit.api.rankio.app',
            TIMEOUT: 5000,
        },
        WSS: {
            ENDPOINT: 'wss://detroit.api.rankio.app',
        },
        CDN: {
            ENDPOINT: 'https://detroit.cdn.rankio.app/',
            CACHEAGE: 31536000,
        },
        ANALYTICS: {
            urlBase: 'https://analytics.rankio.app',
            siteId: 3,
        },
    },
    staging: {
        API: {
            ENDPOINT: 'https://beta.api.rankio.app',
            TIMEOUT: 3000,
        },
        WSS: {
            ENDPOINT: 'wss://beta.api.rankio.app',
        },
        CDN: {
            ENDPOINT: 'https://detroit.cdn.rankio.app/',
            CACHEAGE: 31536000,
        },
        ANALYTICS: {
            urlBase: 'https://analytics.rankio.app',
            siteId: 4,
            // disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
            heartBeat: {
                // optional, enabled by default
                active: true, // optional, default value: true
                seconds: 10, // optional, default value: `15
            },
            // linkTracking: false, // optional, default value: true
            configurations: {
                disableCookies: true,
                setSecureCookie: true,
                setRequestMethod: 'POST',
            },
        },
    },
    production: {
        API: {
            ENDPOINT: 'https://api.rankio.app',
            TIMEOUT: 2000,
        },
        WSS: {
            ENDPOINT: 'wss://api.rankio.app',
        },
        CDN: {
            ENDPOINT: 'https://cdn.rankio.app/',
            CACHEAGE: 31536000,
        },
        ANALYTICS: {
            urlBase: 'https://analytics.rankio.app',
            siteId: 2,
            // disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
            heartBeat: {
                // optional, enabled by default
                active: true, // optional, default value: true
                seconds: 10, // optional, default value: `15
            },
            // linkTracking: false, // optional, default value: true
            configurations: {
                disableCookies: true,
                setSecureCookie: true,
                setRequestMethod: 'POST',
            },
        },
    },
};
export default {
    APP_ENV: process.env.REACT_APP_ENV || 'production',
    APP_ENDPOINT: window.location.origin + '/',
    TAG_VERSION: process.env.REACT_APP_TAG_VERSION,
    MAX_ATTACHMENT_SIZE: 5000000,
    ...environments[process.env.REACT_APP_ENV || 'production'],
};
