import { Row, Col, Form, Button } from 'react-bootstrap';
import Switch from 'react-switch';
import { FiLock, FiMail, FiImage, FiTrash2, FiInfo } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import Validator from '../../util/Validator';
import User from '../../util/User';
import Modal from '../modal/Modal';
import { useState, useEffect } from 'react';
import ChangePassword from './ChangePassword';
import ChangeEmail from './ChangeEmail';
import ChangePhoto from './ChangePhoto';
import $ from 'jquery';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import { useSelector, useDispatch } from 'react-redux';
import Echo from '../../util/Echo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
export default () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const Workspace = activeUser[0].workspace[activeUser[0].activeWorkspace];
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [errors, setErrors] = useState({});
    const [change, setChange] = useState(false);
    const [payload, setPayload] = useState({
        workspaces: [
            {
                job: '',
                company_name: '',
            },
        ],
    });
    const [message, setMessage] = useState('');
    const handleChange = (event, input = '') => {
        if (input === 'job' || input === 'company_name') {
            const newPayload = payload;
            const index = newPayload.workspaces.findIndex(
                (el) => el.id === Workspace.id
            );
            if (index !== -1) {
                newPayload.workspaces[index][input] = event.target.value;
            } else {
                newPayload.workspaces.push({
                    id: Workspace.id,
                    job: input === 'job' ? event.target.value : '',
                    company_name:
                        input === 'company_name' ? event.target.value : '',
                    notification: true,
                });
            }
            setPayload(newPayload);
            setChange(!change);
        } else {
            setPayload({ ...payload, [event.target.name]: event.target.value });
            setChange(!change);
            const e = Validator({
                ...payload,
                [event.target.name]: event.target.value,
            });
            if (e) setErrors(e);
        }
    };
    const deleteMyAccount = (commit = false) => {
        if (commit) {
            User.delete(activeUser[0].token)
                .then((res) => {
                    if (res.status === 202) {
                        dispatch({
                            type: 'LOGOUT',
                            value: activeUser[0].id,
                        });
                        window.location.href = '/login';
                    } else {
                        window.location.href = '/error/403';
                    }
                })
                .catch((err) => {
                    window.location.href = '/error/400';
                });
        } else {
            setShowModalDelete(true);
        }
    };
    const { trackPageView } = useMatomo();
    useEffect(() => {
        document.title = Echo().seo.title.account_profile
            ? Echo().seo.title.prefix +
              Echo().seo.title.account_profile +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        getUserDetails();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, []);

    const getUserDetails = (event) => {
        Axios({
            ...ApiCallConfig,
            method: 'GET',
            url: '/user',
            params: {
                id: users[0].id,
            },
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
        }).then((res) => {
            if (res.status === 200) {
                let userObject = {
                    email: res.data.data[0].object.email,
                    first_name: res.data.data[0].object.first_name,
                    last_name: res.data.data[0].object.last_name,
                    phone_number: res.data.data[0].object.phone_number,
                };
                if (
                    res.data.data[0].object.workspaces &&
                    res.data.data[0].object.workspaces.length
                ) {
                    userObject.workspaces = res.data.data[0].object.workspaces;
                } else {
                    userObject.workspaces = [
                        {
                            id: Workspace.id,
                            job: '',
                            company_name: '',
                            notification: true,
                        },
                    ];
                }
                setPayload(userObject);
                setChange(!change);
            }
        });
    };
    const handleBlur = () => {
        const e = Validator(payload);
        if (Object.keys(e).length !== 0 || e.constructor !== Object) {
            setErrors(e);
        } else {
            Axios({
                ...ApiCallConfig,
                timeout: 10000,
                method: 'PATCH',
                url: '/user',
                headers: {
                    authorization: 'Bearer ' + activeUser[0].token,
                },
                data: payload,
            })
                .then((res) => {
                    if (res.status === 200) {
                        getUserDetails();
                        let userData = {};
                        let objectDetails = {
                            first_name: payload.first_name,
                            last_name: payload.last_name,
                            email: payload.email,
                        };
                        userData.id = activeUser[0].id;
                        userData.object = objectDetails;
                        dispatch({ type: 'UPDATE_USER', value: userData });
                    }
                })
                .catch((error) => {
                    setMessage(error);
                });
        }
    };
    $(document).on('click', function (e) {
        if (
            $(e.target).closest('#profil_photo_modal').length === 0 &&
            showPhotoModal
        ) {
            setShowPhotoModal(false);
        }
    });

    return (
        <>
            <div className="card text-black m-2 pb-2">
                <Row className="no-gutters bg-gray rounded-top">
                    <div className="font-weight-bold display-24 py-3 ml-3 text-uppercase">
                        Profil utilisateur
                    </div>
                </Row>
                <ChangePassword />
                <ChangeEmail />
                <div className="profil-container">
                    <Row className="mt-3">
                        <Col sm={5} xl={6}>
                            <div className="input-label m-2 ml-3">
                                Compte / Email
                            </div>
                            <div className="font-weight-800 display-18 m-2 ml-3">
                                {payload.email}
                            </div>
                            <div className="mt-4">
                                <Form className="ml-2">
                                    <Form.Group controlId="first_name">
                                        <Form.Label className="input-label m-2">
                                            Prénom
                                        </Form.Label>
                                        <Form.Control
                                            name="first_name"
                                            className="text-capitalize input mx-2 mb-2"
                                            type="text"
                                            placeholder="Prénom..."
                                            value={payload.first_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{ maxWidth: '35ch' }}
                                        />
                                        <Form.Text className="text-danger font-weight-bold ml-3 mb-4">
                                            {errors.first_name}
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="last_name">
                                        <Form.Label className="input-label m-2">
                                            Nom
                                        </Form.Label>
                                        <Form.Control
                                            name="last_name"
                                            className="text-capitalize input mx-2 mb-2"
                                            type="text"
                                            placeholder="Nom..."
                                            value={payload.last_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{ maxWidth: '35ch' }}
                                        />
                                        <Form.Text className="text-danger font-weight-bold ml-3 mb-4">
                                            {errors.last_name}
                                        </Form.Text>
                                    </Form.Group>

                                    <Row className="font-weight-bold display-16 mt-2">
                                        <Col className="col-auto pr-0">
                                            <Form.Group controlId="phone_number_dialing_code">
                                                <Form.Label className="input-label m-2">
                                                    FR
                                                </Form.Label>
                                                <Form.Control
                                                    name="phone_number_dialing_code"
                                                    className="input mx-2 mb-2"
                                                    type="text"
                                                    value="+33"
                                                    style={{ maxWidth: '6ch' }}
                                                    disabled
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-auto pl-0">
                                            <Form.Group controlId="phone_number">
                                                <Form.Label className="input-label m-2">
                                                    Téléphone
                                                </Form.Label>
                                                <Form.Control
                                                    name="phone_number"
                                                    className="input mx-2 mb-2"
                                                    type="text"
                                                    placeholder="Téléphone..."
                                                    value={
                                                        payload.phone_number ||
                                                        ''
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    style={{ maxWidth: '15ch' }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                        <Col sm={7} xl={6}>
                            <Row className="input-label mt-2 ml-0">
                                Photo de profil
                            </Row>
                            <Row
                                className="mt-3"
                                onClick={() => {
                                    setShowPhotoModal(!showPhotoModal);
                                }}
                                id="profil_photo_modal"
                                style={{ width: 100 }}
                            >
                                <ChangePhoto
                                    show={showPhotoModal}
                                    setShow={setShowPhotoModal}
                                />
                            </Row>

                            <Row
                                style={{
                                    position: 'absolute',
                                    right: '60px',
                                    top: '8px',
                                }}
                            >
                                <Button
                                    variant="primary"
                                    className="btn-l"
                                    style={{ height: '60px', width: '220px' }}
                                    onClick={() => {
                                        document.getElementById(
                                            'profil_change_pw'
                                        ).style.display = 'block';
                                    }}
                                >
                                    <Row>
                                        <Col sm={2} className="my-auto pl-1">
                                            <FiLock size="2.2rem" />
                                        </Col>
                                        <Col
                                            sm={10}
                                            className="display-16 font-weight-bold text-align-left pl-4 pr-0"
                                        >
                                            Modifier votre
                                            <br /> mot de passe
                                        </Col>
                                    </Row>
                                </Button>
                            </Row>
                            <Row
                                style={{
                                    position: 'absolute',
                                    right: '60px',
                                    top: '88px',
                                }}
                            >
                                <Button
                                    variant="dark"
                                    className="btn-l"
                                    style={{ height: '60px', width: '220px' }}
                                    onClick={() => {
                                        document.getElementById(
                                            'profil_change_email'
                                        ).style.display = 'block';
                                    }}
                                >
                                    <Row>
                                        <Col sm={2} className="my-auto pl-1">
                                            <FiMail size="2.2rem" />
                                        </Col>
                                        <Col
                                            sm={10}
                                            className="display-16 font-weight-bold text-align-left pl-4 pr-0"
                                        >
                                            Modifier votre
                                            <br /> mail
                                        </Col>
                                    </Row>
                                </Button>
                            </Row>
                            {/*<Row
                                style={{
                                    position: 'absolute',
                                    right: '60px',
                                    top: '168px',
                                }}
                            >
                                <Button
                                    variant="dark"
                                    className="btn-l"
                                    style={{ height: '60px', width: '220px' }}
                                    onClick={() => {
                                        setShowPhotoModal(true);
                                    }}
                                    id="profil_photo_modal"
                                >
                                    <Row>
                                        <Col sm={2} className="my-auto pl-1">
                                            <FiImage size="2.2rem" />
                                        </Col>
                                        <Col
                                            sm={10}
                                            className="display-16 font-weight-bold text-align-left pl-4 pr-0"
                                        >
                                            Modifier votre
                                            <br /> photo
                                        </Col>
                                    </Row>
                                </Button>
                            </Row>*/}
                            <Row
                                style={{
                                    position: 'absolute',
                                    right: '60px',
                                    top: '168px',
                                    /*top: '248px',*/
                                }}
                            >
                                <Button
                                    variant="delete"
                                    className="btn-l"
                                    style={{ height: '60px', width: '220px' }}
                                >
                                    <Row>
                                        <Col sm={2} className="my-auto pl-1">
                                            <FiTrash2 size="2.2rem" />
                                        </Col>
                                        <Col
                                            sm={10}
                                            className="display-16 font-weight-bold text-align-left pl-4 pr-0"
                                            onClick={() => deleteMyAccount()}
                                        >
                                            Supprimer
                                            <br /> votre compte
                                        </Col>
                                    </Row>
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                    <Row
                        className="no-gutters font-weight-bold p-3 mt-5 display-18"
                        style={{
                            backgroundColor: 'rgba(0, 85, 255, 0.1)',
                        }}
                    >
                        <Col sm={12} className="d-none d-xl-block">
                            <FiInfo
                                color="#0055ff"
                                size="2.2rem"
                                className="mr-3"
                            />
                            <span>
                                Les informations de profil suivantes sont
                                uniquement liées au workspace&nbsp;:
                            </span>
                            <span className="font-weight-800 pl-1">
                                {Workspace.object.name}
                            </span>
                        </Col>
                        <Col sm={12} className="d-xl-none d-block display-16">
                            <FiInfo
                                color="#0055ff"
                                size="2.2rem"
                                className="mr-3 mt-1"
                            />
                            <span>
                                Les informations de profil suivantes sont
                                uniquement liées au workspace&nbsp;:
                            </span>
                            <span className="font-weight-800 pl-1">
                                {Workspace.object.name}
                            </span>
                        </Col>
                    </Row>
                    <Row className="no-gutters bg-gray font-weight-bold display-24 p-3 my-3 text-uppercase">
                        Rôle dans ce workspace
                    </Row>
                    <Row className="mt-3 mb-5">
                        <Col xs={4} className="ml-2">
                            <div className="input-label m-2">Rôle</div>
                            <div className="display-18 font-weight-800 m-2 pt-1">
                                {Workspace.object.teams.on.filter(
                                    (el) => el.id === activeUser[0].id
                                )[0].role === 'owner'
                                    ? 'Propriétaire'
                                    : Workspace.object.teams.on.filter(
                                          (el) => el.id === activeUser[0].id
                                      )[0].role === 'guest'
                                    ? 'Invité'
                                    : Workspace.object.teams.on.filter(
                                          (el) => el.id === activeUser[0].id
                                      )[0].role === 'member'
                                    ? 'Participant'
                                    : 'Admin'}
                            </div>
                        </Col>
                        <Col>
                            <div className="input-label m-2">Statut</div>
                            <div className="mt-2">
                                <Switch
                                    onColor={'#0055FF'}
                                    offColor={'#999999'}
                                    disabled={true}
                                    checked={
                                        Workspace.object.teams.on.filter(
                                            (el) => el.id === activeUser[0].id
                                        ).length > 0
                                            ? true
                                            : false
                                    }
                                    checkedIcon={
                                        <p className="font-weight-bold display-12 text-white pt-10 pl-3">
                                            Actif
                                        </p>
                                    }
                                    height={40}
                                    width={100}
                                    handleDiameter={35}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="no-gutters bg-gray mt-5 font-weight-bold display-24 p-3 mb-3 text-uppercase">
                        Entreprise
                    </Row>
                    <Row className="mt-3">
                        <Col sm={5} xl={6}>
                            <Form className="ml-2 pb-5">
                                <Form.Group controlId="nom_entreprise">
                                    <Form.Label className="input-label m-2">
                                        Nom de l'entreprise
                                    </Form.Label>
                                    <Form.Control
                                        className="input mx-2 mb-4"
                                        type="text"
                                        placeholder="Nom entreprise..."
                                        style={{ maxWidth: '44ch' }}
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                            handleChange(e, 'company_name')
                                        }
                                        value={
                                            payload.workspaces.filter(
                                                (el) => el.id === Workspace.id
                                            )[0]?.company_name || ''
                                        }
                                    />
                                </Form.Group>
                                <Form.Group controlId="job">
                                    <Form.Label className="input-label m-2">
                                        Poste au sein de l'entreprise
                                    </Form.Label>
                                    <Form.Control
                                        name="job"
                                        className="input mx-2 mb-2"
                                        type="text"
                                        placeholder="Poste..."
                                        value={
                                            payload.workspaces.filter(
                                                (el) => el.id === Workspace.id
                                            )[0]?.job
                                        }
                                        style={{ maxWidth: '44ch' }}
                                        onBlur={handleBlur}
                                        onChange={(e) => handleChange(e, 'job')}
                                    />
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal
                show={showModalDelete}
                centered={true}
                modalFirstText="Etes-vous certain de vouloir supprimer votre compte ?"
                modalSecondText="Cette action est irréversible."
                modalThirdText=""
                modalContainerClassname="axeModalContainerClassname"
                modalContentClassName="axeModalContentClassName"
                closeIcon="axeModalcloseIcon cursorPointer"
                containerTextWithIconClassname="axesContainerTextWithIconClassname"
                alertIconClassName="axesAlertIconClassName"
                firstBtnClassName="axeModalFirstBtn"
                secondBtnClassName="axeModalSecondBtn"
                setShowModalFunction={() => setShowModalDelete(false)}
                textClassName="axeModalTextClassName"
                saveBtnFunction={() => deleteMyAccount(true)}
            />
        </>
    );
};
