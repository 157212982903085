import { Container, Fade, Navbar, Button, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link, useHistory } from 'react-router-dom';
import { validate as isUuid } from 'uuid';
import Subscription from '../../util/Subscription';
import Workspace from '../../util/Workspace';
import Logo from '../commun/Logo';
import Echo from '../../util/Echo';
import { FiX } from 'react-icons/fi';
import { IoHelp } from 'react-icons/io5';
import { useMatomo } from '@datapunt/matomo-tracker-react';
export default () => {
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const selectedPlan = activeUser[0].selectedPlan;
    const selectedSubscription = activeUser[0].selectedSubscription;
    const selectedWorkspace = activeUser[0].selectedWorkspace;
    const [payload, setPayload] = useState({
        onboarding: true,
    });
    const [logo, setLogo] = useState(false);
    const [allContent, setAllContent] = useState(true);
    const [content, setContent] = useState(false);
    const [message, setMessage] = useState('');
    const { trackPageView } = useMatomo();
    useEffect(() => {
        document.title = Echo().seo.title.onboarding
            ? Echo().seo.title.prefix +
              Echo().seo.title.onboarding +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, []);
    useEffect(() => {
        switch (true) {
            case activeUser[0].first_name === undefined:
            case activeUser[0].last_name === undefined:
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/profile');
                }, 1000);
                break;
            case !isUuid(selectedPlan):
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/reset');
                }, 1000);
                break;
            case !isUuid(selectedSubscription):
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/letsgo');
                }, 1000);
                break;
            case !isUuid(selectedWorkspace):
                setTimeout(() => {
                    history.push('/' + id + '/onboarding/workspace');
                }, 1000);
                break;
            default:
                Workspace.get({}, activeUser[0].token)
                    .then((res) => {
                        if (res.status === 200) {
                            const workspaces = res.data.data.filter(
                                (w) => w.id === selectedWorkspace
                            );
                            dispatch({
                                type: 'UPDATE_WORKSPACE_LIST',
                                value: {
                                    workspace: res.data.data,
                                    id: activeUser[0].id,
                                },
                            });
                            dispatch({
                                type: 'SET_ACTIVE_WORKSPACE',
                                value: selectedWorkspace,
                            });
                            if (workspaces.length) {
                                setContent(true);
                                if (
                                    res.data.data[0].object.status === 'unpaid'
                                ) {
                                    history.push(
                                        '/' + id + '/onboarding/checkout'
                                    );
                                }
                            } else {
                                setTimeout(() => {
                                    window.location.reload();
                                }, 20000);
                            }
                        } else if (res.status === 404) {
                            history.push('/' + id + '/onboarding/reset');
                        } else {
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                        }
                    })
                    .catch((msg) => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 20000);
                    });
        }
        window.scrollTo(0, 0);
    }, []);
    const handleDone = (page = 'dashboard') => {
        setLogo(true);
        setAllContent(false);
        Workspace.patch(selectedWorkspace, activeUser[0].token, {
            onboarding: true,
        })
            .then((res) => {
                setLogo(true);
                setContent(false);
                if (res.status === 200 || res.status === 204) {
                    dispatch({
                        type: 'SET_SELECTED_PLAN',
                        value: { id: activeUser[0].id, data: null },
                    });
                    dispatch({
                        type: 'SET_SELECTED_SUBSCRIPTION',
                        value: { id: activeUser[0].id, data: null },
                    });
                    dispatch({
                        type: 'SET_SELECTED_WORKSPACE',
                        value: { id: activeUser[0].id, data: null },
                    });
                    Workspace.get({}, activeUser[0].token)
                        .then((res) => {
                            if (res.status === 200) {
                                dispatch({
                                    type: 'UPDATE_WORKSPACE_LIST',
                                    value: {
                                        workspace: res.data.data,
                                        id: activeUser[0].id,
                                    },
                                });
                                history.push('/' + id + '/' + page);
                            } else {
                                history.push('/error/' + res.status);
                            }
                        })
                        .catch((msg) => {
                            history.push('/error/400');
                        });
                } else if (res.status === 202 || res.status === 204) {
                    setTimeout(() => {
                        history.push('/' + id + '/' + page);
                    }, 1000);
                } else {
                    history.push('/error/' + res.status);
                }
            })
            .catch((err) => {
                history.push('/error/400');
            });
    };
    return (
        <>
            <Fade in={logo}>
                <div style={{ height: '100%' }}>
                    <center
                        style={{
                            width: 'auto',
                            height: 'auto',
                            position: 'absolute',
                            top: '45%',
                            bottom: '0',
                            left: '0',
                            right: '0',
                            margin: 'auto',
                        }}
                    >
                        <div className="mb-5 px-3">
                            <Logo />
                        </div>
                    </center>
                </div>
            </Fade>

            <Fade in={allContent}>
                <Container fluid>
                    <Navbar
                        className="fixed-top rankio-header"
                        bg="white"
                        expand="xl"
                    >
                        <Link to="/">
                            <Navbar.Brand>
                                <Logo />
                            </Navbar.Brand>
                        </Link>
                        <div className="ml-auto">
                            <a
                                title="Nous contacter"
                                href="/contact"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="mr-4"
                            >
                                <IoHelp
                                    size="1.5em"
                                    className="rk-clickable-icon-dark"
                                />
                            </a>
                            <Link
                                title="Dashboard"
                                to={'/' + id + '/dashboard'}
                                className="ml-auto"
                            >
                                <FiX
                                    size="1.5em"
                                    className="rk-clickable-icon-dark"
                                />{' '}
                            </Link>
                        </div>
                    </Navbar>
                    <Fade in={content}>
                        <div className="landing">
                            <Row className="p-4">
                                <Col sm={1} md={2} lg={2} xxl={4}></Col>
                                <Col sm={10} md={8} lg={8} xxl={7}>
                                    <h3 className="font-weight-800 mt-4 mb-5">
                                        La configuration de votre workspace est
                                        maintenant terminée&nbsp;!
                                        <br />
                                    </h3>
                                    <p className="font-weight-600">
                                        Maintenant que vos axes de priorisation
                                        sont définis, vous pouvez dès à présent
                                        utiliser pleinement l'application.
                                    </p>
                                    <p className="font-weight-600">
                                        Vous pouvez ainsi commencer à créer des
                                        projets, ou bien naviguer librement
                                        depuis votre dashboard.
                                    </p>
                                    <Row className="p-0">
                                        <Col sm={10} md={8} lg={6} xxl={4}>
                                            <Button
                                                className="btn-lg btn-primary h3 font-weight-800 display-24 w-100 mt-5"
                                                onClick={() => {
                                                    handleDone('dashboard');
                                                }}
                                                style={{
                                                    height: '5rem',
                                                    maxWidth: '340px',
                                                }}
                                            >
                                                Voir le dashboard
                                            </Button>
                                        </Col>
                                        <Col sm={10} md={8} lg={6} xxl={4}>
                                            <Button
                                                className="btn-lg btn-primary h3 font-weight-800 display-24 w-100 mt-5"
                                                onClick={() => {
                                                    handleDone(
                                                        'project/#view_details_project'
                                                    );
                                                }}
                                                style={{
                                                    height: '5rem',
                                                    maxWidth: '340px',
                                                }}
                                            >
                                                Créer des projets
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Fade>
                </Container>
            </Fade>
        </>
    );
};
