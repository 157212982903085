import Axios from 'axios';
import ApiCallConfig from './ApiCallConfig';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FiCheck } from 'react-icons/fi';
import Select from 'react-select';
import Echo from './Echo';
import Teams from './Teams';
import TeamData from './TeamData';
import TeamIcon from '../components/commun/TeamIcon';
export default class Subscription {
    /**
     * @function get
     * @definition Get subscription
     * @returns {Promise}
     */
    static get(params, token) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'GET',
                url: '/subscription',
                params,
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    /**
     * @function post
     * @definition Create subscription
     * @returns {Promise}
     */
    static post(token, data) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'POST',
                url: '/subscription',
                headers: {
                    authorization: 'Bearer ' + token,
                },
                data,
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    /**
     * @function get
     * @definition Get subscription
     * @returns {Promise}
     */
    static checkout(id, token) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                timeout: 30000,
                method: 'GET',
                url: '/subscription/checkout/' + id,
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    /**
     * @function patch
     * @definition Update subscription
     * @returns {Promise}
     */
    static patch(id, token, data) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                timeout: 30000,
                method: 'PATCH',
                url: '/subscription/' + id,
                headers: {
                    authorization: 'Bearer ' + token,
                },
                data,
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    /**
     * @function delete
     * @definition Remove subscription
     * @returns {Promise}
     */
    static delete(id, token) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'DELETE',
                url: '/subscription/' + id,
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static getExpireDate = (subscription) => {
        switch (true) {
            case subscription?.object?.stripe?.date !== undefined:
                return new Date(
                    subscription?.object?.stripe.date * 1000
                ).toLocaleDateString('fr-FR', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                });
            case subscription?.object?.test_date !== undefined:
                return new Date(
                    subscription?.object?.test_date +
                        86400000 * (14 + subscription?.object?.extra_trial)
                ).toLocaleDateString('fr-FR', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                });
            default:
                return null;
        }
    };
    static renderAdmin = (teams, workspaceId = 0, token = 0, edit = false) => {
        const options = [
            { value: 'member', label: Echo().teams.fields.role.member },
            { value: 'admin', label: Echo().teams.fields.role.admin },
            { value: 'guest', label: Echo().teams.fields.role.guest },
        ];
        if (teams?.length)
            return (
                <>
                    <div className="font-weight-bold mb-4">
                        Liste des utilisateurs avec un rôle d'Admin&nbsp;:
                    </div>
                    {teams?.map((t) => {
                        const tData = TeamData(t.object);
                        return (
                            <Row className="mt-3 v-center mx-0">
                                <Col xs="auto" className="px-0">
                                    <TeamIcon team={t.object} my="0000" />
                                </Col>
                                <Col className="add-option-collapse-first-text text-capitalize display-16 mt-0 ml-0 pl-0">
                                    {tData.value}
                                </Col>
                                <Col className="add-option-collapse-first-text text-capitalize display-16 mt-0 ml-0 pl-0">
                                    {edit ? (
                                        <Select
                                            value={options.filter(
                                                (option) =>
                                                    option.value ===
                                                    t.object.role
                                            )}
                                            onChange={(e) => {
                                                Teams.patch(
                                                    workspaceId,
                                                    t.id,
                                                    token,
                                                    { role: e.value }
                                                );
                                            }}
                                            options={options.filter(
                                                (option) =>
                                                    option.value !==
                                                    t.object.role
                                            )}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </Col>
                            </Row>
                        );
                    })}
                </>
            );
        return (
            <div className="font-weight-bold mb-4">
                Aucun utilisateur actif avec le rôle d'Admin.
            </div>
        );
    };
}
