import { Row } from 'react-bootstrap';
import TagButton from './TagButton';

export default (props) => {
    return (
        <>
            {props.tags?.length ? (
                <Row className="p-1">
                    {props?.tags.map((tag) => {
                        return <TagButton tag={tag} size={props.size} />;
                    })}
                </Row>
            ) : (
                ''
            )}
        </>
    );
};
