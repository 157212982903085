import React from 'react';

function WorkspaceHeader(props) {
    return (
        <div className="bg-gray text-black rounded-top workspace-header-style flex v-center">
            <span className="flex v-center workspace-title py-3 ml-3">
                GESTION DU WORKSPACE
            </span>
        </div>
    );
}

export default WorkspaceHeader;
