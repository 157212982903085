import { Container, Navbar, Row, Col, Card } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import User from '../../util/User';
import Workspace from '../../util/Workspace';
import Logo from '../commun/Logo';
import { FiX, FiLoader, FiArrowDown } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import BlueButton from '../commun/BlueButton';
import Token from '../../util/Token';
export default () => {
    const dispatch = useDispatch();
    const { token } = useParams();
    const history = useHistory();
    const location = useLocation();
    const users = useSelector((state) => state.user.users);
    const landing = () => {
        return (
            <div className="text-center">
                <h3 className="font-weight-800 my-5">
                    Vérification en cours
                    <br />
                </h3>
                <p>Veuillez patienter quelques instants</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <FiLoader size={36} className="rotating" />
            </div>
        );
    };
    const validation = (emails) => {
        return (
            <div className="text-center">
                <h3 className="font-weight-800 my-5">
                    Confirmez la modification de votre adresse mail
                    <br />
                </h3>
                <Card
                    className="display-18 text-center font-weight-bold my-5 mx-auto p-2"
                    style={{ maxWidth: '32rem' }}
                >
                    <p className="my-2">{emails.old}</p>
                    <p className="my-2">
                        <FiArrowDown size="2.0em" className="m-2" />
                    </p>
                    <p className="my-2">{emails.new}</p>
                </Card>
                <p>
                    Pour modifier votre adresse mail,
                    veuillez cliquer ci-dessous :
                </p>
                <BlueButton
                    btnClassnameDetails="btn-width-unset"
                    content="Modifier mon mail"
                    onClick={() => {
                        validate();
                    }}
                />
            </div>
        );
    };
    const loading = () => {
        return (
            <div className="text-center">
                <h3 className="font-weight-800 my-5">
                    Modification en cours
                    <br />
                </h3>
                <p>Veuillez patienter quelques instants</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <FiLoader size={36} className="rotating" />
            </div>
        );
    };
    const success = () => {
        return (
            <>
                <div className="text-center">
                    <h3 className="font-weight-800 my-5">
                        Modification terminée
                        <br />
                    </h3>
                    <p>Vous allez être redirigé automatiquement</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <FiLoader size={36} className="rotating" />
                </div>
            </>
        );
    };
    const fail = (msg, emails) => {
        return (
            <>
                <div className="text-center">
                    <h3 className="font-weight-800 my-5">
                        Echec de la modification !
                        <br />
                    </h3>
                    {emails ? (
                        <Card
                            className="display-18 text-center font-weight-700 my-5 mx-auto p-2"
                            style={{ maxWidth: '32rem' }}
                        >
                            <p className="my-2">{emails.old}</p>
                            <p className="my-2">
                                <FiArrowDown size="2.0em" className="m-2" />
                            </p>
                            <p className="my-2">{emails.new}</p>
                        </Card>
                    ) : (
                        <></>
                    )}
                    <p>{msg}</p>
                </div>
            </>
        );
    };
    const [content, setContent] = useState(landing());
    const validate = () => {
        setContent(loading());
        setTimeout(() => {
            User.setEmail(token)
                .then((res) => {
                    setContent(success());
                    setTimeout(() => {
                        history.push(
                            '/' +
                                res.data.data.id.split('-')[0] +
                                '/account/profile'
                        );
                    }, 2000);
                })
                .catch((err) => {
                    console.log(err);
                    setContent(fail(err));
                });
        }, 1000);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            // check token validity
            Token.get(token)
                .then((data) => {
                    const payload = jwt_decode(token);
                    switch (true) {
                        case !payload?.email:
                        case !payload?.update?.new:
                        case !payload?.update?.old:
                            setContent(fail('Le lien magique est invalide'));
                            break;
                        case data.email === payload?.update?.new:
                            setContent(
                                fail(
                                    'Cette modification a déjà été prise en compte.',
                                    payload.update
                                )
                            );
                            break;
                        default:
                            setContent(validation(payload.update));
                    }
                    // update token
                    dispatch({
                        type: 'SET_USER_TOKEN',
                        value: data,
                    });
                    User.get({}, data.token)
                        .then((user) => {
                            switch (user.status) {
                                case 200:
                                    if (
                                        users.filter((user) =>
                                            user.id.includes(
                                                data.id.split('-')[0]
                                            )
                                        ).length === 0
                                    ) {
                                        dispatch({
                                            type: 'ADD_USER',
                                            value: {
                                                ...user.data.data[0],
                                                workspace: [],
                                            },
                                            token: data.token,
                                        });
                                    } else {
                                        dispatch({
                                            type: 'UPDATE_USER',
                                            value: {
                                                ...user.data.data[0],
                                            },
                                        });
                                    }
                                    Workspace.get({}, data.token)
                                        .then((workspace) => {
                                            if (workspace.status === 200) {
                                                dispatch({
                                                    type: 'UPDATE_WORKSPACE_LIST',
                                                    value: {
                                                        workspace:
                                                            workspace.data.data,
                                                        id: user.data.data[0]
                                                            .id,
                                                    },
                                                });
                                            }
                                        })
                                        .catch(() => {
                                            history.push('/error/400');
                                        });
                                    break;
                                case 404:
                                    history.push('/signup');
                                    break;
                                default:
                                    history.push('/error/' + user.status);
                            }
                        })
                        .catch(() => {
                            history.push('/error/400');
                        });
                })
                .catch(() => {
                    setContent(fail('Le lien magique a expiré'));
                });
        }, 1000);
    }, []);
    return (
        <Container fluid>
            <Navbar className="fixed-top rankio-header" bg="white" expand="xl">
                <Link to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </Link>
                <Link to="/" className="ml-auto">
                    <FiX size="1.5em" className="rk-clickable-icon-dark" />{' '}
                </Link>
            </Navbar>
            <div className="w-75 m-auto py-5">{content}</div>
        </Container>
    );
};
