import Modal from '../commun/RkModal';
import React, { useState, useEffect } from 'react';
import Switch from 'react-switch';
import { Col, Form, Row } from 'react-bootstrap';
import {
    FiTrash2,
    FiArchive,
    FiLink,
    FiPlay,
    FiPlusCircle,
    FiPlus,
    FiCalendar,
    FiInfo,
} from 'react-icons/fi';
/* import DarkButton from '../commun/DarkButton'; */
import DeleteButton from '../commun/DeleteButton';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import $ from 'jquery';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import Echo from '../../util/Echo';
import GreyButton from '../commun/GreyButton';
import BlueButton from '../commun/BlueButton';
import SessionInviterPersonnes from './SessionInviterPersonnes';
import SessionAddProjects from './SessionAddProjects';
import { useHistory } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import TeamIcon from '../commun/TeamIcon';
import Alert from '../commun/Alert';

export default function FicheSession(props) {
    registerLocale('fr', fr);
    const users = useSelector((state) => state.user.users);
    const { id } = useParams();
    const history = useHistory();

    const activeUser = users.filter((user) => user.id.includes(id));
    const ws = users.filter((user) => user.id.includes(id))[0].workspace[
        activeUser[0].activeWorkspace
    ];
    const [sessionUsers, setSessionUsers] = useState([]);
    const [sessionProjects, setSessionProjects] = useState([]);
    const [teams, setTeams] = useState([]);
    const [guests, setGuests] = useState([]);
    const [projectsToPriorize, setProjectsToPriorize] = useState([]);
    const [projectsPriorized, setProjectsPriorized] = useState([]);
    const [change, setChange] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [colorAlert, setColorAlert] = useState('danger');
    const [contentAlert, setContentAlert] = useState(
        'Une erreur est survenue, veuillez nous en excuser'
    );
    const [my] = useState(activeUser[0]);
    const workspace = activeUser[0].workspace[activeUser[0].activeWorkspace];
    const editRole = ['owner', 'admin'].includes(
        workspace.object.teams.on.filter(
            (team) => team.id === activeUser[0].id
        )[0].role
    );
    const liveRole =
        workspace.object.teams.on.filter(
            (team) => team.id === activeUser[0].id
        )[0].role === 'owner' ||
        props?.SelectedSession?.object.teams.filter(
            (t) => t?.id === activeUser[0].id
        ).length === 1;
    console.log(props?.SelectedSession?.object.teams);
    console.log('liveRole', liveRole);
    const archivable =
        editRole &&
        !['00_to_plan', '01_planned', '06_archived'].includes(
            props?.SelectedSession?.object.status
        );
    const editStatus = ['00_to_plan', '01_planned'].includes(
        props?.SelectedSession?.object.status
    );
    useEffect(() => {
        getUsersList();
    }, [props.SelectedSession.object.teams]);
    useEffect(() => {
        getProjecsList();
    }, [props.SelectedSession.object.project]);
    const getUsersList = () => {
        let url = `workspace/${ws.id}/teams`;
        Axios({
            ...ApiCallConfig,
            method: 'GET',
            url: url,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
        }).then((res) => {
            if (res.status === 200) {
                setSessionUsers(res.data.data);
                let teamArray = [];
                let guestArray = [];
                if (props.Mode === 'ADD') {
                    res.data.data.map((el, i) => {
                        if (
                            el.object.role !== 'guest' &&
                            el.object.status !== 'off'
                        ) {
                            teamArray.push(el);
                        } else if (el.object.status !== 'off') {
                            guestArray.push(el);
                        }
                        if (i === res.data.data.length - 1) {
                            setTeams(teamArray);
                            setGuests(guestArray);
                        }
                    });
                } else {
                    res.data.data.map((el, i) => {
                        if (
                            el.object.status !== 'off' &&
                            props.SelectedSession.object.teams.filter(
                                (team) => team.id === el.id
                            ).length
                        ) {
                            teamArray.push(el);
                        } else if (el.object.status !== 'off') {
                            guestArray.push(el);
                        }
                        if (i === res.data.data.length - 1) {
                            setTeams(teamArray);
                            setGuests(guestArray);
                        }
                    });
                }
            }
        });
    };
    const getProjecsList = () => {
        let url = `workspace/${ws.id}/project`;
        Axios({
            ...ApiCallConfig,
            method: 'GET',
            url: url,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
        }).then((res) => {
            if (res.status === 200) {
                let priorizedArray = [];
                let toPriorizeArray = [];
                setSessionProjects(res.data.data);
                res.data.data.map((el, i) => {
                    if (
                        !props.SelectedSession.object.project.filter(
                            (pr) => pr.id === el.id
                        )?.length
                    ) {
                        if (el.object.status === 'to_prioritize') {
                            toPriorizeArray.push(el);
                        } else if (
                            el.object.status !== 'archived' &&
                            el.object.status !== 'done' &&
                            el.object.status !== 'aborted' &&
                            el.object.status !== 'in_progress'
                        ) {
                            priorizedArray.push(el);
                        }
                    }
                    if (i === res.data.data.length - 1) {
                        setProjectsPriorized(priorizedArray);
                        setProjectsToPriorize(toPriorizeArray);
                    }
                });
            }
        });
    };
    const displayRole = (role) => {
        if (role === 'admin' || role?.value === 'admin') {
            return <span className="fiche-user-owner-text">Admin </span>;
        }
        if (role === 'owner' || role?.value === 'owner') {
            return <span className="fiche-user-owner-text">Propriétaire</span>;
        }
        if (role === 'member' || role?.value === 'member') {
            return <span className="fiche-user-owner-text">Participant</span>;
        }
        if (role === 'guest' || role?.value === 'guest') {
            return <span className="fiche-user-owner-text">Invité</span>;
        }
    };
    const inviteUserModal = () => {
        let teamArray = [];
        let guestArray = [];
        if (props.Mode === 'ADD') {
            sessionUsers.map((el, i) => {
                if (el.object.status !== 'off' && el.object.role !== 'guest') {
                    teamArray.push(el);
                } else if (el.object.status !== 'off') {
                    guestArray.push(el);
                }
                if (i === sessionUsers.length - 1) {
                    setTeams(teamArray);
                    setGuests(guestArray);
                }
            });
        } else {
            sessionUsers.map((el, i) => {
                if (
                    el.object.status !== 'off' &&
                    props.SelectedSession.object.teams.filter(
                        (team) => team.id === el.id
                    ).length
                ) {
                    teamArray.push(el);
                } else if (el.object.status !== 'off') {
                    guestArray.push(el);
                }
                if (i === sessionUsers.length - 1) {
                    setTeams(teamArray);
                    setGuests(guestArray);
                }
            });
        }
        document.getElementById('viewSessionInvitePersonnes').style.display =
            'block';
    };
    const addMembersFunction = (teamsData) => {
        if (teamsData.length) {
            setTeams([...teams, ...teamsData]);
            props.getInvitations(teamsData);
        }
    };
    const showAddProjectModal = () => {
        document.getElementById('session_add_projects').style.display = 'block';
    };
    const goToSessionDetails = () => {
        history.push({
            pathname:
                '/' +
                id +
                '/workspace/' +
                props.SelectedSession.object.workspace +
                '/session/' +
                props.SelectedSession.id,
            state: {
                sessionId: props.SelectedSession.id,
                teams: props.Teams,
                projects: props.ProjectList,
            },
        });
    };
    const forbiddenStatus = [
        '02_prioritizing',
        '03_reviewing',
        '04_done',
        '05_aborted',
        '06_archived',
    ];

    return (
        <>
            <Modal
                id="view_create_session"
                title="Fiche session"
                className="card m-2 invite-user-container-width"
                style={{ display: 'block' }}
            >
                <div className="user-file-modal-big-container pb-5">
                    {editRole && (
                        <Row
                            className="no-gutters font-weight-700 p-3 mt-3 display-16"
                            style={{
                                backgroundColor: 'rgba(0, 85, 255, 0.1)',
                            }}
                        >
                            <Col lg={'auto'} className="my-auto mr-3">
                                <FiInfo color="#0055ff" size="2.2rem" />
                            </Col>
                            <Col className="align-middle my-auto">
                                Pour pouvoir ouvrir une session au vote, il faut que
                                sa date de scoring soit supérieure ou égale à la
                                date du jour, qu'un ou plusieurs projets soient
                                listés, et qu'au moins un utilisateur avec un rôle
                                de votant soit ajouté&nbsp;!
                            </Col>
                        </Row>
                    )}
                    <div className="session-container">
                        <Row className="session-file-first-details-container">
                            <Form.Group controlId="session_name">
                                <Form.Label className="input-label">
                                    Nom de la session
                                </Form.Label>
                                <Form.Control
                                    name="session_name"
                                    type="text"
                                    placeholder="Nom de la session"
                                    value={props?.SelectedSession?.object?.name}
                                    className="input mb-40"
                                    style={{
                                        maxWidth: '35ch',
                                    }}
                                    onChange={(e) =>
                                        props.onSessionNameChange(e)
                                    }
                                    onBlur={() => {
                                        props.Mode === 'ADD'
                                            ? props.onBlurAddSession()
                                            : props.onBlurEditSession();
                                    }}
                                    disabled={
                                        editRole && editStatus ? false : true
                                    }
                                />
                            </Form.Group>
                            <Form.Group controlId="session_code">
                                <Form.Label className="input-label">
                                    Code de la session
                                </Form.Label>
                                <Form.Control
                                    name="session_code"
                                    type="text"
                                    placeholder="Code"
                                    value={props?.SelectedSession?.object?.code}
                                    className="input mb-40"
                                    style={{
                                        maxWidth: '10ch',
                                    }}
                                    onChange={props.onSessionCodeChange}
                                    onBlur={() => {
                                        props.Mode === 'ADD'
                                            ? props.onBlurAddSession()
                                            : props.onBlurEditSession();
                                    }}
                                    disabled={
                                        editRole && editStatus ? false : true
                                    }
                                />
                            </Form.Group>
                            <div className="mb-100">
                                <div className="input-label mb-2">
                                    Date du scoring
                                </div>
                                <Row className="m-0">
                                    <ReactDatePicker
                                        className="input style-datepicker-input"
                                        dateFormat={'P'}
                                        placeholderText="JJ/MM/AAAA"
                                        selected={
                                            props.SelectedSession.object
                                                .due_date &&
                                            props.SelectedSession.object
                                                .due_date !== ''
                                                ? new Date(
                                                      props.SelectedSession.object.due_date
                                                  )
                                                : undefined
                                        }
                                        onSelect={(date) =>
                                            props.setDueDate(date)
                                        }
                                        locale="fr"
                                        disabled={
                                            editRole && editStatus
                                                ? false
                                                : true
                                        }
                                    />
                                    <FiCalendar
                                        size="1.3rem"
                                        className="datepicker-icon my-auto"
                                    />
                                </Row>
                            </div>
                        </Row>
                        <Row className="user-file-avatar-container">
                            <div className="mb-4">
                                <div className="input-label mb-3">
                                    Ouverture de la session
                                </div>
                                <Switch
                                    className="pb-2"
                                    id="session-switch"
                                    onColor={'#32C832'}
                                    offColor={'#FF2B00'}
                                    checked={
                                        props.SelectedSession.object.status ===
                                        '02_prioritizing'
                                    }
                                    uncheckedIcon={
                                        <p className="font-weight-bold display-12 text-white pt-10">
                                            Fermée
                                        </p>
                                    }
                                    checkedIcon={
                                        <p className="font-weight-bold display-12 text-white pl-2 pt-10">
                                            Ouverte
                                        </p>
                                    }
                                    height={40}
                                    width={100}
                                    handleDiameter={35}
                                    onChange={() =>
                                        props.selectSessionStatus(
                                            props.SelectedSession.object
                                                .status === '01_planned'
                                                ? {
                                                      value: '02_prioritizing',
                                                  }
                                                : { value: '01_planned' }
                                        )
                                    }
                                    disabled={
                                        editRole && editStatus ? false : true
                                    }
                                />
                            </div>
                            <div className="input-label mb-2">
                                Statut de la session
                            </div>
                            <div class="font-weight-800 mb-40">
                                {
                                    Echo().session.fields.status[
                                        props?.SelectedSession?.object.status
                                    ]
                                }
                            </div>
                        </Row>
                        {props.Mode !== 'ADD' && (
                            <Row className="session-Btn-container">
                                <div
                                    onClick={() => {
                                        if (
                                            props.SelectedSession.object
                                                .status === '02_prioritizing'
                                        ) {
                                            goToSessionDetails();
                                        } else {
                                            setShowAlert(true);
                                            setColorAlert('danger');
                                            setContentAlert(
                                                    !editRole ? (
                                                        <>
                                                            Vous ne pouvez accéder à une session live qu'après son ouverture par l'admin !
                                                        </>
                                                    ) : (
                                                        <>
                                                            Vous ne pouvez lancer une session live qu'après son ouverture !
                                                        </>
                                                    )
                                            );
                                        }
                                    }}
                                >
                                    <BlueButton
                                        content={
                                            !editRole ? (
                                                <>
                                                    Accéder à la<br></br>session
                                                    live
                                                </>
                                            ) : (
                                                <>
                                                    Lancer la<br></br>session
                                                </>
                                            )
                                        }
                                        RW
                                        icon={<FiPlay size="2.2rem" />}
                                        btnClassnameDetails="mt-0 mr-0"
                                        {...(props.SelectedSession.object
                                            .status === '02_prioritizing' &&
                                        liveRole
                                            ? { disabled: false }
                                            : { disabled: true })}
                                    />
                                </div>
                                {/* <DarkButton
                                    content={
                                        <>
                                            Copier le lien <br></br>de la session
                                        </>
                                    }
                                    icon={<FiLink size="2.2rem" />}
                                    btnClassnameDetails='mr-0'
                                    RW
                                /> */}
                                {archivable ? (
                                    <GreyButton
                                        content={'Archiver'}
                                        icon={<FiArchive size="2.2rem" />}
                                        btnClassnameDetails="mr-0"
                                        onClick={() =>
                                            props.archive(
                                                props.SelectedSession.id
                                            )
                                        }
                                    />
                                ) : (
                                    <></>
                                )}
                                {editRole ? (
                                    <DeleteButton
                                        content="Supprimer"
                                        RW
                                        icon={<FiTrash2 size="2.2rem" />}
                                        btnClassnameDetails="mr-0"
                                        onClick={() =>
                                            props.delete(
                                                props.SelectedSession.id
                                            )
                                        }
                                    />
                                ) : (
                                    <></>
                                )}
                            </Row>
                        )}
                    </div>
                    <div className="session-role-container mb-4">
                        <span className="fiche-user-role-title-text text-uppercase">
                            {props.SelectedSession.object.status === '04_done'
                                ? 'Projets priorisés lors de la session'
                                : 'Projets à prioriser lors de la session'}
                        </span>
                    </div>
                    <div>
                        <div className="session-display-tab">
                            <div className="pl-3">
                                <div className="session-first-table border border-gray mb-4">
                                    {props.SelectedSession.object.project.map(
                                        (el, i) => {
                                            let classNameDetails =
                                                'session-second-col';
                                            let inputclassname =
                                                'invitationSecondInputDetails';
                                            if (i % 2 === 0) {
                                                classNameDetails =
                                                    'session-first-col';
                                                inputclassname =
                                                    'invitationInputDetails';
                                            } else if (
                                                i ===
                                                props.SelectedSession.object
                                                    .project.length -
                                                    1
                                            ) {
                                                classNameDetails =
                                                    classNameDetails +
                                                    ' invitationListeDetailsLastCol';
                                            }
                                            return (
                                                <div
                                                    className={classNameDetails}
                                                >
                                                    <span className="session-projet-table">
                                                        {el?.object?.name}
                                                    </span>
                                                    {!forbiddenStatus.includes(
                                                        props.SelectedSession
                                                            .object.status
                                                    ) && (
                                                        <div className="session-table-btn">
                                                            <div
                                                                className="invitationBtnDeleteContainer"
                                                                onClick={() =>
                                                                    props.deleteProject(
                                                                        el.id
                                                                    )
                                                                }
                                                            >
                                                                {editRole &&
                                                                    editStatus && (
                                                                        <FiTrash2
                                                                            size="2rem"
                                                                            color="#999999"
                                                                            className="mx-3"
                                                                        />
                                                                    )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                            {props.Mode !== 'ADD' &&
                                !forbiddenStatus.includes(
                                    props.SelectedSession.object.status
                                ) && (
                                    <div className="pr-15">
                                        <div className="session-table-add-btn">
                                            {editRole && editStatus && (
                                                <BlueButton
                                                    content={<>Ajouter</>}
                                                    icon={
                                                        <FiPlusCircle size="2.2rem" />
                                                    }
                                                    btnClassnameDetails="mt-0 mr-0"
                                                    onClick={() =>
                                                        showAddProjectModal()
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                        </div>
                        {props.Mode !== 'ADD' &&
                            !forbiddenStatus.includes(
                                props.SelectedSession.object.status
                            ) &&
                            editRole &&
                            editStatus && (
                                <FiPlus
                                    size="2.2rem"
                                    className="rk-clickable-icon-primary mb-5 ml-3"
                                    onClick={() => showAddProjectModal()}
                                />
                            )}
                        <div className="session-role-container mb-4">
                            <span className="fiche-user-role-title-text text-uppercase">
                                {props.SelectedSession.object.status ===
                                '04_done'
                                    ? 'Personnes invitées lors de la session'
                                    : 'Personnes à inviter pour la session'}
                            </span>
                        </div>
                        <div className="session-display-tab">
                            <div xl={8} className="pl-3">
                                <div className="session-first-table border border-gray mb-4">
                                    {props.SelectedSession.object.teams.length
                                        ? props.SelectedSession.object.teams.map(
                                              (el, i) => {
                                                  let classNameDetails =
                                                      'session-second-col';
                                                  let inputclassname =
                                                      'invitationSecondInputDetails';
                                                  if (i % 2 === 0) {
                                                      classNameDetails =
                                                          'session-first-col';
                                                      inputclassname =
                                                          'invitationInputDetails';
                                                  } else if (
                                                      i ===
                                                      props.SelectedSession
                                                          .object.teams.length -
                                                          1
                                                  ) {
                                                      classNameDetails =
                                                          classNameDetails +
                                                          ' invitationListeDetailsLastCol';
                                                  }
                                                  return (
                                                      <div
                                                          className={
                                                              classNameDetails
                                                          }
                                                      >
                                                          <div className="session-name-width">
                                                              <TeamIcon
                                                                  team={
                                                                      el?.object
                                                                  }
                                                                  my={props.my}
                                                              />
                                                          </div>
                                                          <div className="session-text-container">
                                                              {displayRole(
                                                                  el?.object
                                                                      ?.role
                                                              )}
                                                          </div>
                                                          {!forbiddenStatus.includes(
                                                              props
                                                                  .SelectedSession
                                                                  .object.status
                                                          ) && (
                                                              <div className="session-table-btn">
                                                                  <div
                                                                      className="invitationBtnDeleteContainer"
                                                                      onClick={() => {
                                                                          props.deleteParticipantItem(
                                                                              el
                                                                          );
                                                                          setTeams(
                                                                              [
                                                                                  ...teams,
                                                                                  el,
                                                                              ]
                                                                          );
                                                                      }}
                                                                  >
                                                                      {editRole &&
                                                                          editStatus && (
                                                                              <FiTrash2
                                                                                  size="2rem"
                                                                                  color="#999999"
                                                                                  className="mx-3"
                                                                              />
                                                                          )}
                                                                  </div>
                                                              </div>
                                                          )}
                                                      </div>
                                                  );
                                              }
                                          )
                                        : teams.map((el, i) => {
                                              let classNameDetails =
                                                  'session-second-col';
                                              let inputclassname =
                                                  'invitationSecondInputDetails';
                                              if (i % 2 === 0) {
                                                  classNameDetails =
                                                      'session-first-col';
                                                  inputclassname =
                                                      'invitationInputDetails';
                                              } else if (
                                                  i ===
                                                  props.SelectedSession.object
                                                      .teams.length -
                                                      1
                                              ) {
                                                  classNameDetails =
                                                      classNameDetails +
                                                      ' invitationListeDetailsLastCol';
                                              }
                                              return (
                                                  <div
                                                      className={
                                                          classNameDetails
                                                      }
                                                  >
                                                      <div className="session-name-width">
                                                          {el.object
                                                              ?.first_name &&
                                                          el.object
                                                              ?.Last_name ? (
                                                              <div className="session-invite-member-container mb-0">
                                                                  <span className="session-invite-member-text ">
                                                                      {el.object?.first_name
                                                                          .charAt(
                                                                              0
                                                                          )
                                                                          .toUpperCase() +
                                                                          el.object?.Last_name.charAt(
                                                                              0
                                                                          ).toUpperCase()}
                                                                  </span>
                                                              </div>
                                                          ) : (
                                                              <div className="session-invite-member-container mb-0">
                                                                  <span className="session-invite-member-text ">
                                                                      {el.object?.email
                                                                          .charAt(
                                                                              0
                                                                          )
                                                                          .toUpperCase() +
                                                                          el.object?.email
                                                                              .charAt(
                                                                                  1
                                                                              )
                                                                              .toUpperCase()}
                                                                  </span>
                                                              </div>
                                                          )}
                                                      </div>
                                                      <div className="session-text-container">
                                                          {displayRole(
                                                              el.object?.role
                                                          )}
                                                      </div>
                                                      <div className="session-table-btn">
                                                          {!forbiddenStatus.includes(
                                                              props
                                                                  .SelectedSession
                                                                  .object.status
                                                          ) && (
                                                              <div
                                                                  className="invitationBtnDeleteContainer"
                                                                  onClick={() =>
                                                                      props.deleteParticipantItem(
                                                                          el
                                                                      )
                                                                  }
                                                              >
                                                                  <FiTrash2
                                                                      size="2rem"
                                                                      color="#999999"
                                                                      className="mx-3"
                                                                  />
                                                              </div>
                                                          )}
                                                      </div>
                                                  </div>
                                              );
                                          })}
                                </div>
                            </div>
                            {props.Mode !== 'ADD' &&
                                !forbiddenStatus.includes(
                                    props.SelectedSession.object.status
                                ) && (
                                    <div className="pr-15">
                                        <div className="session-table-add-btn">
                                            {editRole && editStatus && (
                                                <BlueButton
                                                    content={<>Ajouter</>}
                                                    icon={
                                                        <FiPlusCircle size="2.2rem" />
                                                    }
                                                    btnClassnameDetails="mt-0 mr-0"
                                                    onClick={() =>
                                                        inviteUserModal()
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                    {props.Mode !== 'ADD' &&
                        !forbiddenStatus.includes(
                            props.SelectedSession.object.status
                        ) &&
                        editRole &&
                        editStatus && (
                            <FiPlus
                                size="2.2rem"
                                className="rk-clickable-icon-primary ml-3"
                                onClick={() => inviteUserModal()}
                            />
                        )}
                </div>
            </Modal>
            <SessionAddProjects
                updateProjectsList={props.updateProjectsList}
                SelectedProjectList={props.SelectedProjectList}
                sessionName={props?.SelectedSession?.object?.name}
                checkUncheckProject={props.checkUncheckProject}
                clearSelectedProjects={props.clearSelectedProjects}
                SelectedSession={props.SelectedSession}
                projectsToPriorize={projectsToPriorize}
                projectsPriorized={projectsPriorized}
            />
            <SessionInviterPersonnes
                sessionName={props?.SelectedSession?.object?.name}
                addMembers={addMembersFunction}
                guestsList={guests}
            />
            <Alert
                color={colorAlert}
                content={contentAlert}
                showAlert={showAlert}
                handleCloseAlert={() => setShowAlert(false)}
            />
        </>
    );
}
FicheSession.defaultProps = {
    email: '',
    mailError: '',
    data: [],
};
