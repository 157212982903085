import Modal from '../commun/RkModal';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Col, Form, Row } from 'react-bootstrap';
import { FiSearch, FiTrash2, FiMail, FiX, FiSave } from 'react-icons/fi';
import DarkButton from '../commun/DarkButton';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import Echo from '../../util/Echo';
import GreyButton from '../commun/GreyButton';
import BlueButton from '../commun/BlueButton';
import InviteUser from '../usersModal/InviteUser';
import TeamIcon from '../commun/TeamIcon';

export default function SessionInviterPersonnes(props) {
    const options = [
        { value: 'member', label: Echo().teams.fields.role.member },
        { value: 'admin', label: Echo().teams.fields.role.admin },
        { value: 'guest', label: Echo().teams.fields.role.guest },
    ];
    const users = useSelector((state) => state.user.users);
    const { id } = useParams();

    const activeUser = users.filter((user) => user.id.includes(id));
    const ws = users.filter((user) => user.id.includes(id))[0].workspace[
        activeUser[0].activeWorkspace
    ];
    const [searchInput, setSearchInput] = useState('');
    const [teams, setTeams] = useState([]);
    const [emailDetails, setEmailDetails] = useState('');
    const [mailTextError, setMailTextError] = useState('');
    const [invitationData, setInvitationData] = useState([]);
    const [sessionInvitationData, setSessionInvitationData] = useState([]);
    const [change, setChange] = useState(false);
    const [selectedItem, setSelectedItem] = useState('guest');
    useEffect(() => {
        getUsersList();
    }, [props.guestsList]);

    useEffect(() => {
        renderArrayInvitation();
    }, [invitationData]);
    useEffect(() => {
        renderMemberItems();
    }, [teams]);

    const getUsersList = () => {
        setTeams(props.guestsList);
    };
    const changeSerachValue = (e) => {
        setSearchInput(e.target.value);
        if (!e.target.value.length) {
            getUsersList();
        } else {
            let teamsFilter = teams.filter((el) =>
                el.object?.email.includes(e.target.value)
            );
            setTeams(teamsFilter);
        }
    };
    const renderMemberItems = () => {
        return teams.map((el) => (
            <div>
                <span onClick={() => addMemberItemClicked(el)}>
                    <TeamIcon team={el.object} my={activeUser[0]} />
                </span>
            </div>
        ));
    };
    const addMemberItemClicked = (el) => {
        let itemExist = [];
        itemExist = invitationData.filter(
            (element) => element.object?.email === el.object?.email
        );
        setTeams(
            teams.filter(
                (element) => element.object?.email !== el.object?.email
            )
        );
        if (!itemExist.length) {
            // let role = ''
            // if (el.object?.role === 'member') {
            //     role = 'Participant'
            // }
            // else if (el.object?.role === 'guest') {
            //     role = 'Invité'
            // }
            // else if (el.object?.role === 'admin') {
            //     role = 'Admin'
            // }
            setInvitationData([...invitationData, el]);
        }
    };
    const validateEmail = (value) => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
    };
    const handleSubmit = () => {
        let validateEmailDetails = validateEmail(emailDetails);
        if (
            validateEmailDetails === false ||
            emailDetails.trim().length === 0
        ) {
            setMailTextError('Veuillez vérifier votre adresse mail');
        } else if (
            sessionInvitationData.filter((t) => t.email === emailDetails.trim())
                .length > 0
        ) {
            setMailTextError(
                'Cette adresse mail a déjà été invitée dans ce workspace'
            );
        } else {
            setSessionInvitationData([
                ...sessionInvitationData,
                {
                    email: emailDetails,
                    role: { value: 'guest', label: 'Invité' },
                },
            ]);
            setEmailDetails('');
        }
    };
    const selectedItemDropDownFunction = (index, e) => {
        let newListInvitation = invitationData;
        newListInvitation[index].role = e;
        setInvitationData(newListInvitation);
        setChange(!change);
    };

    const closeModal = () => {
        props.addMembers([]);
        setInvitationData([]);
        document.getElementById('viewSessionInvitePersonnes').style.display =
            'none';
    };
    const renderArrayInvitation = () => {
        if (invitationData.length > 0) {
            return (
                <>
                    <div className="action-label mt-4 pb-3">
                        Personnes à ajouter dans la session
                    </div>
                    <div className="session-member-container">
                        {invitationData.map((el, i) => {
                            return (
                                <div>
                                    <span
                                        onClick={() =>
                                            deleteParticipantItem(el)
                                        }
                                    >
                                        <TeamIcon
                                            team={el.object ? el.object : el}
                                            my={activeUser[0]}
                                        />
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </>
            );
        }
    };
    const selectedSessionItemDropDownFunction = (index, e) => {
        let newListInvitation = sessionInvitationData;
        newListInvitation[index].role = e;
        setSessionInvitationData(newListInvitation);
        setChange(!change);
    };
    const deleteParticipantItem = (e) => {
        setInvitationData(invitationData.filter((el) => el !== e));
        setTeams([...teams, e]);
    };
    const handleChangeInput = (e) => {
        setMailTextError('');
        setEmailDetails(e.target.value);
    };
    const deleteParticipantItemFunction = (e) => {
        setSessionInvitationData(
            sessionInvitationData.filter((el) => el !== e)
        );
    };
    const sendDataFunction = () => {
        var arrayOfUniqueInvitation = [];
        sessionInvitationData.map((el) => {
            let member = invitationData.find(
                (element) => element.object?.email === el.email
            );
            if (member === undefined) {
                arrayOfUniqueInvitation.push(el);
            }
        });
        setInvitationData([...invitationData, ...arrayOfUniqueInvitation]);
        const batchArray = [];
        for (let i = 0; i < sessionInvitationData.length; i++) {
            batchArray.push({
                id: `invite${i}`,
                uri: '/workspace/' + ws.id + '/teams',
                method: 'post',
                headers: {
                    authorization: `Bearer ${activeUser[0].token}`,
                },
                body: {
                    email: `${sessionInvitationData[i].email}`,
                    role: `${sessionInvitationData[i].role.value}`,
                },
            });
        }
        Axios({
            ...ApiCallConfig,
            method: 'POST',
            url: `/batch`,
            data: batchArray,
        }).then((res) => {
            document.getElementById('view_invite_team').style.display = 'none';
            setSessionInvitationData([]);
        });
    };
    const saveData = () => {
        props.addMembers(invitationData);
        closeModal();
    };
    return (
        <>
            <Modal
                id="viewSessionInvitePersonnes"
                title="Participants à la session"
                className="card m-2 invite-user-container-width mt-40"
                style={{ display: 'block' }}
            >
                <div className="session-invite-modal-big-container">
                    <Row className="mr-0 ml-0">
                        <Col lg={7} xl={8} className="pl-0 pb-5">
                            <div className="mt-4">
                                <span className="descr-label">
                                    Quelles sont les personnes qui doivent se
                                    connecter à la session
                                </span>
                                <span className="highlight-label">
                                    {' '}
                                    {props.sessionName}
                                </span>
                                <span className="descr-label"> ?</span>
                            </div>
                            <div className="action-label pt-5 pb-3">
                                Membres du workspace
                            </div>
                            <div className="session-invite-search-container">
                                <Form.Control
                                    name="search"
                                    type="text"
                                    placeholder="Rechercher..."
                                    value={searchInput}
                                    className="rounded border-0 session-search-input "
                                    onChange={changeSerachValue}
                                />
                                <FiSearch
                                    size="1.5rem"
                                    className="session-search-icon"
                                />
                            </div>
                            <div className="session-member-container">
                                {renderMemberItems()}
                            </div>
                            {renderArrayInvitation()}
                        </Col>
                        <Col
                            lg={'auto'}
                            className="session-details-col-display"
                        >
                            <div className="add-session-btn-container">
                                <BlueButton
                                    content={<>Enregistrer</>}
                                    icon={<FiSave size="2.2rem" />}
                                    displayRowClassname="axeDisplayRowClassnameBtn"
                                    onClick={() => saveData()}
                                />
                                <GreyButton
                                    content={<>Annuler</>}
                                    icon={<FiX size="2.2rem" />}
                                    onClick={() => closeModal()}
                                />
                                <DarkButton
                                    content={
                                        <>
                                            Inviter des<br></br>utilisateurs
                                        </>
                                    }
                                    icon={<FiMail size="2.2rem" />}
                                    onClick={() => {
                                        document.getElementById(
                                            'view_invite_team'
                                        ).style.display = 'block';
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
            <InviteUser
                handleChange={(e) => handleChangeInput(e)}
                email={emailDetails}
                mailError={mailTextError}
                sendMail={() => handleSubmit()}
                data={sessionInvitationData}
                selectedItemDropDown={(email, e) =>
                    selectedSessionItemDropDownFunction(email, e)
                }
                selectedValue={selectedItem}
                deleteParticipantItem={(e) => deleteParticipantItemFunction(e)}
                sendData={() => sendDataFunction()}
                workspace={ws}
                type="session"
            />
        </>
    );
}
SessionInviterPersonnes.defaultProps = {
    email: '',
    mailError: '',
    data: [],
};
