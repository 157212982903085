import Val from 'validator';

export default (schema) => {
    let errors = {};
    //first_name
    if (schema.hasOwnProperty('first_name'))
        if (
            !schema.first_name.trim().match(/^[a-zàâçéèêëîïôûùüÿñæœ .-]*$/i) ||
            schema.first_name.trim().length < 2 ||
            schema.first_name.trim().length > 28
        ) {
            errors.first_name = 'Veuillez saisir votre prénom';
        }
    //last_name
    if (schema.hasOwnProperty('last_name'))
        if (
            !schema.last_name.trim().match(/^[a-zàâçéèêëîïôûùüÿñæœ .-]*$/i) ||
            schema.last_name.trim().length < 2 ||
            schema.last_name.trim().length > 28
        ) {
            errors.last_name = 'Veuillez saisir votre nom';
        }
    //email
    if (schema.hasOwnProperty('email'))
        if (!Val.isEmail(schema.email)) {
            errors.email = 'Veuillez saisir votre adresse mail professionnelle';
        }
    //contact_email
    if (schema.hasOwnProperty('contact_email'))
        if (!Val.isEmail(schema.contact_email)) {
            errors.contact_email =
                'Veuillez saisir une adresse mail valide pour pouvoir vous répondre';
        }

    //code (workspace, project, session)
    if (schema.hasOwnProperty('code'))
        if (!schema.code.match(/[a-zA-Z0-9]{1,7}/)) {
            errors.code = 'Veuillez saisir un code alphanumérique';
        }
    //name (workspace, project, session, tags, wsjf)
    if (schema.hasOwnProperty('name'))
        if (!schema.name.trim().match(/.{1,40}/)) {
            errors.name = 'Veuillez saisir un libellé';
        }
    //description (project description)
    if (schema.hasOwnProperty('description'))
        if (!schema.description.trim().match(/.{0,2000}/)) {
            errors.description = 'Veuillez saisir une description';
        }
    //url ( project first external url)
    if (schema.hasOwnProperty('external_url'))
        if (schema.external_url.length)
            if (!Val.isURL(schema.external_url)) {
                errors.external_url = 'Veuillez saisir une adresse URL valide';
            }
    //password
    if (schema.hasOwnProperty('password')) {
        let passwordErrorOutput = false;
        errors.password = '  ';
        if (schema.password.match(/.{8,}/) && schema.password.length < 28) {
            errors.password += 'o Au moins 8 caractères  ';
        } else {
            passwordErrorOutput = true;
            errors.password += 'x Au moins 8 caractères  ';
        }
        if (schema.password.match(/(?=.*[A-Z])/)) {
            errors.password += 'o Au moins une majuscule  ';
        } else {
            passwordErrorOutput = true;
            errors.password += 'x Au moins une majuscule  ';
        }
        if (schema.password.match(/(?=.*[a-z])/)) {
            errors.password += 'o Au moins une minuscule  ';
        } else {
            passwordErrorOutput = true;
            errors.password += 'x Au moins une minuscule  ';
        }
        if (schema.password.match(/(?=.*\d)/)) {
            errors.password += 'o Au moins un chiffre  ';
        } else {
            passwordErrorOutput = true;
            errors.password += 'x Au moins un chiffre  ';
        }
        if (schema.password.match(/(?=.*[\#\@\?\!\$\%\&\*\+\-\=])/)) {
            errors.password += 'o Au moins un caractère spécial  ';
        } else {
            passwordErrorOutput = true;
            errors.password += 'x Au moins un caractère spécial  ';
        }
        if (schema.password.match(/\s/)) {
            passwordErrorOutput = true;
            errors.password += 'x Sans espace  ';
        }

        if (!passwordErrorOutput) delete errors.password;
    }
    //Password check
    if (schema.hasOwnProperty('password_check'))
        if (schema.password.length > 0) {
            if (schema.password !== schema.password_check) {
                errors.password_check =
                    'Les mots de passe ne correspondent pas';
            }
        }
    //keywords
    if (schema.hasOwnProperty('keywords'))
        if (schema.keywords.trim().length < 2) {
            errors.keywords = 'Veuillez saisir des mots clés';
        }
    //newsletter
    if (schema.hasOwnProperty('newsletter'))
        if (typeof schema.newsletter !== 'boolean') {
            errors.newsletter = '';
        }
    //contact subject
    if (schema.hasOwnProperty('contact_subject')) {
        if (schema.contact_subject.trim().length < 2) {
            errors.contact_subject = 'Veuillez saisir un sujet';
        } else if (schema.contact_subject.trim().length > 40) {
            errors.contact_subject =
                'Votre sujet ne doit pas dépasser 40 caractères';
        }
    }
    //contact message
    if (schema.hasOwnProperty('contact_message')) {
        if (schema.contact_message.trim().length < 2) {
            errors.contact_message = 'Veuillez saisir un message';
        } else if (schema.contact_message.trim().length > 2000) {
            errors.contact_message =
                'Votre message ne doit pas dépasser 2000 caractères';
        }
    }
    //company_name
    if (schema.hasOwnProperty('company_name'))
        if (!schema.company_name.trim().match(/.{1,40}/)) {
            errors.company_name = 'Veuillez saisir un libellé';
        }
    //number
    if (schema.hasOwnProperty('number'))
        if (!schema.number.match(/[0-9]{12,19}/)) {
            errors.number = 'Veuillez saisir le numéro de la carte';
        }
    //cvc
    if (schema.hasOwnProperty('cvc'))
        if (!schema.cvc.match(/[0-9]{3}/)) {
            errors.cvc = 'Veuillez saisir le cvc';
        }
    return errors;
};
