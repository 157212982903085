import Axios from 'axios';
import ApiCallConfig from './ApiCallConfig';

export default class Token {
    /**
     * @function head
     * @definition check token validity
     * @params {string } jwt token
     */
    static head(token) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'HEAD',
                url: '/token',
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        resolve();
                    } else {
                        reject();
                    }
                })
                .catch((err) => {
                    console.error(err);
                    reject();
                });
        });
    }
    /**
     * @function get
     * @definition Get new token from existing one or from LDAP (username, password)
     * @params {string | object} jwt or object of {username, password}
     * @resolve {Promise object} data : {id, type, email, status, token}
     * @reject {Promise string} error message
     */
    static get(basicOrBearerData) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                url: '/token',
                ...(basicOrBearerData.username && basicOrBearerData.password
                    ? { auth: basicOrBearerData }
                    : {
                          headers: {
                              authorization: 'Bearer ' + basicOrBearerData,
                          },
                      }),
            })
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res.data.data);
                    } else {
                        reject(res.data.message);
                    }
                })
                .catch((err) => {
                    console.error(err);
                    reject(
                        'Une erreur est survenue, veuillez réessayer ultérieurement'
                    );
                });
        });
    }
}
