import Modal from '../commun/RkModal';
import React, { useEffect } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import DarkButton from '../commun/DarkButton';
import GreyButton from '../commun/GreyButton';
import BlueButton from '../commun/BlueButton';
import CalcSquare from '../commun/CalcSquare';
import {
    FiInfo,
    FiPenTool,
    FiDelete,
    FiSave,
    FiThumbsUp,
} from 'react-icons/fi';
import TeamIcon from '../commun/TeamIcon';
import GaugeChart from 'react-gauge-chart';
export default function ProjectScoring(props) {
    const renderVotes = () => {
        if (props?.ready)
            return (
                <>
                    {Object.keys(props.data.object.rating?.teams).map(
                        (el, index) => {
                            return (
                                <tr key={'votes_' + index}>
                                    <td className="minem-w-6 p-1 pl-2">
                                        <TeamIcon
                                            team={
                                                props.WorkspaceTeams.filter(
                                                    (t) => t.id === el
                                                )?.[0]?.object
                                            }
                                            my={props.my}
                                        />
                                    </td>
                                    {props.data.object.snapshot.wsjf.business_value.axis.filter(
                                        (ax) => ax.active
                                    ).length ? (
                                        props.data.object.snapshot.wsjf.business_value.axis.map(
                                            (axis) => {
                                                if (axis.active)
                                                    return (
                                                        <td className="minem-w-8 p-1 pl-2">
                                                            <CalcSquare
                                                                centred={false}
                                                                vote={true}
                                                                value={
                                                                    props.data
                                                                        .object
                                                                        .rating
                                                                        .teams[
                                                                        el
                                                                    ]?.[
                                                                        props.id
                                                                    ] &&
                                                                    props.data
                                                                        .object
                                                                        .rating
                                                                        .teams[
                                                                        el
                                                                    ]?.[
                                                                        props.id
                                                                    ]
                                                                        ?.business_value?.[
                                                                        axis.ref
                                                                    ]
                                                                }
                                                            />
                                                        </td>
                                                    );
                                            }
                                        )
                                    ) : (
                                        <td className="minem-w-8 p-1 pl-2">
                                            <CalcSquare
                                                centred={false}
                                                vote={true}
                                                value={
                                                    props.data.object.rating
                                                        .teams[el]?.[
                                                        props.id
                                                    ] &&
                                                    props.data.object.rating
                                                        .teams[el]?.[props.id]
                                                        ?.business_value
                                                }
                                            />
                                        </td>
                                    )}
                                    {props.data.object.snapshot.wsjf
                                        .time_criticality.active ? (
                                        <td className="minem-w-8 p-1 pl-2">
                                            <CalcSquare
                                                centred={false}
                                                vote={true}
                                                value={
                                                    props.data.object.rating
                                                        .teams[el]?.[
                                                        props.id
                                                    ] &&
                                                    props.data.object.rating
                                                        .teams[el]?.[props.id]
                                                        ?.time_criticality
                                                }
                                            />
                                        </td>
                                    ) : (
                                        <></>
                                    )}
                                    {props.data.object.snapshot.wsjf
                                        .risk_opportunity.active ? (
                                        <td className="minem-w-12 p-1 pl-2">
                                            <CalcSquare
                                                centred={false}
                                                vote={true}
                                                value={
                                                    props.data.object.rating
                                                        .teams[el]?.[
                                                        props.id
                                                    ] &&
                                                    props.data.object.rating
                                                        .teams[el]?.[props.id]
                                                        ?.risk_opportunity
                                                }
                                            />
                                        </td>
                                    ) : (
                                        <></>
                                    )}
                                    <td className="minem-w-6 p-1 pl-2">
                                        <CalcSquare
                                            centred={false}
                                            vote={true}
                                            value={
                                                props.data.object.rating.teams[
                                                    el
                                                ]?.[props.id] &&
                                                props.data.object.rating.teams[
                                                    el
                                                ]?.[props.id]?.effort
                                            }
                                        />
                                    </td>
                                </tr>
                            );
                        }
                    )}
                </>
            );
        else {
            return <></>;
        }
    };
    const renderResultat = (consensus = false) => {
        const result = consensus ? 'consensus' : 'result';
        if (props?.ready)
            return (
                <>
                    <tr>
                        {props.data.object.rating.project[props.id]?.consensus
                            ?.wsjf !== undefined ? (
                            <td className="minem-w-7 p-1 pl-2">
                                <div className="rk-std-value font-weight-bold">
                                    {consensus ? 'Convergence' : 'Moyenne'}
                                </div>
                            </td>
                        ) : (
                            <></>
                        )}

                        <td className="minem-w-6 p-1 pl-2">
                            <CalcSquare
                                centred={false}
                                value={
                                    props.data.object.rating.project[
                                        props.id
                                    ]?.[result]?.wsjf || '-'
                                }
                                variant="primary"
                            />
                        </td>
                        <td className="minem-w-6 p-1 pl-2">
                            <CalcSquare
                                centred={false}
                                value={
                                    props.data.object.rating.project[
                                        props.id
                                    ]?.[result]?.business_value || '-'
                                }
                            />
                        </td>
                        {props.data.object.snapshot.wsjf.business_value.axis.filter(
                            (ax) => ax.active
                        ).length ? (
                            props.data.object.snapshot.wsjf.business_value.axis.map(
                                (axis) => {
                                    if (axis.active)
                                        return (
                                            <td className="minem-w-8 p-1 pl-2">
                                                <CalcSquare
                                                    centred={false}
                                                    value={
                                                        props.data.object.rating
                                                            .project[
                                                            props.id
                                                        ]?.[result]
                                                            ?.business_value_axis[
                                                            axis.ref
                                                        ] || '-'
                                                    }
                                                />
                                            </td>
                                        );
                                }
                            )
                        ) : (
                            <></>
                        )}

                        {props.data.object.snapshot.wsjf.time_criticality
                            .active ? (
                            <td className="minem-w-8 p-1 pl-2">
                                <CalcSquare
                                    centred={false}
                                    value={
                                        props.data.object.rating.project[
                                            props.id
                                        ]?.[result]?.time_criticality || '-'
                                    }
                                />
                            </td>
                        ) : (
                            <></>
                        )}
                        {props.data.object.snapshot.wsjf.risk_opportunity
                            .active ? (
                            <td className="minem-w-12 p-1 pl-2">
                                <CalcSquare
                                    centred={false}
                                    value={
                                        props.data.object.rating.project[
                                            props.id
                                        ]?.[result]?.risk_opportunity || '-'
                                    }
                                />
                            </td>
                        ) : (
                            <></>
                        )}
                        <td className="minem-w-8 p-1 pl-2">
                            <CalcSquare
                                centred={false}
                                value={
                                    props.data.object.rating.project[
                                        props.id
                                    ]?.[result]?.cost || '-'
                                }
                            />
                        </td>
                        <td className="minem-w-6 p-1 pl-2">
                            <CalcSquare
                                centred={false}
                                value={
                                    props.data.object.rating.project[
                                        props.id
                                    ]?.[result]?.effort || '-'
                                }
                            />
                        </td>
                    </tr>
                </>
            );
        else {
            return <></>;
        }
    };
    return (
        <>
            <Modal
                id="scoring-project"
                title={
                    props?.data?.object?.snapshot?.project.filter(
                        (p) => p.id === props.id
                    )?.[0]?.object?.name
                }
                className="card m-2 invite-user-container-width"
                style={{ display: 'block' }}
                backgroundColor="blue"
            >
                <div className="user-file-modal-big-container">
                    <Row className="mr-0 ml-0">
                        <Col className="px-0" style={{ width: '60%' }}>
                            <div
                                className="ml-3 mt-4"
                                style={{ maxWidth: '1500px' }}
                            >
                                <div className="action-label mb-3">
                                    Résultat des votes
                                </div>
                                <div className="scroll-x radius-14 border border-gray m-0 p-0">
                                    <Table borderless striped className="m-0">
                                        <thead>
                                            <tr>
                                                {props?.data?.object?.rating
                                                    ?.project[props?.projet?.id]
                                                    ?.consensus?.wsjf !==
                                                undefined ? (
                                                    <th className="font-weight-700 display-14 color-gray minem-w-7 p-1 pl-2">
                                                        Type
                                                    </th>
                                                ) : (
                                                    <></>
                                                )}

                                                <th className="font-weight-700 display-14 color-gray minem-w-6 p-1 pl-2">
                                                    WSJF
                                                </th>
                                                <th className="font-weight-700 display-14 color-gray minem-w-6 p-1 pl-2">
                                                    Valeur business
                                                </th>
                                                {props?.data?.object?.snapshot?.wsjf?.business_value.axis.filter(
                                                    (ax) => ax.active
                                                ).length ? (
                                                    props.data.object.snapshot.wsjf.business_value.axis.map(
                                                        (axis) => {
                                                            if (axis.active)
                                                                return (
                                                                    <th className="font-weight-700 display-14 color-gray minem-w-8 p-1 pl-2">
                                                                        {
                                                                            axis.name
                                                                        }
                                                                    </th>
                                                                );
                                                        }
                                                    )
                                                ) : (
                                                    <></>
                                                )}
                                                {props?.data?.object?.snapshot
                                                    ?.wsjf?.time_criticality
                                                    ?.active ? (
                                                    <th className="font-weight-700 display-14 color-gray minem-w-8 p-1 pl-2">
                                                        Contrainte de temps
                                                    </th>
                                                ) : (
                                                    <></>
                                                )}
                                                {props?.data?.object?.snapshot
                                                    ?.wsjf?.risk_opportunity
                                                    ?.active ? (
                                                    <th className="font-weight-700 display-14 color-gray minem-w-12 p-1 pl-2">
                                                        Réduction de risque /
                                                        <br />
                                                        Création d'opportunité
                                                    </th>
                                                ) : (
                                                    <></>
                                                )}
                                                <th className="font-weight-700 display-14 color-gray minem-w-8 p-1 pl-2">
                                                    Coût du délai
                                                    <br />
                                                    de livraison
                                                </th>
                                                <th className="font-weight-700 display-14 color-gray minem-w-6 p-1 pl-2">
                                                    Effort
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className={'radius-buttom-14'}>
                                            {props?.data?.object?.rating
                                                ?.project[props.id]?.result
                                                ?.wsjf !== undefined ? (
                                                renderResultat(false)
                                            ) : (
                                                <>
                                                    {props?.data?.object?.rating
                                                        ?.project[props.id]
                                                        ?.consensus?.wsjf !==
                                                    undefined ? (
                                                        <></>
                                                    ) : (
                                                        <>
                                                            <tr>
                                                                <td className="bg-white font-weight-700 display-14 p-0 pt-3 pl-2">
                                                                    En attente
                                                                    des votes...
                                                                </td>
                                                            </tr>
                                                            <tr></tr>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {props?.data?.object?.rating
                                                ?.project[props.id]?.consensus
                                                ?.wsjf !== undefined ? (
                                                renderResultat(true)
                                            ) : (
                                                <></>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                                {Object.keys(
                                    props?.data?.object?.rating?.teams || {}
                                ).length ? (
                                    <>
                                        <div className="action-label mt-5 mb-3">
                                            Détail des votes
                                        </div>
                                        <div className="scroll-x radius-14 border border-gray m-0 p-0">
                                            <Table
                                                borderless
                                                striped
                                                className="m-0"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th className="font-weight-700 display-14 p-2 color-gray minem-w-6">
                                                            Votant
                                                        </th>
                                                        {props?.data?.object?.snapshot?.wsjf?.business_value.axis.filter(
                                                            (ax) => ax.active
                                                        ).length ? (
                                                            props.data.object.snapshot.wsjf.business_value.axis.map(
                                                                (axis) => {
                                                                    if (
                                                                        axis.active
                                                                    )
                                                                        return (
                                                                            <th className="font-weight-700 display-14 p-2 color-gray minem-w-8">
                                                                                {
                                                                                    axis.name
                                                                                }
                                                                            </th>
                                                                        );
                                                                }
                                                            )
                                                        ) : (
                                                            <>
                                                                <th className="font-weight-700 display-14 p-2 color-gray minem-w-8">
                                                                    Valeur
                                                                    business
                                                                </th>
                                                            </>
                                                        )}
                                                        {props?.data?.object
                                                            ?.snapshot?.wsjf
                                                            ?.time_criticality
                                                            ?.active ? (
                                                            <th className="font-weight-700 display-14 p-2 color-gray minem-w-8">
                                                                Contrainte de
                                                                temps
                                                            </th>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {props?.data?.object
                                                            ?.snapshot?.wsjf
                                                            ?.risk_opportunity
                                                            ?.active ? (
                                                            <th className="font-weight-700 display-14 p-2 color-gray minem-w-12">
                                                                Réduction de
                                                                risque /
                                                                <br />
                                                                Création
                                                                d'opportunité
                                                            </th>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <th className="font-weight-700 display-14 p-2 color-gray minem-w-6">
                                                            Effort
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody
                                                    className={
                                                        'radius-buttom-14'
                                                    }
                                                >
                                                    {renderVotes()}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                                <Row className="my-5 mx-0">
                                    <Col lg={'auto'} className="p-0 mr-3 mb-3">
                                        <div
                                            className="text-center radius-14 border border-gray"
                                            style={{ width: '300px' }}
                                        >
                                            <div className="action-label p-3">
                                                Taux de participation
                                            </div>
                                            {/* https://www.npmjs.com/package/react-gauge-chart */}
                                            <GaugeChart
                                                id="vote_rate"
                                                style={{
                                                    marginLeft: '-10%',
                                                    width: '120%',
                                                }}
                                                animate={false}
                                                nrOfLevels={12}
                                                cornerRadius={2}
                                                arcPadding={0.02}
                                                hideText={true}
                                                textColor={'#000000'}
                                                colors={['#F8FBFF', '#0055FF']}
                                                needleColor={'#000'}
                                                needleBaseColor={'#999'}
                                                percent={
                                                    (
                                                        Math.round(
                                                            ((props?.data
                                                                ?.object?.rating
                                                                ?.project?.[
                                                                props.id
                                                            ]?.vote_rate ||
                                                                0.1) +
                                                                Number.EPSILON) *
                                                                100
                                                        ) / 100
                                                    ).toFixed() / 100
                                                }
                                            />
                                            <div className="font-weight-800 display-18 pt-0 pb-3">
                                                {(
                                                    Math.round(
                                                        ((props?.data?.object
                                                            ?.rating?.project?.[
                                                            props.id
                                                        ]?.vote_rate || 0.1) +
                                                            Number.EPSILON) *
                                                            100
                                                    ) / 100
                                                ).toFixed()}
                                                %
                                            </div>
                                            <Row
                                                className="text-left font-weight-600 display-14 radius-buttom-14 m-0"
                                                style={{
                                                    backgroundColor:
                                                        'rgba(0, 85, 255, 0.1)',
                                                }}
                                            >
                                                <Col
                                                    lg={'auto'}
                                                    className="my-auto mx-0"
                                                >
                                                    <FiInfo
                                                        color="#0055ff"
                                                        size="1.4rem"
                                                    />
                                                </Col>
                                                <Col className="align-middle pl-0 my-2">
                                                    Seuls les votes complets
                                                    sont comptabilisés.
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col
                                        className="p-0"
                                        style={{
                                            minWidth: '350px',
                                            maxWidth: '800px',
                                        }}
                                    >
                                        {(Object.keys(
                                            props?.data?.object?.rating
                                                ?.project?.[props?.id || 0]
                                                ?.votes || {}
                                        )?.length || 0) <
                                        (props?.data?.object?.teams?.length ||
                                            0) ? (
                                            <div className="radius-14 border border-gray">
                                                <div className="p-3">
                                                    <span className="action-label">
                                                        Personnes restant à
                                                        voter
                                                    </span>
                                                    <span className="float-right action-label">
                                                        {
                                                            props?.data?.object?.teams?.filter(
                                                                (tid) =>
                                                                    props?.data
                                                                        ?.object
                                                                        ?.rating
                                                                        ?.project[
                                                                        props?.id ||
                                                                            0
                                                                    ]?.votes?.[
                                                                        tid
                                                                    ]?.valid !==
                                                                    true
                                                            ).length
                                                        }
                                                        /
                                                        {
                                                            props?.data?.object
                                                                ?.teams?.length
                                                        }
                                                    </span>
                                                </div>
                                                <hr className="m-0" />
                                                <div className="px-3 py-2">
                                                    {props?.data?.object?.teams
                                                        ?.filter(
                                                            (tid) =>
                                                                props?.data
                                                                    ?.object
                                                                    ?.rating
                                                                    ?.project[
                                                                    props?.id ||
                                                                        0
                                                                ]?.votes?.[tid]
                                                                    ?.valid !==
                                                                true
                                                        )
                                                        .map((tid) => {
                                                            const teams =
                                                                props.WorkspaceTeams.filter(
                                                                    (team) =>
                                                                        team.id ===
                                                                        tid
                                                                );
                                                            return (
                                                                <TeamIcon
                                                                    team={
                                                                        teams?.[0]
                                                                            ?.object
                                                                    }
                                                                    my={
                                                                        props?.my
                                                                    }
                                                                />
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={'auto'} className="flex items-end column">
                            <div className="d-grid pt-3">
                                <BlueButton
                                    content={
                                        <>
                                            Enregistrer <br></br>les votes
                                        </>
                                    }
                                    icon={<FiSave size="2.2rem" />}
                                    displayRowClassname="axeDisplayRowClassnameBtn"
                                    onClick={() =>
                                        props.saveProjectScoring(props.id)
                                    }
                                />
                                <DarkButton
                                    content={<>Voter</>}
                                    icon={<FiPenTool size="2.2rem" />}
                                    onClick={() =>
                                        (document.getElementById(
                                            'session_votes'
                                        ).style.display = 'block')
                                    }
                                />
                                <DarkButton
                                    content={<>Vote de convergence</>}
                                    icon={<FiThumbsUp size="2.2rem" />}
                                    onClick={() =>
                                        (document.getElementById(
                                            'session_votes_consensus'
                                        ).style.display = 'block')
                                    }
                                />
                                {props?.data?.object?.rating?.project?.[
                                    props.id
                                ]?.consensus ? (
                                    <GreyButton
                                        content={
                                            <>
                                                Annuler le vote <br></br>
                                                convergence
                                            </>
                                        }
                                        icon={<FiDelete size="2.2rem" />}
                                        onClick={() => props.removeConsensus()}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    );
}
ProjectScoring.defaultProps = {
    email: '',
    mailError: '',
    data: [],
};
