import { useState, useEffect } from 'react';
import { Card, Row, Col, Table } from 'react-bootstrap';
import { FiUserPlus, FiRefreshCw, FiPlusCircle } from 'react-icons/fi';
import BlueButton from '../commun/BlueButton';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import RkTableOptions from '../commun/RkTableOptions';

export default (props) => {
    const { id } = useParams();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = activeUser[0].workspace[activeUser[0].activeWorkspace];
    const editRole = ['owner', 'admin'].includes(
        workspace.object.teams.on.filter(
            (team) => team.id === activeUser[0].id
        )[0].role
    );
    const [init, setInit] = useState(0);
    const [metrics, setMetrics] = useState({
        all: {
            count: null,
        },
        '00_to_plan': {
            count: null,
        },
        '01_planned': {
            count: null,
        },
        next_session: {
            count: null,
        },
    });
    const getMetrics = () => {
        if (init >= 2) {
            setMetrics({
                all: {
                    count: null,
                },
                '00_to_plan': {
                    count: null,
                },
                '01_planned': {
                    count: null,
                },
                next_session: {
                    count: null,
                },
            });
            Axios({
                ...ApiCallConfig,
                method: 'POST',
                url: '/batch',
                data: [
                    {
                        id: 'all',
                        method: 'get',
                        uri: `/workspace/${workspace.id}/session?field=id`,
                        headers: {
                            authorization: `Bearer ${activeUser[0].token}`,
                        },
                    },
                    {
                        id: '00_to_plan',
                        method: 'get',
                        uri: `/workspace/${workspace.id}/session?field=id&object-%3E%3E'status'=00_to_plan`,
                        headers: {
                            authorization: `Bearer ${activeUser[0].token}`,
                        },
                    },
                    {
                        id: '01_planned',
                        method: 'get',
                        uri: `/workspace/${workspace.id}/session?object-%3E%3E'status'=01_planned`,
                        headers: {
                            authorization: `Bearer ${activeUser[0].token}`,
                        },
                    },
                    {
                        id: 'next_session',
                        method: 'get',
                        uri: `/workspace/${workspace.id}/session?object-%3E%3E'status'=00_to_plan&object-%3E%3E'status'=01_planned`,
                        headers: {
                            authorization: `Bearer ${activeUser[0].token}`,
                        },
                    },
                ],
            })
                .then((res) => {
                    switch (true) {
                        case res.status === 200:
                            setMetrics(res.data.responses);
                            break;
                        default:
                            setMetrics({
                                all: {
                                    count: null,
                                },
                                '00_to_plan': {
                                    count: null,
                                },
                                '01_planned': {
                                    count: null,
                                },
                                next_session: {
                                    count: null,
                                },
                            });
                            break;
                    }
                })
                .catch((error) => {});
        } else {
            setInit(init >= 0 ? init + 1 : 1);
        }
    };
    useEffect(getMetrics, [props.data]);
    useEffect(() => {
        setMetrics({
            all: {
                count: null,
            },
            '00_to_plan': {
                count: null,
            },
            '01_planned': {
                count: null,
            },
            next_session: {
                count: null,
            },
        });
    }, [props.loading === true]);

    const renderMetrics = (key) => {
        if (metrics[key].count === null) {
            return (
                <FiRefreshCw size="1.4rem" color="#000" className="rotating" />
            );
        } else if (key === 'next_session') {
            return metrics[key].count === undefined
                ? '-'
                : new Date(
                      metrics[key].data.map((s) => s.object.due_date).sort()[0]
                  ).toLocaleDateString('fr-FR', {
                      weekday: 'short',
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                  });
        } else {
            return metrics[key].count === undefined ? '0' : metrics[key].count;
        }
    };
    return (
        <>
            <Row id="dashcards" className="m-0 w-100">
                <Col xs={9} xl={10} className="col-xxl-8 col-xxxl-6">
                    <Row className="minem-h-9">
                        <Col sm={6} xl={3} className="d-flex p-2">
                            <Card className="d-none d-xl-block border-0 w-100 line-height-14">
                                <Card.Body className="d-flex flex-column">
                                    <Card.Title className="display-16 font-weight-700 mb-3">
                                        Nombre total de sessions&nbsp;:
                                    </Card.Title>
                                    <Card.Text className="position-absolute font-weight-800 display-32 text-align-left pt-5 mt-1">
                                        {renderMetrics('all')}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card
                                className="d-xl-none d-block p-0 px-2 m-0 w-100"
                                style={{ height: '57px' }}
                            >
                                <Row className="align-items-center p-1">
                                    <Col className="font-weight-700 display-16 pl-4 p-0">
                                        Nombre total de sessions&nbsp;:
                                    </Col>
                                    <Col
                                        sm={'auto'}
                                        className="font-weight-800 display-24 pr-4 py-2"
                                    >
                                        {renderMetrics('all')}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col sm={6} xl={3} className="d-flex p-2">
                            <Card className="d-none d-xl-block border-0 w-100 line-height-14">
                                <Card.Body className="d-flex flex-column">
                                    <Card.Title className="display-16 font-weight-700 mb-3">
                                        Nombre de sessions à planifier&nbsp;:
                                    </Card.Title>
                                    <Card.Text className="position-absolute font-weight-800 display-32 text-align-left pt-5 mt-1">
                                        {renderMetrics('00_to_plan')}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card
                                className="d-xl-none d-block p-0 px-2 m-0 w-100"
                                style={{ height: '57px' }}
                            >
                                <Row className="align-items-center p-1">
                                    <Col className="font-weight-700 display-16 pl-4 p-0">
                                        Nombre de sessions à planifier&nbsp;:
                                    </Col>
                                    <Col
                                        sm={'auto'}
                                        className="font-weight-800 display-24 pr-4 py-2"
                                    >
                                        {renderMetrics('00_to_plan')}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col sm={6} xl={3} className="d-flex p-2">
                            <Card className="d-none d-xl-block border-0 w-100 line-height-14">
                                <Card.Body className="d-flex flex-column">
                                    <Card.Title className="display-16 font-weight-700 mb-3">
                                        Nombre de sessions planifiées&nbsp;:
                                    </Card.Title>
                                    <Card.Text className="position-absolute font-weight-800 display-32 text-align-left pt-5 mt-1">
                                        {renderMetrics('01_planned')}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card
                                className="d-xl-none d-block p-0 px-2 m-0 w-100"
                                style={{ height: '57px' }}
                            >
                                <Row className="align-items-center p-1">
                                    <Col className="font-weight-700 display-16 pl-4 p-0">
                                        Nombre de sessions planifiées&nbsp;:
                                    </Col>
                                    <Col
                                        sm={'auto'}
                                        className="font-weight-800 display-24 pr-4 py-2"
                                    >
                                        {renderMetrics('01_planned')}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col sm={6} xl={3} className="d-flex p-2">
                            <Card className="d-none d-xl-block border-0 w-100 line-height-14">
                                <Card.Body className="d-flex flex-column">
                                    <Card.Title className="display-16 font-weight-700 mb-3">
                                        Prochaine session de priorisation&nbsp;:
                                    </Card.Title>
                                    <Card.Text
                                        style={{ lineHeight: '100%' }}
                                        className="position-absolute font-weight-800 text-capitalize display-24 text-align-left pt-5 pr-2 mt-3"
                                    >
                                        {renderMetrics('next_session')
                                            .toString()
                                            .replace(/\./g, '')}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card
                                className="d-xl-none d-block p-0 px-2 m-0 w-100"
                                style={{ height: '57px' }}
                            >
                                <Row className="align-items-center p-1">
                                    <Col
                                        sm={'auto'}
                                        className="font-weight-700 display-16 px-4 p-0"
                                    >
                                        Prochaine session de priorisation&nbsp;:
                                        <span className="font-weight-800 text-capitalize display-18">
                                            {
                                                renderMetrics('next_session')
                                                    .toString()
                                                    .split('.')[1]
                                            }
                                        </span>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xs={3} xl={2} className="p-0 d-flex flex-column">
                    <div className="m-0 mt-auto" style={{ maxWidth: '22ch' }}>
                        {editRole ? (
                            <BlueButton
                                btnClassnameDetails="btn-width-unset"
                                content={
                                    <>
                                        Nouvelle
                                        <br />
                                        session
                                    </>
                                }
                                icon={<FiPlusCircle size="2.2rem" />}
                                onClick={() => props.openCreateSessionModal()}
                            />
                        ) : (
                            <div style={{ height: '76px' }}></div>
                        )}
                    </div>
                    <RkTableOptions
                        resource="session"
                        optionsStates={props.optionsStates}
                        handleOptionsState={props.handleOptionsState}
                    />
                </Col>
            </Row>
        </>
    );
};
