import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Alert } from 'react-bootstrap';
import { FiInfo } from 'react-icons/fi';
export default () => {
    const { s } = useParams();
    return (
        <Row>
            <Col lg={2} className="d-none d-lg-block"></Col>
            <Col lg={8}>
                <br />
                <br />
                <br />
                <br />
                <h4>
                    Aucun document ne correspond aux termes de recherche
                    spécifiés ({s})
                </h4>
                <br />
                <br />
                <br />
                <Alert variant="secondary" className="w-100">
                    <Row>
                        <Col xs="auto">
                            <FiInfo size="1.5em" />
                        </Col>
                        <Col>
                            (1)Vérifiez l’orthographe.
                            <br />
                            (2)Essayez d'autres mots.
                            <br />
                            (3)Utilisez des mots clés plus généraux.
                        </Col>
                    </Row>
                </Alert>
            </Col>
            <Col lg={2} className="d-none d-lg-block"></Col>
        </Row>
    );
};
