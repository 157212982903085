import Config from '../../util/Config';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FiImage, FiTrash2 } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import ChangePhotoModal from '../commun/ChangePhotoModal';
import $ from 'jquery';
import DarkButton from '../commun/DarkButton';
import DeleteButton from '../commun/DeleteButton';
import Alert from '../commun/Alert';

function WorkspaceBody(props) {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.user.users);
    const { id } = useParams();
    const activeUser = users.filter((user) => user.id.includes(id));
    const [Workspace, setWorkspace] = useState(
        users.filter((user) => user.id.includes(id))[0].workspace[
            activeUser[0].activeWorkspace
        ]
    );
    const [Changes, setChanges] = useState(false);
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [codeError, setCodeError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const userRole = Workspace.object.teams.on.filter(
        (team) => team.id === activeUser[0].id
    )[0].role;
    useEffect(() => {
        setWorkspace(
            users.filter((user) => user.id.includes(id))[0].workspace[
                activeUser[0].activeWorkspace
            ]
        );
    }, [activeUser[0].activeWorkspace]);
    const getWorkspace = () => {
        Axios({
            ...ApiCallConfig,
            method: 'GET',
            url: '/workspace',
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
        }).then((res) => {
            if (res.status === 200) {
                setWorkspace(res.data.data[activeUser[0].activeWorkspace]);
                let action = {
                    type: 'UPDATE_WORKSPACE_LIST',
                    value: { workspace: res.data.data, id: activeUser[0].id },
                };
                dispatch(action);
            }
        });
    };
    useEffect(() => {
        getWorkspace();
    }, []);
    const setWorkspaceName = (name) => {
        let newWs = Workspace;
        newWs.object.name = name;
        setWorkspace(newWs);
        setChanges(!Changes);
    };
    const setWorkspaceCode = (code) => {
        let newWs = Workspace;
        newWs.object.code = code;
        setWorkspace(newWs);
        setChanges(!Changes);
    };
    const setWorkspaceImage = (image) => {
        let newWs = Workspace;
        newWs.object.image = image;
        setWorkspace(newWs);
        setChanges(!Changes);
    };
    const handleNameChange = () => {
        setNameError(false);
        Axios({
            ...ApiCallConfig,
            method: 'PATCH',
            url: `/workspace/${Workspace.id}`,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
            data: { name: Workspace.object.name },
        }).then((res) => {
            if (res.status === 200) {
                let action = {
                    type: 'SET_WORKSPACE',
                    value: {
                        workspace: res.data.data,
                        id,
                        index: activeUser[0].activeWorkspace,
                    },
                };
                dispatch(action);
            } else if (res.status === 422) {
                setNameError(true);
            }
        });
    };
    const handleCodeChange = () => {
        setCodeError(false);
        Axios({
            ...ApiCallConfig,
            method: 'PATCH',
            url: `/workspace/${Workspace.id}`,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
            data: { code: Workspace.object.code },
        }).then((res) => {
            if (res.status === 200) {
                let action = {
                    type: 'SET_WORKSPACE',
                    value: {
                        workspace: res.data.data,
                        id,
                        index: activeUser[0].activeWorkspace,
                    },
                };
                dispatch(action);
            } else if (res.status === 409) {
                setCodeError(true);
            }
        });
    };
    const onCopyLinkClick = async () => {
        if ('clipboard' in navigator) {
            handleShowAlert();
            return await navigator.clipboard.writeText(
                Config.APP_ENDPOINT + Workspace.object.code
            );
        } else {
            handleShowAlert();
            return document.execCommand(
                'copy',
                true,
                Config.APP_ENDPOINT + Workspace.object.code
            );
        }
    };

    $(document).on('click', function (e) {
        if (
            $(e.target).closest('#workspace_photo_modal').length === 0 &&
            showPhotoModal
        ) {
            setShowPhotoModal(false);
        }
    });

    const handleShowAlert = () => {
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    };
    const handleCloseAlert = () => {
        setShowAlert(false);
    };
    const onShowModalClick = () => {
        if (['owner', 'admin'].includes(userRole)) {
            setShowPhotoModal(true);
            scrollToBottom();
        }
    };
    const scrollToBottom = () => {
        setTimeout(() => {
            var element = document.getElementById('wsContainer');
            element.scrollTop = element.scrollHeight;
        }, 50);
    };
    return (
        <Row className="workspace-body-Controller mr-0" id="wsContainer">
            <Col lg={7}>
                <Form className="ml-3 mt-4">
                    <Form.Group controlId="workspaceName">
                        <Form.Label className="input-label">
                            Nom du workspace
                        </Form.Label>
                        <div className="mb-40">
                            <Form.Control
                                type="text"
                                placeholder="Libellé du workspace..."
                                className="input"
                                style={{ maxWidth: '24ch' }}
                                value={Workspace.object.name}
                                onChange={(e) =>
                                    setWorkspaceName(e.target.value)
                                }
                                onBlur={handleNameChange}
                                maxLength={24}
                                disabled={
                                    !['owner', 'admin'].includes(userRole)
                                }
                            />
                            {nameError && (
                                <Form.Text className="ml-2 mt-2">
                                    <span className="text-danger font-weight-700">
                                        Veuillez renseigner un nom de workspace
                                    </span>
                                </Form.Text>
                            )}
                        </div>
                    </Form.Group>
                    {/* <Form.Group controlId="workspaceUrl">
                        <Form.Label className="input-label">
                            URL du workspace
                        </Form.Label>
                        <div className="flex mb-40">
                            <span className="app-url flex v-center">
                                rankio.app/
                            </span>
                            <div>
                                <Form.Control
                                    type="text"
                                    placeholder="workspace..."
                                    className="input"
                                    style={{ maxWidth: '24ch' }}
                                    value={Workspace.object.code}
                                    onChange={(e) =>
                                        setWorkspaceCode(e.target.value)
                                    }
                                    onBlur={handleCodeChange}
                                    disabled={
                                        !['owner', 'admin'].includes(userRole)
                                    }
                                />
                                {codeError && (
                                    <Form.Text className="mt-2 ml-2">
                                        <span className="text-danger font-weight-700">
                                            URL déjà utilisée
                                        </span>
                                    </Form.Text>
                                )}
                            </div>
                        </div>
                    </Form.Group> */}
                    <Form.Group
                        controlId="workspaceIcon"
                        className="col-6 pl-0 flex column"
                    >
                        <Form.Label className="input-label mb-3">
                            Photo du workspace
                        </Form.Label>
                        {Workspace.object.image ? (
                            <img
                                src={
                                    Config.CDN.ENDPOINT + Workspace.object.image
                                }
                                className="default-wokspace-image pointer"
                                onClick={() => onShowModalClick()}
                                id="workspace_photo_modal"
                            />
                        ) : (
                            // <FiImage className="ml-15 default-wokspace-imageWithoutBorder pointer" onClick={() => setShowPhotoModal(true)} id="workspace_photo_modal" />
                            <div
                                className="ml-15 pointer flex v-center h-center ws-def-img"
                                onClick={() => onShowModalClick()}
                                id="workspace_photo_modal"
                            >
                                <strong className="text-center display-24 font-weight-800 text-uppercase text-lg">
                                    {Workspace.object.name.substr(0, 2)}
                                </strong>
                            </div>
                        )}
                        <Row>
                            <ChangePhotoModal
                                show={showPhotoModal}
                                setShow={setShowPhotoModal}
                                type="workSpace"
                                workspaceId={Workspace.id}
                                setNewImage={(image) =>
                                    setWorkspaceImage(image)
                                }
                            />
                        </Row>
                    </Form.Group>
                </Form>
            </Col>
            <Col lg={5} className="flex items-end column pr-2 mt-3">
                {/* <DarkButton
                    content={
                        <>
                            Copier le lien
                            <br />
                            du workspace
                        </>
                    }
                    icon={<FiLink size="2.2rem" />}
                    onClick={() => onCopyLinkClick()}
                /> */}
                {['owner', 'admin'].includes(userRole) && (
                    <DarkButton
                        content={'Modifier la photo'}
                        icon={<FiImage size="2.2rem" />}
                        onClick={() => onShowModalClick()}
                        id="workspace_photo_modal"
                    />
                )}
                {/* {['owner'].includes(userRole) && (
                    <DeleteButton
                        content={'Supprimer le workspace'}
                        icon={<FiTrash2 size="2.2rem" />}
                    />
                )} */}
            </Col>
            <Alert
                color="primary"
                content="Le lien du workspace a été copié avec succés !"
                showAlert={showAlert}
                setAlertShow={handleShowAlert}
                handleCloseAlert={() => handleCloseAlert()}
            />
        </Row>
    );
}

export default WorkspaceBody;
