import React, { useState, useEffect } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import {
    FiPlay,
    FiLock,
    FiCheckCircle,
    FiSave,
    FiInfo,
    FiArrowLeft,
} from 'react-icons/fi';
import ProjectScoring from '../Scoring/ProjectScoring';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import Config from '../../util/Config';
import SessionVote from './SessionVote';
import SessionVoteConsensus from './SessionVoteConsensus';
import BlueButton from '../commun/BlueButton';
import GreyButton from '../commun/GreyButton';
import CalcSquare from '../commun/CalcSquare';
import TeamIcon from '../commun/TeamIcon';
import Modal from '../modal/Modal';
import Alert from '../commun/Alert';
import Teams from '../../util/Teams';

function SessionDetails(props) {
    const { id, workspaceId, sessionId } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const fibonacci = [0, 1, 2, 3, 5, 8, 13, 20, 40, 100]; // Rm
    const [isOwnerOrAdmin, setIsOwnerOrAdmin] = useState(true);
    const [WorkspaceTeams, setWorkspaceTeams] = useState([]);
    const [ProjetToRate, setProjetToRate] = useState(null);
    const [showModalDoneSession, setShowModalDoneSession] = useState(false);
    const [Ready, setReady] = useState(false);

    const [alertShow, setAlertShow] = useState(false);
    const [alertColor, setAlertColor] = useState('danger');
    const [alertContent, setAlertContent] = useState(
        'Une erreur est survenue, veuillez réessayer ultérieurement'
    );
    const [alertTimeout, setAlertTimeout] = useState(null);
    const handleShowAlert = (setAlertShow) => {
        setAlertShow(true);
        setAlertTimeout(
            setTimeout(() => {
                setAlertShow(false);
            }, 3000)
        );
    };

    const [AxesObj, setAxesObj] = useState(''); // Rm
    const [AxesObjCons, setAxesObjCons] = useState(''); // Rm
    const [Workspace, setWorkspace] = useState(''); // Rm
    const [SelectedSession, setSelectedSession] = useState('');

    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const [my] = useState(activeUser[0]);
    const workspace = activeUser[0].workspace[activeUser[0].activeWorkspace];
    const editRole = ['owner', 'admin'].includes(
        workspace.object.teams.on.filter(
            (team) => team.id === activeUser[0].id
        )[0].role
    );
    const [changed, setChanged] = useState(true);

    const displayProjectScoring = (el) => {
        if (
            'guest' !==
            activeUser[0].workspace[
                activeUser[0].activeWorkspace
            ].object.teams.on.filter((team) => team.id === activeUser[0].id)[0]
                .role
        ) {
            setProjetToRate(el.id);
            if (
                Object.keys(SelectedSession.object.rating.teams).length &&
                SelectedSession.object.rating.teams[activeUser[0].id] &&
                SelectedSession.object.rating.teams[activeUser[0].id][el.id]
            ) {
                setAxesObj({
                    effort: SelectedSession.object.rating.teams[
                        activeUser[0].id
                    ][el.id].effort,
                    business_value:
                        SelectedSession.object.snapshot.wsjf.business_value.axis.filter(
                            (ax) => ax.active
                        ).length
                            ? SelectedSession.object.snapshot.wsjf.business_value.axis
                                  .filter((ax) => ax.active)
                                  .reduce((map, obj) => {
                                      map[obj.ref] =
                                          SelectedSession.object.rating.teams[
                                              activeUser[0].id
                                          ][el.id].business_value[obj.ref];
                                      return map;
                                  }, {})
                            : SelectedSession.object.rating.teams[
                                  activeUser[0].id
                              ][el.id].business_value,
                    ...(SelectedSession.object.snapshot.wsjf.risk_opportunity
                        .active && {
                        risk_opportunity:
                            SelectedSession.object.rating.teams[
                                activeUser[0].id
                            ][el.id].risk_opportunity,
                    }),
                    ...(SelectedSession.object.snapshot.wsjf.time_criticality
                        .active && {
                        time_criticality:
                            SelectedSession.object.rating.teams[
                                activeUser[0].id
                            ][el.id].time_criticality,
                    }),
                });
            } else {
                setAxesObj({
                    effort: -1,
                    business_value:
                        SelectedSession.object.snapshot.wsjf.business_value.axis.filter(
                            (ax) => ax.active
                        ).length
                            ? SelectedSession.object.snapshot.wsjf.business_value.axis
                                  .filter((ax) => ax.active)
                                  .reduce((map, obj) => {
                                      map[obj.ref] = -1;
                                      return map;
                                  }, {})
                            : -1,
                    ...(SelectedSession.object.snapshot.wsjf.risk_opportunity
                        .active && {
                        risk_opportunity: -1,
                    }),
                    ...(SelectedSession.object.snapshot.wsjf.time_criticality
                        .active && {
                        time_criticality: -1,
                    }),
                });
            }
            if (isOwnerOrAdmin) {
                setAxesObjCons({
                    effort: -1,
                    business_value:
                        SelectedSession.object.snapshot.wsjf.business_value.axis.filter(
                            (ax) => ax.active
                        ).length
                            ? SelectedSession.object.snapshot.wsjf.business_value.axis
                                  .filter((ax) => ax.active)
                                  .reduce((map, obj) => {
                                      map[obj.ref] = -1;
                                      return map;
                                  }, {})
                            : -1,
                    ...(SelectedSession.object.snapshot.wsjf.risk_opportunity
                        .active && { risk_opportunity: -1 }),
                    ...(SelectedSession.object.snapshot.wsjf.time_criticality
                        .active && { time_criticality: -1 }),
                });
                if (
                    SelectedSession.object.rating.project[el.id]?.consensus_src
                ) {
                    setAxesObjCons(
                        SelectedSession.object.rating.project[el.id]
                            .consensus_src
                    );
                }
                setReady(true);
                document.getElementById('scoring-project').style.display =
                    'block';
            } else {
                document.getElementById('session_votes').style.display =
                    'block';
            }
        }
    };
    const closeSession = (commit = false) => {
        if (commit) {
            Axios({
                ...ApiCallConfig,
                method: 'PATCH',
                url: `/workspace/${Workspace.id}/session/${SelectedSession.id}/`,
                headers: {
                    authorization: 'Bearer ' + activeUser[0].token,
                },
                data: { status: '04_done' },
            })
                .then((res) => {
                    if (res.status === 200 || res.status === 204) {
                        history.push(
                            '/' +
                                id +
                                '/session/#view_create_session#' +
                                SelectedSession.id
                        );
                    }
                })
                .catch((err) => {
                    handleShowAlert(setAlertShow);
                });
        } else {
            setShowModalDoneSession(true);
        }
    };
    const unlockProjectScoring = (pid) => {
        Axios({
            ...ApiCallConfig,
            method: 'PATCH',
            url: `/workspace/${Workspace.id}/session/${SelectedSession.id}/project/${pid}/rating/on`,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
        }).catch((err) => {
            handleShowAlert(setAlertShow);
        });
    };
    const saveProjectScoring = (pid) => {
        Axios({
            ...ApiCallConfig,
            method: 'PATCH',
            url: `/workspace/${Workspace.id}/session/${SelectedSession.id}/project/${pid}/rating/off`,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
        })
            .then((res) => {
                if (res.status < 300) {
                    document.getElementById('scoring-project').style.display =
                        'none';
                }
            })
            .catch((err) => {
                handleShowAlert(setAlertShow);
            });
    };

    const liveStream = () => {
        const ws = new WebSocket(
            Config.WSS.ENDPOINT +
                '/workspace/' +
                workspaceId +
                '/session/' +
                sessionId,
            activeUser[0].token,
            {
                origin: Config.API.ENDPOINT,
            }
        );
        ws.onopen = () => {};
        ws.onmessage = (event) => {
            const response = JSON.parse(event.data);
            if (response.status === 200) {
                setSelectedSession(response.data);
                setIsOwnerOrAdmin(
                    ['owner', 'admin'].includes(
                        activeUser[0].workspace[
                            activeUser[0].activeWorkspace
                        ].object.teams.on.filter(
                            (team) => team.id === activeUser[0].id
                        )[0].role
                    )
                );
                setWorkspace(
                    activeUser[0].workspace[activeUser[0].activeWorkspace]
                ); // rm
            } else {
                history.push('/error/' + response.status);
            }
        };
    };
    useEffect(() => {
        if (
            activeUser[0].workspace.filter((w) => w.id === workspaceId).length
        ) {
            if (
                workspaceId !==
                activeUser[0].workspace[activeUser[0].activeWorkspace].id
            ) {
                dispatch({ type: 'SET_ACTIVE_WORKSPACE', value: workspaceId });
            }
            // get current teams infos
            Teams.get(workspaceId, activeUser[0].token)
                .then((result) => {
                    if (result.status === 200) {
                        setWorkspaceTeams(result.data.data);
                        liveStream();
                    } else {
                        history.push('/error/' + result.status);
                    }
                })
                .catch((err) => {
                    history.push('/error/500');
                });
        } else {
            history.push('/error/403');
        }
    }, []);

    const voteForProject = (votes, consensus = false) => {
        Axios({
            ...ApiCallConfig,
            method: 'PUT',
            url: `/workspace/${Workspace.id}/session/${
                SelectedSession.id
            }/project/${ProjetToRate}/${consensus ? 'consensus' : 'rating'}`,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
            data: votes,
        }).then(() => {
            if (!consensus) {
                setChanged(!changed);
            }
        });
    };
    const removeConsensus = () => {
        Axios({
            ...ApiCallConfig,
            method: 'DELETE',
            url: `/workspace/${Workspace.id}/session/${SelectedSession.id}/project/${ProjetToRate}/consensus`,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
        }).then((res) => {
            setAxesObjCons({
                effort: -1,
                business_value:
                    SelectedSession.object.snapshot.wsjf.business_value.axis.filter(
                        (ax) => ax.active
                    ).length
                        ? SelectedSession.object.snapshot.wsjf.business_value.axis
                              .filter((ax) => ax.active)
                              .reduce((map, obj) => {
                                  map[obj.ref] = -1;
                                  return map;
                              }, {})
                        : -1,
                ...(SelectedSession.object.snapshot.wsjf.risk_opportunity
                    .active && { risk_opportunity: -1 }),
                ...(SelectedSession.object.snapshot.wsjf.time_criticality
                    .active && { time_criticality: -1 }),
            });
        });
    };
    const setAxeValue = (cle, fibo, consensus = false) => {
        const newAxeObj = consensus ? { ...AxesObjCons } : { ...AxesObj };
        newAxeObj[cle] = fibo;
        voteForProject(newAxeObj, consensus);
        consensus ? setAxesObjCons(newAxeObj) : setAxesObj(newAxeObj);
    };
    const setSousAxeValue = (cle, fibo, consensus = false) => {
        const newAxeObj = consensus ? { ...AxesObjCons } : { ...AxesObj };
        newAxeObj['business_value'][cle] = fibo;
        voteForProject(newAxeObj, consensus);
        consensus ? setAxesObjCons(newAxeObj) : setAxesObj(newAxeObj);
    };
    return (
        <>
            <div className="card text-black m-2 pb-2">
                <Row className="no-gutters bg-blue rounded-top">
                    <div className="font-weight-700 display-24 py-3 ml-3 text-uppercase session-details-title">
                        {`Session ${SelectedSession?.object?.name}`}
                    </div>
                </Row>
                <div className="sesion-details-big-container">
                    {editRole && (
                        <Row
                            className="no-gutters font-weight-700 p-3 mt-3 display-16"
                            style={{
                                backgroundColor: 'rgba(0, 85, 255, 0.1)',
                            }}
                        >
                            <Col lg={'auto'} className="my-auto mr-3">
                                <FiInfo color="#0055ff" size="2.2rem" />
                            </Col>
                            <Col className="align-middle my-auto">
                                Pour ouvrir le vote sur un projet à l'ensemble des
                                votants autorisés, il faut commencer par le
                                débloquer en cliquant sur l'icône en forme de
                                cadenas&nbsp;!
                            </Col>
                        </Row>
                    )}
                    <Row className="mr-0 ml-0">
                        <Col className="px-0" style={{ width: '60%' }}>
                            <div
                                className="ml-3 mt-4"
                                style={{ maxWidth: '1500px' }}
                            >
                                <div className="scroll-x radius-14 border border-gray m-0 p-0">
                                    <Table borderless striped className="m-0">
                                        <thead>
                                            <tr>
                                                <th className="font-weight-700 display-14 color-gray minem-w-6 p-1 pl-2"></th>
                                                <th className="font-weight-700 display-14 color-gray minem-w-7 p-1 pl-2">
                                                    Projet
                                                </th>
                                                <th className="font-weight-700 display-14 color-gray minem-w-7 p-1 pl-2">
                                                    Taux de <br />
                                                    participation
                                                </th>

                                                <th className="font-weight-700 display-14 color-gray minem-w-6 p-1 pl-2">
                                                    WSJF
                                                </th>
                                                <th className="font-weight-700 display-14 color-gray minem-w-6 p-1 pl-2">
                                                    Valeur business
                                                </th>
                                                {SelectedSession?.object?.snapshot?.wsjf?.business_value.axis.filter(
                                                    (ax) => ax.active
                                                ).length ? (
                                                    SelectedSession.object.snapshot.wsjf.business_value.axis.map(
                                                        (axis) => {
                                                            if (axis.active)
                                                                return (
                                                                    <th className="font-weight-700 display-14 color-gray minem-w-8 p-1 pl-2">
                                                                        {
                                                                            axis.name
                                                                        }
                                                                    </th>
                                                                );
                                                        }
                                                    )
                                                ) : (
                                                    <></>
                                                )}
                                                {SelectedSession?.object
                                                    ?.snapshot?.wsjf
                                                    ?.time_criticality
                                                    ?.active ? (
                                                    <th className="font-weight-700 display-14 color-gray minem-w-8 p-1 pl-2">
                                                        Contrainte de temps
                                                    </th>
                                                ) : (
                                                    <></>
                                                )}
                                                {SelectedSession?.object
                                                    ?.snapshot?.wsjf
                                                    ?.risk_opportunity
                                                    ?.active ? (
                                                    <th className="font-weight-700 display-14 color-gray minem-w-12 p-1 pl-2">
                                                        Réduction de risque /
                                                        <br />
                                                        Création d'opportunité
                                                    </th>
                                                ) : (
                                                    <></>
                                                )}
                                                <th className="font-weight-700 display-14 color-gray minem-w-8 p-1 pl-2">
                                                    Coût du délai
                                                    <br />
                                                    de livraison
                                                </th>
                                                <th className="font-weight-700 display-14 color-gray minem-w-6 p-1 pl-2">
                                                    Effort
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className={'radius-buttom-14'}>
                                            {SelectedSession?.object?.snapshot.project.map(
                                                (p) => {
                                                    const isDone =
                                                        SelectedSession?.object
                                                            .rating.project?.[
                                                            p.id
                                                        ]?.result ||
                                                        SelectedSession?.object
                                                            .rating.project?.[
                                                            p.id
                                                        ]?.consensus;
                                                    const result =
                                                        SelectedSession?.object
                                                            ?.rating?.project[
                                                            p.id
                                                        ]?.consensus?.wsjf !==
                                                        undefined
                                                            ? 'consensus'
                                                            : 'result';
                                                    return (
                                                        <tr>
                                                            <td className="align-middle minem-w-6 p-1 pl-2">
                                                                {SelectedSession
                                                                    ?.object
                                                                    .rating
                                                                    .project?.[
                                                                    p.id
                                                                ]?.status ===
                                                                'on' ? (
                                                                    <FiPlay
                                                                        size="2rem"
                                                                        className="text-primary sessionRowSelected"
                                                                        onClick={() =>
                                                                            displayProjectScoring(
                                                                                p
                                                                            )
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <div
                                                                        className={`text-secondary ${
                                                                            isOwnerOrAdmin
                                                                                ? 'pointer'
                                                                                : 'not-allowed'
                                                                        }`}
                                                                        {...(isOwnerOrAdmin && {
                                                                            onClick:
                                                                                () =>
                                                                                    unlockProjectScoring(
                                                                                        p.id
                                                                                    ),
                                                                        })}
                                                                    >
                                                                        {isDone ? (
                                                                            <FiCheckCircle
                                                                                size="2rem"
                                                                                className="text-success"
                                                                            />
                                                                        ) : (
                                                                            <FiLock 
                                                                                size="2rem"
                                                                                title="En attente d'ouverture par l'Admin de la session"
                                                                            />
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td className="minem-w-7 p-1 pl-2">
                                                                <div className="rk-std-value">
                                                                    {
                                                                        p.object
                                                                            .name
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className="font-weight-700 minem-w-7 p-1 pl-2">
                                                                {isDone ? (
                                                                    <div className="rk-std-value">
                                                                        {Number(
                                                                            SelectedSession
                                                                                ?.object
                                                                                ?.rating
                                                                                ?.project[
                                                                                p
                                                                                    .id
                                                                            ]
                                                                                ?.vote_rate
                                                                        )
                                                                            .toFixed()
                                                                            .toString()}
                                                                        %
                                                                    </div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </td>

                                                            <th className="font-weight-700 display-14 minem-w-6 p-1 pl-2">
                                                                {isDone ? (
                                                                    <CalcSquare
                                                                        centred={
                                                                            false
                                                                        }
                                                                        value={
                                                                            SelectedSession
                                                                                .object
                                                                                .rating
                                                                                .project[
                                                                                p
                                                                                    .id
                                                                            ]?.[
                                                                                result
                                                                            ]
                                                                                ?.wsjf ||
                                                                            '-'
                                                                        }
                                                                        variant="primary"
                                                                    />
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </th>
                                                            <th className="font-weight-700 display-14 minem-w-6 p-1 pl-2">
                                                                {isDone ? (
                                                                    <CalcSquare
                                                                        centred={
                                                                            false
                                                                        }
                                                                        value={
                                                                            SelectedSession
                                                                                .object
                                                                                .rating
                                                                                .project[
                                                                                p
                                                                                    .id
                                                                            ]?.[
                                                                                result
                                                                            ]
                                                                                ?.business_value ||
                                                                            '-'
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </th>
                                                            {SelectedSession?.object?.snapshot?.wsjf?.business_value.axis.filter(
                                                                (ax) =>
                                                                    ax.active
                                                            ).length ? (
                                                                SelectedSession.object.snapshot.wsjf.business_value.axis.map(
                                                                    (axis) => {
                                                                        if (
                                                                            axis.active
                                                                        )
                                                                            return (
                                                                                <td className="minem-w-8 p-1 pl-2">
                                                                                    {isDone ? (
                                                                                        <CalcSquare
                                                                                            centred={
                                                                                                false
                                                                                            }
                                                                                            value={
                                                                                                SelectedSession
                                                                                                    .object
                                                                                                    .rating
                                                                                                    .project[
                                                                                                    p
                                                                                                        .id
                                                                                                ]?.[
                                                                                                    result
                                                                                                ]
                                                                                                    ?.business_value_axis[
                                                                                                    axis
                                                                                                        .ref
                                                                                                ] ||
                                                                                                '-'
                                                                                            }
                                                                                        />
                                                                                    ) : (
                                                                                        <>

                                                                                        </>
                                                                                    )}
                                                                                </td>
                                                                            );
                                                                    }
                                                                )
                                                            ) : (
                                                                <></>
                                                            )}
                                                            {SelectedSession
                                                                ?.object
                                                                ?.snapshot?.wsjf
                                                                ?.time_criticality
                                                                ?.active ? (
                                                                <td className="minem-w-8 p-1 pl-2">
                                                                    {isDone ? (
                                                                        <CalcSquare
                                                                            centred={
                                                                                false
                                                                            }
                                                                            value={
                                                                                SelectedSession
                                                                                    .object
                                                                                    .rating
                                                                                    .project[
                                                                                    p
                                                                                        .id
                                                                                ]?.[
                                                                                    result
                                                                                ]
                                                                                    ?.time_criticality ||
                                                                                '-'
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </td>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            {SelectedSession
                                                                ?.object
                                                                ?.snapshot?.wsjf
                                                                ?.risk_opportunity
                                                                ?.active ? (
                                                                <td className="minem-w-8 p-1 pl-2">
                                                                    {isDone ? (
                                                                        <CalcSquare
                                                                            centred={
                                                                                false
                                                                            }
                                                                            value={
                                                                                SelectedSession
                                                                                    .object
                                                                                    .rating
                                                                                    .project[
                                                                                    p
                                                                                        .id
                                                                                ]?.[
                                                                                    result
                                                                                ]
                                                                                    ?.risk_opportunity ||
                                                                                '-'
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </td>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <td className="minem-w-8 p-1 pl-2">
                                                                {isDone ? (
                                                                    <CalcSquare
                                                                        centred={
                                                                            false
                                                                        }
                                                                        value={
                                                                            SelectedSession
                                                                                ?.object
                                                                                .rating
                                                                                .project[
                                                                                p
                                                                                    .id
                                                                            ]?.[
                                                                                result
                                                                            ]
                                                                                ?.cost ||
                                                                            '-'
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </td>
                                                            <td className="minem-w-6 p-1 pl-2">
                                                                {isDone ? (
                                                                    <CalcSquare
                                                                        centred={
                                                                            false
                                                                        }
                                                                        value={
                                                                            SelectedSession
                                                                                ?.object
                                                                                .rating
                                                                                .project[
                                                                                p
                                                                                    .id
                                                                            ]?.[
                                                                                result
                                                                            ]
                                                                                ?.effort ||
                                                                            '-'
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                            <Row className="my-5 mx-0">
                                <Col lg={'auto'} className="p-0 mr-3"></Col>
                                <Col
                                    className="p-0"
                                    style={{
                                        minWidth: '350px',
                                        maxWidth: '800px',
                                    }}
                                >
                                    <div className="radius-14 border border-gray">
                                        <div className="p-3">
                                            <span className="action-label pb-3">
                                                Personnes connectées
                                            </span>
                                            <span className="float-right action-label">
                                                {
                                                    [
                                                        ...new Set(
                                                            SelectedSession?.object?.connected_teams
                                                        ),
                                                    ]?.length
                                                }
                                                /
                                                {
                                                    SelectedSession?.object
                                                        ?.teams?.length
                                                }
                                            </span>
                                        </div>
                                        <hr className="m-0" />
                                        <div className="px-3 py-2">
                                            {[
                                                ...new Set(
                                                    SelectedSession?.object?.connected_teams
                                                ),
                                            ].map((tid) => {
                                                const teams =
                                                    WorkspaceTeams.filter(
                                                        (team) =>
                                                            team.id === tid
                                                    );
                                                return (
                                                    <TeamIcon
                                                        team={
                                                            teams?.[0]?.object
                                                        }
                                                        my={my}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <hr className="m-0" />
                                        <div className="p-3">
                                            <span className="action-label pb-3">
                                                Personnes invitées hors ligne
                                            </span>
                                            <span className="float-right action-label">
                                                {SelectedSession?.object?.teams
                                                    ?.length
                                                    ? SelectedSession?.object
                                                          ?.teams?.length -
                                                      [
                                                          ...new Set(
                                                              SelectedSession?.object?.connected_teams
                                                          ),
                                                      ]?.length
                                                    : ''}
                                                /
                                                {
                                                    SelectedSession?.object
                                                        ?.teams?.length
                                                }
                                            </span>
                                        </div>
                                        <hr className="m-0" />
                                        <div className="px-3 py-2">
                                            {SelectedSession?.object?.teams
                                                .filter(
                                                    (tid) =>
                                                        !SelectedSession?.object?.connected_teams.includes(
                                                            tid
                                                        )
                                                )
                                                .map((tid) => {
                                                    const teams =
                                                        WorkspaceTeams.filter(
                                                            (team) =>
                                                                team.id === tid
                                                        );
                                                    return (
                                                        <TeamIcon
                                                            team={
                                                                teams?.[0]
                                                                    ?.object
                                                            }
                                                            my={my}
                                                        />
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={'auto'} className="flex items-end column">
                            <div className="d-grid pt-3">
                                {editRole && (
                                    <BlueButton
                                        content={
                                            <>
                                                Enregistrer
                                                <br />
                                                la session
                                            </>
                                        }
                                        icon={<FiSave size="2.2rem" />}
                                        {...(SelectedSession?.object?.status ===
                                        '02_prioritizing'
                                            ? { disabled: false }
                                            : { disabled: true })}
                                        onClick={() => closeSession()}
                                    />
                                )}
                                <GreyButton
                                    content={
                                        <>
                                            Retour sur la<br></br>
                                            fiche session
                                        </>
                                    }
                                    icon={<FiArrowLeft size="2.2rem" />}
                                    onClick={() =>
                                        history.push(
                                            '/' +
                                                id +
                                                '/session/#view_create_session#' +
                                                SelectedSession.id
                                        )
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <ProjectScoring
                ready={Ready}
                id={ProjetToRate}
                WorkspaceTeams={WorkspaceTeams}
                my={my}
                data={SelectedSession}
                saveProjectScoring={(pid) => saveProjectScoring(pid)}
                removeConsensus={() => removeConsensus()}
            />
            <Modal
                show={showModalDoneSession}
                centered={true}
                modalFirstText="Etes-vous certain de vouloir enregistrer la session ?"
                modalSecondText="Cette action est irréversible. 
                                Néanmoins, si vous voulez apporter une correction sur un vote, 
                                vous pouvez toujours créer une nouvelle session 
                                et y inclure le projet à modifier."
                modalContainerClassname="axeModalContainerClassname"
                modalContentClassName="axeModalContentClassName"
                closeIcon="axeModalcloseIcon cursorPointer"
                containerTextWithIconClassname="axesContainerTextWithIconClassname"
                alertIconClassName="axesAlertIconClassName"
                firstBtnClassName="axeModalFirstBtn"
                secondBtnClassName="axeModalSecondBtn"
                setShowModalFunction={() => setShowModalDoneSession(false)}
                textClassName="axeModalTextClassName"
                saveBtnFunction={() => closeSession(true)}
            />
            <SessionVote
                fibonacci={fibonacci}
                AxesObj={AxesObj}
                Wsjf={SelectedSession?.object?.snapshot?.wsjf}
                setAxeValue={(cle, fibo) => setAxeValue(cle, fibo)}
                setSousAxeValue={(cle, fibo) => setSousAxeValue(cle, fibo)}
            />
            <SessionVoteConsensus
                fibonacci={fibonacci}
                AxesObj={AxesObjCons}
                Wsjf={SelectedSession?.object?.snapshot?.wsjf}
                setAxeValue={(cle, fibo, cons) => setAxeValue(cle, fibo, cons)}
                setSousAxeValue={(cle, fibo, cons) =>
                    setSousAxeValue(cle, fibo, cons)
                }
            />
            {/* <Alert
                color={alertColor}
                content={alertContent}
                showAlert={alertShow}
                setAlertShow={setAlertShow}
                alertTimeout={alertTimeout}
            /> */}
        </>
    );
}
export default SessionDetails;
