import React from 'react';

function AxePrioHeader(props) {
    return (
        <div className="bg-gray text-black rounded-top workspace-header-style flex v-center">
            <span className="flex v-center workspace-title ml-3 py-3">Gestion des axes de priorisation</span>
        </div>
    );
}

export default AxePrioHeader;
