import {
    Container,
    Navbar,
    Button,
    Form,
    FormControl,
    InputGroup,
    Row,
    Col,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Validator from '../../util/Validator';
import Config from '../../util/Config';
import Echo from '../../util/Echo';
import ErrorSelector from '../error/ErrorSelector';
import Logo from '../commun/Logo';
import { FiEye, FiEyeOff, FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import ApiCallConfig from '../../util/ApiCallConfig';
export default (props) => {
    const { trackPageView, trackEvent } = useMatomo();
    const state = useSelector((state) => state.user);
    useEffect(() => {
        document.title = Echo(state.lang).seo.title.login
            ? Echo(state.lang).seo.title.prefix +
              Echo(state.lang).seo.title.login +
              Echo(state.lang).seo.title.suffix
            : Echo(state.lang).seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo(state.lang).seo.title.default;
        };
    }, []);
    if (Config.APP_ENV === 'staging') {
        return (
            <ErrorSelector
                code={200}
                title="Connexion"
                message="Page indisponible pour les beta testeurs.  Veuillez nous contacter, nous vous enverrons les instructions pour vous reconnecter simplement."
                retry_value="Contactez-nous"
                retry_link="/contact"
                retry_color="outline-primary"
            />
        );
    }
    const history = useHistory();
    const [payload, setPayload] = useState({
        email: '',
        password: '',
    });
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const [pwdInput, setPwdInput] = useState({
        type: 'password',
        on: 'd-block',
        off: 'd-none',
    });
    const handleChange = (event) => {
        setMessage('');
        setPayload({ ...payload, [event.target.name]: event.target.value });
        if (Object.keys(errors).length !== 0 || errors.constructor !== Object) {
            const e = Validator({
                ...payload,
                [event.target.name]: event.target.value,
            });
            if (e.password) e.password = 'Mot de passe invalide';
            if (e.email) e.email = 'Adresse mail invalide';
            if (e) setErrors(e);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const e = Validator(payload);
        if (Object.keys(e).length !== 0 || e.constructor !== Object) {
            if (e.password) e.password = 'Mot de passe invalide';
            if (e.email) e.email = 'Adresse mail invalide';
            setErrors(e);
        } else {
            Axios({
                ...ApiCallConfig,
                url: '/token',
                auth: {
                    username: payload.email,
                    password: payload.password,
                },
            }).then((result) => {
                if (result.status === 200) {
                    dispatch({
                        type: 'SET_USER_TOKEN',
                        value: result.data.data,
                    });
                    Axios({
                        ...ApiCallConfig,
                        method: 'GET',
                        url: '/user',
                        headers: {
                            authorization: 'Bearer ' + result.data.data.token,
                        },
                    })
                        .then((data) => {
                            if (data.status === 200) {
                                dispatch({
                                    type: 'ADD_USER',
                                    value: {
                                        ...data.data.data[0],
                                        workspace: [],
                                    },
                                    token: result.data.data.token,
                                });
                                Axios({
                                    ...ApiCallConfig,
                                    method: 'GET',
                                    url: '/workspace',
                                    headers: {
                                        authorization:
                                            'Bearer ' + result.data.data.token,
                                    },
                                }).then((res) => {
                                    if (res.status === 200) {
                                        dispatch({
                                            type: 'UPDATE_USER',
                                            value: data.data.data[0],
                                        });
                                        dispatch({
                                            type: 'UPDATE_WORKSPACE_LIST',
                                            value: {
                                                workspace: res.data.data,
                                                id: data.data.data[0].id,
                                            },
                                        });
                                        history.push(
                                            '/' +
                                                data.data.data[0].id.split(
                                                    '-'
                                                )[0] +
                                                '/dashboard'
                                        );
                                    } else if (res.status === 404) {
                                        dispatch({
                                            type: 'UPDATE_USER',
                                            value: data.data.data[0],
                                        });
                                        dispatch({
                                            type: 'UPDATE_WORKSPACE_LIST',
                                            value: {
                                                workspace: [],
                                                id: data.data.data[0].id,
                                            },
                                        });
                                        history.push(
                                            '/' +
                                                data.data.data[0].id.split(
                                                    '-'
                                                )[0] +
                                                '/onboarding'
                                        );
                                    } else {
                                        setMessage(
                                            'Un problème est survenu, veuillez réessayer.'
                                        );
                                    }
                                    trackEvent({
                                        category: 'connection',
                                        action: 'login',
                                        name: 'Je me connecte',
                                        value: res.status === 200 ? 1 : 0,
                                    });
                                });
                            } else {
                                setMessage(
                                    'Un problème est survenu, veuillez réessayer.'
                                );
                            }
                        })
                        .catch((err) => {
                            setMessage(
                                'Un problème est survenu, veuillez réessayer.'
                            );
                        });
                } else if (result.status === 401 || result.status === 404) {
                    setMessage('Adresse email et/ou mot de passe invalide.');
                } else if (result.status === 412) {
                    const expire = Date.now() + 3600 * 1000;
                    dispatch({
                        type: 'SET_EMAIL_EXPIRY',
                        value: { email: payload.email, expire },
                    });
                    history.push('/validate');
                } else if (result.status === 429) {
                    setMessage(
                        'Trop de tentatives. Veuillez patienter quelques instants puis réessayez.'
                    );
                } else {
                    setMessage('Un problème est survenu, veuillez réessayer.');
                }
            });
        }
    };
    const showHidePwd = () => {
        if (pwdInput.type === 'password')
            setPwdInput({ type: 'text', on: 'd-none', off: 'd-block' });
        else {
            setPwdInput({ type: 'password', on: 'd-block', off: 'd-none' });
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <Navbar className="fixed-top rankio-header" bg="white" expand="xl">
                <Link to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </Link>
                <Link to="/" className="ml-auto">
                    <FiX size="1.5em" className="rk-clickable-icon-dark" />
                </Link>
            </Navbar>
            <div className="landing">
                <Row className="p-4">
                    <Col sm={1} md={2} lg={1}></Col>
                    <Col lg={4} className="d-none d-lg-block">
                        <h3 className="font-weight-800 mb-4">
                            Pour bien prioriser vos projets
                        </h3>
                        <a
                            href={
                                Config.CDN.ENDPOINT +
                                'static/media/dashboard.jpg'
                            }
                            target="_blank"
                            title="Une liste de projets priorisés"
                        >
                            <img
                                className="card w-100 mt-3 p-3"
                                src={
                                    Config.CDN.ENDPOINT +
                                    'static/media/dashboard.jpg'
                                }
                                alt="Consulter le dashboard listant les projets priorisés"
                            />
                        </a>
                    </Col>
                    <Col lg={1} className="d-none d-lg-block"></Col>
                    <Col sm={10} md={8} lg={5}>
                        <h3 className="font-weight-800 mb-4">
                            Connectez-vous à votre compte Rankio
                        </h3>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group controlId="email">
                                <Form.Label className="input-label mt-3">
                                    E-mail
                                </Form.Label>
                                <Form.Control
                                    name="email"
                                    type="email"
                                    placeholder="nom@entreprise.com"
                                    value={payload.email}
                                    className="input"
                                    onChange={handleChange}
                                />
                                <Form.Text className="mt-2 ml-2 text-danger font-weight-bold">
                                    {errors.email}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Form.Label className="input-label mt-3">
                                    Mot de passe
                                </Form.Label>
                                <InputGroup>
                                    <FormControl
                                        name="password"
                                        type={pwdInput.type}
                                        placeholder="Mot de passe"
                                        value={payload.password}
                                        className="input"
                                        onChange={handleChange}
                                    />
                                    <InputGroup.Text
                                        id="pwdEys"
                                        className="cursor-pointer radius-14 border-0 text-secondary display-20 hover-grey"
                                        style={{
                                            backgroundColor:
                                                'rgba(153, 153, 153, 0.1)',
                                            borderTopLeftRadius: '0px',
                                            borderBottomLeftRadius: '0px',
                                        }}
                                        onClick={showHidePwd}
                                    >
                                        <FiEye className={pwdInput.off} />
                                        <FiEyeOff className={pwdInput.on} />
                                    </InputGroup.Text>
                                </InputGroup>
                                <Form.Text className="mt-2 ml-2 text-danger font-weight-bold">
                                    {errors.password}
                                </Form.Text>
                            </Form.Group>
                            <Link to="/recovery" className="text-black small">
                                <span className="font-weight-bold">
                                    <u>Mot de passe oublié ?</u>
                                </span>
                            </Link>
                            <center>
                                <Button
                                    className="btn-lg btn-primary w-100 mt-5 mb-4"
                                    style={{
                                        height: '5rem',
                                        maxWidth: '340px',
                                    }}
                                    type="submit"
                                >
                                    <span className="h3 font-weight-800 display-24">
                                        Se connecter
                                    </span>
                                </Button>
                            </center>
                            <div className="d-none d-lg-block">
                                <Row className="my-3">
                                    <Col className="col-12 text-center d-xl-none d-block font-weight-600">
                                        Nouveau sur Rankio&nbsp;?
                                    </Col>
                                    <Col className="col-12 text-center d-xl-none d-block">
                                        <Link
                                            to="/signup"
                                            className="text-black font-weight-800"
                                        >
                                            <span>
                                                <u>Créer un compte</u>
                                            </span>
                                        </Link>
                                    </Col>
                                    <Col className="col-6 text-right d-none d-xl-block font-weight-600">
                                        Nouveau sur Rankio&nbsp;?
                                    </Col>
                                    <Col className="col-6 text-left d-none d-xl-block">
                                        <Link
                                            to="/signup"
                                            className="text-black font-weight-800"
                                        >
                                            <span>
                                                <u>Créer un compte</u>
                                            </span>
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                            <div className="d-lg-none d-block">
                                <Row className="my-3">
                                    <Col className="col-12 text-center d-sm-none d-block font-weight-600">
                                        Nouveau sur Rankio&nbsp;?
                                    </Col>
                                    <Col className="col-12 text-center d-sm-none d-block">
                                        <Link
                                            to="/signup"
                                            className="text-black font-weight-800"
                                        >
                                            <span>
                                                <u>Créer un compte</u>
                                            </span>
                                        </Link>
                                    </Col>
                                    <Col className="col-6 text-right d-none d-sm-block font-weight-600 pl-0">
                                        Nouveau sur Rankio&nbsp;?
                                    </Col>
                                    <Col className="col-6 text-left d-none d-sm-block">
                                        <Link
                                            to="/signup"
                                            className="text-black font-weight-800"
                                        >
                                            <span>
                                                <u>Créer un compte</u>
                                            </span>
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                            <Form.Group>
                                <Form.Text className="text-danger font-weight-bold">
                                    {message}
                                </Form.Text>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col sm={1} md={2} lg={1}></Col>
                </Row>
            </div>
        </Container>
    );
};
