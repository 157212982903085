import React from 'react';
import RkModal from '../commun/RkModal';

function SessionVote(props) {
    const getAxeName = (name) => {
        switch (name) {
            case 'business_value':
                return 'Valeur business';
            case 'risk_opportunity':
                return "Réduction de risque ou création d'opportunité";
            case 'time_criticality':
                return 'Contrainte de temps';
            case 'effort':
                return 'Effort';
            default:
                return '';
        }
    };
    return (
        <RkModal
            id="session_votes"
            title="Vote"
            className="card m-2 invite-user-container-width vote-modal mt-40"
            backgroundColor="blue"
            style={{ display: 'block' }}
        >
            {Object.keys(props.AxesObj).map((cle, cleIndex) => {
                if (cle === 'business_value') {
                    if (typeof props.AxesObj.business_value === 'object') {
                        return Object.keys(props.AxesObj.business_value).map(
                            (sousAxe, index) => {
                                return (
                                    <div className="px-3 mt-4" key={index}>
                                        <span className="action-label">
                                            {
                                                props.Wsjf.business_value.axis.filter(
                                                    (axis) =>
                                                        axis.ref === sousAxe
                                                )[0].name
                                            }
                                        </span>
                                        <div className="flex pt-3 mb-4">
                                            <div
                                                className="flex space-between wrap mb-20"
                                                style={{ maxWidth: 580 }}
                                            >
                                                {props.fibonacci.map((fibo) => (
                                                    <div
                                                        onClick={() =>
                                                            props.setSousAxeValue(
                                                                sousAxe,
                                                                fibo
                                                            )
                                                        }
                                                        className={`pointer flex v-center h-center ${
                                                            Object.values(
                                                                props.AxesObj
                                                            )[cleIndex][
                                                                sousAxe
                                                            ] === fibo
                                                                ? 'score-card-selected'
                                                                : 'score-card'
                                                        }  mb-20`}
                                                    >
                                                        <span>{fibo}</span>
                                                    </div>
                                                ))}
                                            </div>
                                            <div
                                                onClick={() =>
                                                    props.setSousAxeValue(
                                                        sousAxe,
                                                        null
                                                    )
                                                }
                                                className={`pointer flex v-center h-center ${
                                                    Object.values(
                                                        props.AxesObj
                                                    )[cleIndex][sousAxe] ===
                                                    null
                                                        ? 'score-card-selected'
                                                        : 'score-card'
                                                } mb-20`}
                                                style={{
                                                    height: 220,
                                                    marginLeft: 20,
                                                }}
                                            >
                                                <span>?</span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        );
                    } else {
                        return (
                            <div className="px-3 mt-4" key={cleIndex}>
                                <span className="action-label">
                                    {getAxeName(cle)}
                                </span>
                                <div className="flex pt-3 mb-4">
                                    <div
                                        className="flex space-between wrap mb-20"
                                        style={{ maxWidth: 580 }}
                                    >
                                        {props.fibonacci.map((fibo) => (
                                            <div
                                                onClick={() =>
                                                    props.setAxeValue(cle, fibo)
                                                }
                                                className={`pointer flex v-center h-center ${
                                                    Object.values(
                                                        props.AxesObj
                                                    )[cleIndex] === fibo
                                                        ? 'score-card-selected'
                                                        : 'score-card'
                                                }  mb-20`}
                                            >
                                                <span>{fibo}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <div
                                        onClick={() =>
                                            props.setAxeValue(cle, null)
                                        }
                                        className={`pointer flex v-center h-center ${
                                            Object.values(props.AxesObj)[
                                                cleIndex
                                            ] === null
                                                ? 'score-card-selected'
                                                : 'score-card'
                                        } mb-20`}
                                        style={{ height: 220, marginLeft: 20 }}
                                    >
                                        <span>?</span>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                } else {
                    return (
                        <div className="px-3 mt-4" key={cleIndex}>
                            <span className="action-label">
                                {getAxeName(cle)}
                            </span>
                            <div className="flex pt-3 mb-4">
                                <div
                                    className="flex space-between wrap mb-20"
                                    style={{ maxWidth: 580 }}
                                >
                                    {props.fibonacci.map((fibo) => (
                                        <div
                                            onClick={() =>
                                                props.setAxeValue(cle, fibo)
                                            }
                                            className={`pointer flex v-center h-center ${
                                                Object.values(props.AxesObj)[
                                                    cleIndex
                                                ] === fibo
                                                    ? 'score-card-selected'
                                                    : 'score-card'
                                            }  mb-20`}
                                        >
                                            <span>{fibo}</span>
                                        </div>
                                    ))}
                                </div>
                                <div
                                    onClick={() => props.setAxeValue(cle, null)}
                                    className={`pointer flex v-center h-center ${
                                        Object.values(props.AxesObj)[
                                            cleIndex
                                        ] === null
                                            ? 'score-card-selected'
                                            : 'score-card'
                                    } mb-20`}
                                    style={{ height: 220, marginLeft: 20 }}
                                >
                                    <span>?</span>
                                </div>
                            </div>
                        </div>
                    );
                }
            })}
        </RkModal>
    );
}

export default SessionVote;
