import Axios from 'axios';
import ApiCallConfig from './ApiCallConfig';
export default class PaymentMethods {
    /**
     * @function post
     * @definition Create payment methods
     * @returns {Promise}
     */
    static post(data, token) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                method: 'POST',
                timeout: 30000,
                url: '/user/billing/paymentmethods',
                headers: {
                    authorization: 'Bearer ' + token,
                },
                data,
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    /**
     * @function get
     * @definition Get user payment methods
     * @returns {Promise}
     */
    static get(params, token) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                timeout: 30000,
                method: 'GET',
                url: '/user/billing/paymentmethods',
                params,
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    /**
     * @function put
     * @definition Set payment methods expiration date
     * @returns {Promise}
     */
    static put(id, token, data) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                timeout: 30000,
                method: 'PATCH',
                url: '/user/billing/paymentmethods/' + id,
                headers: {
                    authorization: 'Bearer ' + token,
                },
                data,
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    /**
     * @function patch
     * @definition Set default payment methods
     * @returns {Promise}
     */
    static patch(id, token) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                timeout: 30000,
                method: 'PATCH',
                url: '/user/billing/paymentmethods/default/' + id,
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    /**
     * @function delete
     * @definition Remove payment methods
     * @returns {Promise}
     */
    static delete(id, token) {
        return new Promise((resolve, reject) => {
            Axios({
                ...ApiCallConfig,
                timeout: 30000,
                method: 'DELETE',
                url: '/user/billing/paymentmethods/' + id,
                headers: {
                    authorization: 'Bearer ' + token,
                },
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}
