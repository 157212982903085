import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import ErrorSelector from '../error/ErrorSelector';
import Echo from '../../util/Echo';
import { FiCheckCircle } from 'react-icons/fi';
import { useMatomo } from '@datapunt/matomo-tracker-react';
export default () => {
    const { trackPageView } = useMatomo();
    useEffect(() => {
        document.title = Echo().seo.title.healthcheck
            ? Echo().seo.title.prefix +
              Echo().seo.title.healthcheck +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, []);
    if (Cookies.get('RK_healthcheck') === 'ok') {
        return (
            <ErrorSelector
                logo="1"
                code={200}
                title={<FiCheckCircle size="2rem" className="text-success" />}
                message="Tous les systèmes sont opérationnels."
                retry_value="Revenir à la page d'accueil"
                retry_link="/"
                retry_color="outline-primary"
                retry_icone="home"
            />
        );
    } else {
        return (
            <ErrorSelector
                logo="1"
                code={503}
                title="503"
                message="Le service est momentanément indisponible."
                retry_value="Réessayer"
                retry_href="javascript:window.location.reload();"
                retry_color="primary"
                retry_icone="reload"
            />
        );
    }
};
