import Modal from '../commun/RkModal';
import React, { useEffect, useState } from 'react';
import { FiSend, FiX, FiTrash2, FiArrowRight } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';
import { Button, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import GreyButton from '../commun/GreyButton';
import BlueButton from '../commun/BlueButton';
import Teams from '../../util/Teams';
import Workspace from '../../util/Workspace';
import Alert from '../commun/Alert';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Echo from '../../util/Echo';
export default function InviteUser(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const rgx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const users = useSelector((state) => state.user.users);
    const { trackPageView, trackEvent } = useMatomo();
    const { id } = useParams();
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = users.filter((user) => user.id.includes(id))[0].workspace[
        activeUser[0].activeWorkspace
    ];
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [payload, setPayload] = useState({});
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [showAlertFail, setShowAlertFail] = useState(false);
    const options = [
        { value: 'member', label: Echo().teams.fields.role.member },
        { value: 'admin', label: Echo().teams.fields.role.admin },
        { value: 'guest', label: Echo().teams.fields.role.guest },
    ];
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleAddEmail();
        }
    };
    const handleAddEmail = () => {
        Teams.get(workspace.id, activeUser[0].token)
            .then((res) => {
                if (res.status === 200) {
                    switch (true) {
                        case !rgx.test(String(email).toLowerCase()):
                        case email.trim().length === 0:
                            setError('Veuillez saisir une adresse mail valide');
                            break;
                        case res.data.data.filter(
                            (t) => t.object.email === email.trim().toLowerCase()
                        ).length > 0:
                            setError(
                                'Cette adresse mail existe déjà dans la liste des membres'
                            );
                            break;
                        case Object.values(payload).filter(
                            (t) => t.email === email.trim().toLowerCase()
                        ).length > 0:
                            setError(
                                'Cette adresse mail existe déjà dans la liste ci-dessous'
                            );
                            break;
                        default:
                            if (Object.keys(payload).length === 9) {
                                setError(
                                    `Vous avez atteint le maximum d’invitations en un seul envoi, n’hésitez pas à renouveler cette opération autant de fois que nécessaire pour continuer à inviter d’autres utilisateurs !`
                                );
                            } else {
                                setError('');
                            }
                            setPayload({
                                ...payload,
                                [uuidv4()]: {
                                    email,
                                    role: 'guest',
                                },
                            });
                            setEmail('');
                    }
                } else {
                    setError(
                        'Une erreur est survenue, veuillez réessayer ultérieurement'
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleChange = (e) => {
        setError('');
        setEmail(e.target.value);
    };
    const deleteItem = async (key) => {
        const result = { ...payload };
        delete result[key];
        setPayload(result);
        setError('');
    };
    const handleSubmit = () => {
        Teams.post(workspace.id, activeUser[0].token, payload)
            .then((res) => {
                Workspace.get({ id: workspace.id }, activeUser[0].token).then(
                    (res) => {
                        if (res.status === 200) {
                            dispatch({
                                type: 'SET_WORKSPACE',
                                value: {
                                    workspace: res.data.data[0],
                                    id,
                                    index: activeUser[0].activeWorkspace,
                                },
                            });
                        }
                    }
                );
                if (
                    Object.keys(res.data.responses).filter(
                        (t) =>
                            res.data.responses[t].status === 402 &&
                            payload[t].role === 'admin'
                    ).length
                ) {
                    document.getElementById('view_add_option').style.display =
                        'block';
                    props.getData();
                    Object.keys(res.data.responses)
                        .filter(
                            (id) =>
                                !(
                                    res.data.responses[id].status === 402 &&
                                    payload[id].role === 'admin'
                                )
                        )
                        .forEach(async (key) => {
                            await delete payload[key];
                            setPayload({ ...payload });
                        });
                } else {
                    document.getElementById('view_invite_team').style.display =
                        'none';
                    props.getData();
                    setShowAlertSuccess(true);
                    setPayload({});
                    setTimeout(() => {
                        setShowAlertSuccess(false);
                    }, 5000);
                }
            })
            .catch((err) => {
                console.log(err);
                setShowAlertFail(true);
                setTimeout(() => {
                    setShowAlertFail(false);
                }, 5000);
            });
    };
    useEffect(() => {
        setEmail('');
        setError('');
        setPayload({});
        Teams.get(workspace.id, activeUser[0].token)
            .then((res) => {
                if (res.status === 200) {
                    document.getElementById('view_invite_team').style.display =
                        'none';
                } else {
                    history.pushState('/error/' + res.status);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [workspace.object.teams]);
    return (
        <>
            <Modal
                id="view_invite_team"
                title="Invitation"
                className={`card rk-modal-level-${props.level || 1} m-2`}
            >
                <div className={`rk-modal-body-level-${props.level || 1} p-0`}>
                    <Row className="m-0">
                        <Col className="pl-3 pt-4">
                            <div style={{ height: '200px' }}>
                                <span className="descr-label">
                                    Qui voulez-vous inviter dans le workspace
                                </span>
                                <span className="highlight-label">
                                    {workspace.object.name}
                                </span>
                                <span className="descr-label"> ?</span>

                                <div className="action-label pt-5 pb-3">
                                    Veuillez indiquer le mail de la personne à
                                    inviter&nbsp;:
                                </div>
                                <div className="invitationArrowContainer">
                                    <Form.Control
                                        type="text"
                                        placeholder="E-mail"
                                        value={email}
                                        className="rounded border-0 input mb-1"
                                        onChange={(e) => handleChange(e)}
                                        style={{
                                            maxWidth: '40ch',
                                        }}
                                        onKeyPress={(e) => handleKeyPress(e)}
                                        disabled={
                                            Object.keys(payload).length === 10
                                        }
                                    />
                                    <Button
                                        variant="primary"
                                        className="invitationArrowIcon"
                                        style={{
                                            width: '2.7rem',
                                            height: '2.7rem',
                                        }}
                                        onClick={handleAddEmail}
                                        disabled={
                                            Object.keys(payload).length === 10
                                        }
                                    >
                                        <FiArrowRight
                                            size="1.8rem"
                                            strokeWidth="3"
                                            className="invitationArrowIconDetails"
                                        />
                                    </Button>
                                    <div
                                        className="invitationErrorMessage pl-2"
                                        style={{
                                            maxWidth: '72ch',
                                        }}
                                    >
                                        {error}
                                    </div>
                                </div>
                            </div>

                            {Object.keys(payload).length > 0 && (
                                <>
                                    <div
                                        className="action-label"
                                        style={{ width: '610px' }}
                                    >
                                        Veuillez indiquer le futur rôle de la
                                        personne à inviter&nbsp;:
                                        <span
                                            className={
                                                (Object.keys(payload).length ===
                                                10
                                                    ? 'text-danger'
                                                    : 'text-secondary') +
                                                ' float-right'
                                            }
                                        >
                                            {Object.keys(payload).length}/10
                                        </span>
                                    </div>

                                    <div className="listInvitedUserContainer">
                                        {Object.keys(payload)?.map((key, i) => {
                                            let classNameDetails =
                                                'invitationListeDetailsSecondCol';
                                            let inputclassname =
                                                'invitationSecondInputDetails';
                                            if (i % 2 === 0) {
                                                classNameDetails =
                                                    'invitationListeDetailsFirstCol';
                                                inputclassname =
                                                    'invitationInputDetails';
                                            } else if (
                                                i ===
                                                Object.values(payload).length -
                                                    1
                                            ) {
                                                classNameDetails =
                                                    classNameDetails +
                                                    ' invitationListeDetailsLastCol';
                                            }
                                            return (
                                                <div
                                                    className={classNameDetails}
                                                >
                                                    <span className="invitationEmailText">
                                                        {payload[key].email}
                                                    </span>
                                                    <div className="invitationDropDownContainer">
                                                        <Select
                                                            value={options.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    payload[key]
                                                                        .role
                                                            )}
                                                            onChange={(e) => {
                                                                setPayload({
                                                                    ...payload,
                                                                    [key]: {
                                                                        email: payload[
                                                                            key
                                                                        ].email,
                                                                        role: e.value,
                                                                    },
                                                                });
                                                            }}
                                                            options={options.filter(
                                                                (option) =>
                                                                    option.value !==
                                                                    payload[key]
                                                                        .role
                                                            )}
                                                            className={
                                                                inputclassname
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className="invitationBtnDeleteContainer"
                                                        onClick={() =>
                                                            deleteItem(key)
                                                        }
                                                    >
                                                        <FiTrash2
                                                            size="2rem"
                                                            color="#999999"
                                                            className="mx-3"
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </Col>
                        <Col
                            lg={'auto'}
                            className="sticky-fixed flex items-end column axe-priorisation-btn-container pr-4 pt-4"
                        >
                            {Object.keys(payload).length ? (
                                <>
                                    <BlueButton
                                        btnClassnameDetails="mt-0"
                                        content={
                                            Object.keys(payload).length == 1 ? (
                                                <>
                                                    Envoyer
                                                    <br />
                                                    l'invitation
                                                </>
                                            ) : (
                                                <>
                                                    Envoyer les{' '}
                                                    {
                                                        Object.keys(payload)
                                                            .length
                                                    }
                                                    <br />
                                                    invitations
                                                </>
                                            )
                                        }
                                        icon={<FiSend size="2.2rem" />}
                                        onClick={handleSubmit}
                                    />
                                    <GreyButton
                                        content="Annuler"
                                        icon={<FiX size="2.2rem" />}
                                        onClick={() => {
                                            setEmail('');
                                            setError('');
                                            setPayload({});
                                            document.getElementById(
                                                'view_invite_team'
                                            ).style.display = 'none';
                                        }}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </Col>
                    </Row>
                </div>
            </Modal>
            <Alert
                color="primary"
                content="Les invitations ont bien été envoyées !"
                showAlert={showAlertSuccess}
                setAlertShow={setShowAlertSuccess}
                handleCloseAlert={() => setShowAlertSuccess(false)}
                contentClassname="axecontentClassname"
            />
            <Alert
                color="danger"
                content="Une erreur est survenue lors de l'envoi des invitations. Veuillez réessayer."
                showAlert={showAlertFail}
                setAlertShow={setShowAlertFail}
                handleCloseAlert={() => setShowAlertFail(false)}
                contentClassname="axecontentClassname"
            />
        </>
    );
}
