import React, { useState } from 'react';
import { Accordion, Card, Row, Col } from 'react-bootstrap';
import { FiTrendingUp, FiTruck, FiTarget } from 'react-icons/fi';
import { useMatomo } from '@datapunt/matomo-tracker-react';

export default () => {
    const { trackEvent } = useMatomo();
    const [open, setOpen] = useState({ a1: false, a2: false, a3: false });

    return (
        <div className="mt-5 p-3">
            <h2 className="font-weight-900 text-center mt-4 mb-5 mx-auto col-md-8">
                Utilisez Rankio pour dégager un maximum de valeur
            </h2>
            <Row className="mx-0">
                <Col sm={12} md={1} className="my-5 col-xxl-2"></Col>
                <Col sm={12} md={10} className="my-5 col-xxl-8">
                    <Row className="mx-0 h-center">
                        <Col sm={10} md={7} xl={4} className="my-5">
                            <Accordion>
                                <Card>
                                    <Accordion.Toggle
                                        className="bg-white cursor-pointer p-4"
                                        style={{
                                            height: '300px',
                                        }}
                                        as={Card.Header}
                                        eventKey="0"
                                        onClick={() => {
                                            setOpen({ ...open, a1: !open.a1 });
                                            if (!open.a1)
                                                trackEvent({
                                                    category: 'benefits',
                                                    action: 'open',
                                                    name: 'Augmentation de la valeur produite',
                                                    value: 0,
                                                });
                                        }}
                                    >
                                        <FiTrendingUp
                                            size="2.2rem"
                                            className="text-primary mb-3"
                                        />
                                        <div className="font-weight-800 display-24 text-center mt-4">
                                            Augmentation de la valeur produite
                                            pour l'entreprise, les équipes, et
                                            les clients
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body className="font-weight-600 p-4">
                                            Inspiré du mouvement Lean, cette
                                            approche de la création de valeur
                                            permet de&nbsp;:
                                            <br />
                                            <br />
                                            - Faciliter l'émergence d'une
                                            organisation centrée utilisateur
                                            <br />
                                            <br />
                                            - Développer une culture de la
                                            mesure et de la data
                                            <br />
                                            <br />
                                            - Mettre en place un fonctionnement
                                            agile facilitant les itérations et
                                            l'amélioration continue (test &
                                            learn)
                                            <br />
                                            <br />
                                            - Légitimer et rationaliser la prise
                                            de décision
                                            <br />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>
                        <Col sm={10} md={7} xl={4} className="my-5">
                            <Accordion>
                                <Card>
                                    <Accordion.Toggle
                                        className="bg-white cursor-pointer p-4"
                                        style={{
                                            height: '300px',
                                        }}
                                        as={Card.Header}
                                        eventKey="0"
                                        onClick={() => {
                                            setOpen({ ...open, a2: !open.a2 });
                                            if (!open.a2)
                                                trackEvent({
                                                    category: 'benefits',
                                                    action: 'open',
                                                    name: 'Amélioration du time-to-market',
                                                    value: 0,
                                                });
                                        }}
                                    >
                                        <FiTruck
                                            size="2.2rem"
                                            className="text-primary mb-3"
                                        />
                                        <div className="font-weight-800 display-24 text-center mt-4">
                                            Amélioration du time-to-market
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body className="font-weight-600 p-4">
                                            La création de valeur le plus
                                            rapidement possible permet de&nbsp;:
                                            <br />
                                            <br />
                                            - Satisfaire vos clients
                                            <br />
                                            <br />
                                            - Devancer vos concurrents
                                            <br />
                                            <br />
                                            - Utiliser le plus efficacement
                                            possible vos ressources
                                            <br />
                                            <br />
                                            - S'adapter à vos changements de
                                            stratégie, aux évolutions du marché,
                                            aux nouvelles attentes ou besoins de
                                            vos clients
                                            <br />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>
                        <Col sm={10} md={7} xl={4} className="mt-5">
                            <Accordion>
                                <Card>
                                    <Accordion.Toggle
                                        className="bg-white cursor-pointer p-4"
                                        style={{
                                            height: '300px',
                                        }}
                                        as={Card.Header}
                                        eventKey="0"
                                        onClick={() => {
                                            setOpen({ ...open, a3: !open.a3 });
                                            if (!open.a3)
                                                trackEvent({
                                                    category: 'benefits',
                                                    action: 'open',
                                                    name: 'Alignement des équipes',
                                                    value: 0,
                                                });
                                        }}
                                    >
                                        <FiTarget
                                            size="2.2rem"
                                            className="text-primary mb-3"
                                        />
                                        <div className="font-weight-800 display-24 text-center mt-4">
                                            Alignement des équipes autour d'une
                                            stratégie commune
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body className="font-weight-600 p-4">
                                            L'alignement total de l’organisation
                                            permet de&nbsp;:
                                            <br />
                                            <br />
                                            - Mettre en place et partager un
                                            cadre et une direction commune dans
                                            lesquels évoluer
                                            <br />
                                            <br />
                                            - Obtenir une plus grande stabilité
                                            de la stratégie
                                            <br />
                                            <br />
                                            - S'engager plus fortement autour
                                            d’objectifs ambitieux et mesurables
                                            <br />
                                            <br />
                                            - Simplifier la communication sur
                                            les priorités opérationnelles et
                                            leur mise en œuvre
                                            <br />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
