import React, { useEffect } from 'react';
import ErrorSelector from '../error/ErrorSelector';
import Echo from '../../util/Echo';
import { useMatomo } from '@datapunt/matomo-tracker-react';

export default () => {
    const { trackPageView } = useMatomo();
    useEffect(() => {
        document.title = Echo().seo.title.account_setting
            ? Echo().seo.title.prefix +
              Echo().seo.title.account_setting +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, []);
    return (
        <ErrorSelector
            code={200}
            title="Paramètres"
            message="En construction"
            retry_value="Revenir à mon profil"
            retry_link="/account/profile"
            retry_color="outline-primary"
            retry_icone="home"
        />
    );
};
