import React, { useState } from 'react';
import { Row, Col, Collapse } from 'react-bootstrap';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useMatomo } from '@datapunt/matomo-tracker-react';

/*
 * break line : 2 whitespaces
 * paragraph  : 3 whitespaces
 * tab (4 whitespaces) : __tab__
 */

export default (props) => {
    const { trackEvent } = useMatomo();
    const [open, setOpen] = useState(false);
    return (
        <div className="w-100 card p-0 my-4">
            <Row
                className="p-3 m-0 cursor-pointer"
                onClick={() => {
                    setOpen(!open);
                    if (!open) {
                        trackEvent({
                            category: 'faq',
                            action: 'open',
                            name: props.data.request,
                            value: 0,
                        });
                    }
                }}
            >
                <Col xs={'auto'} className="pl-0 pr-3 my-auto">
                    {open === false ? (
                        <FiChevronDown size="2rem" />
                    ) : (
                        <FiChevronUp size="2rem" />
                    )}
                </Col>
                <Col className="pl-0 pr-3 my-auto">
                    <span className="font-weight-bold">
                        {props.data.request}
                    </span>
                </Col>
            </Row>
            <Collapse in={open}>
                <div className="px-3 ml-5 mt-3">
                    {props.data.responses.split('   ').map((p, i) => {
                        return (
                            <p key={i}>
                                {p.split('  ').map((ln, ii) => {
                                    return (
                                        <p
                                            className="m-0 font-weight-600"
                                            key={ii}
                                        >
                                            {ln
                                                .split('__tab__')
                                                .map((tab, iii) => {
                                                    if (
                                                        ln.startsWith(
                                                            '__tab__'
                                                        ) &&
                                                        iii
                                                    ) {
                                                        return (
                                                            <span key={iii}>
                                                                &emsp;
                                                                {tab}
                                                            </span>
                                                        );
                                                    } else {
                                                        return <>{tab}</>;
                                                    }
                                                })}
                                        </p>
                                    );
                                })}
                            </p>
                        );
                    })}
                </div>
            </Collapse>
        </div>
    );
};
