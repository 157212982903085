import { useState, useEffect } from 'react';
import { Card, Row, Col, Table } from 'react-bootstrap';
import { FiUserPlus, FiRefreshCw } from 'react-icons/fi';
import BlueButton from '../commun/BlueButton';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import RkTableOptions from '../commun/RkTableOptions';

export default (props) => {
    const { id } = useParams();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = activeUser[0].workspace[activeUser[0].activeWorkspace];
    const editRole = ['owner', 'admin'].includes(
        workspace.object.teams.on.filter(
            (team) => team.id === activeUser[0].id
        )[0].role
    );
    const [init, setInit] = useState(0);
    const [metrics, setMetrics] = useState({
        all: {
            count: null,
        },
        waiting: {
            count: null,
        },
        member: {
            count: null,
        },
        admin: {
            count: null,
        },
    });
    const getMetrics = () => {
        if (init >= 1) {
            setMetrics({
                all: {
                    count: null,
                },
                waiting: {
                    count: null,
                },
                member: {
                    count: null,
                },
                admin: {
                    count: null,
                },
            });
            Axios({
                ...ApiCallConfig,
                method: 'GET',
                url: `/workspace/${workspace.id}/teams`,
                headers: {
                    authorization: `Bearer ${activeUser[0].token}`,
                },
            })
                .then((res) => {
                    switch (true) {
                        case res.status === 200:
                            setMetrics({
                                all: {
                                    count: res.data.data.filter(
                                        (t) => t.object.status !== 'off'
                                    ).length,
                                },
                                member: {
                                    count: res.data.data.filter(
                                        (t) =>
                                            t.object.role === 'member' &&
                                            t.object.status !== 'off'
                                    ).length,
                                },
                                waiting: {
                                    count: res.data.data.filter(
                                        (t) => t.object.status === 'waiting'
                                    ).length,
                                },
                                admin: {
                                    count: res.data.data.filter(
                                        (t) => t.object.role === 'admin'
                                    ).length,
                                },
                            });
                            break;
                        default:
                            setMetrics({
                                all: {
                                    count: null,
                                },
                                member: {
                                    count: null,
                                },
                                waiting: {
                                    count: null,
                                },
                                admin: {
                                    count: null,
                                },
                            });
                            break;
                    }
                })
                .catch((error) => {});
        } else {
            setInit(init >= 0 ? init + 1 : 1);
        }
    };
    useEffect(getMetrics, [props.data]);
    useEffect(() => {
        setMetrics({
            all: {
                count: null,
            },
            member: {
                count: null,
            },
            waiting: {
                count: null,
            },
            admin: {
                count: null,
            },
        });
    }, [props.loading === true]);

    const renderMetrics = (key) => {
        return metrics[key].count === undefined ? (
            '0'
        ) : metrics[key].count !== null ? (
            metrics[key].count
        ) : (
            <FiRefreshCw size="1.4rem" color="#000" className="rotating" />
        );
    };
    return (
        <>
            <Row id="dashcards" className="m-0 w-100">
                <Col xs={9} xl={10} className="col-xxl-8 col-xxxl-6">
                    <Row className="minem-h-9">
                        <Col sm={6} xl={3} className="d-flex p-2">
                            <Card className="d-none d-xl-block border-0 w-100 line-height-14">
                                <Card.Body className="d-flex flex-column">
                                    <Card.Title className="display-16 font-weight-700 mb-3">
                                        Nombre d'utilisateurs&nbsp;:
                                    </Card.Title>
                                    <Card.Text className="position-absolute font-weight-800 display-32 text-align-left pt-5 mt-1">
                                        {renderMetrics('all')}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card
                                className="d-xl-none d-block p-0 px-2 m-0 w-100"
                                style={{ height: '57px' }}
                            >
                                <Row className="align-items-center p-1">
                                    <Col className="font-weight-700 display-16 pl-4 p-0">
                                        Nombre d'utilisateurs&nbsp;:
                                    </Col>
                                    <Col
                                        sm={'auto'}
                                        className="font-weight-800 display-24 pr-4 py-2"
                                    >
                                        {renderMetrics('all')}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col sm={6} xl={3} className="d-flex p-2">
                            <Card className="d-none d-xl-block border-0 w-100 line-height-14">
                                <Card.Body className="d-flex flex-column">
                                    <Card.Title className="display-16 font-weight-700 mb-3">
                                        Nombre de participants&nbsp;:
                                    </Card.Title>
                                    <Card.Text className="position-absolute font-weight-800 display-32 text-align-left pt-5 mt-1">
                                        {renderMetrics('member')}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card
                                className="d-xl-none d-block p-0 px-2 m-0 w-100"
                                style={{ height: '57px' }}
                            >
                                <Row className="align-items-center p-1">
                                    <Col className="font-weight-700 display-16 pl-4 p-0">
                                        Nombre de participants&nbsp;:
                                    </Col>
                                    <Col
                                        sm={'auto'}
                                        className="font-weight-800 display-24 pr-4 py-2"
                                    >
                                        {renderMetrics('member')}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col sm={6} xl={3} className="d-flex p-2">
                            <Card className="d-none d-xl-block border-0 w-100 line-height-14">
                                <Card.Body className="d-flex flex-column">
                                    <Card.Title className="display-16 font-weight-700 mb-3">
                                        Nombre d'invitations en attente&nbsp;:
                                    </Card.Title>
                                    <Card.Text className="position-absolute font-weight-800 display-32 text-align-left pt-5 mt-1">
                                        {renderMetrics('waiting')}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card
                                className="d-xl-none d-block p-0 px-2 m-0 w-100"
                                style={{ height: '57px' }}
                            >
                                <Row className="align-items-center p-1">
                                    <Col className="font-weight-700 display-16 pl-4 p-0">
                                        Nombre d'invitations en attente&nbsp;:
                                    </Col>
                                    <Col
                                        sm={'auto'}
                                        className="font-weight-800 display-24 pr-4 py-2"
                                    >
                                        {renderMetrics('waiting')}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col sm={6} xl={3} className="d-flex p-2">
                            <Card className="d-none d-xl-block border-0 w-100 line-height-14">
                                <Card.Body className="d-flex flex-column">
                                    <Card.Title className="display-16 font-weight-700 mb-3">
                                        Nombre d'admin&nbsp;:
                                    </Card.Title>
                                    <Card.Text className="position-absolute font-weight-800 display-32 text-align-left pt-5 mt-1">
                                        {renderMetrics('admin')}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card
                                className="d-xl-none d-block p-0 px-2 m-0 w-100"
                                style={{ height: '57px' }}
                            >
                                <Row className="align-items-center p-1">
                                    <Col className="font-weight-700 display-16 pl-4 p-0">
                                        Nombre d'admin&nbsp;:
                                    </Col>
                                    <Col
                                        sm={'auto'}
                                        className="font-weight-800 display-24 pr-4 py-2"
                                    >
                                        {renderMetrics('admin')}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xs={3} xl={2} className="p-0 d-flex flex-column">
                    <div className="m-0 mt-auto" style={{ maxWidth: '22ch' }}>
                        {editRole ? (
                            <BlueButton
                                btnClassnameDetails="btn-width-unset pr-3"
                                content={
                                    <>
                                        Inviter un
                                        <br />
                                        utilisateur
                                    </>
                                }
                                icon={<FiUserPlus size="2.2rem" />}
                                onClick={() => {
                                    document.getElementById(
                                        'view_invite_team'
                                    ).style.display = 'block';
                                }}
                            />
                        ) : (
                            <div style={{ height: '76px' }}></div>
                        )}
                    </div>
                    <RkTableOptions
                        resource="teams"
                        optionsStates={props.optionsStates}
                        handleOptionsState={props.handleOptionsState}
                    />
                </Col>
            </Row>
        </>
    );
};
