import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ErrorSelector from '../error/ErrorSelector';
import Subscription from '../subscription/Subscription';
export default (props) => {
    const { id } = useParams();
    const users = useSelector((state) => state.user.users);
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = users.filter((user) => user.id.includes(id))[0].workspace[
        activeUser[0].activeWorkspace
    ];
    if (
        workspace.object.teams.on.filter((t) => t.role === 'owner')[0].id ===
        activeUser[0].id
    ) {
        return (
            <>
                <Subscription subscriptionId={workspace.object.subscription} />￼
            </>
        );
    } else {
        return (
            <ErrorSelector
                code={402}
                title="Accès refusé"
                message="Veuillez contacter le propriétaire du workspace"
                retry_value="Revenir à la page d'accueil"
                retry_link="/"
                retry_color="outline-primary"
                retry_icone="home"
            />
        );
    }
};
