import Modal from '../commun/RkModal';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import BlueButton from '../commun/BlueButton';
import Echo from '../../util/Echo';
import { FiSend, FiX, FiTrash2, FiArrowRight } from 'react-icons/fi';
import GreyButton from '../commun/GreyButton';
export default function InviteUser(props) {
    const options = [
        { value: 'member', label: Echo().teams.fields.role.member },
        { value: 'admin', label: Echo().teams.fields.role.admin },
        { value: 'guest', label: Echo().teams.fields.role.guest },
    ];
    const closeModal = () => {
        document.getElementById('view_invite_team').style.display = 'none';
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            props.sendMail();
        }
    };
    return (
        <Modal
            id="view_invite_team"
            title="Invitation"
            className={
                props.type === 'session'
                    ? 'card h-auto my-0 m-2 invite-user-container-width mt-70'
                    : 'card h-auto my-0 m-2 invite-user-container-width'
            }
        >
            <Row
                className={
                    props.type === 'session'
                        ? 'sessionInvitationContainer'
                        : 'invitationContainer'
                }
            >
                <Col className="pl-0">
                    <span className="descr-label">
                        Qui voulez-vous inviter dans le workspace
                    </span>
                    <span className="highlight-label">
                        {props.workspace.object.name}
                    </span>
                    <span className="descr-label"> ?</span>

                    <div className="action-label pt-5 pb-3">
                        Veuillez indiquer le mail de la personne à
                        inviter&nbsp;:
                    </div>
                    <div className="invitationArrowContainer">
                        <Form.Control
                            type="text"
                            placeholder="E-mail"
                            value={props.email}
                            className="rounded border-0 input mb-1"
                            onChange={props.handleChange}
                            style={{
                                maxWidth: '40ch',
                            }}
                            onKeyPress={(e) => handleKeyPress(e)}
                        />
                        <Button
                            variant="primary"
                            className="invitationArrowIcon"
                            style={{ width: '2.7rem', height: '2.7rem' }}
                            onClick={props.sendMail}
                        >
                            <FiArrowRight
                                size="1.8rem"
                                strokeWidth="3"
                                className="invitationArrowIconDetails"
                            />
                        </Button>
                        <span className="invitationErrorMessage ml-2">
                            {props.mailError}
                        </span>
                    </div>

                    {props.data.length > 0 && (
                        <>
                            <span className="invitationRoleTitle">
                                Veuillez indiquer le futur rôle de la personne à
                                inviter&nbsp;:
                            </span>
                            <div className="listInvitedUserContainer">
                                {props.data?.map((el, i) => {
                                    let classNameDetails =
                                        'invitationListeDetailsSecondCol';
                                    let inputclassname =
                                        'invitationSecondInputDetails';
                                    if (i % 2 === 0) {
                                        classNameDetails =
                                            'invitationListeDetailsFirstCol';
                                        inputclassname =
                                            'invitationInputDetails';
                                    } else if (i === props.data.length - 1) {
                                        classNameDetails =
                                            classNameDetails +
                                            ' invitationListeDetailsLastCol';
                                    }
                                    return (
                                        <div className={classNameDetails}>
                                            <span className="invitationEmailText">
                                                {el.email}
                                            </span>
                                            <div className="invitationDropDownContainer">
                                                <Select
                                                    value={el.role}
                                                    onChange={(e) =>
                                                        props.selectedItemDropDown(
                                                            i,
                                                            e
                                                        )
                                                    }
                                                    options={options.filter(
                                                        (option) =>
                                                            option.value !==
                                                            el.role.value
                                                    )}
                                                    className={inputclassname}
                                                />
                                            </div>
                                            <div
                                                className="invitationBtnDeleteContainer"
                                                onClick={() =>
                                                    props.deleteParticipantItem(
                                                        el
                                                    )
                                                }
                                            >
                                                <FiTrash2
                                                    size="2rem"
                                                    color="#999999"
                                                    className="mx-3"
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </Col>
                <Col
                    lg={'auto'}
                    className="sticky-fixed flex items-end column axe-priorisation-btn-container pr-4"
                >
                    <BlueButton
                        content={
                            <>
                                Envoyer<br></br>l'invitation
                            </>
                        }
                        RW
                        onClick={props.sendData}
                        icon={<FiSend size="2.2rem" />}
                    />
                    <GreyButton
                        content="Annuler"
                        icon={<FiX size="2.2rem" />}
                        RW
                        btnClassnameDetails="mr-40"
                        onClick={() => closeModal()}
                    />
                </Col>
            </Row>
        </Modal>
    );
}
InviteUser.defaultProps = {
    email: '',
    mailError: '',
    data: [],
    type: '',
};
