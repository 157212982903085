import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, ButtonGroup, Card } from 'react-bootstrap';
import { FiPlus, FiMinus, FiDownload } from 'react-icons/fi';
import { toJpeg } from 'html-to-image';

export default (props) => {
    const [costWidth, setCostWidth] = useState(200);
    const [axisWidth, setAxisWidth] = useState(0);
    const [wsjfWidth, setWsjfWidth] = useState(200);
    const [fullWidth, setFullWidth] = useState(200);
    const [leftMargin, setLeftMargin] = useState(0);
    const [varMargin, setVarMargin] = useState(90);
    const [stepZoom, setStepZoom] = useState(0);
    const [maxZoom, setMaxZoom] = useState(1.5);
    const [minZoom, setMinZoom] = useState(0.6);
    const [opacity, setOpacity] = useState(0);
    const mapZoom = {
        max: [1.5, 1.5, 1.5, 1.4, 1.4, 1.3, 1.3, 1.2, 1.2, 1.1, 1.1], // 0 -> 10
        min: [0.8, 0.8, 0.7, 0.7, 0.6, 0.6, 0.5, 0.5, 0.4, 0.4, 0.3],
        var: [
            -37 +
                (props.RiskOpportunity.active ? 16 : 0) +
                (props.TimeCriticality.active ? 13 : 0),
            -37 +
                (props.RiskOpportunity.active ? 16 : 0) +
                (props.TimeCriticality.active ? 13 : 0),
            -37 +
                (props.RiskOpportunity.active ? 16 : 0) +
                (props.TimeCriticality.active ? 13 : 0),
            3,
            15,
            27,
            39,
            52,
            65,
            78,
            91,
        ],
        // cal 0, 1, 2, 3  ??? size < first separator
    };

    useEffect(() => {
        setTimeout(() => {
            setOpacity(1);
        }, 500);
    }, []);

    useEffect(() => {
        setCostWidth(
            200 +
                (props.TimeCriticality.active ? 265 : 0) + // 210 + 55
                (props.RiskOpportunity.active ? 445 : 0) // 390 + 55
        );
        setAxisWidth(
            props.activeSousAxesArray.length
                ? props.activeSousAxesArray.length * 200 +
                      (props.activeSousAxesArray.length - 1) * 54
                : 0
        );
        setMinZoom(mapZoom.min[props.activeSousAxesArray.length]);
        setMaxZoom(mapZoom.max[props.activeSousAxesArray.length]);
    }, [props]);
    useEffect(() => {
        setWsjfWidth(Math.max(costWidth, axisWidth));
    }, [costWidth, axisWidth]);
    useEffect(() => {
        setFullWidth(wsjfWidth + 240);
    }, [wsjfWidth]);
    useEffect(() => {
        // setVarMargin(fullWidth * 0.04);
        setVarMargin(mapZoom.var[props.activeSousAxesArray.length]);
    }, [fullWidth]);
    useEffect(() => {
        setStepZoom(Math.round(fullWidth * 0.1) + varMargin);
    }, [wsjfWidth, varMargin]);
    const [zoom, setZoom] = useState(1);
    const zoomIn = () => {
        console.log('varMargin', varMargin);
        if (zoom < maxZoom) {
            setZoom(zoom + 0.1);
            setLeftMargin(leftMargin + stepZoom);
            // setVarMargin(varMargin * 1.5 );
            // setFullWidth(fullWidth * 1.1);
        }
    };
    const zoomOut = () => {
        console.log('varMargin', varMargin);
        if (zoom > minZoom) {
            setZoom(zoom - 0.1);
            setLeftMargin(leftMargin - stepZoom);
            // setVarMargin(varMargin * 0.5 );
            // setFullWidth(fullWidth * 0.9);
            // console.log(stepZoom);
        }
    };

    const deliveryTimeCost = () => {
        return (
            <div className="flex mb-40 ml-240 h-center">
                <div className="business-value wsjf-container relative">
                    <span>Valeur business</span>
                    <div className="axis-val flex v-center h-center">
                        {props.BusinessValues.value + '%'}
                    </div>
                </div>
                {props.TimeCriticality.active ? (
                    <>
                        <div className="my-auto">
                            <span className="formula-plus ml-20 mr-20">+</span>
                        </div>
                        <div className="time-criticality wsjf-container relative">
                            <span>Contrainte de temps</span>
                            <div className="axis-val flex v-center h-center">
                                {props.TimeCriticality.value + '%'}
                            </div>
                        </div>
                    </>
                ) : (
                    <></>
                )}
                {props.RiskOpportunity.active ? (
                    <>
                        <div className="my-auto">
                            <span className="formula-plus ml-20 mr-20">+</span>
                        </div>
                        <div className="risk-reduction wsjf-container relative">
                            <span>
                                Réduction de risque / Création d'opportunité
                            </span>
                            <div className="axis-val flex v-center h-center">
                                {props.RiskOpportunity.value + '%'}
                            </div>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
        );
    };
    const onButtonClick = () => {
        var node = document.getElementById('wsjf-image');
        toJpeg(node, { cacheBust: true, backgroundColor: '#ffffff' })
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = 'ma-config-WSJF.jpeg';
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <>
            <div className="position-absolute top-0 start-0 p-2">
                <ButtonGroup className="m-2">
                    <Button
                        style={{
                            zIndex: 100,
                            cursor: zoom >= maxZoom ? 'initial' : 'pointer',
                        }}
                        className={`btn-sm ${zoom >= maxZoom && 'disabled'}`}
                        variant="gray"
                        onClick={zoomIn}
                    >
                        <FiPlus size="1.2rem" />
                    </Button>
                    <Button
                        style={{
                            zIndex: 100,
                            cursor: zoom <= minZoom ? 'initial' : 'pointer',
                        }}
                        className={`btn-sm ${zoom <= minZoom && 'disabled'}`}
                        variant="gray"
                        onClick={zoomOut}
                    >
                        <FiMinus size="1.2rem" />
                    </Button>
                </ButtonGroup>
                <ButtonGroup className="m-2">
                    <Button
                        className="btn-sm"
                        variant="gray"
                        onClick={onButtonClick}
                        title='Télécharger une image'
                        style={{
                            zIndex: 100,
                        }}
                    >
                        <FiDownload size="1.2rem" />
                    </Button>
                </ButtonGroup>
            </div>
            <div id="wsjf-image" className="formula w-100 h-100" style={{}}>
                <div
                    style={{
                        opacity: opacity,
                        marginLeft: leftMargin + 'px',
                        width: fullWidth + 'px',
                        transform: `scale(${zoom})`,
                        transition: 'all .4s',
                    }}
                >
                    <div className="ml-240 h-center">
                        <div className="formula-header wsjf-container mb-40 mt-40">
                            <span>Coût du délai de livraison</span>
                        </div>
                        <div
                            className="first-separator"
                            style={{ width: costWidth + 'px' }}
                        ></div>
                    </div>
                    {deliveryTimeCost()}
                    {props.activeSousAxesArray.length ? (
                        <div className="ml-240">
                            <div
                                className="business-separator"
                                style={{ width: axisWidth + 'px' }}
                            ></div>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div id="sub-axis-container" className="flex ml-240">
                        {props.activeSousAxesArray.map((sousAxe, index) => {
                            return (
                                <div className="flex">
                                    <div className="sub-axis wsjf-container relative flex v-center h-center">
                                        <span className="flex v-center h-center">
                                            {sousAxe.name}
                                        </span>
                                        <div className="sub-axis-val flex v-center h-center">
                                            {sousAxe.value}%
                                        </div>
                                    </div>
                                    {index <
                                    props.activeSousAxesArray.length - 1 ? (
                                        <div className="my-auto">
                                            <span className="formula-plus ml-20 mr-20">
                                                +
                                            </span>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div className="flex v-center">
                        <div className="wsjf-container flex v-center h-center ml-3">
                            <span className="wsjf-text flex v-center h-center">
                                WSJF
                            </span>
                        </div>
                        <span className="formula-plus mx-3">=</span>
                        <div
                            className="second-separator"
                            style={{
                                width: wsjfWidth + 80 + 'px',
                            }}
                        ></div>
                    </div>
                    <div
                        style={{
                            marginLeft: wsjfWidth / 2 + 140 + 'px',
                        }}
                    >
                        <div className=" effort-container flex v-center h-center">
                            <span className="effort-text  flex v-center h-center">
                                Effort
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
