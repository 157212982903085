import Cookies from 'js-cookie';
import Axios from 'axios';
import ApiCallConfig from './ApiCallConfig';
export default () => {
    return new Promise((resolve) => {
        console.log('healthcheck');
        Axios({ ...ApiCallConfig, url: '/healthcheck' })
            .then((result) => {
                Cookies.set('RK_healthcheck', result.data.message);
                resolve(result.data.message);
            })
            .catch((error) => {
                Cookies.set('RK_healthcheck', 'unavailable');
                resolve('unavailable');
            });
    });
};
