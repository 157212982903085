import { Container, Navbar, Card, Row, Col } from 'react-bootstrap';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../commun/Logo';
import Echo from '../../util/Echo';
import { FiMail, FiX } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useMatomo } from '@datapunt/matomo-tracker-react';

export default () => {
    const history = useHistory();
    const email = useSelector((state) => state.user.newEmail);
    const expiryDate = useSelector((state) => state.user.expiryDate);
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        document.title = Echo().seo.title.validate
            ? Echo().seo.title.prefix +
              Echo().seo.title.validate +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
        if (expiryDate < Date.now()) {
            history.push('/login');
        }
    }, []);
    return (
        <Container fluid>
            <Navbar className="fixed-top rankio-header" bg="white" expand="xl">
                <Link to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </Link>
                <Link to="/" className="ml-auto">
                    <FiX size="1.5em" className="rk-clickable-icon-dark" />
                </Link>
            </Navbar>
            <div className="landing">
                <Row className="p-4">
                    <Col sm={1} md={2} lg={3} xxl={4}></Col>
                    <Col sm={10} md={8} lg={8} xxl={7}>
                        <h3 className="font-weight-800 py-4">
                            Merci !
                            <br />
                            Un email vient de vous être envoyé.
                        </h3>
                        <p className="descr-label py-2">
                            Pour vous connecter, cliquez sur le lien que nous
                            venons de vous envoyer sur l'adresse
                            <span className="highlight-label"> {email}</span>.
                        </p>
                        <p className="descr-label py-2">
                            Attention, ce lien n'est valable que pour une durée
                            limitée !
                        </p>
                    </Col>
                </Row>
                <Row className="p-4 mt-5">
                    <Col sm={1} md={2} lg={3} xxl={4}></Col>
                    <Col sm={10} md={8} lg={8} xxl={7}>
                        <Row>
                            <Col
                                sm={6}
                                md={6}
                                lg={5}
                                xl={4}
                                xxl={3}
                                className="p-2 col-xxl-3"
                            >
                                <a
                                    href="https://outlook.office.com/mail/inbox"
                                    className="text-decoration-none text-black"
                                    target="_blank"
                                >
                                    <Card className="border-0 w-100 line-height-14">
                                        <Card.Body className="d-flex flex-column">
                                            <Card.Title className="display-16 font-weight-700">
                                                <FiMail
                                                    size={28}
                                                    className="text-primary"
                                                />
                                            </Card.Title>
                                            <Card.Text className="font-weight-800 display-16 text-align-center pb-2">
                                                Ouvrir Outlook
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </a>
                            </Col>
                            <Col
                                sm={6}
                                md={6}
                                lg={5}
                                xl={4}
                                xxl={3}
                                className="p-2 col-xxl-3"
                            >
                                <a
                                    href="https://mail.google.com/mail/u/0/#search/from%3Arankio.app"
                                    className="text-decoration-none text-black"
                                    target="_blank"
                                >
                                    <Card className="border-0 w-100 line-height-14">
                                        <Card.Body className="d-flex flex-column">
                                            <Card.Title className="display-16 font-weight-700">
                                                <FiMail
                                                    size={28}
                                                    className="text-danger"
                                                />
                                            </Card.Title>
                                            <Card.Text className="font-weight-800 display-16 text-align-center pb-2">
                                                Ouvrir Gmail
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};
