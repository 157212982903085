import Modal from '../commun/RkModal';
import {
    Form,
    FormControl,
    InputGroup,
    Row,
    Col,
} from 'react-bootstrap';
import Alert from '../commun/Alert';
import { useParams } from 'react-router-dom';
import { FiEye, FiEyeOff, FiCheck, FiX, FiLock } from 'react-icons/fi';
import BlueButton from '../commun/BlueButton';
import GreyButton from '../commun/GreyButton';
import Validator from '../../util/Validator';
import { useState } from 'react';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import { useSelector} from 'react-redux';

export default () => {
    const [alertPasswordSuccessShow, setAlertPasswordSuccessShow] = useState(
        false
    );
    const [alertPasswordFailedShow, setAlertPasswordFailedShow] = useState(
        false
    );
    const [alertTimeout, setAlertTimeout] = useState(null);
    const handleShowAlert = (setAlertShow) => {
        setAlertShow(true);
        setAlertTimeout(
            setTimeout(() => {
                setAlertShow(false);
            }, 3000)
        );
    };

    const { id } = useParams();
    const users = useSelector(state => state.user.users)
    const activeUser = users.filter(user => user.id.includes(id))
    const [payload, setPayload] = useState({
        password: '',
        password_check: '',
    });
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const [pwdInput, setPwdInput] = useState({
        type: 'password',
        on: 'd-block',
        off: 'd-none',
    });
    const [pwdInputCheck, setPwdInputCheck] = useState({
        type: 'password',
        on: 'd-block',
        off: 'd-none',
    });
    const handleChange = (event) => {
        setPayload({ ...payload, [event.target.name]: event.target.value });
        if (Object.keys(errors).length !== 0 || errors.constructor !== Object) {
            const e = Validator({
                ...payload,
                [event.target.name]: event.target.value,
            });
            if (e) setErrors(e);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const e = Validator(payload);
        if (Object.keys(e).length !== 0 || e.constructor !== Object) {
            setErrors(e);
        } else {
            Axios({
                ...ApiCallConfig,
                method: 'PATCH',
                url: '/user',
                headers: {
                    authorization:
                        'Bearer ' + activeUser[0].token,
                },
                data: { password: payload.password }
            }).then((result) => {
                handleShowAlert(setAlertPasswordSuccessShow);
                document.getElementById('profil_change_pw').style.display = 'none';
            })
                .catch((error) => {
                    setMessage(error);
                    handleShowAlert(setAlertPasswordFailedShow);
                    document.getElementById('profil_change_pw').style.display =
                        'none';
                });
        }
    };
    const showHidePwd = () => {
        if (pwdInput.type === 'password')
            setPwdInput({ type: 'text', on: 'd-none', off: 'd-block' });
        else {
            setPwdInput({ type: 'password', on: 'd-block', off: 'd-none' });
        }
    };
    const showHidePwdCheck = () => {
        if (pwdInputCheck.type === 'password')
            setPwdInputCheck({ type: 'text', on: 'd-none', off: 'd-block' });
        else {
            setPwdInputCheck({ type: 'password', on: 'd-block', off: 'd-none' });
        }
    };
    return (
        <>
            <Modal
                id="profil_change_pw"
                title="Changement de mot de passe"
                className="card m-0 h-auto w-100"
            >
                <div className="h-100-330 p-0 m-0">
                    <Row>
                        <Col className="pb-5">
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group
                                    controlId="password"
                                    className="ml-3 mt-4"
                                >
                                    <Form.Label className="action-label pb-2">
                                        Renseigner votre nouveau mot de passe
                                    </Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            name="password"
                                            type={pwdInput.type}
                                            placeholder="Un mot de passe robuste !"
                                            value={payload.password}
                                            className="input"
                                            onChange={handleChange}
                                            style={{ maxWidth: '30ch' }}
                                        />
                                        <InputGroup.Text
                                            id="pwdEys"
                                            className="cursor-pointer border-0 text-secondary display-20"
                                            style={{
                                                backgroundColor:
                                                    'rgba(153, 153, 153, 0.1)',
                                                borderRadius: '0px 14px 14px 0',
                                                width: 'fit-content',
                                            }}
                                            onClick={showHidePwd}
                                        >
                                            <FiEye className={pwdInput.off} />
                                            <FiEyeOff className={pwdInput.on} />
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Form.Text className="mt-2 ml-2">
                                        <span className="text-danger font-weight-700">
                                            {!errors.password
                                                ? ''
                                                : errors.password.split('  ')[0]}
                                        </span>
                                        <ul className="pl-0">
                                            {!errors.password
                                                ? ''
                                                : errors.password
                                                    .split('  ')
                                                    .map((e, i) => {
                                                        if (i)
                                                            if (e[0] === 'o')
                                                                return (
                                                                    <li
                                                                        key={i}
                                                                        className="text-success font-weight-700"
                                                                    >
                                                                        <FiCheck />
                                                                        {e.substring(
                                                                            1
                                                                        )}
                                                                    </li>
                                                                );
                                                            else if (e[0] === 'x') {
                                                                return (
                                                                    <li
                                                                        key={i}
                                                                        className="text-danger font-weight-700"
                                                                    >
                                                                        <FiX />
                                                                        {e.substring(
                                                                            1
                                                                        )}
                                                                    </li>
                                                                );
                                                            }
                                                    })}
                                        </ul>
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group
                                    controlId="password"
                                    className="ml-3 mt-4 pt-3"
                                >
                                    <Form.Label className="action-label pb-2">
                                        Confirmer votre nouveau mot de passe
                                    </Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            name="password_check"
                                            type={pwdInputCheck.type}
                                            placeholder="Le même que précédemment !"
                                            value={payload.password_check}
                                            className="input"
                                            onChange={handleChange}
                                            style={{ maxWidth: '30ch' }}
                                        />
                                        <InputGroup.Text
                                            id="pwdEys"
                                            className="cursor-pointer border-0 text-secondary display-20"
                                            style={{
                                                backgroundColor:
                                                    'rgba(153, 153, 153, 0.1)',
                                                borderRadius: '0px 14px 14px 0',
                                                width: 'fit-content',
                                            }}
                                            onClick={showHidePwdCheck}
                                        >
                                            <FiEye className={pwdInputCheck.off} />
                                            <FiEyeOff className={pwdInputCheck.on} />
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Form.Text className="mt-2 ml-2">
                                        <span className="text-danger font-weight-700">
                                            {errors.password_check}
                                        </span>
                                    </Form.Text>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col
                            lg={'auto'}
                            className="sticky-fixed flex items-end column axe-priorisation-btn-container pr-4 pt-4"
                        >
                            <BlueButton
                                content={
                                    <>
                                        Modifier le<br></br>mot de passe
                                    </>
                                }
                                icon={<FiLock size="2.2rem" />}
                                btnClassnameDetails='mt-0'
                                onClick={handleSubmit}
                            />
                            <GreyButton
                                content="Annuler"
                                icon={<FiX size="2.2rem" />}
                                onClick={() => {
                                    document.getElementById(
                                        'profil_change_pw'
                                    ).style.display = 'none';
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>
            <Alert
                color="primary"
                content="Le mot de passe a bien été changé !"
                showAlert={alertPasswordSuccessShow}
                setAlertShow={setAlertPasswordSuccessShow}
                alertTimeout={alertTimeout}
            />
            <Alert
                color="danger"
                content="Le mot de passe n'a pas pu être changé, veuillez réessayer."
                showAlert={alertPasswordFailedShow}
                setAlertShow={setAlertPasswordFailedShow}
                alertTimeout={alertTimeout}
            />
        </>
    );
};
