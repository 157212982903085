import RkTable from '../commun/RkTable';
import RkModal from '../commun/RkModal';
import Header from './Header';
import ModalDetails from './ModalDetails';
import ModalAssignment from './ModalAssignment';
import ModalTags from './ModalTags';
import AddTeam from '../teams/Add';
import AddTags from '../tags/TagsBody';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Axios from 'axios';
import ApiCallConfig from '../../util/ApiCallConfig';
import echo from '../../util/Echo';
import config from './Config';
import Project from '../../util/Project';
import Modal from '../modal/Modal';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Echo from '../../util/Echo';
import Unpaid from '../commun/Unpaid';
import IniteAccept from '../commun/IniteAccept';

export default function ProjectLanding() {
    const users = useSelector((state) => state.user.users);
    const { trackPageView, trackEvent } = useMatomo();
    const { id } = useParams();
    const activeUser = users.filter((user) => user.id.includes(id));
    const workspace = users.filter((user) => user.id.includes(id))[0].workspace[
        activeUser[0].activeWorkspace
    ];
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginationStates, setPaginationStates] = useState({
        ...config.pagination,
    });
    const [optionsStates, setOptionsStates] = useState({ ...config.options });
    const [filterStates, setFilterStates] = useState({ ...config.filter });
    const [sortStates, setSortStates] = useState({ ...config.sort });
    const [selectedRow, setSelectedRow] = useState(null);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalArchive, setShowModalArchive] = useState(false);
    const handleOptionsState = (column) => {
        setOptionsStates(
            column === 'all'
                ? optionsStates.all
                    ? Object.keys(optionsStates).reduce((accumulator, key) => {
                          return { ...accumulator, [key]: false };
                      }, {})
                    : Object.keys(optionsStates).reduce((accumulator, key) => {
                          return { ...accumulator, [key]: true };
                      }, {})
                : {
                      ...optionsStates,
                      [column]: !optionsStates[column],
                      all:
                          Object.values({
                              ...optionsStates,
                              [column]: !optionsStates[column],
                              all: true,
                          }).filter((c) => !c).length === 0,
                  }
        );
    };
    const handleFilterState = (column, value) => {
        switch (config.type[column]) {
            case 'string':
                setFilterStates({
                    ...filterStates,
                    [column]: value,
                });
                break;
            case 'enum':
            case 'status_project':
                setFilterStates({
                    ...filterStates,
                    [column]:
                        value === 'all'
                            ? filterStates[column].all
                                ? Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: false,
                                          };
                                      },
                                      {}
                                  )
                                : Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: true,
                                          };
                                      },
                                      {}
                                  )
                            : {
                                  ...filterStates[column],
                                  [value]: !filterStates[column][value],
                                  all:
                                      Object.values({
                                          ...filterStates[column],
                                          [value]: !filterStates[column][value],
                                          all: true,
                                      }).filter((c) => !c).length === 0,
                              },
                });
                break;
            case 'teams':
                setFilterStates({
                    ...filterStates,
                    [column]:
                        value === 'all'
                            ? filterStates[column].all.selected
                                ? Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: {
                                                  ...filterStates[column][key],
                                                  selected: false,
                                              },
                                          };
                                      },
                                      {}
                                  )
                                : Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: {
                                                  ...filterStates[column][key],
                                                  selected: true,
                                              },
                                          };
                                      },
                                      {}
                                  )
                            : {
                                  ...filterStates[column],
                                  [value]: {
                                      ...filterStates[column][value],
                                      selected:
                                          !filterStates[column][value].selected,
                                  },
                                  all: {
                                      selected:
                                          Object.values({
                                              ...filterStates[column],
                                              [value]:
                                                  !filterStates[column][value]
                                                      .selected,
                                              all: { selected: true },
                                          }).filter((c) => !c.selected)
                                              .length === 0,
                                  },
                              },
                });
                break;
            case 'tags':
                setFilterStates({
                    ...filterStates,
                    [column]:
                        value === 'all'
                            ? filterStates[column].all.selected
                                ? Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: {
                                                  ...filterStates[column][key],
                                                  selected: false,
                                              },
                                          };
                                      },
                                      {}
                                  )
                                : Object.keys(filterStates[column]).reduce(
                                      (accumulator, key) => {
                                          return {
                                              ...accumulator,
                                              [key]: {
                                                  ...filterStates[column][key],
                                                  selected: true,
                                              },
                                          };
                                      },
                                      {}
                                  )
                            : {
                                  ...filterStates[column],
                                  [value]: {
                                      ...filterStates[column][value],
                                      selected:
                                          !filterStates[column][value].selected,
                                  },
                                  all: {
                                      selected:
                                          Object.values({
                                              ...filterStates[column],
                                              [value]: {
                                                  ...filterStates[column][
                                                      value
                                                  ],
                                                  selected:
                                                      !filterStates[column][
                                                          value
                                                      ].selected,
                                              },
                                              all: { selected: true },
                                          }).filter((c) => !c.selected)
                                              .length === 0,
                                  },
                              },
                });
                break;
            default:
                console.log('undefined column', column, 'type');
        }
    };
    const handleSortState = (column, type) => {
        setSortStates(
            sortStates?.type === type && sortStates?.column === column
                ? { type: '', column: '' }
                : { type, column }
        );
    };
    const handleFilterReset = (column) => {
        console.log(column);
        console.log(config.type[column]);
        switch (config.type[column]) {
            case 'string':
                setFilterStates({
                    ...filterStates,
                    [column]: '',
                });
                break;
            case 'enum':
            case 'status_project':
                setFilterStates({
                    ...filterStates,
                    [column]: Object.keys(filterStates[column]).reduce(
                        (accumulator, key) => {
                            return {
                                ...accumulator,
                                [key]: false,
                            };
                        },
                        {}
                    ),
                });
                break;
            case 'teams':
                setFilterStates({
                    ...filterStates,
                    [column]: Object.keys(filterStates[column]).reduce(
                        (accumulator, key) => {
                            return {
                                ...accumulator,
                                [key]: {
                                    ...filterStates[column][key],
                                    selected: false,
                                },
                            };
                        },
                        {}
                    ),
                });

                break;
            case 'tags':
                setFilterStates({
                    ...filterStates,
                    [column]: Object.keys(filterStates[column]).reduce(
                        (accumulator, key) => {
                            return {
                                ...accumulator,
                                [key]: {
                                    ...filterStates[column][key],
                                    selected: false,
                                },
                            };
                        },
                        {}
                    ),
                });
                break;
            default:
                console.log('undefined column', column, 'type');
        }
    };
    const getData = (next = false) => {
        setLoading(true);
        if (next) {
            if (paginationStates.end === undefined) {
                setPaginationStates({
                    ...paginationStates,
                    skip: paginationStates.skip + paginationStates.limit,
                });
            } else {
                return;
            }
        } else {
            setPaginationStates({
                ...config.pagination,
            });
        }
        let url = `workspace/${workspace.id}/project?limit=${
            paginationStates.limit
        }&skip=${paginationStates.skip}${
            sortStates.type !== '' && sortStates.column !== ''
                ? '&' +
                  sortStates.type +
                  "=object-%3E%3E'" +
                  sortStates.column +
                  "'"
                : ''
        }`;
        Object.keys(filterStates).forEach((column) => {
            switch (true) {
                case config.type[column] === 'string' &&
                    filterStates[column].length > 1:
                    url += `&object-%3E%3E'${column}'=%${filterStates[column]}%`;
                    break;
                case config.type[column] === 'enum':
                case config.type[column] === 'status_project':
                    Object.keys(filterStates[column]).forEach((box) => {
                        if (box !== 'all' && filterStates[column][box])
                            url += `&object-%3E%3E'${column}'=%${box}%`;
                    });
                    break;
                case config.type[column] === 'teams':
                    Object.keys(filterStates[column]).forEach((box) => {
                        if (box !== 'all' && filterStates[column][box].selected)
                            url += `&object-%3E%3E'${column}'=%25%22${box}%22%25`;
                    });
                case config.type[column] === 'tags':
                    Object.keys(filterStates[column]).forEach((box) => {
                        if (box !== 'all' && filterStates[column][box].selected)
                            url += `&object-%3E%3E'${column}'=%25%22${box}%22%25`;
                    });
                    break;
                default:
                    break;
            }
        });
        Axios({
            ...ApiCallConfig,
            method: 'GET',
            url: url,
            headers: {
                authorization: 'Bearer ' + activeUser[0].token,
            },
        })
            .then((res) => {
                setLoading(false);
                switch (true) {
                    case res.status === 200:
                        if (next) {
                            setData([...data, ...res.data.data]);
                        } else {
                            setData(res.data.data);
                        }
                        if (res.data.count <= res.data.skip) {
                            setPaginationStates({
                                ...config.pagination,
                                end: true,
                            });
                        }
                        break;
                    default:
                        setData([]);
                        break;
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    const updateProject = (projectId, payload) => {
        setLoading(true);
        Project.patch(workspace.id, projectId, activeUser[0].token, payload)
            .then((res) => {
                if (res.status === 200) {
                    getData();
                    setSelectedRow(res.data.data);
                } else {
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    const archiveProject = (projectId, commit = false) => {
        if (commit) {
            setLoading(true);
            Project.patch(workspace.id, projectId, activeUser[0].token, {
                status: 'archived',
            })
                .then((res) => {
                    getData();
                    setSelectedRow(res.data.data);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else {
            setShowModalArchive(true);
            setSelectedRow({ ...selectedRow, id: projectId });
        }
    };
    const deleteProject = (projectId, commit = false) => {
        if (commit) {
            setLoading(true);
            Project.delete(workspace.id, projectId, activeUser[0].token)
                .then((res) => {
                    getData();
                    setSelectedRow(res.data.data);
                    document.getElementById(
                        'view_details_project'
                    ).style.display = 'none';
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else {
            setShowModalDelete(true);
            setSelectedRow({ ...selectedRow, id: projectId });
        }
    };
    useEffect(() => {
        if (window.location.hash) {
            try {
                document.getElementById(
                    window.location.hash.split('#')[1]
                ).style.display = 'block';
            } catch (e) {
                console.log(e);
            }
        }
        setTimeout(() => {
            Axios({
                ...ApiCallConfig,
                method: 'POST',
                url: `/batch`,
                data: [
                    {
                        id: 'tags',
                        method: 'get',
                        uri: `/workspace/${workspace.id}/tags`,
                        headers: {
                            authorization: `Bearer ${activeUser[0].token}`,
                        },
                    },
                    {
                        id: 'assignation',
                        method: 'get',
                        uri: `/workspace/${workspace.id}/teams`,
                        headers: {
                            authorization: `Bearer ${activeUser[0].token}`,
                        },
                    },
                ],
            })
                .then((res) => {
                    if (res.status === 200) {
                        const result = { ...config.filter };
                        switch (true) {
                            case res.data.responses.tags.status === 200:
                                result.tags = {
                                    ...config.filter.tags,
                                    ...res.data.responses.tags.data.reduce(
                                        (accumulator, key) => {
                                            return {
                                                ...accumulator,
                                                [key.ref]: {
                                                    data: { ...key },
                                                    selected: false,
                                                },
                                            };
                                        },
                                        {}
                                    ),
                                };
                            case res.data.responses.assignation.status === 200:
                                result.assignation = {
                                    ...config.filter.assignation,
                                    ...res.data.responses.assignation.data.reduce(
                                        (accumulator, key) => {
                                            return {
                                                ...accumulator,
                                                [key.id]: {
                                                    data: key.object,
                                                    selected: false,
                                                },
                                            };
                                        },
                                        {}
                                    ),
                                };
                            default:
                                setFilterStates({ ...result });
                        }
                    }
                })
                .catch((error) => {});
        }, 0);
    }, [activeUser[0].activeWorkspace]);
    useEffect(getData, [
        sortStates,
        filterStates,
        activeUser[0].activeWorkspace,
    ]);
    useEffect(() => {
        document.title = Echo().seo.title.project
            ? Echo().seo.title.prefix +
              workspace.object.name +
              ' - ' +
              Echo().seo.title.project +
              Echo().seo.title.suffix
            : Echo().seo.title.default;
        trackPageView();
        return () => {
            document.title = Echo().seo.title.default;
        };
    }, [activeUser[0].activeWorkspace]);
    const showProject = (data = undefined) => {
        setSelectedRow(data);
        document.getElementById('view_details_project').style.display = 'block';
    };
    switch (true) {
        case workspace.object.status === 'unpaid':
            return <Unpaid />;
        case workspace.object.teams.on.filter(
            (t) => t.id === activeUser[0].id
        )[0].invit !== undefined:
            return <IniteAccept veiw="project"/>;
        default:
            return (
                <>
                    {workspace.object.status === 'unpaid' ? (
                        <>
                            <Unpaid />
                        </>
                    ) : (
                        <>
                            <ModalDetails
                                data={selectedRow}
                                setData={(r) => showProject(r)}
                                getData={getData}
                                loading={loading}
                                setLoading={(s) => setLoading(s)}
                                filterStates={filterStates}
                                update={(id, payload) =>
                                    updateProject(id, payload)
                                }
                                archive={(id) => archiveProject(id)}
                                delete={(id) => deleteProject(id)}
                            />
                            <ModalAssignment
                                data={selectedRow}
                                getData={getData}
                                loading={loading}
                                setLoading={(s) => setLoading(s)}
                                filterStates={filterStates}
                                update={(id, payload) =>
                                    updateProject(id, payload)
                                }
                            />
                            <ModalTags
                                data={selectedRow}
                                getData={getData}
                                loading={loading}
                                setLoading={(s) => setLoading(s)}
                                filterStates={filterStates}
                                update={(id, payload) =>
                                    updateProject(id, payload)
                                }
                            />
                            <AddTeam level={3} getData={getData} />
                            <RkModal
                                id="view_tags_manager"
                                title="Gestion des tags"
                                className="card rk-modal-level-3 m-2"
                            >
                                <div className="rk-modal-body-level-3">
                                    <AddTags getData={getData} />
                                </div>
                            </RkModal>
                            <Header
                                data={data}
                                addRow={(r) => showProject(r)}
                                loading={loading}
                                optionsStates={optionsStates}
                                handleOptionsState={(column) =>
                                    handleOptionsState(column)
                                }
                            />
                            <RkTable
                                resource="project"
                                config={config}
                                data={data}
                                getData={getData}
                                loading={loading}
                                setLoading={(s) => setLoading(s)}
                                selectedRow={(r) => showProject(r)}
                                optionsStates={optionsStates}
                                filterStates={filterStates}
                                sortStates={sortStates}
                                handleFilterState={(column, value) =>
                                    handleFilterState(column, value)
                                }
                                handleFilterReset={(column) =>
                                    handleFilterReset(column)
                                }
                                handleSortState={(column, type) =>
                                    handleSortState(column, type)
                                }
                                archive={(id) => archiveProject(id)}
                                delete={(id) => deleteProject(id)}
                            />
                            <Modal
                                show={showModalDelete}
                                centered={true}
                                modalFirstText="Etes-vous certain de vouloir supprimer ce projet ?"
                                modalSecondText="Cette action est irréversible."
                                modalThirdText=""
                                modalContainerClassname="axeModalContainerClassname"
                                modalContentClassName="axeModalContentClassName"
                                closeIcon="axeModalcloseIcon cursorPointer"
                                containerTextWithIconClassname="axesContainerTextWithIconClassname"
                                alertIconClassName="axesAlertIconClassName"
                                firstBtnClassName="axeModalFirstBtn"
                                secondBtnClassName="axeModalSecondBtn"
                                setShowModalFunction={() =>
                                    setShowModalDelete(false)
                                }
                                textClassName="axeModalTextClassName"
                                saveBtnFunction={() =>
                                    deleteProject(selectedRow.id, true)
                                }
                            />
                            <Modal
                                show={showModalArchive}
                                centered={true}
                                modalFirstText="Etes-vous certain de vouloir archiver ce projet ?"
                                modalSecondText="Il disparaîtra bien de votre liste de projets par défaut,"
                                modalThirdText='mais restera accessible en filtrant sur le statut "Archivé".'
                                modalContainerClassname="axeModalContainerClassname"
                                modalContentClassName="axeModalContentClassName"
                                closeIcon="axeModalcloseIcon cursorPointer"
                                containerTextWithIconClassname="axesContainerTextWithIconClassname"
                                alertIconClassName="axesAlertIconClassName"
                                firstBtnClassName="axeModalFirstBtn"
                                secondBtnClassName="axeModalSecondBtn"
                                setShowModalFunction={() =>
                                    setShowModalArchive(false)
                                }
                                textClassName="axeModalTextClassName"
                                saveBtnFunction={() =>
                                    archiveProject(selectedRow.id, true)
                                }
                            />
                        </>
                    )}
                </>
            );
    }
}
