import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { scroller, Link as ScrollLink } from 'react-scroll';
import User from '../../util/User';
import Config from '../../util/Config';
import Echo from '../../util/Echo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
export default (props) => {
    const history = useHistory();
    const state = useSelector((state) => state.user);
    const users = state.users;
    const activeIndex = useSelector((state) => state.user.activeUserIndex);
    const activeUser = users[activeIndex];
    const [testCondition, setTestCondition] = useState('signup');
    const dispatch = useDispatch();
    const { trackEvent } = useMatomo();
    useEffect(() => {
        if (props.testCondition) {
            setTestCondition(props.testCondition);
        } else if (activeUser && activeUser.id) {
            User.headTest(activeUser.token)
                .then((res) => {
                    switch (res.status) {
                        case 403:
                            dispatch(
                                {
                                    type: 'LOGOUT',
                                    value: activeUser.id,
                                },
                                history.push('/login')
                            );
                            break;
                        case 402:
                            setTestCondition('buy');
                            break;
                        case 200:
                            setTestCondition('test');
                            break;
                        default:
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                    }
                })
                .catch((err) => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                });
        } else {
            setTestCondition('signup');
        }
    }, [activeUser?.id]);
    return (
        <div className="text-center my-5 p-2">
            <h1 className="display-1 text-black font-weight-900 w-75 mx-auto d-none d-md-block">
                {Echo(state.lang).home.title}
            </h1>
            <h1 className="display-40 text-black font-weight-900 w-100 mx-auto d-md-none d-block">
                {Echo(state.lang).home.title}
            </h1>
            <h2 className="display-5 text-black font-weight-bold w-75 mx-auto my-5 d-none d-md-block">
                {Echo(state.lang).home.sub_title}
            </h2>
            <h2 className="display-18 text-black font-weight-bold w-75 mx-auto my-5 d-md-none d-block">
                {Echo(state.lang).home.sub_title}
            </h2>
            <Row className="px-5">
                <Col></Col>
                <Col md={4} lg={3} className="p-1 col-xxl-2">
                    {testCondition === 'buy' ? (
                        <Button
                            className="btn-lg btn-primary h3 font-weight-800 display-24 w-100 my-3"
                            style={{
                                height: '5rem',
                                maxWidth: '340px',
                            }}
                            onClick={() => {
                                scroller.scrollTo('prices', {
                                    activeClass: 'active',
                                    spy: true,
                                    smooth: true,
                                    duration: 500,
                                });
                                trackEvent({
                                    category: 'subscription',
                                    action: 'scroll',
                                    name: 'Achetez',
                                    value: 1,
                                });
                            }}
                        >
                            {Echo(state.lang).button.buy}
                        </Button>
                    ) : (
                        <>
                            {testCondition === 'test' ? (
                                <Link
                                    className="mx-auto"
                                    to={'/onboarding/reset'}
                                    onClick={() => {
                                        trackEvent({
                                            category: 'subscription',
                                            action: 'go_to_onboarding',
                                            name: 'Testez gratuitement',
                                            value: 1,
                                        });
                                    }}
                                >
                                    <Button
                                        className="btn-lg btn-primary h3 font-weight-800 display-24 w-100 my-3"
                                        style={{
                                            height: '5rem',
                                            maxWidth: '340px',
                                        }}
                                    >
                                        {Echo(state.lang).button.test}
                                    </Button>
                                </Link>
                            ) : (
                                <Link
                                    className="mx-auto"
                                    to={'/signup'}
                                    onClick={() => {
                                        trackEvent({
                                            category: 'subscription',
                                            action: 'go_to_signup',
                                            name: 'Testez gratuitement',
                                            value: 0,
                                        });
                                    }}
                                >
                                    <Button
                                        className="btn-lg btn-primary h3 font-weight-800 display-24 w-100 my-3"
                                        style={{
                                            height: '5rem',
                                            maxWidth: '340px',
                                        }}
                                    >
                                        {Echo(state.lang).button.test}
                                    </Button>
                                </Link>
                            )}
                        </>
                    )}
                </Col>
                <Col md={4} lg={3} className="p-1 col-xxl-2">
                    <a
                        className="mx-auto"
                        href="https://calendly.com/ybuffet/rankio-demo"
                        target="_blank"
                        aria-label="Lien vers la réservation d'une démo"
                    >
                        <Button
                            className="btn-lg btn-outline-primary h3 font-weight-800 display-24 w-100 my-3"
                            style={{
                                height: '5rem',
                                maxWidth: '340px',
                            }}
                            onClick={() => {
                                trackEvent({
                                    category: 'demo',
                                    action: 'go_to_demo_calendar',
                                    name: 'Réservez  une démo',
                                    value: 0,
                                });
                            }}
                        >
                            {Echo(state.lang).button.discover}
                        </Button>
                    </a>
                </Col>
                <Col></Col>
            </Row>
            <Col md={10} lg={9} className="mx-auto">
                <a
                    href={Config.CDN.ENDPOINT + 'static/media/dashboard.jpg'}
                    target="_blank"
                    title={Echo(state.lang).img.dashboard_title}
                >
                    <img
                        className="card w-100 mx-auto mt-5 p-3"
                        src={Config.CDN.ENDPOINT + 'static/media/dashboard.jpg'}
                        alt={Echo(state.lang).img.dashboard_alt}
                    />
                </a>
            </Col>
        </div>
    );
};
