import { Modal, Button } from 'react-bootstrap';
import React from 'react';
import { FiX, FiAlertTriangle, FiAlertCircle } from 'react-icons/fi';
export default function ModalDetails(props) {
    const clickOkModal = () => {
        if (props.alertIcon) {
            props.addOption();
        } else {
            props.saveBtnFunction();
            props.setShowModalFunction();
        }
    };
    return (
        <Modal
            show={props.show}
            className={props.className}
            centered={props.centered}
            contentClassName={props.modalContentClassName}
            dialogClassName="delete-workspace"
        >
            <div className={props.modalContainerClassname}>
                <div
                    className={props.closeIcon}
                    onClick={() => props.setShowModalFunction()}
                >
                    <FiX size="1.5rem" className="rk-clickable-icon-dark" />
                </div>
                <div className={props.containerTextWithIconClassname}>
                    {props.alertIcon ? (
                        <div className={props.alertIconClassName}>
                            <FiAlertCircle size="5rem" color="#0055ff" />
                        </div>
                    ) : (
                        <div className={props.alertIconClassName}>
                            <FiAlertTriangle size="5rem" color="#ff2b00" />
                        </div>
                    )}
                    <div>
                        <div className={props.textClassName}>
                            {props.modalFirstText}{' '}
                        </div>
                        <div className={props.textClassName}>
                            {props.modalSecondText}
                        </div>
                        <div className={props.textClassName}>
                            {props.modalThirdText}
                        </div>
                        <div className="flex mt-4">
                            {props.info === undefined && (
                                <Button
                                    className={props.firstBtnClassName}
                                    onClick={() =>
                                        props.setShowModalFunction()
                                    }
                                >
                                    {props.firstBtnText}
                                </Button>
                            )}
                            <Button
                                className={props.secondBtnClassName}
                                onClick={() => clickOkModal()}
                            >
                                {props.secondBtnText}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
ModalDetails.defaultProps = {
    firstBtnClassName: 'axeModalFirstBtn',
    secondBtnClassName: 'axeModalSecondBtn',
    firstBtnText: 'Non',
    secondBtnText: 'Oui',
};
